import salaries from "../data/data.js";

function computeAverageArray(arr) {
  if (arr.length === 0) {
    return 0; // or `null` or `undefined`, depending on how you want to handle empty arrays
  }

  console.log("ARR IS", arr);
  const sum = arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return sum / arr.length;
}

function computeAverageSalary(arrayTitles) {
  const medianSalaries = salaries
    .filter((salaryEntry) => {
      return arrayTitles.some((title) =>
        salaryEntry.title.toLowerCase().includes(title)
      );
    })
    .map((salaryEntry) => {
      return salaryEntry.average_salary;
    });
  return computeAverageArray(medianSalaries);
}

export default computeAverageSalary;
