import LineChart from "../LineChart";
import "./Evolution.css";
import Navbar from "./NavBar";

const Evolution = () => {
  return (
    <>
      <Navbar></Navbar>
      <h3 className="title">Barcelona Tech Salary Analysis</h3>
      <LineChart className="lineChart"></LineChart>
    </>
  );
};

export default Evolution;
