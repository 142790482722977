import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import salaries from "./data/data.js";

const computeAverageMonthly = (data) => {
  const averageSalaryByMonth = [];
  const salariesGrouped = d3.group(salaries, (d) => d.date);
  for (let monthlySalary of salariesGrouped.keys()) {
    let arraySalaries = salariesGrouped.get(monthlySalary);
    arraySalaries = arraySalaries.map((salary) => salary.average_salary);
    averageSalaryByMonth.push({
      x: monthlySalary,
      y: arraySalaries.reduce((a, b) => a + b) / arraySalaries.length,
    });
  }
  return averageSalaryByMonth;
};

const LineChart = () => {
  const [windowWidth] = useState(window.innerWidth);
  const [windowHeight] = useState(window.innerHeight);

  const ref = useRef();
  const margin = { top: 25, right: 150, bottom: 150, left: 150 },
    width = windowWidth - margin.left - margin.right,
    height = windowHeight - margin.top - margin.bottom;

  useEffect(() => {
    // Set up SVG
    const svg = d3
      .select(ref.current)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    d3.select("svg").attr("width", windowWidth).attr("height", windowHeight);

    let averageSalaryByMonth = computeAverageMonthly(salaries);

    const parseTime = d3.timeParse("%B %Y");
    averageSalaryByMonth.forEach((d) => {
      d.x = parseTime(d.x);
    });

    // Set the dimensions and margins of the graph

    // Add X axis
    const x = d3
      .scaleTime()
      .domain(d3.extent(averageSalaryByMonth, (d) => d.x))
      .range([0, width]);

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(18)
          .tickFormat(function (d) {
            return d3.timeFormat("%b")(new Date(d));
          })
      )
      .selectAll(".tick text") // Selects all text elements within ticks
      .style("font-size", "24px");

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([
        d3.min(averageSalaryByMonth, (d) => d.y - 15),
        d3.max(averageSalaryByMonth, (d) => d.y + 15),
      ])
      .range([height, 0]);

    svg
      .append("g")
      .call(
        d3.axisLeft(y).tickFormat(function (d) {
          return d + "K";
        })
      )
      .selectAll(".tick text") // Selects all text elements within ticks
      .style("font-size", "24px");

    svg
      .append("path")
      .datum(averageSalaryByMonth)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 3.5)
      .attr(
        "d",
        d3
          .line()
          .x((d) => x(d.x))
          .y((d) => y(d.y))
      );

    let yMinPos = d3.min(averageSalaryByMonth, (d) => d.y);
    let yMaxPos = d3.max(averageSalaryByMonth, (d) => d.y);
    let xtechLayoffs = x(new Date("Sat Jan 01 2022 00:00:00 GMT+0100"));

    svg
      .append("line")
      .attr("x1", 0) // Line start x-coordinate
      .attr("y1", y(yMinPos)) // Line start y-coordinate
      .attr("x2", width) // Line end x-coordinate
      .attr("y2", y(yMinPos)) // Line end y-coordinate
      .attr("stroke", "black") // Line color
      .attr("stroke-dasharray", "4,5") // Line color
      .attr("stroke-width", 2); // Line thickness

    svg
      .append("line")
      .attr("x1", 0) // Line start x-coordinate
      .attr("y1", y(yMaxPos)) // Line start y-coordinate
      .attr("x2", width) // Line end x-coordinate
      .attr("y2", y(yMaxPos)) // Line end y-coordinate
      .attr("stroke", "black") // Line color
      .attr("stroke-dasharray", "4,5") // Line color
      .attr("stroke-width", 2); // Line thickness

    svg
      .append("line")
      .attr("x1", xtechLayoffs) // Line start x-coordinate
      .attr("y1", y(yMaxPos) - 50) // Line start y-coordinate
      .attr("x2", xtechLayoffs) // Line end x-coordinate
      .attr("y2", y(yMaxPos) + 50) // Line end y-coordinate
      .attr("stroke", "black") // Line color
      .attr("stroke-width", 0.5); // Line thickness

    svg
      .append("text")
      .attr("x", xtechLayoffs + 10)
      .attr("y", y(yMaxPos) - 20)
      .text("BigTech layoffs started")
      .attr("font-family", "sans-serif")
      .attr("font-size", "20px")
      .attr("fill", "black");
  });
  return <svg ref={ref} />;
};

export default LineChart;
