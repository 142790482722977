import HistogramBySalaries from "../HistogramBySalaries";
import computeAverageSalary from "../utils/regexSalary";
import "./Field.css";
import Navbar from "./NavBar";

const Field = () => {
  const dataField = [
    { profession: "Frontend", salary: computeAverageSalary(["frontend"]) },
    { profession: "Backend", salary: computeAverageSalary(["backend"]) },
    {
      profession: "FullStack",
      salary: computeAverageSalary(["fullstack", "full stack"]),
    },
    {
      profession: "DevOps",
      salary: computeAverageSalary(["devops", "reliability"]),
    },
    { profession: "QA", salary: computeAverageSalary(["qa"]) },
    {
      profession: "UX/UI Designer",
      salary: computeAverageSalary(["designer", "ux", "ui"]),
    },
    { profession: "Security", salary: computeAverageSalary(["security"]) },
    {
      profession: "Data Scientist",
      salary: computeAverageSalary(["data scientist"]),
    },
    {
      profession: "Data Engineer",
      salary: computeAverageSalary(["data engineer"]),
    },
  ];

  return (
    <>
      <Navbar></Navbar>
      <h3 className="title">Barcelona Salaries by Software Field</h3>
      <HistogramBySalaries data={dataField}></HistogramBySalaries>
      <br></br>
      <br></br>
    </>
  );
};

export default Field;
