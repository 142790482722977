import * as d3 from "d3";
import { useEffect, useRef } from "react";

const Histogram = ({ data, width = 2500, height = 400 }) => {
  const ref = useRef();
  const margin = { top: 30, right: 100, bottom: 30, left: 150 };
  const effectiveWidth = width - margin.left - margin.right;
  const effectiveHeight = height - margin.top - margin.bottom;

  useEffect(() => {
    // Set up SVG
    const svg = d3
      .select(ref.current)
      .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height}`)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set up scales
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data) + 10])
      .range([0, effectiveWidth]);

    let customTicks = [
      0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150, 250,
    ];

    // let customTicksSeparatedBy5 = [
    //   0, 10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
    //   100, 105, 110, 115, 120, 125, 130, 140, 145, 150, 155, 160, 165, 170, 176,
    //   180, 185, 190, 200, 250,
    // ];

    const bin = d3
      .bin()
      .value((d) => d)
      .domain(xScale.domain())
      .thresholds(customTicks);

    const bins = bin(data);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(bins, (d) => d.length)])
      .range([effectiveHeight, 0]);

    // Draw bars
    svg
      .selectAll("rect")
      .data(bins)
      .join("rect")
      .attr(
        "transform",
        (d) => `translate(${xScale(d.x0) + 5},${yScale(d.length)})`
      )
      .attr("width", (d) => xScale(d.x1) - xScale(d.x0) - 10)
      .attr("height", (d) => yScale(d3.max(bins, (d) => d.length) - d.length))
      .style("fill", "#69b3a2");

    const xAxis = d3.axisBottom(xScale).tickFormat(function (d) {
      return d + "K";
    });

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom - margin.top})`)
      .call(xAxis)
      .selectAll(".tick text") // Selects all text elements within ticks
      .style("font-size", "15px")
      .call((g) =>
        g
          .append("text")
          .attr("x", width - 200)
          .attr("y", margin.bottom - 100)
          .attr("fill", "currentColor")
          .attr("text-anchor", "end")
          .text("Salary in EUR")
      );
  }, [data, height, width, effectiveWidth, effectiveHeight, margin]);

  return <svg ref={ref} />;
};

export default Histogram;
