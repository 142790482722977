import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    console.log("isMobileMenuOpen", isMobileMenuOpen);
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar-wrapper">
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        <div className="bar2"></div>
        <div className="bar2"></div>
        <div className="bar2"></div>
      </div>

      <div
        className={`navbar-links ${isMobileMenuOpen ? "active" : "negative"}`}
      >
        <NavLink className="NavLink" to="/offers">
          Home / Offers
        </NavLink>
        <div className="others">
          <NavLink
            className="NavLink"
            to="/barcelona-salary-evolution-analysis"
          >
            Salary Evolution
          </NavLink>
          <NavLink
            className="NavLink"
            to="/barcelona-salary-experience-analysis"
          >
            Salary By Experience
          </NavLink>
          <NavLink
            className="NavLink"
            to="/barcelona-salary-software-field-analysis"
          >
            Salaries by Software Field
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
