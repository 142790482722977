const salaries = [
  {
    id: 2,
    title: "QA Engineer",
    company: "Belvo",
    salary: "32K - 40K EUR",
    date: "November 2020",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 3,
    title: "Technical Content Writer",
    company: "Belvo",
    salary: "35K - 45K EUR",
    date: "November 2020",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 4,
    title: "Sysadmin Windows",
    company: "CELSA Group",
    salary: "28K - 32K EUR",
    date: "November 2020",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 5,
    title: "Senior Software Engineer",
    company: "Stayforlong",
    salary: "55K - 55K EUR",
    date: "November 2020",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 6,
    title: "Machine Learning Engineer",
    company: "Typeform",
    salary: "65K - 80K EUR",
    date: "November 2020",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 7,
    title: "Backend Software Engineer",
    company: "Qonto",
    salary: "55K - 80K EUR",
    date: "November 2020",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 8,
    title: "Data Engineer",
    company: "The Hotels Network",
    salary: "30K - 45K EUR",
    date: "November 2020",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 9,
    title: "Backend PHP Developer",
    company: "Bodas.net (The Knot Worldwide)",
    salary: "28K - 45K EUR",
    date: "November 2020",
    average_salary: 36.5,
    place: "Barcelona",
  },
  {
    id: 10,
    title: "Senior Android Developer",
    company: "Bumble - Badoo",
    salary: "55K - 75K EUR + Bonuses + Benefits",
    date: "November 2020",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 11,
    title: "Principal/Senior Software Engineer (Java/Kotlin)",
    company: "Bumble - Badoo",
    salary: "55K - 75K EUR + Bonus + Benefits",
    date: "November 2020",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 12,
    title: "Full Stack Developer",
    company: "parallel.so",
    salary: "30K - 40K EUR",
    date: "November 2020",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 13,
    title: "Backend Senior",
    company: "https://www.couragium.com/",
    salary: "36K - 36K EUR",
    date: "November 2020",
    average_salary: 36,
    place: "Remote",
  },
  {
    id: 14,
    title: "Product Owner",
    company: "Devex",
    salary: "35K - 45K EUR",
    date: "November 2020",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 15,
    title: "Senior Software Engineer",
    company: "Tempus Labs",
    salary: "50K - 65K EUR",
    date: "November 2020",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 16,
    title: "Backend Software Engineer",
    company: "Lobelia",
    salary: "30K - 35K EUR",
    date: "November 2020",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 17,
    title: "Backend Developer",
    company: "Holded",
    salary: "35K - 50K EUR",
    date: "November 2020",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 18,
    title: "JS Developer (webscript)",
    company: "The Hotels Network",
    salary: "30K - 50K EUR",
    date: "November 2020",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 19,
    title: "Senior Software Engineer",
    company: "TeamCMP",
    salary: "45K - 55K EUR",
    date: "November 2020",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 20,
    title: "Senior Backend Engineer (Python/APIs)",
    company: "Flanks",
    salary: "35K - 50K EUR",
    date: "November 2020",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 21,
    title: "DevOps Engineer",
    company: "Service Layers",
    salary: "50K - 80K EUR",
    date: "November 2020",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 22,
    title: "Senior Data Engineer",
    company: "Lifull Connect",
    salary: "50K - 60K EUR",
    date: "November 2020",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 23,
    title:
      ":ruby: :ruby_on_rails: :python: :react: eCommerce Platform Team-lead (Ruby, Python, Javascript/React)",
    company: "Farmy.ch",
    salary: "75K - 90K EUR",
    date: "November 2020",
    average_salary: 82.5,
    place: "Barcelona",
  },
  {
    id: 24,
    title: "Cyber Security Engineer",
    company: "Stuart",
    salary: "30K - 55K EUR",
    date: "November 2020",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 25,
    title: "Senior Backend Developer",
    company: "Counterest",
    salary: "30K - 35K EUR",
    date: "December 2020",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 26,
    title: "Senior PHP Engineer",
    company: "Bumble Badoo",
    salary: "60K - 70K EUR",
    date: "December 2020",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 27,
    title: "Product Manager",
    company: "CoCircular",
    salary: "25K - 35K EUR",
    date: "December 2020",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 28,
    title: "Frontend Engineer (REACT)",
    company: "Xceed",
    salary: "35K - 45K EUR",
    date: "December 2020",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 29,
    title: "FrontEnd Developer",
    company: "The Hotels Network",
    salary: "30K - 40K EUR",
    date: "December 2020",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 30,
    title: "Senior Java Engineer",
    company: "Adaptive",
    salary: "40K - 60K EUR",
    date: "December 2020",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 31,
    title: "SRE/DevOps Engineer",
    company: "Adaptive",
    salary: "60K - 60K EUR",
    date: "December 2020",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 32,
    title: "Técnico de soporte de red nivel II",
    company: "AXIANS",
    salary: "24K - 26K EUR",
    date: "December 2020",
    average_salary: 25,
    place: "Barcelona",
  },
  {
    id: 33,
    title: "Back End Developer",
    company: "World Mastery",
    salary: "35K - 45K EUR",
    date: "December 2020",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 34,
    title: "Head of Data Science & Analytics",
    company: "Typeform",
    salary: "80K - 80K EUR",
    date: "December 2020",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 35,
    title: "Senior Product Data Scientist",
    company: "Typeform",
    salary: "70K - 70K EUR",
    date: "December 2020",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 36,
    title: "Senior Backend Engineer",
    company: "Vesta Healthcare",
    salary: "60K - 80K EUR",
    date: "December 2020",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 37,
    title: "DevOps Engineer",
    company: "TBSCG",
    salary: "35K - 50K EUR",
    date: "December 2020",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 38,
    title: "Cloud Engineer",
    company: "TBSCG",
    salary: "35K - 50K EUR",
    date: "December 2020",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 39,
    title: "Data Engineer",
    company: "TBSCG",
    salary: "30K - 45K EUR",
    date: "December 2020",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 40,
    title: "Test Automation Engineer",
    company: "TBSCG",
    salary: "30K - 45K EUR",
    date: "December 2020",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 41,
    title: "Frontend Developer",
    company: "TBSCG",
    salary: "30K - 45K EUR",
    date: "December 2020",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 42,
    title: "Backend Engineer (Kotlin/Spring)",
    company: "Kognia Sports Intelligence",
    salary: "35K - 45K EUR",
    date: "December 2020",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 43,
    title: "Senior Frontend Engineer",
    company: "Dynatrace",
    salary: "45K - 62K EUR",
    date: "December 2020",
    average_salary: 53.5,
    place: "Barcelona",
  },
  {
    id: 44,
    title: "Senior JavaScript Engineer",
    company: "Dynatrace",
    salary: "45K - 62K EUR",
    date: "December 2020",
    average_salary: 53.5,
    place: "Barcelona",
  },
  {
    id: 45,
    title: "Senior UX Designer",
    company: "Dynatrace",
    salary: "40K - 55K EUR",
    date: "December 2020",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 46,
    title: "Senior Software Engineer",
    company: "Stayforlong",
    salary: "40K - 55K EUR",
    date: "December 2020",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 47,
    title: "Senior FullStack Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "55K - 66K EUR",
    date: "December 2020",
    average_salary: 60.5,
    place: "Barcelona",
  },
  {
    id: 48,
    title: "JS Developer (web script)",
    company: "The Hotels Network",
    salary: "30K - 40K EUR",
    date: "December 2020",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 49,
    title: "Backend Developer (Java)",
    company: "Sportradar",
    salary: "30K - 35K EUR",
    date: "December 2020",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 50,
    title: "Senior Backend Developer (Java)",
    company: "Sportradar",
    salary: "40K - 45K EUR",
    date: "December 2020",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 51,
    title: "Network Engineer",
    company: "TBSCG",
    salary: "40K - 60K EUR",
    date: "December 2020",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 52,
    title: "Lead Software Engineer",
    company: "New Relic",
    salary: "65K - 80K EUR",
    date: "December 2020",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 53,
    title: "Senior Data Engineer",
    company: "Gartner DM",
    salary: "60K - 80K EUR",
    date: "December 2020",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 54,
    title: "Senior Backend Developer (Java)",
    company: "Aba English",
    salary: "35K - 45K EUR",
    date: "December 2020",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 55,
    title: "JS Senior Developer",
    company: "Tempus Labs",
    salary: "55K - 70K EUR",
    date: "December 2020",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 56,
    title: "Java Full Stack Engineer",
    company: "Webfleet Solutions",
    salary: "30K - 40K EUR",
    date: "December 2020",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 57,
    title: "Data Engineer",
    company: "Preply",
    salary: "45K - 65K EUR",
    date: "December 2020",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 58,
    title: "Front-End Engineer",
    company: "Preply",
    salary: "45K - 65K EUR",
    date: "December 2020",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 59,
    title: "Software Developer",
    company: "MusicTechnologyGroup",
    salary: "24K - 24K EUR",
    date: "December 2020",
    average_salary: 24,
    place: "Barcelona/Remote",
  },
  {
    id: 60,
    title: "Software Engineer",
    company: "Preply",
    salary: "45K - 65K EUR",
    date: "December 2020",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 61,
    title: "Cloud Fanatic",
    company: "Dynatrace",
    salary: "55K - 70K EUR",
    date: "December 2020",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 62,
    title: "Backend API Software Engineer",
    company: "Postman",
    salary: "90K - 90K EUR",
    date: "December 2020",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 63,
    title: "Python Developer / Data Engineer",
    company: "Nnergix",
    salary: "40K - 60K EUR",
    date: "December 2020",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 64,
    title: "Python Developer for Data Intensive Applications",
    company: "Nnergix",
    salary: "40K - 50K EUR",
    date: "December 2020",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 65,
    title: "Senior Data Scientist",
    company: "Nnergix",
    salary: "40K - 50K EUR",
    date: "December 2020",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 66,
    title: "Team Lead (Web)",
    company: "Nnergix",
    salary: "40K - 60K EUR",
    date: "December 2020",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 67,
    title: "Senior Frontend Engineer",
    company: "Streamloots",
    salary: "35K - 50K EUR",
    date: "December 2020",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 68,
    title: "Lead Frontend Developer",
    company: "Satoshis Games",
    salary: "50K - 60K EUR",
    date: "December 2020",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 69,
    title: "CTO / Head of Engineering",
    company: "Deliberry",
    salary: "60K - 75K EUR",
    date: "January 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 70,
    title: "Senior Software Engineer (Go) - 100% remote",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "January 2021",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 71,
    title: "Senior Software Engineer (Kubernetes",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "January 2021",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 72,
    title: "Data Engineer",
    company: "The Hotels Network",
    salary: "30K - 50K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 73,
    title: "Senior DevOps Engineer",
    company: "Decagames",
    salary: "40K - 40K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 74,
    title: "(+-2 years) Full Stack Developer NodeJS+VueJS",
    company: "WIRIS",
    salary: "35K - 35K EUR",
    date: "January 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 75,
    title: "Senior QA Engineer / QA Lead",
    company: "WIRIS",
    salary: "45K - 45K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 76,
    title: "Senior Full Stack Developer (JavaScript+PHP)",
    company: "WIRIS",
    salary: "45K - 45K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 77,
    title: "Senior JavaScript Engineer",
    company: "Dynatrace",
    salary: "50K - 65K EUR",
    date: "January 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 78,
    title: "Senior Angular Engineer",
    company: "Dynatrace",
    salary: "50K - 60K EUR",
    date: "January 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 79,
    title: "Senior UX Designer",
    company: "Dynatrace",
    salary: "45K - 60K EUR",
    date: "January 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 80,
    title: "Senior Product Manager",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "January 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 81,
    title: "Cloud Fanatic",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "January 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 82,
    title: "Tech Blogger",
    company: "Dynatrace",
    salary: "40K - 45K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 83,
    title: "Data Engineer",
    company: "Typeform",
    salary: "70K - 70K EUR",
    date: "January 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 84,
    title: "Nodejs developer",
    company: "Exoticca",
    salary: "35K - 45K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 85,
    title: "Senior CMS Developer",
    company: "ICTI",
    salary: "35K - 40K EUR",
    date: "January 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 86,
    title: "Android Developer (mid-senior)",
    company: "Xing",
    salary: "40K - 60K EUR",
    date: "January 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 87,
    title: "Senior Developer",
    company: "Capchase",
    salary: "50K - 65K EUR",
    date: "January 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 88,
    title: "Senior Frontend Engineer (React)",
    company: "Xing",
    salary: "40K - 70K EUR",
    date: "January 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 89,
    title: "Frontend Engineer (Vuejs, RN, Angular, React)",
    company: "Docplanner",
    salary: "37K - 60K EUR",
    date: "January 2021",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 90,
    title: "Software Engineer (.NET CORE, C#)",
    company: "Docplanner",
    salary: "37K - 55K EUR",
    date: "January 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 91,
    title: "Quality Assurance Automation Eng (Generalist)",
    company: "Docplanner",
    salary: "35K - 50K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 92,
    title: "DevOps/SRE Engineer",
    company: "Docplanner",
    salary: "45K - 75K EUR",
    date: "January 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 93,
    title: "Product/User Researcher",
    company: "Docplanner",
    salary: "25K - 38K EUR",
    date: "January 2021",
    average_salary: 31.5,
    place: "Barcelona/Remote",
  },
  {
    id: 94,
    title: "Frontend Engineer",
    company: "Xceed",
    salary: "35K - 45K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 95,
    title: "Software Engineer",
    company: "Stayforlong",
    salary: "40K - 55K EUR",
    date: "January 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 96,
    title: "Senior Software Engineer (back-end)",
    company: "Devex",
    salary: "41K - 51K EUR",
    date: "January 2021",
    average_salary: 46,
    place: "Barcelona",
  },
  {
    id: 97,
    title: "Senior Back-end Developer",
    company: "Link",
    salary: "40K - 45K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 98,
    title: "Java BE Developer",
    company: "Sportradar",
    salary: "30K - 45K EUR",
    date: "January 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 99,
    title: "Frontend Developer",
    company: "Sportradar",
    salary: "30K - 45K EUR",
    date: "January 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 100,
    title: "Senior Backend - Java/Kotlin",
    company: "Xing",
    salary: "40K - 70K EUR",
    date: "January 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 101,
    title: "Product designer",
    company: "Draftbit",
    salary: "120K - 120K EUR",
    date: "January 2021",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 102,
    title: "Frontend Engineer",
    company: "Kochava",
    salary: "35K - 50K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 103,
    title: "UX Designer",
    company: "Kochava",
    salary: "35K - 50K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 104,
    title: "Senior Mobile Engineer (Android,iOS), Local/Remote",
    company: "Futurae Technologies",
    salary: "85K - 100K EUR",
    date: "January 2021",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 105,
    title: "Senior Developer (SalesForce Commerce Cloud)",
    company: "OSF Digital",
    salary: "30K - 40K EUR",
    date: "January 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 106,
    title: "FrontEnd Developer (Mid or Senior)",
    company: "The Hotels Network",
    salary: "30K - 50K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 107,
    title: "QA Engineer",
    company: "Payflow",
    salary: "32K - 40K EUR",
    date: "January 2021",
    average_salary: 36,
    place: "Barcelona",
  },
  {
    id: 108,
    title: "Senior Rails Backend Developer",
    company: "Localistico",
    salary: "56K - 62K EUR",
    date: "January 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 109,
    title: "Senior Backend Developer",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "January 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 110,
    title: "Programador junior",
    company: "SIRT",
    salary: "20K - 22K EUR",
    date: "January 2021",
    average_salary: 21,
    place: "Barcelona",
  },
  {
    id: 111,
    title: "Junior DevOps Engineer",
    company: "Signaturit",
    salary: "35K - 46K EUR",
    date: "January 2021",
    average_salary: 40.5,
    place: "Barcelona/Remote",
  },
  {
    id: 112,
    title: "Product Manager",
    company: "WIRIS",
    salary: "40K - 40K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 113,
    title: "Backend developer (PHP)",
    company: "parkimeter.com",
    salary: "35K - 50K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 114,
    title: "Backend Engineer (100% Remote)",
    company: "Streamloots",
    salary: "30K - 50K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 115,
    title: "Software Engineer (Front/React)",
    company: "BigBlueAnalytics",
    salary: "40K - 50K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 116,
    title: "Software Engineer (Backend/Python)",
    company: "BigBlueAnalytics",
    salary: "40K - 50K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 117,
    title: "Jira and Confluence Expert",
    company: "Dynatrace",
    salary: "45K - 60K EUR",
    date: "January 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 118,
    title: "Lead Platform Enablement",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "January 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 119,
    title: "Tech Lead",
    company: "Holded",
    salary: "50K - 60K EUR",
    date: "January 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 120,
    title: "Senior Backend Developer",
    company: "Holded",
    salary: "40K - 50K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 121,
    title: "Senior Frontend Developer",
    company: "Codi Cooperatiu",
    salary: "22K - 22K EUR",
    date: "January 2021",
    average_salary: 22,
    place: "Remote",
  },
  {
    id: 122,
    title: "Intermediate Front-end Developer",
    company: "caravelo",
    salary: "30K - 40K EUR",
    date: "January 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 123,
    title: "Magento 2 full stack developer",
    company: "Omitsis Consulting",
    salary: "26K - 36K EUR",
    date: "January 2021",
    average_salary: 31,
    place: "Barcelona/Remote",
  },
  {
    id: 124,
    title: "PHP Developer",
    company: "iTalenters",
    salary: "35K - 50K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 125,
    title: "iOS or Android developer",
    company: "iTalenters",
    salary: "35K - 50K EUR",
    date: "January 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 126,
    title: "Backend Developer (PHP)",
    company: "Mailtrack.io",
    salary: "45K - 55K EUR",
    date: "January 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 127,
    title: "Project Manager",
    company: "MarsBased",
    salary: "35K - 42K EUR",
    date: "January 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 128,
    title: "Senior Backend Developer (PHP & Symfony)",
    company: "Deliberry",
    salary: "35K - 45K EUR",
    date: "January 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 129,
    title: "Software Developer",
    company: "Lifull Connect",
    salary: "40K - 60K EUR",
    date: "January 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 130,
    title: ":rocket: Vue.js Engineer :rocket:",
    company: "Kochava",
    salary: "40K - 55K EUR",
    date: "January 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 131,
    title: "Frontend Software Engineer",
    company: "Codely",
    salary: "40K - 50K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 132,
    title: "Backend Software Engineer",
    company: "Codely",
    salary: "40K - 50K EUR",
    date: "January 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 133,
    title: "Software Engineers (Mobile-Backend-Web Frontend)",
    company: "Mobile Jazz",
    salary: "40K - 60K EUR",
    date: "January 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 134,
    title: "Senior Backend Engineer",
    company: "Picker",
    salary: "40K - 60K EUR",
    date: "January 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 135,
    title: "Software engineer (Typescript/React)",
    company: "Kognia Sports",
    salary: "25K - 40K EUR",
    date: "January 2021",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 136,
    title: "DevOps + Team Security Intern",
    company: "Yova AG",
    salary: "30K - 35K EUR",
    date: "January 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 137,
    title: "Product Manager - Apps & Microservices",
    company: "Dynatrace",
    salary: "55K - 70K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Remote (COVID)",
  },
  {
    id: 138,
    title: "Senior Software Engineer",
    company: "TravelPerk",
    salary: "55K - 75K EUR",
    date: "February 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 139,
    title: "Product Manager - Solution Experience",
    company: "Dynatrace",
    salary: "55K - 70K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Remote (COVID)",
  },
  {
    id: 140,
    title: "Senior Product Designer",
    company: "Dynatrace",
    salary: "45K - 60K EUR",
    date: "February 2021",
    average_salary: 52.5,
    place: "Remote (COVID)",
  },
  {
    id: 141,
    title: "Senior Backend Engineer (Scala or Kotlin)",
    company: "Adevinta",
    salary: "60K - 60K EUR",
    date: "February 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 142,
    title: "React Developer",
    company: "Gartner (GetApp)",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 143,
    title: "Full Stack Engineer",
    company: "Suntransfers.com",
    salary: "35K - 45K EUR",
    date: "February 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 144,
    title: "Senior Backend Developer (.NET)",
    company: "Yuki",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 145,
    title: "Frontend Developer (Vue.js)",
    company: "Yuki",
    salary: "30K - 50K EUR",
    date: "February 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 146,
    title: "Programador Senior (Unity)",
    company: "Bliss Games",
    salary: "25K - 35K EUR",
    date: "February 2021",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 147,
    title: "Mid front-end",
    company: "CoCircular",
    salary: "30K - 30K EUR",
    date: "February 2021",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 148,
    title: "Senior Back-end Developer (RoR)",
    company: "Amenitiz",
    salary: "45K - 55K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 149,
    title: "Senior Front-End Developer",
    company: "Amenitiz",
    salary: "40K - 55K EUR",
    date: "February 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 150,
    title: "Technical Support Engineer",
    company: "Signaturit",
    salary: "30K - 40K EUR",
    date: "February 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 151,
    title: "Scrum Master / Agile Master",
    company: "XpertAI",
    salary: "43K - 43K EUR",
    date: "February 2021",
    average_salary: 43,
    place: "Barcelona/Remote",
  },
  {
    id: 152,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "100K - 100K EUR",
    date: "February 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 153,
    title: "C++ Rockstar Engineer",
    company: "Wallbox",
    salary: "60K - 75K EUR",
    date: "February 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 154,
    title: "Senior PHP Backend Developer",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 155,
    title: "(Mid/Senior) Android developer",
    company: "Xing",
    salary: "40K - 60K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 156,
    title: "Full Stack Engineer",
    company: "Suntransfers.com",
    salary: "35K - 45K EUR",
    date: "February 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 157,
    title: "React Frontent Developer",
    company: "Sparta",
    salary: "35K - 45K EUR",
    date: "February 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 158,
    title: "MID PHO Developer",
    company: "The Hotels Network",
    salary: "30K - 40K EUR",
    date: "February 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 159,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner (Capterra)",
    salary: "60K - 65K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 160,
    title: "Senior Python Developer (Data Team)",
    company: "jobandtalent",
    salary: "55K - 70K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 161,
    title: "Senior Back-end Developer",
    company: "Holaluz",
    salary: "42K - 47K EUR",
    date: "February 2021",
    average_salary: 44.5,
    place: "Barcelona",
  },
  {
    id: 162,
    title: "DevOps Engineer",
    company: "Vualto",
    salary: "30K - 40K EUR",
    date: "February 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 163,
    title: "Tech Lead / DevOps",
    company: "Hector Kitchen",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 164,
    title: "FullStack Developer",
    company: "Hector Kitchen",
    salary: "25K - 25K EUR",
    date: "February 2021",
    average_salary: 25,
    place: "Barcelona/Remote",
  },
  {
    id: 165,
    title: "Mid/Senior Android Developer",
    company: "Future Workshops",
    salary: "45K - 55K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 166,
    title: "Frontend Engineer",
    company: "LoveToKnow Media",
    salary: "45K - 55K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 167,
    title: "Senior JavaScript Developer",
    company: "Nicecactus",
    salary: "50K - 60K EUR",
    date: "February 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 168,
    title: "Senior Backend Developer",
    company: "LoveToKnow Media",
    salary: "55K - 60K EUR",
    date: "February 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 169,
    title: "Backend Software Engineer",
    company: "StockAgile",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 170,
    title: "Site Reliability Engineer (mid-senior)",
    company: "N26",
    salary: "40K - 75K EUR",
    date: "February 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 171,
    title: "Software Engineer",
    company: "Devex",
    salary: "36K - 46K EUR",
    date: "February 2021",
    average_salary: 41,
    place: "Barcelona",
  },
  {
    id: 172,
    title: "Junior Frontend Developer",
    company: "Holded",
    salary: "25K - 30K EUR",
    date: "February 2021",
    average_salary: 27.5,
    place: "Barcelona",
  },
  {
    id: 173,
    title: "Senior Data Quality Engineer",
    company: "Auth0",
    salary: "65K - 80K EUR",
    date: "February 2021",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 174,
    title: "Senior Security Engineer, Vulnerability Management",
    company: "Auth0",
    salary: "85K - 85K EUR",
    date: "February 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 175,
    title: "Software Engineer",
    company: "Flywire",
    salary: "30K - 45K EUR",
    date: "February 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 176,
    title: "Senior Software Engineer",
    company: "Flywire",
    salary: "45K - 65K EUR",
    date: "February 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 177,
    title: "Senior Backend Software Engineer (Ruby)",
    company: "Gartner (GetApp)",
    salary: "60K - 65K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 178,
    title: "Frontend Software Engineer (React)",
    company: "Gartner (GetApp)",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 179,
    title: "Senior Backend Software Engineer (Elixir)",
    company: "Gartner Digital",
    salary: "60K - 65K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 180,
    title: "Full Stack Software Engineer (Ruby/React)",
    company: "Gartner (Capterra)",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 181,
    title: "Prestashop Developer",
    company: "Tot-em",
    salary: "35K - 40K EUR",
    date: "February 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 182,
    title: "Senior Software Engineer",
    company: "N26",
    salary: "50K - 75K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 183,
    title: "PHP Backend (Llinars del Vallés)",
    company: "Carethy",
    salary: "30K - 30K EUR",
    date: "February 2021",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 184,
    title: "Sr. Database Reliability Engineer",
    company: "Zapier",
    salary: "90K - 90K EUR",
    date: "February 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 185,
    title: "Senior Backend NodeJS",
    company: "Wallbox",
    salary: "50K - 55K EUR",
    date: "February 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 186,
    title: "Backend Rust",
    company: "Couragium Solutions",
    salary: "30K - 36K EUR",
    date: "February 2021",
    average_salary: 33,
    place: "Remote",
  },
  {
    id: 187,
    title: "Database Developer",
    company: "IOMED",
    salary: "40K - 50K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 188,
    title: "React Native Engineers",
    company: "Preply",
    salary: "50K - 75K EUR",
    date: "February 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 189,
    title: "Site Reliability Engineer (Platform Tribe)",
    company: "Preply",
    salary: "50K - 85K EUR",
    date: "February 2021",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 190,
    title: "Senior Software Engineer",
    company: "Edpuzzle",
    salary: "55K - 66K EUR",
    date: "February 2021",
    average_salary: 60.5,
    place: "Barcelona",
  },
  {
    id: 191,
    title: "Junior Systems Engineer",
    company: "The Hotels Network",
    salary: "22K - 25K EUR",
    date: "February 2021",
    average_salary: 23.5,
    place: "Barcelona/Remote",
  },
  {
    id: 192,
    title: "Full-Stack Software Engineer",
    company: "Avrios",
    salary: "55K - 85K EUR",
    date: "February 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 193,
    title: "Senior Python Developer",
    company: "Seez App",
    salary: "50K - 80K EUR",
    date: "February 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 194,
    title: "Self-driven ReactJS Developer",
    company: "Seez App",
    salary: "45K - 60K EUR",
    date: "February 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 195,
    title: "Sr Frontend Engineer-Angular (React in mid term)",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "February 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 196,
    title: "Sr Product Designer (data visualization)",
    company: "Dynatrace",
    salary: "40K - 60K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Remote (COVID)",
  },
  {
    id: 197,
    title: "Senior Engineer, Foundations",
    company: "Auth0",
    salary: "80K - 85K EUR",
    date: "February 2021",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 198,
    title: "Senior Software Engineer, Security Enablement",
    company: "Auth0",
    salary: "85K - 90K EUR",
    date: "February 2021",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 199,
    title: "Backend developer",
    company: "Deliberry",
    salary: "35K - 45K EUR",
    date: "February 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 200,
    title: "Python Developer",
    company: "Red Points",
    salary: "40K - 60K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 201,
    title: "Data DevOps",
    company: "Typeform",
    salary: "75K - 75K EUR",
    date: "February 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 202,
    title: "QA Manager",
    company: "Typeform",
    salary: "60K - 60K EUR",
    date: "February 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 203,
    title: "Frontend Engineer",
    company: "Vintra.io",
    salary: "50K - 60K EUR",
    date: "February 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 204,
    title: "Sr iOS Developer (Perm/ Freelancer)",
    company: "XpertAI",
    salary: "50K - 50K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 205,
    title: "SRE I DevOps",
    company: "Portfolio Stock",
    salary: "40K - 40K EUR",
    date: "February 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 206,
    title: "Front-End Developer Junior/Mid-Level",
    company: "Ulabox",
    salary: "25K - 40K EUR",
    date: "February 2021",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 207,
    title: "Front-End Developer Senior",
    company: "Ulabox",
    salary: "45K - 55K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 208,
    title: "Senior Front-end Developer",
    company: "Elements",
    salary: "40K - 48K EUR",
    date: "February 2021",
    average_salary: 44,
    place: "Barcelona",
  },
  {
    id: 209,
    title: "React Native Developer",
    company: "Elements",
    salary: "39K - 39K EUR",
    date: "February 2021",
    average_salary: 39,
    place: "Barcelona",
  },
  {
    id: 210,
    title: "DEVOPS ENGINEER",
    company: "Red Points",
    salary: "45K - 50K EUR",
    date: "February 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 211,
    title: "Python Developer",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "February 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 212,
    title: "Product Support Engineer",
    company: "Red Points",
    salary: "26K - 26K EUR",
    date: "February 2021",
    average_salary: 26,
    place: "Barcelona",
  },
  {
    id: 213,
    title: "Technical Support Team Leader",
    company: "Red Points",
    salary: "35K - 40K EUR",
    date: "February 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 214,
    title: "Backend Team Leader",
    company: "Red Points",
    salary: "65K - 65K EUR",
    date: "February 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 215,
    title: "Senior Frontend Engineer Lead",
    company: "Bulevip",
    salary: "35K - 50K EUR",
    date: "February 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 216,
    title: "Javascript Developer",
    company: "Mailtrack.io",
    salary: "45K - 55K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 217,
    title: "Senior Backend Developer",
    company: "Voodoo.io",
    salary: "80K - 80K EUR",
    date: "February 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 218,
    title: "Senior Backend Developer",
    company: "i-surance AG",
    salary: "45K - 45K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 219,
    title: "Senior Software Developer",
    company: "Innovamat",
    salary: "45K - 45K EUR",
    date: "February 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 220,
    title: "Backend Developer",
    company: "Hivency",
    salary: "40K - 60K EUR",
    date: "February 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 221,
    title: "Senior IT Support Engineer",
    company: "Belvo",
    salary: "35K - 45K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 222,
    title: "oftware Engineer",
    company: "e.pilot GmbH",
    salary: "55K - 85K EUR",
    date: "March 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 223,
    title: "Backend Engineer (Python/APIs)",
    company: "Belvo",
    salary: "35K - 50K EUR",
    date: "March 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 224,
    title: "Scraping Engineer",
    company: "Belvo",
    salary: "35K - 50K EUR",
    date: "March 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 225,
    title: "Backend Engineer",
    company: "Arbolus",
    salary: "30K - 40K EUR",
    date: "March 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 226,
    title: "Senior Backend Engineer",
    company: "Arbolus",
    salary: "40K - 50K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 227,
    title: "Frontend Engineer",
    company: "Arbolus",
    salary: "30K - 40K EUR",
    date: "March 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 228,
    title: "Senior Frontend Engineer",
    company: "Arbolus",
    salary: "40K - 50K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 229,
    title: "Engineering Manager",
    company: "Quipu",
    salary: "50K - 70K EUR",
    date: "March 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 230,
    title: "React Developer",
    company: "Gartner (GetApp)",
    salary: "40K - 50K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Remote (COVID)",
  },
  {
    id: 231,
    title: "Unity Developer",
    company: "LoveToKnow Media",
    salary: "45K - 55K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Remote (COVID)",
  },
  {
    id: 232,
    title: "Angular Engineer",
    company: "Seqera Labs",
    salary: "40K - 60K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 233,
    title: "PHP Backend Team Leader",
    company: "First Talent",
    salary: "45K - 50K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 234,
    title: "Data Scientist Technical Lead",
    company: "Typeform",
    salary: "85K - 85K EUR",
    date: "March 2021",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 235,
    title: "Senior Software Engineer (Java)",
    company: "Seqera Labs",
    salary: "55K - 70K EUR",
    date: "March 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 236,
    title: "Android Engineer",
    company: "TravelPerk",
    salary: "45K - 70K EUR",
    date: "March 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 237,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "March 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 238,
    title: "React Native Developer",
    company: "Docplanner",
    salary: "37K - 55K EUR",
    date: "March 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 239,
    title: "Senior Ruby on Rails Full-stack Developer",
    company: "MarsBased",
    salary: "35K - 42K EUR",
    date: "March 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 240,
    title: "Senior DevOps Engineer",
    company: "Gartner (GetApp)",
    salary: "60K - 70K EUR",
    date: "March 2021",
    average_salary: 65,
    place: "Remote (COVID)",
  },
  {
    id: 241,
    title: "Senior FullStack Developer (Ruby on Rails + React)",
    company: "Factorial",
    salary: "50K - 65K EUR",
    date: "March 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 242,
    title: "iOS developer",
    company: "First Talent",
    salary: "35K - 40K EUR",
    date: "March 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 243,
    title: "Junior Systems Engineer",
    company: "The Hotels Network",
    salary: "22K - 25K EUR",
    date: "March 2021",
    average_salary: 23.5,
    place: "Barcelona/Remote",
  },
  {
    id: 244,
    title: "Technical Support Specialist",
    company: "FIrst Talent",
    salary: "25K - 30K EUR",
    date: "March 2021",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 245,
    title: "Senior Backend Java Engineer",
    company: "Revolut",
    salary: "70K - 70K EUR",
    date: "March 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 246,
    title: "Senior Backend Developer",
    company: "Voodoo.io",
    salary: "60K - 80K EUR",
    date: "March 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 247,
    title: "Back-end Developer (RoR)",
    company: "Amenitiz",
    salary: "35K - 42K EUR",
    date: "March 2021",
    average_salary: 38.5,
    place: "Barcelona",
  },
  {
    id: 248,
    title: "Senior Lead Backend Engineer",
    company: "Revolut",
    salary: "70K - 90K EUR",
    date: "March 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 249,
    title: "System Administrator",
    company: "Kodify",
    salary: "50K - 60K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 250,
    title: "Junior Crawler Analyst",
    company: "Red Points",
    salary: "20K - 23K EUR",
    date: "March 2021",
    average_salary: 21.5,
    place: "Barcelona/Remote",
  },
  {
    id: 251,
    title: "Team Leader - tech support team",
    company: "Red Points",
    salary: "35K - 40K EUR",
    date: "March 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 252,
    title: "Backend Manager",
    company: "Red Points",
    salary: "65K - 65K EUR",
    date: "March 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 253,
    title: "FullStack Developer (stack agnostic)",
    company: "Codurance",
    salary: "55K - 55K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 254,
    title: "Site Reliability Engineer",
    company: "Flywire",
    salary: "30K - 45K EUR",
    date: "March 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 255,
    title: "Senior Site Reliability Engineer",
    company: "Flywire",
    salary: "45K - 65K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 256,
    title: "Senior Backend Engineer",
    company: "Typeform",
    salary: "55K - 60K EUR",
    date: "March 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 257,
    title: "Senior Fullstack Engineer (Angular, React, Vue...)",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 258,
    title: "DevOps Engineer",
    company: "XpertAI",
    salary: "50K - 50K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 259,
    title: "DevOps Specialist",
    company: "Sportradar",
    salary: "50K - 60K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 260,
    title: "Head of Product FullStack",
    company: "Assembler School",
    salary: "40K - 50K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 261,
    title: "Site Reliability Engineer",
    company: "Sketch",
    salary: "65K - 85K EUR",
    date: "March 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 262,
    title: "Backend Engineer (Go)",
    company: "Ritmo",
    salary: "35K - 45K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 263,
    title: "Senior Frontend Engineer",
    company: "Ulabox",
    salary: "40K - 55K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 264,
    title: "Engineering Team Lead",
    company: "e.pilot GmbH,",
    salary: "70K - 100K EUR",
    date: "March 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 265,
    title: "Tech Lead",
    company: "Salvus Health",
    salary: "45K - 60K EUR",
    date: "March 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 266,
    title: "Lead Frontend Engineer",
    company: "Streamloots",
    salary: "35K - 55K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 267,
    title: "Lead Backend Engineer",
    company: "Streamloots",
    salary: "30K - 50K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 268,
    title: "Mobile Engineer (Android)",
    company: "Meeting Lawyers",
    salary: "30K - 45K EUR",
    date: "March 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 269,
    title: "Mobile Engineer (iOS)",
    company: "Meeting Lawyers",
    salary: "30K - 45K EUR",
    date: "March 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 270,
    title: "Backend Engineer (PHP)",
    company: "Meeting Lawyers",
    salary: "30K - 42K EUR",
    date: "March 2021",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 271,
    title: "Python Developer (Backend)",
    company: "Bamboo Energy",
    salary: "25K - 35K EUR",
    date: "March 2021",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 272,
    title: "ML Engineer",
    company: "Adevinta",
    salary: "50K - 65K EUR",
    date: "March 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 273,
    title: "Backend Manager",
    company: "Red Points",
    salary: "60K - 65K EUR",
    date: "March 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 274,
    title: "Site Reliability Engineer (SRE)",
    company: "Audiense",
    salary: "30K - 60K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 275,
    title: "Data Engineer",
    company: "Clarity.ai",
    salary: "60K - 60K EUR",
    date: "March 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 276,
    title: "Data Engineer",
    company: "Stayforlong",
    salary: "40K - 50K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 277,
    title: "Administrador de red",
    company: "SIRT",
    salary: "30K - 35K EUR",
    date: "March 2021",
    average_salary: 32.5,
    place: "Remote (COVID)",
  },
  {
    id: 278,
    title: "Senior Software Engineer",
    company: "StreamSets",
    salary: "45K - 65K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 279,
    title: "Technical Project Manager",
    company: "LoveToKnow Media",
    salary: "45K - 50K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 280,
    title: "Full stack developer",
    company: "Goufone",
    salary: "35K - 45K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 281,
    title: "Fullstack developer",
    company: "Parallel",
    salary: "35K - 45K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 282,
    title: ".NET Developer",
    company: "SportRadar",
    salary: "30K - 44K EUR",
    date: "March 2021",
    average_salary: 37,
    place: "Remote",
  },
  {
    id: 283,
    title: "Frontend Developer",
    company: "Modo Studio",
    salary: "30K - 40K EUR",
    date: "March 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 284,
    title: "Android Developer",
    company: "Modo Studio",
    salary: "30K - 40K EUR",
    date: "March 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 285,
    title: "Senior Software Engineer - Frontend",
    company: "Vesta",
    salary: "60K - 80K EUR",
    date: "March 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 286,
    title: "Software Engineer",
    company: "Bling",
    salary: "40K - 60K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 287,
    title: "DevOps",
    company: "Bling",
    salary: "40K - 70K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 288,
    title: "Senior Infra Engineer",
    company: "Andjaro",
    salary: "60K - 95K EUR",
    date: "March 2021",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 289,
    title: "Full Stack Engineer",
    company: "Andjaro",
    salary: "50K - 80K EUR",
    date: "March 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 290,
    title: "Back-End Engineer",
    company: "Andjaro",
    salary: "50K - 80K EUR",
    date: "March 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 291,
    title: "Lead Data Engineer",
    company: "Preply",
    salary: "60K - 85K EUR",
    date: "March 2021",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 292,
    title: "Senior Python backend developer",
    company: "Flanks",
    salary: "30K - 45K EUR",
    date: "March 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 293,
    title: "Junior Software Engineer (No experience required)",
    company: "Mitek Systems",
    salary: "24K - 28K EUR + Bonus",
    date: "March 2021",
    average_salary: 26,
    place: "Barcelona/Remote",
  },
  {
    id: 294,
    title: "[Senior] QA Automation (Web & Mobile)",
    company: "Xing",
    salary: "35K - 57K EUR",
    date: "March 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 295,
    title: "Head of Machine Learning",
    company: "Typeform",
    salary: "90K - 90K EUR",
    date: "March 2021",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 296,
    title: "Full Stack Ninja",
    company: "Meetaitech",
    salary: "35K - 45K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 297,
    title: "Software Engineer",
    company: "SAPonAWS",
    salary: "50K - 50K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 298,
    title: "Xamarin .NET Developer",
    company: "Apiumhub",
    salary: "35K - 50K EUR",
    date: "March 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 299,
    title: "[Senior] FE engineer (React)",
    company: "Xing",
    salary: "35K - 57K EUR",
    date: "March 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 300,
    title: "[Senior] BE engineer (Java/Kotlin, Ruby On Rails)",
    company: "Xing",
    salary: "40K - 60K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 301,
    title: "DevOps Engineer !!!",
    company: "Red Points",
    salary: "45K - 50K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 302,
    title: "Senior Fullstack Developer (React Native)",
    company: "Factorial HR",
    salary: "50K - 60K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 303,
    title: "Frontend Developer",
    company: "Reby Rides",
    salary: "30K - 40K EUR",
    date: "March 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 304,
    title: "Fullstack developer",
    company: "Parallel",
    salary: "35K - 45K EUR",
    date: "March 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 305,
    title: "Internal Product Manager (CMS)",
    company: "LoveToKnow Media",
    salary: "45K - 50K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 306,
    title: "IT Recruiter",
    company: "Trentia",
    salary: "18K - 27K EUR",
    date: "March 2021",
    average_salary: 22.5,
    place: "Remote (COVID)",
  },
  {
    id: 307,
    title: "Senior Frontend Developer",
    company: "Holded",
    salary: "40K - 50K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 308,
    title: "Lead Data Analyst",
    company: "XpertAI",
    salary: "45K - 45K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 309,
    title: "Lead Backend Engineer",
    company: "Streamloots",
    salary: "50K - 60K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 310,
    title: "Lead Frontend Engineer",
    company: "Streamloots",
    salary: "50K - 60K EUR",
    date: "March 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 311,
    title: "iOS Tech Lead",
    company: "XpertAI",
    salary: "45K - 50K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 312,
    title: "Sr iOS Developer",
    company: "XpertAI",
    salary: "45K - 45K EUR",
    date: "March 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 313,
    title: "Senior QA Engineer",
    company: "LoveToKnow Media",
    salary: "45K - 50K EUR",
    date: "March 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 314,
    title: "Software Engineer",
    company: "Linke IT",
    salary: "50K - 50K EUR",
    date: "March 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 315,
    title: "Software Engineer Java",
    company: "CoreMedia GmbH",
    salary: "40K - 56K EUR",
    date: "March 2021",
    average_salary: 48,
    place: "Remote",
  },
  {
    id: 316,
    title: "DevOps Specialist",
    company: "SportRadar",
    salary: "50K - 65K EUR",
    date: "March 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 317,
    title: "Frontend Engineer",
    company: "Signaturit",
    salary: "35K - 50K EUR",
    date: "March 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 318,
    title: "Mobile Software Engineer (Mid/Sr)",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "April 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 319,
    title: "PHP Backend Engineer (Freelance- 6 months)",
    company: "LoveToKnow Media",
    salary: "55K - 55K EUR",
    date: "April 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 320,
    title: "Junior Data Engineer",
    company: "Signaturit",
    salary: "35K - 35K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 321,
    title: "Front-end Developer",
    company: "Spathios",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 322,
    title: "Senior Product Manager – Apps and Microservices",
    company: "Dynatrace",
    salary: "55K - 75K EUR",
    date: "April 2021",
    average_salary: 65,
    place: "Remote (COVID)",
  },
  {
    id: 323,
    title: "PHP Backend Software Engineer",
    company: "Colvin",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 324,
    title: "Junior FullStack Developer",
    company: "The Hotels Network",
    salary: "24K - 32K EUR",
    date: "April 2021",
    average_salary: 28,
    place: "Barcelona/Remote",
  },
  {
    id: 325,
    title: "Senior Backend Developer",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 326,
    title: "Mid Backend Developer",
    company: "The Hotels Network",
    salary: "35K - 45K EUR",
    date: "April 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 327,
    title: "Senior Frontend Developer",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 328,
    title: "Mid Frontend Developer",
    company: "The Hotels Network",
    salary: "35K - 45K EUR",
    date: "April 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 329,
    title: "React Developer",
    company: "Gartner (GetApp)",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 330,
    title: "Software Engineer (Python)",
    company: "Preply (edTech)",
    salary: "50K - 75K EUR",
    date: "April 2021",
    average_salary: 62.5,
    place: "Remote (COVID)",
  },
  {
    id: 331,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "April 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 332,
    title: "Frontend Developer",
    company: "Modo Studio",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 333,
    title: "Senior Frontend Developer (React Native)",
    company: "Nannyfy",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 334,
    title: "https://www.linkedin.com/jobs/view/2486049630/",
    company: "Nannyfy",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 335,
    title: "Senior PHP Developer",
    company: "Nannyfy",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 336,
    title: "Frontend Engineers",
    company: "AptoPayments",
    salary: "45K - 75K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 337,
    title: "Backend Engineer",
    company: "Signaturit",
    salary: "40K - 55K EUR",
    date: "April 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 338,
    title: "PHP Freelancer Contractor (6 months project)",
    company: "LoveToKnow Media",
    salary: "24K - 24K EUR",
    date: "April 2021",
    average_salary: 24,
    place: "Remote",
  },
  {
    id: 339,
    title: "Infrastructure Engineering Director",
    company: "Factorial",
    salary: "65K - 85K EUR",
    date: "April 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 340,
    title: "Engineering Director",
    company: "Factorial",
    salary: "65K - 85K EUR",
    date: "April 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 341,
    title: "Lead Full Stack Software Engineer (.NET)",
    company: "Gartner_DataInsights",
    salary: "70K - 80K EUR",
    date: "April 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 342,
    title: "Senior Ruby on Rails Backend developer(m/f/d)",
    company: "OpenProject GmbH",
    salary: "70K - 100K EUR",
    date: "April 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 343,
    title: "DevOps Junior",
    company: "Couragium",
    salary: "30K - 36K EUR",
    date: "April 2021",
    average_salary: 33,
    place: "Remote",
  },
  {
    id: 344,
    title: "Frontend Engineer (ReactJS)",
    company: "Arbolus",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 345,
    title: "Backend Engineer (.NET)",
    company: "Arbolus",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 346,
    title: "DevOps",
    company: "Plazah",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 347,
    title: "Backend Engineer",
    company: "Plazah",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 348,
    title: "DevOps Engineer",
    company: "Adevinta",
    salary: "45K - 70K + Bonus",
    date: "April 2021",
    average_salary: 0,
    place: "Barcelona",
  },
  {
    id: 349,
    title: "Senior Software Engineer",
    company: "DataDog",
    salary: "72K - 88K EUR",
    date: "April 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 350,
    title: "Senior Backend Developer",
    company: "Picker",
    salary: "50K - 65K EUR",
    date: "April 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 351,
    title: "Senior Frontend Software Engineer",
    company: "Gartner (INTL)",
    salary: "65K - 75K EUR",
    date: "April 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 352,
    title: "Senior Fullstack Engineer",
    company: "CRIPTAN",
    salary: "44K - 55K EUR",
    date: "April 2021",
    average_salary: 49.5,
    place: "Remote",
  },
  {
    id: 353,
    title: "Mid Fullstack Engineer",
    company: "CRIPTAN",
    salary: "38K - 44K EUR",
    date: "April 2021",
    average_salary: 41,
    place: "Remote",
  },
  {
    id: 354,
    title: "Mid Backend Engineer",
    company: "CRIPTAN",
    salary: "38K - 44K EUR",
    date: "April 2021",
    average_salary: 41,
    place: "Remote",
  },
  {
    id: 355,
    title: "Sr DevOps Engineer",
    company: "Xcelirate",
    salary: "45K - 50K EUR",
    date: "April 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 356,
    title: "Sr Frontend Developer - React.js",
    company: "Xcelirate",
    salary: "45K - 55K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 357,
    title: "PHP Developer",
    company: "Wiris",
    salary: "32K - 38K + Bonus",
    date: "April 2021",
    average_salary: 0,
    place: "Barcelona",
  },
  {
    id: 358,
    title: "Senior Software Engineer Tech Lead",
    company: "humanITcare",
    salary: "55K - 65K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 359,
    title: "Fullstack developer",
    company: "Parallel",
    salary: "35K - 45K EUR",
    date: "April 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 360,
    title: "Software Engineer",
    company: "Plytix",
    salary: "35K - 45K EUR",
    date: "April 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 361,
    title: "DevOps/SRE Engineer",
    company: "Docplanner",
    salary: "35K - 75K EUR",
    date: "April 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 362,
    title: "Software Engineer – Back End - Python/AWS",
    company: "WeGetFinancing",
    salary: "40K - 55K EUR",
    date: "April 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 363,
    title: "Sr Javascript Engineer - Web Browsers or NodeJS",
    company: "Dynatrace",
    salary: "50K - 65K EUR",
    date: "April 2021",
    average_salary: 57.5,
    place: "Remote (COVID)",
  },
  {
    id: 364,
    title: "Senior PHP Developer",
    company: "Autoescuelas SOY18",
    salary: "35K - 48K EUR",
    date: "April 2021",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 365,
    title: "Backend Engineer (Golang)",
    company: "mytheresa.com",
    salary: "30K - 57K EUR",
    date: "April 2021",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 366,
    title: "Senior Javascript Developer",
    company: "Nicecactus",
    salary: "50K - 65K EUR",
    date: "April 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 367,
    title: "Backend Engineer (PHP)",
    company: "mytheresa.com",
    salary: "30K - 57K EUR",
    date: "April 2021",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 368,
    title: "Fullstack Engineer",
    company: "Plumriver",
    salary: "40K - 60K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 369,
    title: "Senior Software Engineer",
    company: "Typeform",
    salary: "60K - 60K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 370,
    title: "Senior Back End Engineer",
    company: "BYHOURS",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 371,
    title: "Junior Full Stack Developer",
    company: "BYHOURS",
    salary: "20K - 25K EUR",
    date: "April 2021",
    average_salary: 22.5,
    place: "Barcelona/Remote",
  },
  {
    id: 372,
    title: "React Software Engineer",
    company: "Plumriver",
    salary: "40K - 60K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 373,
    title: "Sr. Site Reliability Engineer",
    company: "Zapier",
    salary: "80K - 110K EUR",
    date: "April 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 374,
    title: "Lead Site Reliability Engineer",
    company: "Zapier",
    salary: "90K - 110K EUR",
    date: "April 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 375,
    title: "GO Developer",
    company: "The Hotels Network",
    salary: "35K - 55K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 376,
    title: "Backend Engineer",
    company: "Wallapop",
    salary: "35K - 70K EUR",
    date: "April 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 377,
    title: "Frontend Engineer",
    company: "Wallapop",
    salary: "30K - 55K EUR",
    date: "April 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 378,
    title: "DevOps",
    company: "Wallapop",
    salary: "35K - 65K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 379,
    title: "Search Engineer",
    company: "Wallapop",
    salary: "45K - 70K EUR",
    date: "April 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 380,
    title: "QA Engineer - Python/React",
    company: "WeGetFinancing",
    salary: "35K - 45K EUR",
    date: "April 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 381,
    title: "QA Engineer",
    company: "Koa Health",
    salary: "36K - 67K EUR",
    date: "April 2021",
    average_salary: 51.5,
    place: "Barcelona/Remote",
  },
  {
    id: 382,
    title: "Go Engineer",
    company: "The Hotels Network",
    salary: "35K - 55K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 383,
    title: "JS Engineer",
    company: "The Hotels Network",
    salary: "35K - 45K EUR",
    date: "April 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 384,
    title: "PHP Engineer",
    company: "The Hotels Network",
    salary: "30K - 55K EUR",
    date: "April 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 385,
    title: "Test Automation",
    company: "corrux",
    salary: "45K - 75K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 386,
    title: "Ecommerce Frontend Engineer",
    company: "Bulevip",
    salary: "32K - 38K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 387,
    title: "Software engineer (Backend) - .net",
    company: "Sunweb group",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 388,
    title: "Nodejs Developer",
    company: "Exoticca",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 389,
    title: "Junior Full Stack Developer",
    company: "The Hotels Network",
    salary: "24K - 26K EUR",
    date: "April 2021",
    average_salary: 25,
    place: "Barcelona/Remote",
  },
  {
    id: 390,
    title: "iOS Developer",
    company: "humanITcare",
    salary: "30K - 41K EUR",
    date: "April 2021",
    average_salary: 35.5,
    place: "Remote",
  },
  {
    id: 391,
    title: "Senior Software Engineer Tech Lead",
    company: "humanITcare",
    salary: "55K - 65K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 392,
    title: "AWS Systems Engineer",
    company: "Exoticca",
    salary: "40K - 60K EUR",
    date: "April 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 393,
    title: "Data Engineer",
    company: "Exoticca",
    salary: "40K - 70K EUR",
    date: "April 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 394,
    title: "Software Engineer (Node.js)",
    company: "Tempus Labs",
    salary: "55K - 70K EUR",
    date: "April 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 395,
    title: "Senior Engineer, Foundations (Nodejs)",
    company: "Auth0",
    salary: "50K - 70K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 396,
    title: "QA Manual",
    company: "Signaturit",
    salary: "30K - 40K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 397,
    title: "Software engineer",
    company: "Badi",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 398,
    title: "Mobile software engineer",
    company: "Badi",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 399,
    title: "Software engineer in test",
    company: "Badi",
    salary: "40K - 50K EUR",
    date: "April 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 400,
    title: "Frontend Developer",
    company: "Docplanner",
    salary: "37K - 60K EUR",
    date: "April 2021",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 401,
    title: "UI-KIT Frontend Developer",
    company: "Docplanner",
    salary: "37K - 55K EUR",
    date: "April 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 402,
    title: "Senior Software Engineer",
    company: "Cimpress",
    salary: "50K - 60K EUR",
    date: "April 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 403,
    title: "FullStack Developer",
    company: "Hector Kitchen",
    salary: "20K - 30K EUR",
    date: "April 2021",
    average_salary: 25,
    place: "Barcelona/Remote",
  },
  {
    id: 404,
    title: "Tech Lead",
    company: "Hector Kitchen",
    salary: "45K - 50K EUR",
    date: "April 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 405,
    title: "Senior Frontend Developer",
    company: "Codegram",
    salary: "32K - 38K EUR",
    date: "April 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 406,
    title: "SeSenior Security Engineer, Incident Response",
    company: "Auth0",
    salary: "60K - 60K EUR",
    date: "April 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 407,
    title: "Backend Developer",
    company: "Pam Learning",
    salary: "30K - 40K EUR",
    date: "May 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 408,
    title: "IT Recruiter",
    company: "Trentia Consulting",
    salary: "20K - 24K EUR",
    date: "May 2021",
    average_salary: 22,
    place: "Barcelona/Remote",
  },
  {
    id: 409,
    title: "Senior Mobile Software Engineer (iOS & Android)",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 410,
    title: "iOS Software Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 411,
    title: "Android Software Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 412,
    title: "Fullstack Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 413,
    title: "Sr DevOps Engineer",
    company: "Xcelirate",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 414,
    title: "Lead DevOps Engineer",
    company: "Xcelirate",
    salary: "70K - 100K EUR",
    date: "May 2021",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 415,
    title: "Sr PHP Developer",
    company: "Xcelirate",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 416,
    title: "Sr Frontend Developer",
    company: "Xcelirate",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 417,
    title: "Javascript Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 418,
    title: "Java Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 419,
    title: "Senior FullStack Engineer",
    company: "Oliva Health",
    salary: "65K - 70K EUR",
    date: "May 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 420,
    title: "Full Stack Developer",
    company: "Talent Clue",
    salary: "40K - 40K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 421,
    title: "Tech Lead",
    company: "Talent Clue",
    salary: "50K - 50K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 422,
    title: "Senior Cloud Engineer",
    company: "Mitek Systems",
    salary: "65K - 65K EUR + Bonus",
    date: "May 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 423,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner (Capterra)",
    salary: "60K - 70K EUR",
    date: "May 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 424,
    title: "Frontend Software Engineer (React)",
    company: "Gartner (GetApp)",
    salary: "45K - 50K EUR",
    date: "May 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 425,
    title: "Software Engineer - Frontend (Remote)",
    company: "Ensemble IP",
    salary: "50K - 70K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 426,
    title: "React Native Developer",
    company: "Docplanner",
    salary: "60K - 60K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 427,
    title: "Product Analyst",
    company: "Docplanner",
    salary: "30K - 50K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 428,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "May 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 429,
    title: "Senior Software Engineer (Kubernetes)",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "May 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 430,
    title: "Cloud Security Engineer",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "May 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 431,
    title: "Engineering Manager",
    company: "Belvo",
    salary: "60K - 60K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 432,
    title: "Fullstack Developer",
    company: "Emoco Labs",
    salary: "48K - 60K EUR",
    date: "May 2021",
    average_salary: 54,
    place: "Remote (COVID)",
  },
  {
    id: 433,
    title: "Frontend engineer",
    company: "Axel Arigato",
    salary: "42K - 42K EUR",
    date: "May 2021",
    average_salary: 42,
    place: "Remote (COVID)",
  },
  {
    id: 434,
    title: "Java developer",
    company: "Etravelio",
    salary: "55K - 65K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Remote (COVID)",
  },
  {
    id: 435,
    title: "CTO at early stage startup",
    company: "Sane (sane.global)",
    salary: "70K - 100K EUR + equity",
    date: "May 2021",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 436,
    title: "Head of Engineering",
    company: "Seqera Labs",
    salary: "65K - 100K EUR",
    date: "May 2021",
    average_salary: 82.5,
    place: "Barcelona",
  },
  {
    id: 437,
    title: "Senior Software Engineer",
    company: "Rviewer",
    salary: "45K - 55K EUR + equity",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 438,
    title: "Frontend Developer",
    company: "Red Points",
    salary: "30K - 36K EUR",
    date: "May 2021",
    average_salary: 33,
    place: "Barcelona",
  },
  {
    id: 439,
    title: "Senior Python Developer (remote optional)",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 440,
    title: "SQL Developer",
    company: "Red points",
    salary: "35K - 40K EUR",
    date: "May 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 441,
    title: "DevOps Engineer!!!",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 442,
    title: "iOS Developer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 443,
    title: "PHP Developer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 444,
    title: "Android Developer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 445,
    title: "Senior QA Automation",
    company: "XpertAI",
    salary: "45K - 45K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 446,
    title: "Software Engineer - Angular",
    company: "Seqera Labs",
    salary: "42K - 42K EUR+ ESOP",
    date: "May 2021",
    average_salary: 42,
    place: "Barcelona/Remote",
  },
  {
    id: 447,
    title: "Solution Architect",
    company: "Seqera Labs",
    salary: "125K - 125K EUR + ESOP",
    date: "May 2021",
    average_salary: 125,
    place: "Remote",
  },
  {
    id: 448,
    title: "Principal Frontend Engineer (Hands On)",
    company: "Eventbrite",
    salary: "80K - 88K EUR + RSUs",
    date: "May 2021",
    average_salary: 84,
    place: "Remote",
  },
  {
    id: 449,
    title: "Senior Lead Engineer",
    company: "Edinu",
    salary: "74K - 75K EUR",
    date: "May 2021",
    average_salary: 74.5,
    place: "Remote",
  },
  {
    id: 450,
    title: "Mid/Senior Backend Developer",
    company: "Devengo",
    salary: "40K - 50K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 451,
    title: "Infrastructure Senior Software Engineer",
    company: "Factorial",
    salary: "50K - 60K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 452,
    title: "Senior FrontEnd Engineer",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 453,
    title: "PHP Engineer (Mid / Senior)",
    company: "The Hotels Network",
    salary: "35K - 55K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 454,
    title: "Go Engineer",
    company: "The Hotels Network",
    salary: "35K - 55K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 455,
    title: "Lead Instructor - Web Development",
    company: "Ironhack",
    salary: "35K - 45K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 456,
    title: "iOS Developer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 457,
    title: "Android Developer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 458,
    title: "PHP Developer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 459,
    title: "Kotlin Engineer",
    company: "Bumble",
    salary: "45K - 75K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 460,
    title: "QA Automation Engineer",
    company: "Bumble",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 461,
    title: "Junior Frontend developer",
    company: "Proveedores.com",
    salary: "24K - 24K EUR",
    date: "May 2021",
    average_salary: 24,
    place: "Barcelona/Remote",
  },
  {
    id: 462,
    title: "Android Developer",
    company: "Baby2Body",
    salary: "60K - 60K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 463,
    title: "Frontend Developer",
    company: "Codamic Innovations",
    salary: "30K - 50K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 464,
    title: "Senior Back-end Engineer - Payroll (f/m/d)",
    company: "Personio",
    salary: "55K - 70K EUR",
    date: "May 2021",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 465,
    title: "Frontend Engineer",
    company: "Abi Global Health",
    salary: "40K - 40K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 466,
    title: "Data Engineer",
    company: "Stayforlong",
    salary: "65K - 65K EUR",
    date: "May 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 467,
    title: "Front-end engineer",
    company: "Dragonfly",
    salary: "25K - 35K EUR",
    date: "May 2021",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 468,
    title: "Senior Software Engineer",
    company: "Bling",
    salary: "40K - 60K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 469,
    title: "Senior Front End Developer",
    company: "Playtomic",
    salary: "50K - 55K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 470,
    title: "Mid Front End Developer",
    company: "Playtomic",
    salary: "43K - 45K EUR",
    date: "May 2021",
    average_salary: 44,
    place: "Barcelona/Remote",
  },
  {
    id: 471,
    title: "FullStack Engineer",
    company: "Baila",
    salary: "35K - 45K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 472,
    title: "Senior Software Engineer",
    company: "StreamSets",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 473,
    title: "Mid Software Engineer",
    company: "StreamSets",
    salary: "38K - 45K EUR",
    date: "May 2021",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 474,
    title: "Junior Software Engineer",
    company: "StreamSets",
    salary: "30K - 38K EUR",
    date: "May 2021",
    average_salary: 34,
    place: "Remote (COVID)",
  },
  {
    id: 475,
    title: "Full Stack Developer (PHP & React)",
    company: "LIFULL Connect",
    salary: "40K - 50K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 476,
    title: "Expert React Developer",
    company: "MONEI",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 477,
    title: "Mid Maquetador Web (+NoCode)",
    company: "Minimum",
    salary: "26K - 32K EUR",
    date: "May 2021",
    average_salary: 29,
    place: "Remote",
  },
  {
    id: 478,
    title: "Senior Engineering Manager",
    company: "RevenueCat",
    salary: "135K - 152K EUR",
    date: "May 2021",
    average_salary: 143.5,
    place: "Remote",
  },
  {
    id: 479,
    title: "Senior Talent Acquisition Specialist",
    company: "The Hotels Network",
    salary: "35K - 50K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 480,
    title: "Software Engineer – Back End",
    company: "WeGetFinancing",
    salary: "40K - 60K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 481,
    title: "QA Engineer - Python",
    company: "WeGetFinancing",
    salary: "35K - 45K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 482,
    title: "Junior NLP Engineer",
    company: "Transperfect",
    salary: "40K - 40K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 483,
    title: "Backend engineer with PHP Laravel",
    company: "Grocasa",
    salary: "35K - 37K EUR",
    date: "May 2021",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 484,
    title: "Mid Frontend developer",
    company: "Abi Global Health",
    salary: "35K - 45K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 485,
    title: "Senior Frontend Developer",
    company: "Abi Global Health",
    salary: "45K - 60K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 486,
    title: "Senior Software Engineer",
    company: "Voxel",
    salary: "60K - 60K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 487,
    title: "SENIOR SOFTWARE ENGINEER",
    company: "BLING",
    salary: "40K - 60K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 488,
    title: "Frontend (React, GraphQL)",
    company: "EnsembleIP",
    salary: "60K - 75K EUR",
    date: "May 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 489,
    title: "Fullstack Intructor (JS) - Full Time",
    company: "Skylab",
    salary: "50K - 50K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 490,
    title: "Backend Engineer (golang)",
    company: "Iomob",
    salary: "40K - 60K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 491,
    title: "DevOps",
    company: "Bodas.net",
    salary: "40K - 50K EUR + Bonus",
    date: "May 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 492,
    title: "Android Developer",
    company: "Bodas.net (TKWW)",
    salary: "30K - 45K EUR",
    date: "May 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 493,
    title: "QA Automation",
    company: "Bodas.Net (TKWW)",
    salary: "30K - 45K EUR + Bonus",
    date: "May 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 494,
    title: "Senior PHP",
    company: "Bodas.net (TKWW)",
    salary: "30K - 50K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 495,
    title: "Fullstack Developer (NodeJS, ReactJS)",
    company: "Bodas.net (TKWW)",
    salary: "35K - 55K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 496,
    title: "Junior Developer",
    company: "Talent Clue",
    salary: "20K - 24K EUR",
    date: "May 2021",
    average_salary: 22,
    place: "Barcelona/Remote",
  },
  {
    id: 497,
    title: "Junior Test Engineer / Consultant",
    company: "Dragonfly",
    salary: "18K - 22K EUR",
    date: "May 2021",
    average_salary: 20,
    place: "Barcelona",
  },
  {
    id: 498,
    title: "React Engineers - multiple roles and levels",
    company: "Dragonfly People",
    salary: "40K - 80K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 499,
    title: "Web Developer",
    company: "Curelator",
    salary: "35K - 50K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 500,
    title: "Quality Assurance Lead",
    company: "Curelator",
    salary: "35K - 50K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 501,
    title: "Mid/Senior Backend Developer",
    company: "Localistico",
    salary: "56K - 62K EUR + 0.1%",
    date: "May 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 502,
    title: "DevOps Engineer",
    company: "Stayforlong",
    salary: "45K - 65K EUR",
    date: "May 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 503,
    title: "Senior Frontend Developer",
    company: "Docplanner",
    salary: "37K - 55K EUR",
    date: "May 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 504,
    title: "UI-KIT Frontend Developer",
    company: "Docplanner",
    salary: "37K - 55K EUR",
    date: "May 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 505,
    title: "React Frontend Software Engineer",
    company: "EdgeTier",
    salary: "30K - 55K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 506,
    title: "Senior Backend Engineer (100% Remote)",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "May 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 507,
    title: "Fullstack Software Developer",
    company: "Last.app",
    salary: "30K - 55K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 508,
    title: "PHP Backend Developer Sr.",
    company: "Housfy",
    salary: "40K - 45K EUR",
    date: "May 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 509,
    title: "Senior Software Engineer (Node.js)",
    company: "Tempus Labs",
    salary: "55K - 70K EUR",
    date: "May 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 510,
    title: "Junior or Mid Full-stack developer",
    company: "DeWocracy",
    salary: "25K - 35K EUR",
    date: "May 2021",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 511,
    title: "Trainee Technology- Software Engineer",
    company: "Interhyp AG",
    salary: "30K - 50K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 512,
    title: "Ruby on Rails Developer",
    company: "MarsBased",
    salary: "35K - 42K EUR",
    date: "May 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 513,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner (Capterra)",
    salary: "60K - 70K EUR",
    date: "May 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 514,
    title: "Junior DevOps (Early Careers)",
    company: "Adevinta",
    salary: "33K - 33K EUR + Bonus",
    date: "May 2021",
    average_salary: 33,
    place: "Barcelona",
  },
  {
    id: 515,
    title: "Mid/Senior Backend Developer",
    company: "Smith",
    salary: "40K - 65K EUR",
    date: "May 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 516,
    title: "Junior or Mid Python Backend Developer",
    company: "Bamboo Energy",
    salary: "25K - 45K EUR",
    date: "May 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 517,
    title: "PHP Backend Developers (middle&senior)",
    company: "Housfy",
    salary: "30K - 45K EUR",
    date: "May 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 518,
    title: "Trainee PHP Backend / Full Stack Developer",
    company: "Deliberry",
    salary: "10K - 20K EUR",
    date: "May 2021",
    average_salary: 15,
    place: "Barcelona/Remote",
  },
  {
    id: 519,
    title: "Full Stack Developer",
    company: "LIFULL Connect",
    salary: "40K - 50K EUR",
    date: "May 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 520,
    title: "Frontend Developer",
    company: "Sparta",
    salary: "30K - 35K EUR",
    date: "May 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 521,
    title: "Data Engineer",
    company: "Tinybird",
    salary: "63K - 63K EUR",
    date: "May 2021",
    average_salary: 63,
    place: "Remote",
  },
  {
    id: 522,
    title: "SRE I DevOps",
    company: "Zscaler",
    salary: "45K - 55K EUR",
    date: "May 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 523,
    title: "Ruby Developer",
    company: "Inspiring Benefits",
    salary: "35K - 42K EUR",
    date: "May 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 524,
    title: "VP of Engineering",
    company: "Upfront",
    salary: "150K - 150K EUR",
    date: "May 2021",
    average_salary: 150,
    place: "Barcelona/Remote",
  },
  {
    id: 525,
    title: "Senior Backend Engineer",
    company: "Holaluz",
    salary: "40K - 55K EUR",
    date: "May 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 526,
    title: "Junior Backend Engineer",
    company: "Holaluz",
    salary: "20K - 30K EUR",
    date: "May 2021",
    average_salary: 25,
    place: "Barcelona",
  },
  {
    id: 527,
    title: "Frontend Engineer",
    company: "Opticks Security",
    salary: "35K - 35K EUR",
    date: "May 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 528,
    title: "Fullstack developer",
    company: "Parallel",
    salary: "35K - 45K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 529,
    title: "Senior Fronted Developer",
    company: "Red Points",
    salary: "30K - 45K EUR",
    date: "May 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 530,
    title: "Technical Support TEAM LEADER",
    company: "Red Points",
    salary: "35K - 45K EUR",
    date: "May 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 531,
    title: "Senior Full Stack Developer",
    company: "FCM travel solutions",
    salary: "40K - 55K EUR",
    date: "May 2021",
    average_salary: 47.5,
    place: "Remote (COVID)",
  },
  {
    id: 532,
    title: '"DevOps" engineer',
    company: "FCM travel solutions",
    salary: "40K - 55K EUR",
    date: "May 2021",
    average_salary: 47.5,
    place: "Remote (COVID)",
  },
  {
    id: 533,
    title: "Shopify developer",
    company: "Barner Brand",
    salary: "30K - 40K EUR",
    date: "May 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 534,
    title: "Product support with SQL",
    company: "Red Points",
    salary: "24K - 26K EUR",
    date: "June 2021",
    average_salary: 25,
    place: "Barcelona",
  },
  {
    id: 535,
    title: "Python Developer",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "June 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 536,
    title: "Data Engineer",
    company: "Mailtrack.io",
    salary: "40K - 55K EUR",
    date: "June 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 537,
    title: "Senior Javascript Developer",
    company: "Mailtrack.io",
    salary: "40K - 55K EUR",
    date: "June 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 538,
    title: "Senior Backend Developer (PHP)",
    company: "Mailtrack.io",
    salary: "40K - 55K EUR",
    date: "June 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 539,
    title: "Senior Backend Developer",
    company: "MONEI",
    salary: "50K - 70K EUR",
    date: "June 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 540,
    title: "Mid Backend Developer",
    company: "MONEI",
    salary: "30K - 50K EUR",
    date: "June 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 541,
    title: "Product Analyst",
    company: "Docplanner",
    salary: "50K - 50K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 542,
    title: "Java Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 543,
    title: "Javascript Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 544,
    title: "Frontend Engineer (Angular or React)",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 545,
    title: "Android Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote (COVID)",
  },
  {
    id: 546,
    title: "Senior Backend Engineer (Go)",
    company: "Ritmo",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 547,
    title: "Senior DevOps Engineer",
    company: "Ritmo",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 548,
    title: "DevOps Cloud Engineer",
    company: "Cubyn",
    salary: "50K - 60K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 549,
    title: "Java Backend Developer",
    company: "NPAW",
    salary: "30K - 40K EUR",
    date: "June 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 550,
    title: "Senior Software Engineer, Backend",
    company: "Vesta Healthcare",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 551,
    title: "Customer Engineer",
    company: "NPAW",
    salary: "30K - 40K EUR",
    date: "June 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 552,
    title: "Senior Frontend Developer (React)",
    company: "Playtomic",
    salary: "55K - 55K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 553,
    title: "Mid Backend Developer (Java)",
    company: "Playtomic",
    salary: "46K - 46K EUR",
    date: "June 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 554,
    title: "PHP Symfony Developer",
    company: "Paymefy",
    salary: "30K - 40K EUR",
    date: "June 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 555,
    title: "Head of Data",
    company: "Picker",
    salary: "50K - 65K EUR",
    date: "June 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 556,
    title: "Senior Java Developer",
    company: "Red Points",
    salary: "40K - 45K EUR",
    date: "June 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 557,
    title: "Senior Front-End Engineer",
    company: "Belvo",
    salary: "35K - 50K EUR",
    date: "June 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 558,
    title: "Senior Software Developer (.NET Core)",
    company: "ELCA Informatik AG",
    salary: "100K - 125K EUR",
    date: "June 2021",
    average_salary: 112.5,
    place: "Remote",
  },
  {
    id: 559,
    title: "PHP Engineer (mid / senior)",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 560,
    title: "Frontend Engineer (mid / senior)",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 561,
    title: "Talent Acquisition",
    company: "The Hotels Network",
    salary: "30K - 50K EUR",
    date: "June 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 562,
    title: "Data Engineer",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 563,
    title: "Senior Software Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "55K - 66K EUR",
    date: "June 2021",
    average_salary: 60.5,
    place: "Remote (COVID)",
  },
  {
    id: 564,
    title: "Frontend Developer",
    company: "Modo Studio",
    salary: "30K - 40K EUR",
    date: "June 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 565,
    title: "Senior Head of Product",
    company: "Camillion",
    salary: "45K - 55K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 566,
    title: "Senior Fullstack Engineer",
    company: "Revive",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 567,
    title: "Senior Back-end Developer",
    company: "TOQIO",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 568,
    title: "Software Engineer Angular",
    company: "Seqera Labs",
    salary: "50K - 50K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 569,
    title: "Senior Mobile (React Native) Developer",
    company: "Bravado",
    salary: "60K - 90K USD",
    date: "June 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 570,
    title: "QA Engineer",
    company: "Bravado",
    salary: "20K - 60K USD",
    date: "June 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 571,
    title: "Senior Full-Stack/Backend Engineer",
    company: "Bravado",
    salary: "60K - 90K USD",
    date: "June 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 572,
    title: "Senior Frontend Developer",
    company: "Bravado",
    salary: "60K - 90K USD",
    date: "June 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 573,
    title: "Junior Product Owner",
    company: "re:ceeve",
    salary: "45K - 55K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 574,
    title: "Technical Product Manager - SRE",
    company: "Ecosia.org",
    salary: "69K - 79K EUR",
    date: "June 2021",
    average_salary: 74,
    place: "Remote",
  },
  {
    id: 575,
    title: ".NET Backend developer",
    company: "Dept Agency",
    salary: "35K - 45K EUR",
    date: "June 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 576,
    title: "Mid Fullstack Developer",
    company: "Soamee",
    salary: "27K - 35K EUR",
    date: "June 2021",
    average_salary: 31,
    place: "Remote",
  },
  {
    id: 577,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "June 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 578,
    title: "Senior Software Engineer (Kubernetes)",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "June 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 579,
    title: "Senior Platform Engineer",
    company: "Form3",
    salary: "95K - 95K EUR",
    date: "June 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 580,
    title: "Senior Backend Engineer (100% Remote)",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "June 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 581,
    title: "System Administrator",
    company: "Kodify.io",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 582,
    title: "Senior Backend Engineer",
    company: "Lingokids",
    salary: "55K - 85K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 583,
    title: "Senior Full Stack Engineer",
    company: "Lingokids",
    salary: "55K - 85K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 584,
    title: "Backend Engineer",
    company: "Lingokids",
    salary: "35K - 60K EUR",
    date: "June 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 585,
    title: "Engineering Lead",
    company: "Lingokids",
    salary: "55K - 85K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 586,
    title: "Data Engineer (Mid or Senior)",
    company: "Lingokids",
    salary: "35K - 85K EUR",
    date: "June 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 587,
    title: "Mid/Senior Flutter developer",
    company: "Criptan",
    salary: "30K - 55K EUR",
    date: "June 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 588,
    title: "(Mid) SW Engineer",
    company: "Adobe",
    salary: "55K - 80K EUR",
    date: "June 2021",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 589,
    title: "Product Manager (ideally Senior)",
    company: "Caravelo",
    salary: "30K - 50K EUR",
    date: "June 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 590,
    title: "Flutter developer",
    company: "Criptan",
    salary: "35K - 55K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 591,
    title: "Mobile developer (interested in learning flutter )",
    company: "Criptan",
    salary: "35K - 55K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 592,
    title: "Java Tech Lead",
    company: "Intelygenz",
    salary: "46K - 50K EUR",
    date: "June 2021",
    average_salary: 48,
    place: "Remote",
  },
  {
    id: 593,
    title: "Senior Java Developer",
    company: "Seat:Code",
    salary: "45K - 60K EUR",
    date: "June 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 594,
    title: "Python Developer",
    company: "Seat:Code",
    salary: "45K - 52K EUR",
    date: "June 2021",
    average_salary: 48.5,
    place: "Remote",
  },
  {
    id: 595,
    title: "Flutter Developer (Junior or Mid Level) -Part Time",
    company: "AgencyCoda.com",
    salary: "6K - 12K EUR",
    date: "June 2021",
    average_salary: 9,
    place: "Remote",
  },
  {
    id: 596,
    title: "FullStack Developer",
    company: "Hector Kitchen",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 597,
    title: "Flutter Developer (Junior or Mid Level) -Part Time",
    company: "AgencyCoda.com",
    salary: "6K - 12K EUR",
    date: "June 2021",
    average_salary: 9,
    place: "Remote",
  },
  {
    id: 598,
    title: ".Net Developer / SRE wannabe",
    company: "Docplanner Tech",
    salary: "48K - 55K EUR",
    date: "June 2021",
    average_salary: 51.5,
    place: "Barcelona/Remote",
  },
  {
    id: 599,
    title: "Software Engineer",
    company: "TBSCG",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 600,
    title: "Technical Consultant (Java/AWS)",
    company: "TBSCG",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 601,
    title: "Test Automation Engineer",
    company: "TBSCG",
    salary: "30K - 46K EUR",
    date: "June 2021",
    average_salary: 38,
    place: "Barcelona/Remote",
  },
  {
    id: 602,
    title: "Frontend Engineer",
    company: "TBSCG",
    salary: "35K - 46K EUR",
    date: "June 2021",
    average_salary: 40.5,
    place: "Barcelona/Remote",
  },
  {
    id: 603,
    title: "Cloud Engineer (AWS)",
    company: "TBSCG",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 604,
    title: "DevOps Engineer (AWS)",
    company: "TBSCG",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 605,
    title: "Cloud Architect (AWS)",
    company: "TBSCG",
    salary: "50K - 70K EUR",
    date: "June 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 606,
    title: "DevOps Engineer",
    company: "StreamSets",
    salary: "38K - 45K EUR + Bonus",
    date: "June 2021",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 607,
    title: "SRE Engineer",
    company: "StreamSets",
    salary: "45K - 60K EUR + Bonus",
    date: "June 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 608,
    title: "Lead Data Scientist",
    company: "GoKoan",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 609,
    title: "DevOps Engineer",
    company: "StreamSets",
    salary: "38K - 45K EUR + Bonus",
    date: "June 2021",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 610,
    title: "SRE Engineer",
    company: "StreamSets",
    salary: "45K - 60K EUR + Bonus",
    date: "June 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 611,
    title: "Mid Fullstack Developer",
    company: "iGERENT",
    salary: "30K - 35K EUR",
    date: "June 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 612,
    title: "Mid-level Frontend Developer",
    company: "Modo Studio",
    salary: "30K - 40K EUR",
    date: "June 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 613,
    title: "[Senior] QA Engineer (automation)",
    company: "Xing",
    salary: "37K - 65K EUR",
    date: "June 2021",
    average_salary: 51,
    place: "Barcelona/Remote",
  },
  {
    id: 614,
    title: "Senior Back-end Developer - Scala",
    company: "Link",
    salary: "45K - 55K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 615,
    title: "Technical Agile Coach (30h/w)",
    company: "Exeal",
    salary: "36K - 42K EUR",
    date: "June 2021",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 616,
    title: "Tech Lead MERN Stack",
    company: "Assembler School",
    salary: "25K - 30K EUR",
    date: "June 2021",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 617,
    title: "Cloud Engineer (AWS)",
    company: "LIFULL Connect",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 618,
    title: "Senior Software Developer",
    company: "Lifull Connect",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 619,
    title: "Mid Angular Developer",
    company: "Seidor",
    salary: "25K - 30K EUR",
    date: "June 2021",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 620,
    title: "Machine Learning Engineer - The first one :)",
    company: "Happy Scribe",
    salary: "40K - 60K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 621,
    title: "Software Engineer - Full Stack",
    company: "Happy Scribe",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 622,
    title: "PHP Developer",
    company: "Docplanner Tech",
    salary: "50K - 50K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 623,
    title: "Mid PHP Developer",
    company: "Tekman",
    salary: "28K - 35K EUR",
    date: "June 2021",
    average_salary: 31.5,
    place: "Barcelona",
  },
  {
    id: 624,
    title: "Senior Android Engineer",
    company: "Seat:Code",
    salary: "45K - 60K EUR",
    date: "June 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 625,
    title: "Python Tech Lead",
    company: "Intelygenz",
    salary: "46K - 50K EUR",
    date: "June 2021",
    average_salary: 48,
    place: "Remote",
  },
  {
    id: 626,
    title: "Android Developer (Kotlin + Java)",
    company: "NPAW",
    salary: "25K - 30K EUR",
    date: "June 2021",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 627,
    title: "Backend Java Developer",
    company: "NPAW",
    salary: "30K - 40K EUR",
    date: "June 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 628,
    title: "Android App Engineering [",
    company: "Zühlke Engineering",
    salary: "80K - 110K EUR",
    date: "June 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 629,
    title: "Infrastructure Engineer",
    company: "Voxel",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 630,
    title: "Mid/Senior Cloud Engineer (AWS)",
    company: "BMAT",
    salary: "35K - 40K EUR",
    date: "June 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 631,
    title: "Head of Engineering",
    company: "Lana Fintech",
    salary: "75K - 90K EUR",
    date: "June 2021",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 632,
    title: "Senior Software Engineer (Core banking)",
    company: "Lana Fintech",
    salary: "55K - 65K EUR",
    date: "June 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 633,
    title: "Frontend Engineer",
    company: "Lana Fintech",
    salary: "40K - 55K EUR",
    date: "June 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 634,
    title: "Site Reliability Engineer (DevOps)",
    company: "Languagewire",
    salary: "45K - 55K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 635,
    title: "Backend Engineer",
    company: "Zapier",
    salary: "75K - 90K EUR",
    date: "June 2021",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 636,
    title: "Senior Backend Engineer",
    company: "Zapier",
    salary: "90K - 105K EUR",
    date: "June 2021",
    average_salary: 97.5,
    place: "Remote",
  },
  {
    id: 637,
    title: "Site Reliability Engineer",
    company: "Zapier",
    salary: "95K - 105K EUR",
    date: "June 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 638,
    title: "Data Engineer Junior",
    company: "Romeu NVOCC",
    salary: "24K - 28K EUR",
    date: "June 2021",
    average_salary: 26,
    place: "Barcelona",
  },
  {
    id: 639,
    title: "Senior Analytics Engineer(100%Remote)",
    company: "Streamloots",
    salary: "45K - 45K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 640,
    title: "Full-Stack - u bring React we teach you Node & VV.",
    company: "TAPTAP Digital",
    salary: "40K - 45K EUR",
    date: "June 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 641,
    title: "Senior SW Engineer",
    company: "Adobe",
    salary: "76K - 100K EUR",
    date: "June 2021",
    average_salary: 88,
    place: "Barcelona",
  },
  {
    id: 642,
    title: "Java Software Engineer",
    company: "Adaptive",
    salary: "40K - 60K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 643,
    title: "Back-end Engineer (w/Golang or willing to learn)",
    company: "Brainly",
    salary: "40K - 60K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 644,
    title: "Backend Developer",
    company: "Playtomic",
    salary: "43K - 46K EUR",
    date: "June 2021",
    average_salary: 44.5,
    place: "Barcelona/Remote",
  },
  {
    id: 645,
    title: "Senior Search Engineer (w/Elasticsearch)",
    company: "Brainly",
    salary: "70K - 70K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 646,
    title: "Fullstack PHP Developer",
    company: "Gums Up",
    salary: "32K - 37K EUR",
    date: "June 2021",
    average_salary: 34.5,
    place: "Barcelona/Remote",
  },
  {
    id: 647,
    title: "Software Engineer (PHP)",
    company: "mytheresa.com",
    salary: "40K - 57K EUR",
    date: "June 2021",
    average_salary: 48.5,
    place: "Barcelona",
  },
  {
    id: 648,
    title: "Senior Software Engineer (Go)",
    company: "World Mobile Group",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 649,
    title: "Junior Software Engineer (Go)",
    company: "World Mobile Group",
    salary: "30K - 37K EUR",
    date: "June 2021",
    average_salary: 33.5,
    place: "Barcelona/Remote",
  },
  {
    id: 650,
    title: "Senior SysAdmin",
    company: "World Mobile Group",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 651,
    title: "Junior SysAdmin",
    company: "World Mobile Group",
    salary: "30K - 37K EUR",
    date: "June 2021",
    average_salary: 33.5,
    place: "Barcelona/Remote",
  },
  {
    id: 652,
    title: "Senior Software Engineer (Mobile)",
    company: "World Mobile Group",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 653,
    title: "Junior Software Engineer (Mobile)",
    company: "World Mobile Group",
    salary: "30K - 37K EUR",
    date: "June 2021",
    average_salary: 33.5,
    place: "Barcelona/Remote",
  },
  {
    id: 654,
    title: "Senior Fullstack Web Developer",
    company: "World Mobile Group",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 655,
    title: "Junior Fullstack Web Developer",
    company: "World Mobile Group",
    salary: "30K - 37K EUR",
    date: "June 2021",
    average_salary: 33.5,
    place: "Barcelona/Remote",
  },
  {
    id: 656,
    title: "Senior Product Owner",
    company: "World Mobile Group",
    salary: "60K - 80K EUR",
    date: "June 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 657,
    title: "Backend & Data Lead",
    company: "Sanno Health",
    salary: "50K - 50K EUR + Equity",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 658,
    title: "Ruby on Rails Developer",
    company: "Jobandtalent",
    salary: "45K - 65K EUR",
    date: "June 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 659,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "Capterra (Gartner)",
    salary: "60K - 70K EUR",
    date: "June 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 660,
    title: "React Developer",
    company: "GetApp - Gartner",
    salary: "45K - 55K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 661,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Capterra (Gartner)",
    salary: "60K - 70K EUR",
    date: "June 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 662,
    title: "Senior Front End Developer",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "June 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 663,
    title: "Backend Engineer",
    company: "acework",
    salary: "60K - 70K EUR",
    date: "June 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 664,
    title: "Frontend Engineer",
    company: "acework",
    salary: "60K - 70K EUR",
    date: "June 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 665,
    title: "Javascript developer",
    company: "acework",
    salary: "70K - 110K USD",
    date: "June 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 666,
    title: "DevOps Engineer",
    company: "i-surance",
    salary: "50K - 50K EUR",
    date: "June 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 667,
    title: "Backend Software Engineer (Java)",
    company: "Apiumhub",
    salary: "40K - 50K EUR",
    date: "June 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 668,
    title: "Software Development Engineer",
    company: "Spotahome",
    salary: "35K - 45K EUR",
    date: "June 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 669,
    title: "Java Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 670,
    title: "Javascript Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 671,
    title: "Android Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 672,
    title: "Frontend Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 673,
    title: "Test Automation Engineer",
    company: "Dynatrace",
    salary: "45K - 60K EUR",
    date: "July 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 674,
    title: "Software Engineer, Backend, CH/GR",
    company: "Futurae Technologies",
    salary: "90K - 120K EUR",
    date: "July 2021",
    average_salary: 105,
    place: "Remote",
  },
  {
    id: 675,
    title: "Senior Analytics Engineer(Remote)",
    company: "Streamloots",
    salary: "45K - 45K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 676,
    title: "Mid Frontend Developer",
    company: "RevenueCat",
    salary: "114K - 126K EUR",
    date: "July 2021",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 677,
    title: "Senior Frontend Developer",
    company: "RevenueCat",
    salary: "126K - 143K EUR",
    date: "July 2021",
    average_salary: 134.5,
    place: "Remote",
  },
  {
    id: 678,
    title: "Backend Software Engineer",
    company: "GetApp/Gartner",
    salary: "45K - 55K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 679,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Capterra (Gartner)",
    salary: "70K - 80K EUR",
    date: "July 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 680,
    title: "Jr Product Owner",
    company: "receeve",
    salary: "45K - 55K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 681,
    title: "Full-Stack Engineer",
    company: "Koa Health",
    salary: "35K - 65K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 682,
    title: "Senior Backend Engineer",
    company: "BCome.biz",
    salary: "45K - 55K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 683,
    title: "Senior Backend SW Engineer",
    company: "Plytix",
    salary: "35K - 50K EUR",
    date: "July 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 684,
    title: "Senior Fullstack Engineer",
    company: "Codurance",
    salary: "40K - 55K EUR",
    date: "July 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 685,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "July 2021",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 686,
    title: "Senior Software Engineer (Kubernetes)",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "July 2021",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 687,
    title: "Senior Platform Engineer",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "July 2021",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 688,
    title: "Senior Software Engineer, Full Stack",
    company: "Vesta Healthcare",
    salary: "60K - 80K EUR",
    date: "July 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 689,
    title: "Mid to Senior FullStack Developer (Node, React)",
    company: "The Knot Worldwide",
    salary: "30K - 55K EUR",
    date: "July 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 690,
    title: "Full-Stack / CTO",
    company: "Inviertis",
    salary: "40K - 50K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 691,
    title: "Frontend developer (React/React-Native)",
    company: "FCM Digital",
    salary: "45K - 55K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 692,
    title: "Senior Ruby on Rails Developer",
    company: "FCM Digital",
    salary: "48K - 58K EUR",
    date: "July 2021",
    average_salary: 53,
    place: "Barcelona/Remote",
  },
  {
    id: 693,
    title: "Solution Consultant",
    company: "re:ceeve",
    salary: "40K - 50K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 694,
    title: "Senior Frontend Developer",
    company: "Z1",
    salary: "50K - 65K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 695,
    title: "Senior Backend Developer",
    company: "Z1",
    salary: "50K - 65K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 696,
    title: "Senior DevOps Engineer",
    company: "Stayforlong",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 697,
    title: "Data Engineer",
    company: "Stayforlong",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 698,
    title: "Mid/Senior ReactJS Developer",
    company: "Ritmo",
    salary: "35K - 46K EUR",
    date: "July 2021",
    average_salary: 40.5,
    place: "Remote",
  },
  {
    id: 699,
    title: "Senior Backend Developer",
    company: "Holded",
    salary: "50K - 70K EUR",
    date: "July 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 700,
    title: "Mid Fullstack Developer",
    company: "iGERENT.com",
    salary: "30K - 35K EUR",
    date: "July 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 701,
    title: "Product Manager",
    company: "Signaturit",
    salary: "40K - 50K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 702,
    title: "Data Analyst",
    company: "Signaturit",
    salary: "35K - 45K EUR",
    date: "July 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 703,
    title: "Freelance Product Manager for 2 months",
    company: "I-Surance",
    salary: "20K - 20K EUR",
    date: "July 2021",
    average_salary: 20,
    place: "Barcelona/Remote",
  },
  {
    id: 704,
    title: "Freelance July August DDD PHP Developer",
    company: "I-Surance",
    salary: "20K - 20K EUR",
    date: "July 2021",
    average_salary: 20,
    place: "Barcelona/Remote",
  },
  {
    id: 705,
    title: "Frontend Lead",
    company: "Holded",
    salary: "55K - 75K EUR",
    date: "July 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 706,
    title: "Senior React Developer",
    company: "MONEI",
    salary: "45K - 60K EUR",
    date: "July 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 707,
    title: "Senior Backend Developer",
    company: "MONEI",
    salary: "50K - 70K EUR",
    date: "July 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 708,
    title: "Senior Software Engineer",
    company: "BlueRithm",
    salary: "130K - 160K EUR",
    date: "July 2021",
    average_salary: 145,
    place: "Remote",
  },
  {
    id: 709,
    title: "Mid-level Frontend Developer",
    company: "Psonríe",
    salary: "30K - 35K EUR",
    date: "July 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 710,
    title: "Backend Developer (Java 11)",
    company: "Playtomic",
    salary: "43K - 55K EUR",
    date: "July 2021",
    average_salary: 49,
    place: "Barcelona/Remote",
  },
  {
    id: 711,
    title: "Junior/Mid Backend Engineer",
    company: "Vizzuality",
    salary: "20K - 35K EUR",
    date: "July 2021",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 712,
    title: "Junior Fullstack Developer",
    company: "The Hotels Network",
    salary: "24K - 24K EUR",
    date: "July 2021",
    average_salary: 24,
    place: "Barcelona/Remote",
  },
  {
    id: 713,
    title: "Fullstack Engineer",
    company: "Rover.com",
    salary: "52K - 63K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 714,
    title: "React/React Native Engineer",
    company: "Rover.com",
    salary: "35K - 52K EUR",
    date: "July 2021",
    average_salary: 43.5,
    place: "Remote (COVID)",
  },
  {
    id: 715,
    title: "Software Engineer",
    company: "TravelPerk",
    salary: "45K - 70K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 716,
    title: "Android Developer",
    company: "TravelPerk",
    salary: "50K - 80K EUR",
    date: "July 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 717,
    title: "Fullstack Engineer (React/Node)",
    company: "Tempus Labs",
    salary: "55K - 70K EUR",
    date: "July 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 718,
    title: "(Senior) Software Engineer - Node.js",
    company: "New Work - XING",
    salary: "35K - 60K EUR",
    date: "July 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 719,
    title: "Backend Software Engineer – Node.js",
    company: "SpotMe",
    salary: "85K - 115K EUR",
    date: "July 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 720,
    title: "NLP Engineer",
    company: "M47 Labs",
    salary: "30K - 55K EUR",
    date: "July 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 721,
    title: "Data Engineer",
    company: "Stayforlong",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 722,
    title: "Senior DevOps Engineer",
    company: "Stayforlong",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 723,
    title: "Software Developer in Test",
    company: "M47 Labs",
    salary: "30K - 45K EUR",
    date: "July 2021",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 724,
    title: "Backend Engineer: Core Services, Algorithms",
    company: "Constructor.io",
    salary: "67K - 93K EUR",
    date: "July 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 725,
    title: "Backend Engineer: Search Features & APIs",
    company: "Constructor io",
    salary: "67K - 93K EUR",
    date: "July 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 726,
    title: "Mid/Senior Backend Developer (NodeJS / TypeScript)",
    company: "VIDJET",
    salary: "35K - 45K EUR",
    date: "July 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 727,
    title: "Senior Android Engineer",
    company: "Bumble",
    salary: "45K - 70K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 728,
    title: "Backend Engineer (willing to learn Go)",
    company: "Brainly",
    salary: "40K - 60K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 729,
    title: "iOS Engineer with A/B testing experience",
    company: "Brainly",
    salary: "40K - 60K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 730,
    title: "Automation Engineer for ML projects",
    company: "Brainly",
    salary: "40K - 60K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 731,
    title: "Senior Backend Laravel Engineer",
    company: "Rally",
    salary: "80K - 100K EUR",
    date: "July 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 732,
    title: "Senior Data Engineer",
    company: "Brainly",
    salary: "40K - 60K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 733,
    title: "Lead Data Engineer",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "July 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 734,
    title: "Full Stack Developer Node.js / React",
    company: "Launchmetrics",
    salary: "30K - 60K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 735,
    title: "Data Warehouse Architect",
    company: "Typeform",
    salary: "57K - 69K EUR",
    date: "July 2021",
    average_salary: 63,
    place: "Barcelona/Remote",
  },
  {
    id: 736,
    title: "DevOps Engineer",
    company: "TravelPerk",
    salary: "35K - 55K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 737,
    title: "Senior Backend Engineer",
    company: "SplashThat",
    salary: "50K - 65K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 738,
    title: "Senior Frontend Engineer",
    company: "SplashThat",
    salary: "55K - 70K EUR",
    date: "July 2021",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 739,
    title: "PHP Backend Senior Developer",
    company: "Elma",
    salary: "50K - 50K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 740,
    title: "Backend Engineer",
    company: "Signaturit",
    salary: "35K - 55K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 741,
    title: "PHP Senior Backend or FullStack | Freelance >25€/h",
    company: "Mobile Jazz",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 742,
    title: "Backend Developer (APIs)",
    company: "UNIQ Ventures",
    salary: "40K - 60K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 743,
    title: "PHP Developer Laravel/Symfony/Wordpress welcome",
    company: "Cyberdelia",
    salary: "24K - 24K EUR",
    date: "July 2021",
    average_salary: 24,
    place: "Remote",
  },
  {
    id: 744,
    title: "Senior Frontend Engineer",
    company: "Red Points",
    salary: "35K - 45K EUR",
    date: "July 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 745,
    title: "Senior Java Developer",
    company: "Red Points",
    salary: "45K - 65K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 746,
    title: "Senior Python Developer (bots)",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 747,
    title: "Tech support / Sys admin Team Lead",
    company: "Red Points",
    salary: "40K - 45K EUR",
    date: "July 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 748,
    title: "JS Developer",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 749,
    title: "Ruby on Rails Developer",
    company: "New Work",
    salary: "35K - 55K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 750,
    title: "Technical Project Manager",
    company: "Payflow",
    salary: "28K - 40K EUR",
    date: "July 2021",
    average_salary: 34,
    place: "Barcelona",
  },
  {
    id: 751,
    title: "Sr/Staff Software Engineer (Rails & React)",
    company: "Factorial HR",
    salary: "50K - 65K EUR",
    date: "July 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 752,
    title: "Sr Software Engineer (Growth Enablement)",
    company: "Factorial HR",
    salary: "50K - 75K EUR",
    date: "July 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 753,
    title: "Jr/Mid Software Engineer (Rails & React)",
    company: "Factorial HR",
    salary: "35K - 40K EUR",
    date: "July 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 754,
    title: "Sr Data Analyst",
    company: "Factorial HR",
    salary: "50K - 50K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 755,
    title: "Data Analyst",
    company: "Docplanner",
    salary: "30K - 50K EUR",
    date: "July 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 756,
    title: "Frontend Developer",
    company: "Docplanner",
    salary: "32K - 55K EUR",
    date: "July 2021",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 757,
    title: "React Native Developer",
    company: "Docplanner",
    salary: "32K - 55K EUR",
    date: "July 2021",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 758,
    title: "Backend Engineer (C#)",
    company: "Docplanner",
    salary: "37K - 55K EUR",
    date: "July 2021",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 759,
    title: "Ruby On Rails and React Developer",
    company: "Rankbreeze",
    salary: "30K - 42K EUR",
    date: "July 2021",
    average_salary: 36,
    place: "Remote",
  },
  {
    id: 760,
    title: "Senior Software Engineer",
    company: "Scopely",
    salary: "40K - 60K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 761,
    title: "Frontend Engineer",
    company: "Scopely",
    salary: "35K - 55K EUR",
    date: "July 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 762,
    title: "Senior Frontend Engineer (Remote)",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "July 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 763,
    title: "Backend Developer(Payment integration, backoffice)",
    company: "Streamloots",
    salary: "50K - 50K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 764,
    title: "Full Stack Engineer",
    company: "Payflow",
    salary: "36K - 48K EUR",
    date: "July 2021",
    average_salary: 42,
    place: "Barcelona",
  },
  {
    id: 765,
    title: "Senior Frontend Developer",
    company: "Shimoku",
    salary: "50K - 60K EUR",
    date: "July 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 766,
    title: "Backend Software Engineer",
    company: "Colvin",
    salary: "45K - 55K EUR",
    date: "July 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 767,
    title: "Full-Stack Developer",
    company: "Colvin",
    salary: "40K - 55K EUR",
    date: "July 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 768,
    title: "Senior iOS Developer",
    company: "Teamwire",
    salary: "60K - 75K EUR",
    date: "July 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 769,
    title: "Senior Ruby on Rails Developer",
    company: "Empire Flippers",
    salary: "85K - 100K EUR",
    date: "July 2021",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 770,
    title: "JS Team Lead",
    company: "The Hotels Network",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 771,
    title: "Backend Engineer",
    company: "Yova AG",
    salary: "115K - 140K EUR",
    date: "August 2021",
    average_salary: 127.5,
    place: "Remote",
  },
  {
    id: 772,
    title: "Principal Javascript Software Engineer",
    company: "Elastic.co",
    salary: "100K - 200K EUR",
    date: "August 2021",
    average_salary: 150,
    place: "Remote",
  },
  {
    id: 773,
    title: "Lead Software Engineer (Go)",
    company: "World Mobile",
    salary: "80K - 100K EUR",
    date: "August 2021",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 774,
    title: "Lead Software Engineer (Mobile)",
    company: "World Mobile",
    salary: "80K - 100K EUR",
    date: "August 2021",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 775,
    title: "Web Development Team Lead",
    company: "World Mobile",
    salary: "80K - 100K EUR",
    date: "August 2021",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 776,
    title: "Senior Software Engineer",
    company: "Vistaprint",
    salary: "50K - 60K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 777,
    title: ":sun_with_face:Java Engineer",
    company: "Dynatrace",
    salary: "42K - 62K EUR",
    date: "August 2021",
    average_salary: 52,
    place: "Barcelona/Remote",
  },
  {
    id: 778,
    title: ":sun_with_face: Javascript Engineer",
    company: "Dynatrace",
    salary: "42K - 62K EUR",
    date: "August 2021",
    average_salary: 52,
    place: "Barcelona/Remote",
  },
  {
    id: 779,
    title: ":sun_with_face: Frontend Engineer (Angular or React)",
    company: "Dynatrace",
    salary: "42K - 62K EUR",
    date: "August 2021",
    average_salary: 52,
    place: "Barcelona/Remote",
  },
  {
    id: 780,
    title: ":sun_with_face: Test Automation Engineer (Javascript)",
    company: "Dynatrace",
    salary: "42K - 62K EUR",
    date: "August 2021",
    average_salary: 52,
    place: "Barcelona/Remote",
  },
  {
    id: 781,
    title: ":sun_with_face: Lead Product Engineer",
    company: "Dynatrace",
    salary: "62K - 75K EUR",
    date: "August 2021",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 782,
    title: "Senior Software Engineer - Notifications",
    company: "GitHub",
    salary: "60K - 90K EUR",
    date: "August 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 783,
    title: "PHP Symfony / Laravel Developer",
    company: "Cyberdelia",
    salary: "30K - 30K EUR",
    date: "August 2021",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 784,
    title: "Expert Backend Engineer",
    company: "Back Market",
    salary: "60K - 80K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 785,
    title: "Expert Frontend Engineer",
    company: "Back Market",
    salary: "60K - 80K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 786,
    title: "Director of Engineering",
    company: "Bling",
    salary: "80K - 120K EUR",
    date: "August 2021",
    average_salary: 100,
    place: "Barcelona",
  },
  {
    id: 787,
    title: "Ruby Developer",
    company: "Bloom & Wild",
    salary: "50K - 60K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 788,
    title: "Senior Android Engineer",
    company: "Bloom & Wild",
    salary: "60K - 80K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 789,
    title: "DevOps Engineer (AWS)",
    company: "Bloom & Wild",
    salary: "60K - 80K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 790,
    title: "Software Engineer (Backend)",
    company: "Wayflyer",
    salary: "80K - 100K EUR",
    date: "August 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 791,
    title: "Engineering Manager",
    company: "Tessian",
    salary: "90K - 100K EUR",
    date: "August 2021",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 792,
    title: "Senior Android Engineer :taco:",
    company: "Katoo",
    salary: "40K - 60K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 793,
    title: "Senior Golang Developer",
    company: "Nicecactus",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 794,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "Capterra (Gartner)",
    salary: "70K - 70K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 795,
    title: "Lead Software Engineer (Ruby on Rails)",
    company: "Capterra (Gartner)",
    salary: "70K - 80K EUR",
    date: "August 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 796,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Capterra (Gartner)",
    salary: "70K - 70K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 797,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Capterra (Gartner)",
    salary: "70K - 80K EUR",
    date: "August 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 798,
    title: "Fullstack Javascript (React/Native,Node, Firebase)",
    company: "Emjoy",
    salary: "36K - 36K EUR",
    date: "August 2021",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 799,
    title: "Site Reliability Engineer",
    company: "Manomano",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 800,
    title: "Senior Data Engineer",
    company: "Manomano",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 801,
    title: "Senior Backend Engineer - Node JS",
    company: "Manomano",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 802,
    title: "Python Django Senior Engineer",
    company: "Akuaro",
    salary: "40K - 45K EUR",
    date: "August 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 803,
    title: "Pricing Consultant",
    company: "Akuaro",
    salary: "50K - 58K EUR",
    date: "August 2021",
    average_salary: 54,
    place: "Barcelona",
  },
  {
    id: 804,
    title: "JS Team Lead",
    company: "The Hotels Network",
    salary: "50K - 60K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 805,
    title: "JS Developer",
    company: "The Hotels Network",
    salary: "40K - 60K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 806,
    title: "PHP Engineer (Mid / Senior)",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "August 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 807,
    title: "Go Engineer",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "August 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 808,
    title: "Data Engineer",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "August 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 809,
    title: "QA Analyst (Manual)",
    company: "GetApp (Gartner)",
    salary: "35K - 40K EUR",
    date: "August 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 810,
    title: "Backend Software Engineer (Ruby)",
    company: "GetApp (Gartner)",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 811,
    title: "Frontend Software Engineer (React)",
    company: "GetApp (Gartner)",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 812,
    title: "Remote Software Team Leader",
    company: "LX Group",
    salary: "59K - 98K EUR",
    date: "August 2021",
    average_salary: 78.5,
    place: "Remote",
  },
  {
    id: 813,
    title: "Lead Full Stack Software Engineer (.NET)",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "August 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 814,
    title: "Lead Data Engineer",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "August 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 815,
    title: "Senior Frontend Engineer",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "August 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 816,
    title: "Senior FrontEnd Engineer",
    company: "Totally",
    salary: "40K - 60K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 817,
    title: "Backend Software Engineer – Node.js",
    company: "SpotMe",
    salary: "100K - 130K EUR",
    date: "August 2021",
    average_salary: 115,
    place: "Remote",
  },
  {
    id: 818,
    title: "Senior DevOps Engineer",
    company: "Stayforlong",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 819,
    title: "Data Engineer",
    company: "Stayforlong",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 820,
    title: "Remote Ruby on Rails Developer",
    company: "Craftybase",
    salary: "85K - 127K EUR",
    date: "August 2021",
    average_salary: 106,
    place: "Remote",
  },
  {
    id: 821,
    title: "DevOps Engineer",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "August 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 822,
    title: "Senior Frontend Developer",
    company: "Red Points",
    salary: "30K - 40K EUR",
    date: "August 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 823,
    title: "Senior Java Developer",
    company: "Red Points",
    salary: "35K - 45K EUR",
    date: "August 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 824,
    title: "Python Developer x5!",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "August 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 825,
    title: "Senior Software Engineer, Full Stack",
    company: "Tribaldata",
    salary: "30K - 40K EUR",
    date: "August 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 826,
    title: "Senior Backend Engineer",
    company: "DuckDuckGo",
    salary: "160K - 160K EUR",
    date: "August 2021",
    average_salary: 160,
    place: "Remote",
  },
  {
    id: 827,
    title: "Full Stack Engineer",
    company: "Koa Health",
    salary: "50K - 60K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 828,
    title: "Senior Solutions Architect",
    company: "Koa Health",
    salary: "75K - 85K EUR",
    date: "August 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 829,
    title: "Senior Front End Engineer",
    company: "Ninety Nine",
    salary: "50K - 60K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 830,
    title: "Technology Project Manager with tech background",
    company: "Xcelirate",
    salary: "50K - 80K EUR",
    date: "August 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 831,
    title: "DevOps team lead",
    company: "Xcelirate",
    salary: "70K - 100K EUR",
    date: "August 2021",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 832,
    title: "Senior Mobile Developer",
    company: "Holded",
    salary: "40K - 55K EUR",
    date: "August 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 833,
    title: "Data Scientist",
    company: "Typeform",
    salary: "40K - 50K EUR",
    date: "August 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 834,
    title: "QA Lead",
    company: "Typeform",
    salary: "60K - 60K EUR",
    date: "August 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 835,
    title: "Site Reliability Engineer",
    company: "Mytheresa",
    salary: "53K - 60K EUR",
    date: "August 2021",
    average_salary: 56.5,
    place: "Barcelona",
  },
  {
    id: 836,
    title: "Frontend Developer",
    company: "Mytheresa",
    salary: "50K - 50K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 837,
    title: "Frontend Tech Lead",
    company: "Holded",
    salary: "55K - 75K EUR",
    date: "August 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 838,
    title: "Site Reliability Engineering (SRE)",
    company: "Holded",
    salary: "50K - 70K EUR",
    date: "August 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 839,
    title: "Senior Frontend Developer",
    company: "Qida",
    salary: "35K - 45K EUR",
    date: "August 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 840,
    title: "Senior Software Engineer",
    company: "Zazume",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 841,
    title: "Tech Lead",
    company: "Acqustic",
    salary: "40K - 60K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 842,
    title: "Product Designer",
    company: "Acework",
    salary: "40K - 80K USD",
    date: "August 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 843,
    title: "Javascript Engineer",
    company: "Acework",
    salary: "70K - 110K USD",
    date: "August 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 844,
    title: "Frontend Developer",
    company: "Acework",
    salary: "35K - 70K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 845,
    title: "Full Stack React Engineer",
    company: "Constructor.io",
    salary: "80K - 120K USD",
    date: "August 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 846,
    title: "Lead Software Engineer",
    company: "Personio",
    salary: "70K - 80K EUR",
    date: "August 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 847,
    title: "iOS Developer",
    company: "Volcanic Internet",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 848,
    title: "Android Developer",
    company: "Volcanic Internet",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 849,
    title: "Software Team Leader",
    company: "LX Group",
    salary: "60K - 90K USD",
    date: "August 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 850,
    title: "Senior DevOps",
    company: "Stayforlong",
    salary: "45K - 65K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 851,
    title: "Frontend Developer",
    company: "Nextail",
    salary: "55K - 55K EUR",
    date: "August 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 852,
    title: "Product Data Analyst",
    company: "TheVentureCity",
    salary: "65K - 65K EUR",
    date: "August 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 853,
    title: "Data Engineer",
    company: "Kognia Sports",
    salary: "35K - 45K EUR",
    date: "August 2021",
    average_salary: 40,
    place: "Remote (COVID)",
  },
  {
    id: 854,
    title: "iOS Developer",
    company: "Volcanic Internet",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 855,
    title: "Android Developer",
    company: "Volcanic Internet",
    salary: "45K - 55K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 856,
    title: "Remote JavaScript Developer (Ember, Svelte)",
    company: "DockYard",
    salary: "40K - 110K USD",
    date: "August 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 857,
    title: "Backend Developer (Payment Integration&backoffice)",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "August 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 858,
    title: "Senior Backend Engineer",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "August 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 859,
    title: "Senior Full Stack Developer",
    company: "Flamingo",
    salary: "120K - 150K USD",
    date: "August 2021",
    average_salary: 135,
    place: "Remote",
  },
  {
    id: 860,
    title: "Full-stack Developer",
    company: "Dogfy Diet",
    salary: "40K - 50K EUR",
    date: "August 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 861,
    title: "Fullstack Developer (Junior)",
    company: "Pam Learning",
    salary: "22K - 22K EUR",
    date: "August 2021",
    average_salary: 22,
    place: "Barcelona/Remote",
  },
  {
    id: 862,
    title: "Backend Engineer",
    company: "Inyova AG",
    salary: "100K - 120K CHF",
    date: "August 2021",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 863,
    title: "Senior Frontend Engineer",
    company: "Acework",
    salary: "60K - 70K EUR",
    date: "August 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 864,
    title: "Buena persona que domine Python",
    company: "Alfred Smart Systems",
    salary: "50K - 50K EUR",
    date: "August 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 865,
    title: "Senior Software Engineer in Test - QA Automation",
    company: "New Work",
    salary: "45K - 58K EUR",
    date: "August 2021",
    average_salary: 51.5,
    place: "Barcelona/Remote",
  },
  {
    id: 866,
    title: "Senior Backend Software Engineer",
    company: "EdgeTier",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 867,
    title: "Senior Backend Developer",
    company: "LoveToKnow Media",
    salary: "50K - 65K EUR",
    date: "August 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 868,
    title: "Frontend Engineer",
    company: "LoveToKnow Media",
    salary: "45K - 60K EUR",
    date: "August 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 869,
    title: "Engineering Manager",
    company: "Back Market",
    salary: "60K - 85K EUR",
    date: "August 2021",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 870,
    title: "Engineering Team Lead",
    company: "Cobee",
    salary: "55K - 65K EUR",
    date: "August 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 871,
    title: "Senior Frontend Developer",
    company: "Ramp",
    salary: "60K - 80K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 872,
    title: "Backend Engineer (Node)",
    company: "Ramp",
    salary: "60K - 80K EUR",
    date: "August 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 873,
    title: "Analytics Engineer (any seniority level)",
    company: "Holded",
    salary: "40K - 50K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 874,
    title: "PHP Developer",
    company: "She",
    salary: "40K - 45K EUR",
    date: "September 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 875,
    title: "Android Developer",
    company: "Mobivery",
    salary: "25K - 30K EUR",
    date: "September 2021",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 876,
    title: "Ruby on Rails Developer",
    company: "Jobandtalent",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 877,
    title: "Product Data Analyst",
    company: "TheVentureCity",
    salary: "65K - 65K EUR",
    date: "September 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 878,
    title: "Java Software Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 879,
    title: "Javascript Engineer",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 880,
    title: "Frontend Engineer (Angular or React)",
    company: "Dynatrace",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 881,
    title: "Test Automation Engineer (JS)",
    company: "Dynatrace",
    salary: "45K - 60K EUR",
    date: "September 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 882,
    title: "Python Backend Developer",
    company: "Invisible Bits",
    salary: "40K - 55K EUR",
    date: "September 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 883,
    title: "Software Engineer (web-script agent / JS)",
    company: "The Hotels Network",
    salary: "30K - 60K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 884,
    title: "Platform Engineer Coordinator",
    company: "Adevinta",
    salary: "50K - 75K EUR",
    date: "September 2021",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 885,
    title: "Sw Engineer - Container Orchestration Platform",
    company: "New Relic",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 886,
    title: "Frontend developer",
    company: "Aizon",
    salary: "45K - 70K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 887,
    title: "Senior Site Reliability Engineer",
    company: "PayFit",
    salary: "50K - 70K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 888,
    title: "Senior Python Developer",
    company: "UniQ Ventures",
    salary: "40K - 60K EUR",
    date: "September 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 889,
    title: "Backend Developer (Payments)",
    company: "Genially",
    salary: "35K - 50K EUR",
    date: "September 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 890,
    title: "Full Stack Developer",
    company: "Barkibu",
    salary: "30K - 40K EUR",
    date: "September 2021",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 891,
    title: "Back-end Developer",
    company: "Auvik",
    salary: "45K - 64K EUR",
    date: "September 2021",
    average_salary: 54.5,
    place: "Barcelona/Remote",
  },
  {
    id: 892,
    title: "Senior Software Engineer - Talent Management",
    company: "Factorial",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 893,
    title: "Test Engineer / Consultant",
    company: "Dragonfly",
    salary: "30K - 45K EUR",
    date: "September 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 894,
    title: "Junior Ruby on Rails developer",
    company: "Xing / New Work SE",
    salary: "25K - 44K EUR",
    date: "September 2021",
    average_salary: 34.5,
    place: "Barcelona/Remote",
  },
  {
    id: 895,
    title: "PHP Developer",
    company: "Cyberdelia",
    salary: "25K - 30K EUR",
    date: "September 2021",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 896,
    title: "IT Support (VoIP)",
    company: "Doctoralia",
    salary: "27K - 29K EUR",
    date: "September 2021",
    average_salary: 28,
    place: "Barcelona/Remote",
  },
  {
    id: 897,
    title: "Senior Back-End Engineer",
    company: "Omure",
    salary: "110K - 150K USD",
    date: "September 2021",
    average_salary: 130,
    place: "Remote",
  },
  {
    id: 898,
    title: "Senior Front-End Engineer",
    company: "Omure",
    salary: "110K - 150K USD",
    date: "September 2021",
    average_salary: 130,
    place: "Remote",
  },
  {
    id: 899,
    title: "Platform/Support Engineer (Junior/Mid)",
    company: "Abiquo",
    salary: "28K - 41K EUR",
    date: "September 2021",
    average_salary: 34.5,
    place: "Barcelona/Remote",
  },
  {
    id: 900,
    title: "Mobile Developer",
    company: "Holded",
    salary: "40K - 55K EUR",
    date: "September 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 901,
    title: "Mobile Engineer (iOS/Android)",
    company: "Lupa Run Ltd",
    salary: "30K - 45K EUR",
    date: "September 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 902,
    title: "Full Stack Software Developer",
    company: "Bigle Legal",
    salary: "25K - 35K EUR",
    date: "September 2021",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 903,
    title: "Frontend Developer (Vue.js)",
    company: "LoveToKnow Media",
    salary: "45K - 60K EUR",
    date: "September 2021",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 904,
    title: "Software Engineer, Frontend (React/ React Native)",
    company: "Vesta Healthcare",
    salary: "60K - 85K EUR",
    date: "September 2021",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 905,
    title: "(Senior) Backend Engineer",
    company: "travel audience",
    salary: "65K - 80K EUR",
    date: "September 2021",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 906,
    title: "Extreme Programming Coach",
    company: "Exeal",
    salary: "36K - 42K EUR",
    date: "September 2021",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 907,
    title: "React Native Developer",
    company: "Orbit",
    salary: "65K - 65K EUR",
    date: "September 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 908,
    title: "Site Reliability Engineer (SRE)",
    company: "Tempus Labs",
    salary: "55K - 65K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 909,
    title: "Senior/Staff Software Engineer",
    company: "Factorial",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 910,
    title: "Mid Software Engineer",
    company: "Factorial",
    salary: "40K - 40K EUR",
    date: "September 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 911,
    title: "Senior Backend Engineer",
    company: "Streamloots",
    salary: "50K - 50K EUR",
    date: "September 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 912,
    title: "Engineering Manager",
    company: "Chartboost",
    salary: "80K - 80K EUR",
    date: "September 2021",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 913,
    title: "Senior Android Engineer",
    company: "Chartboost",
    salary: "68K - 68K EUR",
    date: "September 2021",
    average_salary: 68,
    place: "Barcelona",
  },
  {
    id: 914,
    title: "Senior SW Engineer (BE)",
    company: "Chartboost",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 915,
    title: "Tech Lead (any seniority level)",
    company: "Holded",
    salary: "55K - 75K EUR",
    date: "September 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 916,
    title: "Senior Backend Developer (Node.js)",
    company: "LoveToKnow Media",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 917,
    title: "Senior / Lead Rails Engineer",
    company: "Parallel Capital",
    salary: "90K - 150K USD",
    date: "September 2021",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 918,
    title: "Senior back end",
    company: "OriGen AI",
    salary: "40K - 55K EUR",
    date: "September 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 919,
    title: "Engineer - Container Orchestration Platform",
    company: "New Relic",
    salary: "45K - 75K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 920,
    title: "Senior Back-end Developer",
    company: "Dogfy Diet",
    salary: "40K - 50K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 921,
    title: "Back-End Developer -Serverless",
    company: "NextLink Solutions",
    salary: "40K - 50K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 922,
    title: "Sr. Data Scientist / Eng. for NLP",
    company: "Moodys Analytics",
    salary: "70K - 110K EUR",
    date: "September 2021",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 923,
    title: "Python Developer for Electric Vehicle Optimization",
    company: "Ampcontrol.io",
    salary: "60K - 120K USD",
    date: "September 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 924,
    title: "Senior Machine Learning Engineer (with NLP)",
    company: "Brainly",
    salary: "70K - 95K EUR",
    date: "September 2021",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 925,
    title: "Angular Developer",
    company: "Volcanic Internet",
    salary: "40K - 50K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 926,
    title: "Senior BackEnd Engineer",
    company: "GotPhoto/fotograf.de",
    salary: "65K - 75K EUR",
    date: "September 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 927,
    title: "Frontend Developer (Shopify Development)",
    company: "Netalico",
    salary: "20K - 100K USD",
    date: "September 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 928,
    title: "Software developer (30h/semana)",
    company: "Qatium",
    salary: "45K - 45K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 929,
    title: "Senior Frontend Engineer",
    company: "xpertAI",
    salary: "48K - 48K EUR",
    date: "September 2021",
    average_salary: 48,
    place: "Barcelona/Remote",
  },
  {
    id: 930,
    title: "Lead frontend developer y mejor persona",
    company: "Alfred Smart Systems",
    salary: "45K - 50K EUR",
    date: "September 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 931,
    title: "(Senior) Software Engineer",
    company: "Stayforlong.com",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 932,
    title: "Data Engineer",
    company: "Stayforlong.com",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 933,
    title: "DevOps Engineer",
    company: "Stayforlong.com",
    salary: "45K - 65K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 934,
    title: "Backend Software Engineer (Ruby)",
    company: "GetApp - Gartner",
    salary: "50K - 70K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 935,
    title: "Staff Software Engineer ( Full Stack Node/React)",
    company: "Capterra - Gartner",
    salary: "70K - 80K EUR",
    date: "September 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 936,
    title: "Lead Software Engineer (Ruby on Rails)",
    company: "Capterra - Gartner",
    salary: "70K - 80K EUR",
    date: "September 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 937,
    title: "Lead Full Stack Software Engineer (.NET Core)",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "September 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 938,
    title: "Lead Backend Developer",
    company: "Satoshis Games",
    salary: "60K - 70K EUR",
    date: "September 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 939,
    title: "Javascript <> Analytics Engineer",
    company: "Improving Metrics",
    salary: "35K - 45K EUR",
    date: "September 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 940,
    title: "Mobile Developer",
    company: "Paired",
    salary: "80K - 80K EUR",
    date: "September 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 941,
    title: "PHP Developer",
    company: "Widitrade",
    salary: "35K - 45K EUR",
    date: "September 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 942,
    title: "Head of Architecture",
    company: "Small World",
    salary: "65K - 85K EUR",
    date: "September 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 943,
    title: "Software Developer",
    company: "Zazume",
    salary: "60K - 60K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 944,
    title: "iOS Developer",
    company: "Peaks",
    salary: "72K - 72K EUR",
    date: "September 2021",
    average_salary: 72,
    place: "Remote (COVID)",
  },
  {
    id: 945,
    title: "Senior Software Engineer, Android SDKs",
    company: "Auth0",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 946,
    title: "Senior Full Stack Software Engineer",
    company: "800.com",
    salary: "200K - 200K USD",
    date: "September 2021",
    average_salary: 200,
    place: "Remote",
  },
  {
    id: 947,
    title: "Senior Software Engineer (Python)",
    company: "Onna.com",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 948,
    title: "Senior Java Developer",
    company: "Mango",
    salary: "55K - 55K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 949,
    title: "(Senior) Backend Engineer",
    company: "The Jodel Venture",
    salary: "60K - 80K EUR",
    date: "September 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 950,
    title: "Intermediate Software Engineer (back-end)",
    company: "Devex",
    salary: "36K - 46K EUR",
    date: "September 2021",
    average_salary: 41,
    place: "Remote (COVID)",
  },
  {
    id: 951,
    title: "Senior Software Engineer (back-end)",
    company: "Devex",
    salary: "41K - 52K EUR",
    date: "September 2021",
    average_salary: 46.5,
    place: "Remote (COVID)",
  },
  {
    id: 952,
    title: "Full-stack Engineer (with Go or willing to learn)",
    company: "Brainly",
    salary: "35K - 55K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 953,
    title: "Senior Backend Engineer (Remote)",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 954,
    title: "Frontend Developer",
    company: "Tuotempo",
    salary: "30K - 48K EUR",
    date: "September 2021",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 955,
    title: "Backend Developer",
    company: "Tuotempo",
    salary: "28K - 40K EUR",
    date: "September 2021",
    average_salary: 34,
    place: "Remote",
  },
  {
    id: 956,
    title: "IT Manager",
    company: "YouTOOProject",
    salary: "60K - 60K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 957,
    title: "Frontend Developer",
    company: "Inbestme",
    salary: "30K - 35K EUR",
    date: "September 2021",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 958,
    title: "Senior DevOps",
    company: "Seqera Labs",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 959,
    title: "Principal Software Engineer",
    company: "Seqera Labs",
    salary: "60K - 75K EUR",
    date: "September 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 960,
    title: "Software Engineer - Groovy / Java",
    company: "Seqera Labs",
    salary: "40K - 50K EUR",
    date: "September 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 961,
    title: "Back End Developer",
    company: "Inbestme",
    salary: "30K - 35K EUR",
    date: "September 2021",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 962,
    title: "Senior TypeScript/React Engineer",
    company: "Goodlord",
    salary: "65K - 75K EUR",
    date: "September 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 963,
    title: "Front-End Engineer (React)",
    company: "Goodlord",
    salary: "50K - 60K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 964,
    title: "Frontend Engineer",
    company: "Loc.Tax",
    salary: "100K - 100K EUR",
    date: "September 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 965,
    title: "Senior Backend Developer",
    company: "Mailtrack.io",
    salary: "45K - 55K EUR",
    date: "September 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 966,
    title: "Senior Frontend Engineer",
    company: "Stayforlong.com",
    salary: "40K - 65K EUR",
    date: "September 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 967,
    title: "Senior Software Engineer",
    company: "Cubyn",
    salary: "55K - 70K EUR",
    date: "September 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 968,
    title: "Junior Data Analyst",
    company: "Optimus Price",
    salary: "24K - 36K EUR",
    date: "September 2021",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 969,
    title: "Senior Data Engineer",
    company: "LoveToKnow Media",
    salary: "50K - 65K EUR",
    date: "September 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 970,
    title: "Data Engineer",
    company: "Tinybird",
    salary: "63K - 63K EUR",
    date: "September 2021",
    average_salary: 63,
    place: "Remote",
  },
  {
    id: 971,
    title: "Back-end Engineer",
    company: "TipsterChat",
    salary: "45K - 55K EUR",
    date: "September 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 972,
    title: "Senior Front-End Engineer.",
    company: "Omure",
    salary: "90K - 90K USD",
    date: "September 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 973,
    title: "Engineering Lead (React)",
    company: "Capterra (Gartner)",
    salary: "70K - 80K EUR",
    date: "September 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 974,
    title: "SRE / Cloud Engineer",
    company: "Justos",
    salary: "55K - 65K EUR",
    date: "September 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 975,
    title: "Javascript Backend Developer",
    company: "BCOME",
    salary: "50K - 60K EUR",
    date: "September 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 976,
    title: "Tech Lead (Site Reliability Engineering)",
    company: "Adevinta",
    salary: "68K - 90K EUR",
    date: "October 2021",
    average_salary: 79,
    place: "Barcelona/Remote",
  },
  {
    id: 977,
    title: "Senior Software Engineer (back-end)",
    company: "Devex",
    salary: "41K - 52K EUR",
    date: "October 2021",
    average_salary: 46.5,
    place: "Barcelona",
  },
  {
    id: 978,
    title: "Python Developer",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "October 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 979,
    title: "Frontend Developer (JavaScript - React - Redux)",
    company: "Red Points",
    salary: "30K - 50K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 980,
    title: "DevOps Engineer",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 981,
    title: "Backend Java Developer",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "October 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 982,
    title: "Android Software Engineer",
    company: "The Left Bit",
    salary: "50K - 56K EUR",
    date: "October 2021",
    average_salary: 53,
    place: "Barcelona/Remote",
  },
  {
    id: 983,
    title: "Java Software Engineer",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 984,
    title: "Javascript Software Engineer (Browsers)",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 985,
    title: "Frontend Engineer (Angular or React)",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 986,
    title: "Lead Product Engineer",
    company: "Dynatrace",
    salary: "65K - 85K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 987,
    title: "Junior sysadmin (GNU/Linux)",
    company: "Alfred Smart Systems",
    salary: "24K - 30K EUR",
    date: "October 2021",
    average_salary: 27,
    place: "Barcelona/Remote",
  },
  {
    id: 988,
    title: "Tech lead",
    company: "Holafly",
    salary: "55K - 65K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 989,
    title: "Engineering Manager",
    company: "The Workshop",
    salary: "60K - 70K EUR",
    date: "October 2021",
    average_salary: 65,
    place: "Remote (COVID)",
  },
  {
    id: 990,
    title: "QA Engineer (junior/mid)",
    company: "Wiris",
    salary: "25K - 35K EUR",
    date: "October 2021",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 991,
    title: "Senior Frontend Engineer (React)",
    company: "Pennylane",
    salary: "60K - 80K EUR",
    date: "October 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 992,
    title: "Senior Backend Engineer (Ruby)",
    company: "Pennylane",
    salary: "60K - 80K EUR",
    date: "October 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 993,
    title: "Software Architect",
    company: "Idneo Technologies",
    salary: "45K - 55K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 994,
    title: "React Web Developer",
    company: "Adaptive",
    salary: "40K - 55K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 995,
    title: "PHP Senior Backend or FullStack | Freelance >25€/h",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "October 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 996,
    title: "Platform Engineer",
    company: "Jobandtalent",
    salary: "50K - 75K EUR",
    date: "October 2021",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 997,
    title: "Senior Frontend Developer",
    company: "Nextail",
    salary: "65K - 65K EUR",
    date: "October 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 998,
    title: "Mobile Developer",
    company: "Paired",
    salary: "80K - 80K EUR",
    date: "October 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 999,
    title: "PHP Developer",
    company: "Widitrade",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1000,
    title: "Backend Developer",
    company: "Sparta",
    salary: "40K - 45K EUR",
    date: "October 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1001,
    title: "PHP Developer",
    company: "Coches.com",
    salary: "40K - 45K EUR",
    date: "October 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1002,
    title: "Backend .NET Developer",
    company: "Debos",
    salary: "35K - 40K EUR",
    date: "October 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1003,
    title: ".NET Developer con WPF",
    company: "Picis",
    salary: "45K - 52K EUR",
    date: "October 2021",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1004,
    title: "Jr. Front-End Developer & UX/UI Designer",
    company: "Trialing Health",
    salary: "20K - 27K EUR",
    date: "October 2021",
    average_salary: 23.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1005,
    title: "Frontend Developer",
    company: "e.pilot GmbH",
    salary: "55K - 80K EUR",
    date: "October 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1006,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "60K - 100K EUR",
    date: "October 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1007,
    title: "Senior Software Engineer (K8s)",
    company: "Form3",
    salary: "60K - 100K EUR",
    date: "October 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1008,
    title: "Senior Platform Engineer",
    company: "Form3",
    salary: "60K - 100K EUR",
    date: "October 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1009,
    title: "Senior Full-Stack Developer",
    company: "SabadellZurich",
    salary: "35K - 50K EUR",
    date: "October 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1010,
    title: "Infrastructure/Cloud Operations Principal Engineer",
    company: "Openbravo",
    salary: "45K - 55K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1011,
    title: "Senior Java Developer",
    company: "The Agile Monkeys",
    salary: "50K - 60K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1012,
    title: "Data Platform Engineer",
    company: "Tempus Labs",
    salary: "60K - 70K EUR",
    date: "October 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1013,
    title: "Release Engineer",
    company: "Sketch",
    salary: "75K - 95K EUR",
    date: "October 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1014,
    title: "Elixir developer",
    company: "Sketch",
    salary: "70K - 90K EUR",
    date: "October 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1015,
    title: "Backend Engineer (Python)",
    company: "Back Market",
    salary: "50K - 70K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1016,
    title: "Data Engineer",
    company: "Caravelo",
    salary: "40K - 50K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1017,
    title: "Tech Lead",
    company: "GotPhoto",
    salary: "80K - 90K EUR",
    date: "October 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1018,
    title: "Backend Engineer",
    company: "Picker",
    salary: "40K - 50K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1019,
    title: "Senior Ruby on Rails Full-stack Developer",
    company: "MarsBased",
    salary: "35K - 42K EUR",
    date: "October 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 1020,
    title: "Senior Ruby on Rails Backend Developer",
    company: "MarsBased",
    salary: "35K - 42K EUR",
    date: "October 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 1021,
    title: "Senior+ Software Engineer – Developer Experience",
    company: "Factorial",
    salary: "50K - 65K EUR",
    date: "October 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 1022,
    title: "Engineer Manager+ – Developer Experience",
    company: "Factorial",
    salary: "50K - 65K EUR",
    date: "October 2021",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 1023,
    title: "Engineering Director+ – Infrastructure",
    company: "Factorial",
    salary: "65K - 85K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1024,
    title: "Senior Product Manager",
    company: "Docplanner Tech",
    salary: "100K - 100K EUR",
    date: "October 2021",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 1025,
    title: "DevOps Lead",
    company: "Xcelirate",
    salary: "70K - 120K EUR",
    date: "October 2021",
    average_salary: 95,
    place: "Barcelona",
  },
  {
    id: 1026,
    title: "SysAdmin Linux N2",
    company: "ACKStorm",
    salary: "30K - 35K EUR",
    date: "October 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 1027,
    title: "Python Engineer",
    company: "Moonfire Ventures",
    salary: "95K - 110K EUR",
    date: "October 2021",
    average_salary: 102.5,
    place: "Remote",
  },
  {
    id: 1028,
    title: "Medior .Net Developer",
    company: "Asemblia",
    salary: "35K - 40K EUR",
    date: "October 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1029,
    title: "Ruby on Rails developer",
    company: "Reedsy",
    salary: "60K - 85K EUR",
    date: "October 2021",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1030,
    title: "Senior FullStack",
    company: "Vocdoni",
    salary: "65K - 65K EUR",
    date: "October 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1031,
    title: "Senior Backend Developer",
    company: "Adpone",
    salary: "50K - 55K EUR",
    date: "October 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1032,
    title: "Frontend Developer",
    company: "Shapelets",
    salary: "40K - 50K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1033,
    title: "Backend Software Engineer (Mid)",
    company: "HolaLuz",
    salary: "30K - 40K EUR",
    date: "October 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1034,
    title: "Senior Backend Engineer (CX Customer Care)",
    company: "HolaLuz",
    salary: "40K - 55K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1035,
    title: "Senior Backend Engineer (Go, early stage Fintech)",
    company: "Nala",
    salary: "110K - 110K USD",
    date: "October 2021",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 1036,
    title: "Senior Mobile App Engineer (Flutter, Fintech)",
    company: "Nala",
    salary: "110K - 110K USD",
    date: "October 2021",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 1037,
    title: "Senior JavaScript Developer",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1038,
    title: "Product Owner - Internal Scalability",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1039,
    title: "Product Owner Wind Analytics",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1040,
    title: "SCADA Project Manager",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1041,
    title: "Scrum Master",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1042,
    title: "Data Engineer",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1043,
    title: "QA Expert",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1044,
    title: "Senior Software Engineer",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1045,
    title: "Typescript Developer",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1046,
    title: "Senior .net Developer",
    company: "GreenPowerMonitor",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1047,
    title: "Senior DevOps Engineer",
    company: "Dive tech",
    salary: "45K - 55K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1048,
    title: "Senior NodeJS Software Engineer - Remote",
    company: "Colvin",
    salary: "45K - 60K EUR",
    date: "October 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1049,
    title: "Senior Full Stack Javascript Developer",
    company: "Colvin",
    salary: "45K - 65K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1050,
    title: "Software Engineer Tech Lead",
    company: "Colvin",
    salary: "45K - 65K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1051,
    title: "Backend Software Engineer - OpenBravo",
    company: "Colvin",
    salary: "40K - 55K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1052,
    title: "Software Engineer",
    company: "Bankflip",
    salary: "40K - 50K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 1053,
    title: "Senior Frontend Engineer",
    company: "Netskope",
    salary: "50K - 80K EUR",
    date: "October 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1054,
    title: "Senior QA Engineer",
    company: "Netskope",
    salary: "50K - 70K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1055,
    title: "Technical Product Owner",
    company: "XpertAI",
    salary: "55K - 55K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1056,
    title: ".NET Developer",
    company: "Finloop.com",
    salary: "40K - 50K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1057,
    title: "Senior Data Engineer",
    company: "letgo",
    salary: "40K - 72K EUR",
    date: "October 2021",
    average_salary: 56,
    place: "Barcelona/Remote",
  },
  {
    id: 1058,
    title: "Head of engineering / CTO",
    company: "Deliberry",
    salary: "60K - 75K EUR",
    date: "October 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1059,
    title: "Senior Backend Developer (PHP y Symfony)",
    company: "Deliberry",
    salary: "40K - 55K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1060,
    title: "Remote DevOps Engineer",
    company: "Ramp",
    salary: "60K - 80K EUR",
    date: "October 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1061,
    title: "Senior Frontend Engineer",
    company: "TechTree",
    salary: "60K - 80K EUR",
    date: "October 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1062,
    title: "Senior Backend Engineer",
    company: "TechTree",
    salary: "60K - 80K EUR",
    date: "October 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1063,
    title: "Solution Architect",
    company: "Giant Swarm",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1064,
    title: "Ruby on Rails Developer",
    company: "Reedsy",
    salary: "85K - 85K EUR",
    date: "October 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1065,
    title: "Junior Back-end Web Developer (Go or NodeJS)",
    company: "World Mobile Group",
    salary: "30K - 37K EUR",
    date: "October 2021",
    average_salary: 33.5,
    place: "Remote",
  },
  {
    id: 1066,
    title: "Junior Front-end Web Developer",
    company: "World Mobile Group",
    salary: "30K - 37K EUR",
    date: "October 2021",
    average_salary: 33.5,
    place: "Remote",
  },
  {
    id: 1067,
    title: "Senior SRE",
    company: "Adevinta Central",
    salary: "58K - 65K EUR",
    date: "October 2021",
    average_salary: 61.5,
    place: "Barcelona",
  },
  {
    id: 1068,
    title: "Product Minded Software Developer",
    company: "Cooltra",
    salary: "50K - 50K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1069,
    title: "Senior Scala BackEnd Developer",
    company: "Adevinta Central",
    salary: "55K - 80K EUR",
    date: "October 2021",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 1070,
    title: "Data Platform Engineer",
    company: "letgo",
    salary: "50K - 70K EUR",
    date: "October 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1071,
    title: "Lead Android Developer",
    company: "Future Workshops",
    salary: "60K - 65K EUR",
    date: "October 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1072,
    title: "Senior Java Software Engineer (Spring Boot)",
    company: "Idneo Technologies",
    salary: "45K - 55K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1073,
    title: "Senior Full Stack Elixir Developer (PETAL)",
    company: "MiPasa",
    salary: "60K - 110K EUR",
    date: "October 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1074,
    title: "Backend Software Engineer",
    company: "Netcentric",
    salary: "50K - 50K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1075,
    title: "Senior Mobile Developer",
    company: "eKuore",
    salary: "42K - 50K EUR",
    date: "October 2021",
    average_salary: 46,
    place: "Remote",
  },
  {
    id: 1076,
    title: "Director of Engineering",
    company: "Caravelo",
    salary: "50K - 60K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1077,
    title: "Platform Engineer",
    company: "Giant Swarm",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1078,
    title: "BackEnd Engineer",
    company: "Adevinta Central",
    salary: "50K - 58K EUR",
    date: "October 2021",
    average_salary: 54,
    place: "Barcelona",
  },
  {
    id: 1079,
    title: "Senior Backend Software Engineer",
    company: "Myos",
    salary: "60K - 75K EUR",
    date: "October 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1080,
    title: "Mid Software Engineer - Business Operations Team",
    company: "Factorial",
    salary: "40K - 40K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1081,
    title: "BI Developer - Analytics Engineer",
    company: "Typeform",
    salary: "45K - 55K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1082,
    title: "Senior Cloud SRE",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1083,
    title: "Senior Product Manager",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1084,
    title: "Senior iOS Engineer",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1085,
    title: "Senior Android Engineer",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1086,
    title: "Senior Frontend Engineer",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1087,
    title: "Senior Backend Engineer (Mobile Apps team)",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1088,
    title: "Senior Backend Engineer (Webapps team)",
    company: "11Onze",
    salary: "60K - 90K EUR",
    date: "October 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1089,
    title: "Engineering Manager, Product Security Assurance",
    company: "Auth0/Okta",
    salary: "70K - 100K EUR",
    date: "October 2021",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1090,
    title: "React developer",
    company: "Xcelirate",
    salary: "55K - 55K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1091,
    title: "Javascript Backend Developer",
    company: "Justos",
    salary: "50K - 60K EUR",
    date: "October 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1092,
    title: "Project Manager IT",
    company: "Delectatech",
    salary: "36K - 46K EUR",
    date: "October 2021",
    average_salary: 41,
    place: "Barcelona/Remote",
  },
  {
    id: 1093,
    title: "DevOps Engineer, Cloud Governance",
    company: "Adevinta",
    salary: "58K - 65K EUR",
    date: "October 2021",
    average_salary: 61.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1094,
    title: "Senior Front-end Developer",
    company: "Elements",
    salary: "41K - 60K EUR",
    date: "October 2021",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1095,
    title: "PHP Developer",
    company: "Westwing",
    salary: "25K - 45K EUR",
    date: "October 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 1096,
    title: "Senior Software Python Developer",
    company: "BMAT",
    salary: "50K - 50K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1097,
    title: "Technical Operations Engineer",
    company: "BMAT",
    salary: "35K - 35K EUR",
    date: "October 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1098,
    title: "Ruby On Rails Developer",
    company: "FCM Travel",
    salary: "40K - 60K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1099,
    title: "NLP Engineer",
    company: "M47 Labs",
    salary: "30K - 60K EUR",
    date: "October 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 1100,
    title: "Full Stack Developer (React & Python)",
    company: "Ampcontrol.io",
    salary: "40K - 80K USD",
    date: "October 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1101,
    title: "Senior Frontend - React, Javascript, HTML5",
    company: "ForceManager",
    salary: "45K - 55K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1102,
    title: "iOS Enginer",
    company: "Letgo",
    salary: "40K - 60K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1103,
    title: "Tech Lead .Net",
    company: "Turijobs",
    salary: "50K - 75K EUR",
    date: "October 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1104,
    title: "Mid or Senior Javascript Engineer",
    company: "The Hotels Network",
    salary: "35K - 65K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1105,
    title: "Engineering Manager",
    company: "Adobe",
    salary: "55K - 75K EUR",
    date: "October 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1106,
    title: "System Administrator",
    company: "Kodify.io",
    salary: "60K - 80K EUR",
    date: "October 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1107,
    title: "Senior Javascript Developer",
    company: "Adpone",
    salary: "45K - 50K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1108,
    title: "Senior Software Python Developer",
    company: "BMAT",
    salary: "50K - 50K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1109,
    title: "Tech Lead - Full Stack",
    company: "BMAT",
    salary: "50K - 50K EUR",
    date: "October 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1110,
    title: "Senior Frontend Engineer",
    company: "BMAT",
    salary: "37K - 37K EUR",
    date: "October 2021",
    average_salary: 37,
    place: "Barcelona/Remote",
  },
  {
    id: 1111,
    title: "Lead Backend Engineer",
    company: "GETRO",
    salary: "78K - 100K EUR",
    date: "October 2021",
    average_salary: 89,
    place: "Remote",
  },
  {
    id: 1112,
    title: "Tech Lead / CTO",
    company: "Classlife",
    salary: "40K - 55K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 1113,
    title: "Senior iOS Developer",
    company: "ADEVINTA",
    salary: "50K - 55K EUR",
    date: "October 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1114,
    title: "DevOps Engineer",
    company: "Small World",
    salary: "35K - 60K EUR",
    date: "October 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1115,
    title: "Infrastructure Manager",
    company: "Small World",
    salary: "65K - 70K EUR",
    date: "October 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1116,
    title: "Senior Front Developer",
    company: "Mirage Technologies",
    salary: "30K - 45K EUR",
    date: "October 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1117,
    title: "Product Manager",
    company: "GETRO",
    salary: "60K - 85K EUR",
    date: "October 2021",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1118,
    title: "Senior Android Developer",
    company: "ADEVINTA",
    salary: "50K - 55K EUR",
    date: "October 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1119,
    title: "Senior Backend Developer",
    company: "PRODUCT HACKERS",
    salary: "35K - 45K EUR",
    date: "October 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1120,
    title: "Mid/Senior Software Engineer",
    company: "ahead",
    salary: "35K - 50K EUR",
    date: "October 2021",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1121,
    title: "Senior Full Stack Engineer",
    company: "Stuart",
    salary: "50K - 75K EUR",
    date: "October 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1122,
    title: "Shopify/Liquid Developer",
    company: "Fuel Made",
    salary: "100K - 140K USD",
    date: "November 2021",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 1123,
    title: "Software Engineer – Back End - Python/Serverless",
    company: "WeGetFinancing",
    salary: "45K - 65K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1124,
    title: "Software Engineer – Merchant Integrations - PHP",
    company: "WeGetFinancing",
    salary: "45K - 55K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1125,
    title: "Senior Backend Node.js",
    company: "Sesamy",
    salary: "40K - 60K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1126,
    title: "Senior Backend Developer, Node.js /C#",
    company: "LoveToKnow Media",
    salary: "45K - 65K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1127,
    title: "Product Owner",
    company: "FCM Travel",
    salary: "35K - 43K EUR",
    date: "November 2021",
    average_salary: 39,
    place: "Barcelona/Remote",
  },
  {
    id: 1128,
    title: "Javascript Developer",
    company: "FCM Travel",
    salary: "40K - 54K EUR",
    date: "November 2021",
    average_salary: 47,
    place: "Barcelona/Remote",
  },
  {
    id: 1129,
    title: "OBT Product Manager",
    company: "FCM Travel",
    salary: "35K - 40K EUR",
    date: "November 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1130,
    title: "Android Developer",
    company: "XpertAI",
    salary: "42K - 42K EUR",
    date: "November 2021",
    average_salary: 42,
    place: "Remote",
  },
  {
    id: 1131,
    title: "Front End Engineer (React)",
    company: "Tempus Labs",
    salary: "60K - 70K EUR",
    date: "November 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1132,
    title: "Senior Full Stack NodeJS",
    company: "Softonic",
    salary: "45K - 80K EUR",
    date: "November 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1133,
    title: "Lead Backend (Ruby on Rails)",
    company: "Capterra (Gartner)",
    salary: "70K - 80K EUR",
    date: "November 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1134,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "Capterra (Gartner)",
    salary: "70K - 70K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1135,
    title: "Backend Software Engineer (Ruby on Rails)",
    company: "GetApp (Gartner)",
    salary: "45K - 55K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1136,
    title: "Full Stack Software Engineer (Node/React)",
    company: "Capterra (Gartner)",
    salary: "45K - 55K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1137,
    title: "Lead FullStack Software Engineer (NodeJS/React)",
    company: "Capterra (Gartner)",
    salary: "70K - 80K EUR",
    date: "November 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1138,
    title: "Mid-Senior Software Engineer (Elixir/JavaScript)",
    company: "Gartner",
    salary: "50K - 70K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1139,
    title: "Senior Cloud Engineer",
    company: "Capterra (Gartner)",
    salary: "80K - 80K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1140,
    title: "Senior .NET Software Engineer",
    company: "Gartner",
    salary: "70K - 70K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1141,
    title: "Staff Software Engineer (React)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "November 2021",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1142,
    title: "Senior React Software Engineer",
    company: "Gartner",
    salary: "70K - 70K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1143,
    title: "Front End Software Engineer (React)",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1144,
    title: "Senior Frontend Engineer",
    company: "BMAT",
    salary: "35K - 55K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1145,
    title: "Node.js Full-stack Developer",
    company: "MarsBased",
    salary: "35K - 42K EUR",
    date: "November 2021",
    average_salary: 38.5,
    place: "Remote",
  },
  {
    id: 1146,
    title: "Staff Frontend Software Engineer",
    company: "Eventbrite",
    salary: "75K - 85K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1147,
    title: "Senior Backend Developer",
    company: "Product Hackers",
    salary: "38K - 45K EUR",
    date: "November 2021",
    average_salary: 41.5,
    place: "Remote",
  },
  {
    id: 1148,
    title: "Senior PHP Developer",
    company: "Jotelulu",
    salary: "35K - 40K EUR",
    date: "November 2021",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1149,
    title: "Software Engineer (React)",
    company: "SessionLab",
    salary: "60K - 90K USD",
    date: "November 2021",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1150,
    title: "Engineering Manager",
    company: "Devex",
    salary: "47K - 75K EUR",
    date: "November 2021",
    average_salary: 61,
    place: "Remote (COVID)",
  },
  {
    id: 1151,
    title: "Junior Javascript Developer",
    company: "FCM Travel",
    salary: "25K - 32K EUR",
    date: "November 2021",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1152,
    title: "JAVA Developer",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 1153,
    title: "DevOps ( Kubernetes)",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 1154,
    title: "Database administrator ( DBA - Postgres)",
    company: "Red Points",
    salary: "55K - 65K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 1155,
    title: "Desarrollador/a Fullstack .NET",
    company: "Raona",
    salary: "38K - 38K EUR",
    date: "November 2021",
    average_salary: 38,
    place: "Barcelona/Remote",
  },
  {
    id: 1156,
    title: "Desarrollador/a SharePoint I Office 365 I React",
    company: "Raona",
    salary: "38K - 38K EUR",
    date: "November 2021",
    average_salary: 38,
    place: "Barcelona/Remote",
  },
  {
    id: 1157,
    title: "SEO Manager",
    company: "Docplanner",
    salary: "70K - 70K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1158,
    title: "Mid or Senior Backend Developer",
    company: "Shapelets",
    salary: "30K - 50K EUR",
    date: "November 2021",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1159,
    title: "Mid/Senior Backend PHP Laravel",
    company: "Mayo",
    salary: "30K - 40K EUR",
    date: "November 2021",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 1160,
    title: "Tech Lead",
    company: "Weecover",
    salary: "55K - 65K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 1161,
    title: "Software Engineer (Security Team)",
    company: "Adevinta Central",
    salary: "53K - 59K EUR",
    date: "November 2021",
    average_salary: 56,
    place: "Barcelona",
  },
  {
    id: 1162,
    title: "Medior Javascript Developer",
    company: "Adpone",
    salary: "45K - 50K EUR",
    date: "November 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1163,
    title: "Senior Frontend - React",
    company: "Laudus",
    salary: "65K - 75K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1164,
    title: "Senior Backend Java Developer",
    company: "Mindcurv",
    salary: "45K - 55K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1165,
    title: "Remote Junior Low Code Developer",
    company: "Connex Digital",
    salary: "60K - 60K USD",
    date: "November 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1166,
    title: "Frontend Engineer",
    company: "Wallapop",
    salary: "38K - 65K EUR",
    date: "November 2021",
    average_salary: 51.5,
    place: "Barcelona",
  },
  {
    id: 1167,
    title: "Backend Engineer",
    company: "Wallapop",
    salary: "40K - 70K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1168,
    title: "DevOps Engineer",
    company: "Wallapop",
    salary: "38K - 65K EUR",
    date: "November 2021",
    average_salary: 51.5,
    place: "Barcelona",
  },
  {
    id: 1169,
    title: "iOS Engineer",
    company: "Wallapop",
    salary: "38K - 65K EUR",
    date: "November 2021",
    average_salary: 51.5,
    place: "Barcelona",
  },
  {
    id: 1170,
    title: "Android Engineer",
    company: "Wallapop",
    salary: "38K - 65K EUR",
    date: "November 2021",
    average_salary: 51.5,
    place: "Barcelona",
  },
  {
    id: 1171,
    title: "Backend Engineer (Java)",
    company: "Caravelo :airplane:",
    salary: "32K - 48K EUR",
    date: "November 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1172,
    title: "Senior Operations Engineer",
    company: "Cloudbees Inc",
    salary: "180K - 180K USD",
    date: "November 2021",
    average_salary: 180,
    place: "Remote",
  },
  {
    id: 1173,
    title: "Software Engineer (TypeScript, React)",
    company: "Chartboost",
    salary: "45K - 60K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1174,
    title: "Senior Software Python Engineer",
    company: "BMAT",
    salary: "35K - 55K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1175,
    title: "Tech Lead .NET",
    company: "Yuki",
    salary: "55K - 75K EUR",
    date: "November 2021",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1176,
    title: "Senior Backend Developer",
    company: "Yuki",
    salary: "45K - 60K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1177,
    title: "Technical Support Engineer",
    company: "Signaturit",
    salary: "26K - 35K EUR",
    date: "November 2021",
    average_salary: 30.5,
    place: "Remote",
  },
  {
    id: 1178,
    title: "Senior Backend Engineer",
    company: "Tessian",
    salary: "90K - 110K EUR",
    date: "November 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1179,
    title: "QA Automation Engineer (JavaScript)",
    company: "Docplanner",
    salary: "40K - 60K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1180,
    title: "Backend Engineer",
    company: "Signaturit Solution",
    salary: "45K - 50K EUR",
    date: "November 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1181,
    title: "Front-End Engineer",
    company: "Brainly",
    salary: "50K - 50K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1182,
    title: "Mid/Senior Fullstack JavaScript Engineer",
    company: "Emjoy",
    salary: "35K - 50K EUR",
    date: "November 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1183,
    title: "DevOps ( Kubernetes)",
    company: "Iomed",
    salary: "40K - 45K EUR",
    date: "November 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1184,
    title: "Mid/Senior FullStack Python + React.js",
    company: "Dive tech",
    salary: "40K - 50K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1185,
    title: "C++ Developer",
    company: "Dive tech",
    salary: "40K - 55K EUR",
    date: "November 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1186,
    title: "Mid/Senior Cloud Engineer",
    company: "ACKStorm",
    salary: "40K - 50K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1187,
    title: "Mid/Senior Frontend Developer",
    company: "Reboot Studio",
    salary: "36K - 45K EUR",
    date: "November 2021",
    average_salary: 40.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1188,
    title: "Python Lead Software Engineer",
    company: "Allread MLT",
    salary: "45K - 60K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1189,
    title: "QA engineer",
    company: "Teamwire",
    salary: "36K - 42K EUR",
    date: "November 2021",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 1190,
    title: "Senior Backend Developer",
    company: "SMART Technologies",
    salary: "50K - 60K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1191,
    title: "Backend Developer",
    company: "BIMBA Y LOLA",
    salary: "45K - 50K EUR",
    date: "November 2021",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1192,
    title: "Mobile Developer",
    company: "Paired",
    salary: "80K - 80K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1193,
    title: "Senior Java Software Engineer",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1194,
    title: "Senior Javascript Engineer",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1195,
    title: "Senior Frontend Engineer",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1196,
    title: "Senior Product UX Designer",
    company: "Dynatrace",
    salary: "50K - 65K EUR",
    date: "November 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1197,
    title: "Fullstack Developer",
    company: "Cledara",
    salary: "40K - 60K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1198,
    title: "Backend or Frontend Developer",
    company: "Cledara",
    salary: "40K - 60K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1199,
    title: "Senior Ruby Developer",
    company: "RemoteLock",
    salary: "75K - 120K USD",
    date: "November 2021",
    average_salary: 97.5,
    place: "Remote",
  },
  {
    id: 1200,
    title: "DevOps Engineer",
    company: "Xcelirate",
    salary: "60K - 90K EUR",
    date: "November 2021",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1201,
    title: "React Developer",
    company: "Xcelirate",
    salary: "40K - 60K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1202,
    title: "Senior PHP Developer",
    company: "Xcelirate",
    salary: "50K - 65K EUR",
    date: "November 2021",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1203,
    title: "Senior Frontend Developer",
    company: "Xcelirate",
    salary: "45K - 65K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1204,
    title: "QA Automation Engineer",
    company: "Justos",
    salary: "40K - 50K EUR",
    date: "November 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1205,
    title: "Senior Go Engineer",
    company: "Gigapipe",
    salary: "60K - 80K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 1206,
    title: "Node Developer",
    company: "Pulpo",
    salary: "50K - 55K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1207,
    title: "Frontend (Vue / React) Developer",
    company: "Bestiario",
    salary: "32K - 42K EUR",
    date: "November 2021",
    average_salary: 37,
    place: "Barcelona/Remote",
  },
  {
    id: 1208,
    title: "Java (Spring) Backend Developer",
    company: "Bestiario",
    salary: "32K - 42K EUR",
    date: "November 2021",
    average_salary: 37,
    place: "Barcelona/Remote",
  },
  {
    id: 1209,
    title: "Senior Software Engineer",
    company: "Oliva Health",
    salary: "60K - 80K EUR",
    date: "November 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1210,
    title: "Senior Backend Engineer",
    company: "BMAT",
    salary: "45K - 65K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1211,
    title: "Front-end & UI Developer",
    company: "Wiris",
    salary: "27K - 40K EUR",
    date: "November 2021",
    average_salary: 33.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1212,
    title: "QA Engineer (Manual, willing to learn Automation)",
    company: "Wiris",
    salary: "26K - 38K EUR",
    date: "November 2021",
    average_salary: 32,
    place: "Barcelona/Remote",
  },
  {
    id: 1213,
    title: "Senior Backend Engineer (Python)",
    company: "Wayflyer",
    salary: "80K - 100K EUR",
    date: "November 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1214,
    title: "Senior Data Scientist",
    company: "Tessian",
    salary: "80K - 100K EUR",
    date: "November 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1215,
    title: "Engineering Manager / Director",
    company: "Factorial",
    salary: "50K - 85K EUR",
    date: "November 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1216,
    title: "Senior Backend Developer",
    company: "SEAT:CODE",
    salary: "55K - 65K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1217,
    title: "Junior Backend Developer",
    company: "cinemo GmbH",
    salary: "45K - 75K EUR",
    date: "November 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1218,
    title: "Senior Frontend Engineer",
    company: "Elastic.co",
    salary: "75K - 105K EUR",
    date: "November 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1219,
    title: "Product Manager",
    company: "Tempus Labs",
    salary: "60K - 70K EUR",
    date: "November 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1220,
    title: "Full Stack Engineer",
    company: "Mixmax",
    salary: "60K - 115K EUR",
    date: "November 2021",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1221,
    title: "Frontend Engineer (REACT)",
    company: "Xceed",
    salary: "45K - 55K EUR",
    date: "November 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1222,
    title: "Senior Mobile Developer",
    company: "eKuore",
    salary: "42K - 50K EUR",
    date: "November 2021",
    average_salary: 46,
    place: "Remote",
  },
  {
    id: 1223,
    title: "Javasript Backend Developer",
    company: "Justos",
    salary: "50K - 60K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1224,
    title: "Django Backend Developer",
    company: "Optimus Price",
    salary: "30K - 40K EUR",
    date: "November 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1225,
    title: "Back-end Engineer (Payments)",
    company: "Brainly",
    salary: "44K - 68K EUR",
    date: "November 2021",
    average_salary: 56,
    place: "Barcelona/Remote",
  },
  {
    id: 1226,
    title: "Full Stack Tech Lead",
    company: "BMAT",
    salary: "40K - 65K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1227,
    title: "Senior Product Manager :airplane:",
    company: "Caravelo",
    salary: "40K - 45K EUR",
    date: "November 2021",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 1228,
    title: "Senior Android Engineer",
    company: "11Onze",
    salary: "70K - 90K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1229,
    title: "Senior Backend Engineer",
    company: "11Onze",
    salary: "70K - 90K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1230,
    title: "Senior iOS Engineer",
    company: "11Onze",
    salary: "70K - 90K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1231,
    title: "Senior Cloud SRE",
    company: "11Onze",
    salary: "70K - 90K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1232,
    title: "Senior backend engineer",
    company: "Apto Payments",
    salary: "65K - 80K EUR",
    date: "November 2021",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1233,
    title: "Mobile Developer",
    company: "Paired",
    salary: "80K - 80K EUR",
    date: "November 2021",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1234,
    title: "RubyonRails Engineer",
    company: "JobandTalent",
    salary: "50K - 80K EUR",
    date: "November 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1235,
    title: "Platform Engineer",
    company: "JobandTalent",
    salary: "55K - 80K EUR",
    date: "November 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1236,
    title: "Lead Software Engineer (Python)",
    company: "AllRead",
    salary: "50K - 60K EUR",
    date: "November 2021",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1237,
    title: "Junior Software Engineer",
    company: "TravelPerk",
    salary: "35K - 45K EUR",
    date: "November 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1238,
    title: "Software Engineer",
    company: "TravelPerk",
    salary: "45K - 60K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1239,
    title: "iOS Engineer",
    company: "TravelPerk",
    salary: "45K - 60K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1240,
    title: "(Senior) Software Developer (m/f/d)",
    company: "femtasy",
    salary: "45K - 60K EUR",
    date: "November 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1241,
    title: "Frontend Engineer",
    company: "Xceed",
    salary: "40K - 55K EUR",
    date: "November 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1242,
    title: "Senior Backend Developer",
    company: "Product Hackers",
    salary: "37K - 45K EUR",
    date: "November 2021",
    average_salary: 41,
    place: "Remote",
  },
  {
    id: 1243,
    title: "Php Developer",
    company: "Deliverea",
    salary: "35K - 45K EUR",
    date: "November 2021",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1244,
    title: "SysAdmin Linux / DevOps Junior",
    company: "NPAW",
    salary: "25K - 30K EUR",
    date: "November 2021",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1245,
    title: "Junior Java Developer",
    company: "IT Claire Joster",
    salary: "27K - 30K EUR",
    date: "November 2021",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1246,
    title: "Senior React",
    company: "IT Claire Joster",
    salary: "45K - 48K EUR",
    date: "November 2021",
    average_salary: 46.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1247,
    title: "Junior React",
    company: "IT Claire Joster",
    salary: "27K - 30K EUR",
    date: "November 2021",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1248,
    title: "Full Stack Engineer",
    company: "Restb.ai",
    salary: "25K - 40K EUR",
    date: "November 2021",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 1249,
    title: "iOS Developer",
    company: "Storybeat",
    salary: "45K - 55K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1250,
    title: "Product Manager for EHR (Electronic Health Record)",
    company: "Docplanner Tech",
    salary: "108K - 108K EUR",
    date: "December 2021",
    average_salary: 108,
    place: "Barcelona/Remote",
  },
  {
    id: 1251,
    title: "Product Manager for Revenue Integrations",
    company: "Docplanner Tech",
    salary: "79K - 79K EUR",
    date: "December 2021",
    average_salary: 79,
    place: "Barcelona/Remote",
  },
  {
    id: 1252,
    title: "Senior Product Designer",
    company: "Docplanner Tech",
    salary: "82K - 82K EUR",
    date: "December 2021",
    average_salary: 82,
    place: "Barcelona/Remote",
  },
  {
    id: 1253,
    title: "Senior Full Stack Developer",
    company: "Premiumguest",
    salary: "60K - 80K EUR",
    date: "December 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1254,
    title: "Head of Product",
    company: "Gamestry",
    salary: "70K - 100K EUR",
    date: "December 2021",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1255,
    title: "Senior Ruby on Rails Developer",
    company: "Apiumhub",
    salary: "80K - 80K EUR",
    date: "December 2021",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 1256,
    title: "Front End Engineer",
    company: "PODFather Ltd",
    salary: "25K - 40K GBP",
    date: "December 2021",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 1257,
    title: "Senior Backend Developer",
    company: "Plum Guide",
    salary: "60K - 70K GBP",
    date: "December 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1258,
    title: "Senior Frontend Developer",
    company: "Plum Guide",
    salary: "60K - 70K GBP",
    date: "December 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1259,
    title: "Senior Project Manager | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "December 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1260,
    title: "Senior Frontend Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "December 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1261,
    title: "Senior Backend Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "December 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1262,
    title: "Senior Full-Stack Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "December 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1263,
    title: "Senior iOS Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "December 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1264,
    title: "Senior Android Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "December 2021",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1265,
    title: "Fullstack Engineer",
    company: "LocTax",
    salary: "90K - 90K EUR",
    date: "December 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1266,
    title: "Senior React Developer",
    company: "orbit",
    salary: "50K - 60K EUR",
    date: "December 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1267,
    title: "Technology Project Manager / Tech Lead",
    company: "Xcelirate",
    salary: "50K - 80K EUR",
    date: "December 2021",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1268,
    title: "Senior Software Engineer (Node.js)",
    company: "Tempus Labs",
    salary: "65K - 70K EUR",
    date: "December 2021",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1269,
    title: "Frontend developer with Ionic experience",
    company: "TBSCG",
    salary: "50K - 70K EUR",
    date: "December 2021",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1270,
    title: "Staff Frontend Engineer",
    company: "Liferay",
    salary: "60K - 75K EUR",
    date: "December 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1271,
    title: "Mid to Senior SysOps Engineer",
    company: "Flanks",
    salary: "45K - 55K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1272,
    title: "Director of Engineering",
    company: "Caravelo :airplane:",
    salary: "50K - 70K EUR",
    date: "December 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 1273,
    title: "Senior Golang Developer",
    company: "HUBUC",
    salary: "75K - 90K EUR",
    date: "December 2021",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 1274,
    title: ".Net Developer",
    company: "evicertia",
    salary: "45K - 56K EUR",
    date: "December 2021",
    average_salary: 50.5,
    place: "Remote",
  },
  {
    id: 1275,
    title: "Senior Frontend Engineer (React)",
    company: "Cobee",
    salary: "45K - 55K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1276,
    title: "Senior Backend Engineer (Node.js)",
    company: "Cobee",
    salary: "45K - 55K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1277,
    title: "Senior Software Engineer",
    company: "Devex",
    salary: "41K - 60K EUR",
    date: "December 2021",
    average_salary: 50.5,
    place: "Remote",
  },
  {
    id: 1278,
    title: "Data & Analytics Consultant",
    company: "NPAW",
    salary: "35K - 40K EUR",
    date: "December 2021",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1279,
    title: "Data Engineer",
    company: "Wiris",
    salary: "30K - 40K EUR",
    date: "December 2021",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1280,
    title: "QA Engineer",
    company: "Wiris",
    salary: "28K - 35K EUR",
    date: "December 2021",
    average_salary: 31.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1281,
    title: "Platform/Support Engineer (mid-senior)",
    company: "Abiquo",
    salary: "42K - 52K EUR",
    date: "December 2021",
    average_salary: 47,
    place: "Barcelona/Remote",
  },
  {
    id: 1282,
    title: "Solidity Developer",
    company: "Hashfighters",
    salary: "65K - 65K USD",
    date: "December 2021",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1283,
    title: "Junior Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "29K - 36K EUR",
    date: "December 2021",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1284,
    title: "Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "40K - 60K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1285,
    title: "Product Owner",
    company: "FCM Lab",
    salary: "35K - 43K EUR",
    date: "December 2021",
    average_salary: 39,
    place: "Barcelona/Remote",
  },
  {
    id: 1286,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "65K - 75K EUR",
    date: "December 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1287,
    title: "SCALA Data Engineer",
    company: "Adevinta",
    salary: "70K - 95K EUR",
    date: "December 2021",
    average_salary: 82.5,
    place: "Barcelona",
  },
  {
    id: 1288,
    title: "SRE",
    company: "Adevinta",
    salary: "60K - 80K EUR",
    date: "December 2021",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 1289,
    title: "Backend Developer",
    company: "mytheresa.com",
    salary: "50K - 60K EUR",
    date: "December 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1290,
    title: "Python dev Team Lead",
    company: "Red Points",
    salary: "45K - 55K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1291,
    title: "Backend Developer",
    company: "mytheresa",
    salary: "50K - 60K EUR",
    date: "December 2021",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1292,
    title: "Senior Frontend Developer",
    company: "TeamSquad",
    salary: "40K - 65K EUR",
    date: "December 2021",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1293,
    title: "S.R.E. / Cloud Engineer",
    company: "Intellum",
    salary: "85K - 85K EUR",
    date: "December 2021",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1294,
    title: "Ruby On Rails Full Stack Developer",
    company: "Eastern Canada",
    salary: "50K - 70K EUR",
    date: "December 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1295,
    title: "Backend-Rust-Developer",
    company: "filancore",
    salary: "55K - 80K EUR",
    date: "December 2021",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1296,
    title: "Data Engineer",
    company: "Brainly",
    salary: "30K - 60K EUR",
    date: "December 2021",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1297,
    title: "SRE",
    company: "Slite",
    salary: "50K - 70K EUR",
    date: "December 2021",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1298,
    title: "UI Designer",
    company: "appfire",
    salary: "55K - 55K EUR",
    date: "December 2021",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1299,
    title: "Senior Software Engineer",
    company: "humanITcare",
    salary: "55K - 70K EUR",
    date: "December 2021",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1300,
    title: "Full-Stack Developer | 25-40 €/h Freelance",
    company: "Mindbly",
    salary: "35K - 50K EUR",
    date: "December 2021",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1301,
    title: "Tech Lead / Lead Teacher MERN",
    company: "Assembler School",
    salary: "26K - 30K EUR",
    date: "December 2021",
    average_salary: 28,
    place: "Barcelona/Remote",
  },
  {
    id: 1302,
    title: "Freelance MERN Lead Teacher",
    company: "Assembler",
    salary: "26K - 30K EUR",
    date: "December 2021",
    average_salary: 28,
    place: "Remote",
  },
  {
    id: 1303,
    title: "Tech Lead Backend",
    company: "Patterson Agency",
    salary: "50K - 55K EUR",
    date: "December 2021",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1304,
    title: "Fullstack Engineer",
    company: "Veriff",
    salary: "42K - 58K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1305,
    title: "Fullstack Engineer",
    company: "MixMax",
    salary: "100K - 100K EUR",
    date: "December 2021",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1306,
    title: "Engineer Manager",
    company: "Landbot",
    salary: "60K - 85K EUR",
    date: "December 2021",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1307,
    title: "Senior Software Engineer",
    company: "Last.app",
    salary: "40K - 60K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1308,
    title: "Data Analyst",
    company: "Goin",
    salary: "30K - 70K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1309,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "65K - 75K EUR",
    date: "December 2021",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1310,
    title: "Senior Android Engineer",
    company: "Mobile Jazz",
    salary: "59K - 73K EUR",
    date: "December 2021",
    average_salary: 66,
    place: "Remote",
  },
  {
    id: 1311,
    title: "CTO",
    company: "Assembler School",
    salary: "60K - 60K EUR",
    date: "December 2021",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 1312,
    title: "Mid/Senior Back End Engineer (NodeJS)",
    company: "Vizzuality",
    salary: "30K - 48K EUR",
    date: "December 2021",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 1313,
    title: "Mid/Senior Back End Engineer (Ruby on Rails)",
    company: "Vizzuality",
    salary: "30K - 48K EUR",
    date: "December 2021",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 1314,
    title: "Mid/Senior Computer Vision Engineer",
    company: "Clutch",
    salary: "40K - 55K EUR",
    date: "December 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1315,
    title: "Frontend Engineer",
    company: "Xceed",
    salary: "50K - 50K EUR",
    date: "December 2021",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1316,
    title: "Senior Golang Backend Developer",
    company: "HUBUC",
    salary: "75K - 90K EUR",
    date: "December 2021",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 1317,
    title: "Software Engineer Backend",
    company: "Seqera Labs",
    salary: "40K - 55K EUR",
    date: "December 2021",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1318,
    title: "Tech Lead",
    company: "PcComponentes",
    salary: "48K - 65K EUR",
    date: "December 2021",
    average_salary: 56.5,
    place: "Remote",
  },
  {
    id: 1319,
    title: "Senior Android Engineer",
    company: "11Onze",
    salary: "90K - 90K EUR",
    date: "December 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1320,
    title: "Senior iOS Engineer",
    company: "11Onze",
    salary: "90K - 90K EUR",
    date: "December 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1321,
    title: "Senior Cloud SRE",
    company: "11Onze",
    salary: "90K - 90K EUR",
    date: "December 2021",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1322,
    title: "Backend Developer (PHP/Silverstripe)",
    company: "Virti",
    salary: "45K - 60K GBP",
    date: "January 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1323,
    title: "Software Engineer Backend",
    company: "Seqera",
    salary: "40K - 55K EUR",
    date: "January 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1324,
    title: "Senior Software Engineer (Node.js)",
    company: "Tempus",
    salary: "60K - 70K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1325,
    title: "CTO and Co-founder",
    company: "Assembler School",
    salary: "60K - 75K EUR",
    date: "January 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1326,
    title: "Senior Software Engineer",
    company: "humanITcare",
    salary: "55K - 70K EUR",
    date: "January 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1327,
    title: "Frontend Engineer",
    company: "Tempus",
    salary: "60K - 70K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1328,
    title: "Senior Backend Engineer(100%remote)",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "January 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1329,
    title: "Senior Frontend Engineer",
    company: "Streamloots",
    salary: "60K - 60K EUR",
    date: "January 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1330,
    title: "Data Analyst(100% remote)",
    company: "Streamloots",
    salary: "45K - 45K EUR",
    date: "January 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1331,
    title: "Junior Backend Engineer",
    company: "Trialing",
    salary: "20K - 30K EUR",
    date: "January 2022",
    average_salary: 25,
    place: "Barcelona/Remote",
  },
  {
    id: 1332,
    title: "Senior Frontend Developer",
    company: "LoveToKnow Media",
    salary: "50K - 70K EUR",
    date: "January 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1333,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "January 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1334,
    title: "Senior Software Engineer (Kubernetes)",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "January 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1335,
    title: "Senior Platform Engineer",
    company: "Form3",
    salary: "60K - 95K EUR",
    date: "January 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1336,
    title: "Senior React Software Engineer",
    company: "Bondex",
    salary: "70K - 85K EUR",
    date: "January 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1337,
    title: "Product Engineer (Backend)",
    company: "Wayflyer",
    salary: "70K - 90K EUR",
    date: "January 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1338,
    title: "Senior Product Engineer",
    company: "Wayflyer",
    salary: "90K - 110K EUR",
    date: "January 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1339,
    title: "Lead Engineer",
    company: "Wayflyer",
    salary: "110K - 130K EUR",
    date: "January 2022",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 1340,
    title: "Data Scientist",
    company: "Wayflyer",
    salary: "70K - 90K EUR",
    date: "January 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1341,
    title: "Senior Data Scientist",
    company: "Wayflyer",
    salary: "90K - 110K EUR",
    date: "January 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1342,
    title: "JS Developer with CSS",
    company: "Share CRF",
    salary: "25K - 35K EUR",
    date: "January 2022",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 1343,
    title: "React Native Developer",
    company: "Bereik",
    salary: "50K - 60K EUR",
    date: "January 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1344,
    title: ".Net Software Engineer",
    company: "Söderberg & Partners",
    salary: "40K - 60K EUR",
    date: "January 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1345,
    title: "Fullstack Engineer",
    company: "LocTax",
    salary: "60K - 90K EUR",
    date: "January 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1346,
    title: "Software Engineer Backend (100% remote)",
    company: "Seqera",
    salary: "40K - 55K EUR",
    date: "January 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1347,
    title: "QA Engineer",
    company: "Seqera",
    salary: "30K - 45K EUR",
    date: "January 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1348,
    title: "Senior Frontend Developer",
    company: "Edge Impulse",
    salary: "70K - 90K EUR",
    date: "January 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1349,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "January 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 1350,
    title: "Senior Software Engineer",
    company: "Cabify",
    salary: "40K - 85K EUR",
    date: "January 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1351,
    title: "Senior Frontend Engineer",
    company: "LoveToKnow Media",
    salary: "60K - 70K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Remote (COVID)",
  },
  {
    id: 1352,
    title: "Product Manager (Admin Engagement)",
    company: "Docplanner Tech",
    salary: "108K - 108K EUR",
    date: "January 2022",
    average_salary: 108,
    place: "Barcelona/Remote",
  },
  {
    id: 1353,
    title: "Fullstack",
    company: "Shakers",
    salary: "35K - 40K EUR",
    date: "January 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1354,
    title: "SEO Manager",
    company: "Docplanner Tech",
    salary: "79K - 79K EUR",
    date: "January 2022",
    average_salary: 79,
    place: "Barcelona/Remote",
  },
  {
    id: 1355,
    title: "Software Engineer",
    company: "Appfire",
    salary: "45K - 60K EUR",
    date: "January 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1356,
    title: "Software Engineer – Back End - Python/Serverless",
    company: "WeGetFinancing",
    salary: "50K - 70K EUR",
    date: "January 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1357,
    title: "Ruby on Rails Full-stack Developer",
    company: "MarsBased",
    salary: "35K - 50K EUR",
    date: "January 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1358,
    title: "Senior Backend Engineer",
    company: "Apto Payments",
    salary: "65K - 80K EUR",
    date: "January 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1359,
    title: "Senior Backend Engineer",
    company: "Apto Payments",
    salary: "65K - 80K EUR",
    date: "January 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1360,
    title: "QA Automation Engineer (JavaScript)",
    company: "Docplanner Tech",
    salary: "60K - 60K EUR",
    date: "January 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1361,
    title: "Backend Engineer",
    company: "Zopa",
    salary: "50K - 60K EUR",
    date: "January 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1362,
    title: "Data Engineer",
    company: "Harvest",
    salary: "160K - 160K USD",
    date: "January 2022",
    average_salary: 160,
    place: "Remote",
  },
  {
    id: 1363,
    title: "Software Engineer, Security team",
    company: "Harvest",
    salary: "170K - 170K USD",
    date: "January 2022",
    average_salary: 170,
    place: "Remote",
  },
  {
    id: 1364,
    title: "Data Engineer",
    company: "Collibra",
    salary: "45K - 65K EUR",
    date: "January 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1365,
    title: "Senior DevOps Engineer",
    company: "Oliva Health",
    salary: "60K - 80K EUR",
    date: "January 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1366,
    title: "Senior Software Engineer",
    company: "Wave Mobile Money",
    salary: "138K - 200K USD",
    date: "January 2022",
    average_salary: 169,
    place: "Remote",
  },
  {
    id: 1367,
    title: "CTO",
    company: "Assembler School",
    salary: "75K - 75K EUR",
    date: "January 2022",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 1368,
    title: "Front End Developer",
    company: "Innovamat",
    salary: "30K - 35K EUR",
    date: "January 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1369,
    title: "Site Reliability Engineer (Junior)",
    company: "Red Hat",
    salary: "35K - 40K EUR",
    date: "January 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1370,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "January 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 1371,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "January 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 1372,
    title: "Senior Frontend Software Engineer (React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1373,
    title: "Senior Full Stack .NET Developer",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "January 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 1374,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "66K - 82K EUR",
    date: "January 2022",
    average_salary: 74,
    place: "Barcelona/Remote",
  },
  {
    id: 1375,
    title: "Senior Software Engineer (Web)",
    company: "Peanut",
    salary: "80K - 100K GBP",
    date: "January 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1376,
    title: "Senior Software Engineer (iOS)",
    company: "Peanut",
    salary: "100K - 125K EUR",
    date: "January 2022",
    average_salary: 112.5,
    place: "Remote",
  },
  {
    id: 1377,
    title: "Engineering Manager",
    company: "Peanut",
    salary: "100K - 125K GBP",
    date: "January 2022",
    average_salary: 112.5,
    place: "Remote",
  },
  {
    id: 1378,
    title: "Senior IT administrator",
    company: "Pactera EDGE",
    salary: "35K - 45K EUR",
    date: "January 2022",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 1379,
    title: "Frontend Engineer",
    company: "Brickken",
    salary: "25K - 25K EUR",
    date: "January 2022",
    average_salary: 25,
    place: "Barcelona/Remote",
  },
  {
    id: 1380,
    title: "DevOps Engineer",
    company: "Brickken",
    salary: "27K - 37K EUR",
    date: "January 2022",
    average_salary: 32,
    place: "Barcelona/Remote",
  },
  {
    id: 1381,
    title: "Fullstack Developer",
    company: "Brickken",
    salary: "45K - 55K EUR",
    date: "January 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1382,
    title: "Full Stack Developer - Freelancer",
    company: "BMAT",
    salary: "50K - 50K EUR",
    date: "January 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1383,
    title: "QA Engineer (100% remote)",
    company: "Seqera",
    salary: "35K - 45K EUR",
    date: "January 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1384,
    title: "Senior Javascript Engineer",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "January 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1385,
    title: "Senior Product Designer (UX/UI)",
    company: "Product Hackers",
    salary: "30K - 35K EUR",
    date: "January 2022",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 1386,
    title: "Senior/Staff Software Engineer",
    company: "Factorial HR",
    salary: "55K - 70K EUR",
    date: "January 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1387,
    title: "Tech Lead (50% remote)",
    company: "LEAP",
    salary: "40K - 65K EUR",
    date: "January 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1388,
    title: "iOS developer",
    company: "Inqbarna",
    salary: "30K - 40K EUR",
    date: "January 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1389,
    title: "Site Reliability Engineer",
    company: "Cabify",
    salary: "45K - 85K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1390,
    title: "Engineering Manager",
    company: "Cabify",
    salary: "45K - 85K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1391,
    title: "Senior Android Engineer",
    company: "Cabify",
    salary: "45K - 85K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1392,
    title: "Software Engineer",
    company: "Cabify",
    salary: "30K - 48K EUR",
    date: "January 2022",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 1393,
    title: "Senior Software Engineer",
    company: "Cabify",
    salary: "45K - 85K EUR",
    date: "January 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1394,
    title: "Senior Frontend Developer",
    company: "Qbeast",
    salary: "40K - 50K EUR",
    date: "January 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1395,
    title: "Backend Tech Lead",
    company: "Patterson Agency",
    salary: "50K - 55K EUR",
    date: "January 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1396,
    title: "Technical Product Owner",
    company: "Bling",
    salary: "30K - 50K EUR",
    date: "January 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1397,
    title: "Backend Software Engineer",
    company: "RateNow",
    salary: "30K - 45K EUR",
    date: "February 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1398,
    title: "Python Developer",
    company: "Ampcontrol",
    salary: "45K - 110K USD",
    date: "February 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1399,
    title: "Senior FullStack Developer",
    company: "Haufe Group",
    salary: "45K - 55K EUR",
    date: "February 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1400,
    title: "Senior Fullstack Engineer",
    company: "Capdesk",
    salary: "90K - 100K EUR",
    date: "February 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1401,
    title: "Full Stack Software Enginner",
    company: "Prommt",
    salary: "45K - 75K EUR",
    date: "February 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1402,
    title: "Platform DevEx team Engineer (Eng III)",
    company: "Clarity.AI",
    salary: "41K - 60K EUR",
    date: "February 2022",
    average_salary: 50.5,
    place: "Remote",
  },
  {
    id: 1403,
    title: "Platform DevEx team Engineer (Eng I)",
    company: "Clarity.AI",
    salary: "24K - 32K EUR",
    date: "February 2022",
    average_salary: 28,
    place: "Remote",
  },
  {
    id: 1404,
    title: "Chief Architect",
    company: "World Mobile Group",
    salary: "100K - 120K EUR",
    date: "February 2022",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 1405,
    title: "QA Engineer (100% Remote)",
    company: "Seqera",
    salary: "35K - 45K EUR",
    date: "February 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1406,
    title: "Senior Golang Backend Developer",
    company: "HUBUC",
    salary: "75K - 90K EUR",
    date: "February 2022",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 1407,
    title: "Junior/Mid Frontend Developer",
    company: "Trialing",
    salary: "20K - 25K EUR",
    date: "February 2022",
    average_salary: 22.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1408,
    title: "Tech Lead SRE (Resilience Team)",
    company: "Adevinta",
    salary: "68K - 90K EUR",
    date: "February 2022",
    average_salary: 79,
    place: "Barcelona/Remote",
  },
  {
    id: 1409,
    title: "Backend Developer",
    company: "Apto Payments",
    salary: "65K - 80K EUR",
    date: "February 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1410,
    title: "Quality Assurance Automation Engineer (JS)",
    company: "Docplanner",
    salary: "30K - 60K EUR",
    date: "February 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1411,
    title: "Frontend Engineer",
    company: "Docplanner",
    salary: "36K - 72K EUR",
    date: "February 2022",
    average_salary: 54,
    place: "Barcelona/Remote",
  },
  {
    id: 1412,
    title: "Senior Front-end Engineer",
    company: "Personio",
    salary: "50K - 60K EUR",
    date: "February 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1413,
    title: "Full Stack SR (React/NodeJS)",
    company: "Haufe Group",
    salary: "45K - 55K EUR",
    date: "February 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1414,
    title: "Backend Software Engineer (PHP)",
    company: "Filmin",
    salary: "35K - 45K EUR",
    date: "February 2022",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 1415,
    title: "Product Manager",
    company: "Vlex",
    salary: "45K - 50K EUR",
    date: "February 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1416,
    title: "Lead Software Engineer (.NET)",
    company: "OutThink",
    salary: "55K - 65K EUR",
    date: "February 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1417,
    title: "Software Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "32K - 47K EUR",
    date: "February 2022",
    average_salary: 39.5,
    place: "Remote (COVID)",
  },
  {
    id: 1418,
    title: "Software Engineer / DevEx team (Eng III)",
    company: "Clarity.AI",
    salary: "41K - 72K EUR",
    date: "February 2022",
    average_salary: 56.5,
    place: "Remote",
  },
  {
    id: 1419,
    title: "Software Engineer / DevEx team (Eng I)",
    company: "Clarity.AI",
    salary: "24K - 38K EUR",
    date: "February 2022",
    average_salary: 31,
    place: "Remote",
  },
  {
    id: 1420,
    title: "Senior Full-Stack Developer",
    company: "Haddock (YC 2022)",
    salary: "40K - 50K EUR",
    date: "February 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1421,
    title: "Senior Cloud SRE",
    company: "11Onze",
    salary: "70K - 85K EUR",
    date: "February 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1422,
    title: "Senior Frontend Developer",
    company: "Acework",
    salary: "50K - 65K EUR",
    date: "February 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 1423,
    title: "Fullstack Developer",
    company: "Spathios",
    salary: "35K - 40K EUR",
    date: "February 2022",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 1424,
    title: "Principal Fullstack Engineer (Contract)",
    company: "Advent International",
    salary: "100K - 120K EUR",
    date: "February 2022",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 1425,
    title: "Principal Backend (Python) Engineer (Contract)",
    company: "Advent International",
    salary: "100K - 120K EUR",
    date: "February 2022",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 1426,
    title: "Full Stack Developer",
    company: "Shakers",
    salary: "35K - 39K EUR",
    date: "February 2022",
    average_salary: 37,
    place: "Remote",
  },
  {
    id: 1427,
    title: "Senior Software Engineer/Tech Lead",
    company: "EHAB",
    salary: "70K - 100K GBP",
    date: "February 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1428,
    title: "Senior Frontend Developer (Vuejs)",
    company: "LoveToKnow Media",
    salary: "55K - 70K EUR",
    date: "February 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1429,
    title: "Junior Frontend Developer",
    company: "Crowd Digital",
    salary: "18K - 18K EUR",
    date: "February 2022",
    average_salary: 18,
    place: "Barcelona/Remote",
  },
  {
    id: 1430,
    title: "Product Manager",
    company: "Tempus Labs",
    salary: "55K - 70K EUR",
    date: "February 2022",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 1431,
    title: "Senior Frontend Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "February 2022",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1432,
    title: "Senior Backend Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "February 2022",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1433,
    title: "Senior Full-Stack Developer | 25-40 €/h Freelance",
    company: "Mobile Jazz",
    salary: "45K - 73K EUR",
    date: "February 2022",
    average_salary: 59,
    place: "Remote",
  },
  {
    id: 1434,
    title: "Senior Frontend Developer",
    company: "Browser Group",
    salary: "65K - 65K EUR",
    date: "February 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1435,
    title: "QA Engineer (100% remote)",
    company: "Seqera",
    salary: "35K - 50K EUR",
    date: "February 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1436,
    title: "Junior Python Software Engineer",
    company: "Bling",
    salary: "30K - 40K EUR",
    date: "February 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1437,
    title: "Senior Python Software Engineer",
    company: "Bling",
    salary: "50K - 75K EUR",
    date: "February 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1438,
    title: "Python Engineering Manager",
    company: "Bling",
    salary: "50K - 75K EUR",
    date: "February 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1439,
    title: "Software Engineer",
    company: "Ahrefs",
    salary: "75K - 150K EUR",
    date: "February 2022",
    average_salary: 112.5,
    place: "Remote",
  },
  {
    id: 1440,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "February 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1441,
    title: "Senior .NET Software Engineer",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "February 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1442,
    title: "3D Unity Lead Developer - Join the New Era!",
    company: "Gamium",
    salary: "35K - 55K EUR",
    date: "February 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1443,
    title: "Talent Acquisition – A New Era is Coming!",
    company: "Gamium",
    salary: "30K - 35K EUR",
    date: "February 2022",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 1444,
    title: "Lead Frontend Developer",
    company: "Stayforlong",
    salary: "50K - 70K EUR",
    date: "February 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1445,
    title: "Product Owner",
    company: "World Mobile Group",
    salary: "40K - 55K EUR",
    date: "February 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1446,
    title: "Software Engineer",
    company: "Parkimeter",
    salary: "35K - 50K EUR",
    date: "February 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 1447,
    title: "Senior Frontend Engineer",
    company: "Blacklane",
    salary: "60K - 70K EUR",
    date: "February 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1448,
    title: "Node.js & Typescript Engineer",
    company: "OYA",
    salary: "40K - 50K EUR",
    date: "February 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1449,
    title: "Senior Frontend Developer (React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "February 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1450,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "February 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1451,
    title: "Mid Backend Developer",
    company: "Brickken",
    salary: "35K - 45K EUR",
    date: "February 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1452,
    title: "Backend Software Engineer",
    company: "New Relic",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1453,
    title: "Software Engineer (Security)",
    company: "Adevinta Central",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1454,
    title: "Scala Data Engineer",
    company: "Adevinta Central",
    salary: "65K - 85K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 1455,
    title: "Site reliability Engineer (ML area)",
    company: "Adevinta Central",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1456,
    title: "Senior Product Manager (Data Science)",
    company: "Adevinta Central",
    salary: "70K - 80K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 1457,
    title: "Product Data Analyst",
    company: "Adevinta Central",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1458,
    title: "iOS Engineer",
    company: "Adevinta Central",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1459,
    title: "Site Reliability Engineer (Cloud Governance)",
    company: "Adevinta Central",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1460,
    title: "Site Reliability Engineer (Delivery Tribe)",
    company: "Adevinta Central",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 1461,
    title: "Senior Infrastructure Engineer",
    company: "Polygon",
    salary: "60K - 90K USD",
    date: "March 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1462,
    title: "Data Engineer",
    company: "Audiense",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1463,
    title: "Data Analyst",
    company: "Goin",
    salary: "35K - 50K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 1464,
    title: "Senior Backend Engineer",
    company: "Smallpdf",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1465,
    title: "Senior Back-end Developer (Java)",
    company: "Intelygenz",
    salary: "40K - 54K EUR",
    date: "March 2022",
    average_salary: 47,
    place: "Remote",
  },
  {
    id: 1466,
    title: "Java Developer",
    company: "Red Points",
    salary: "35K - 50K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1467,
    title: "Junior Frontend Engineer (React)",
    company: "Opticks Security",
    salary: "28K - 32K EUR",
    date: "March 2022",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 1468,
    title: "Java Backend Developer",
    company: "Meep",
    salary: "40K - 55K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1469,
    title: "Software Engineer",
    company: "Spotahome",
    salary: "45K - 52K EUR",
    date: "March 2022",
    average_salary: 48.5,
    place: "Remote",
  },
  {
    id: 1470,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1471,
    title: "Senior Frontend Developer (React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1472,
    title: "Manager, Software Engineering",
    company: "New Relic",
    salary: "75K - 95K EUR",
    date: "March 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1473,
    title: "Senior Back-End Software Engineer",
    company: "New Relic",
    salary: "60K - 80K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1474,
    title: "Senior Software Engineer - Frontend",
    company: "New Relic",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1475,
    title: "Senior Backend Developer",
    company: "Abi Global Health",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1476,
    title: "SRE / Sys Admin / Devops",
    company: "Tappx",
    salary: "35K - 48K EUR",
    date: "March 2022",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1477,
    title: "IOS Developer",
    company: "Tappx",
    salary: "30K - 48K EUR",
    date: "March 2022",
    average_salary: 39,
    place: "Barcelona/Remote",
  },
  {
    id: 1478,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1479,
    title: "Senior Full Stack .NET Developer",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1480,
    title: "Engineering Manager",
    company: "Eventbrite",
    salary: "75K - 85K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1481,
    title: "Senior Backend Developer",
    company: "BackMarket",
    salary: "65K - 75K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1482,
    title: "Staff Software Engineer",
    company: "Sennder",
    salary: "80K - 95K EUR",
    date: "March 2022",
    average_salary: 87.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1483,
    title: "Backend Engineer (all levels)",
    company: "Sennder",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1484,
    title: "Engineering Manager - Platform",
    company: "Sennder",
    salary: "65K - 95K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1485,
    title: "Senior Platform Engineer",
    company: "Sennder",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1486,
    title: "Senior Data Engineer",
    company: "Sennder",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1487,
    title: "Engineering Manager Data Platform",
    company: "Sennder",
    salary: "65K - 95K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1488,
    title: "Library Software Engineer - Python",
    company: "Datadog",
    salary: "85K - 160K EUR",
    date: "March 2022",
    average_salary: 122.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1489,
    title: "Library Software Engineer - Ruby",
    company: "Datadog",
    salary: "85K - 160K EUR",
    date: "March 2022",
    average_salary: 122.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1490,
    title: "Backend Software Engineer",
    company: "Datadog",
    salary: "70K - 130K EUR",
    date: "March 2022",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 1491,
    title: "Mid-Level SRE",
    company: "Mindcurv",
    salary: "40K - 52K EUR",
    date: "March 2022",
    average_salary: 46,
    place: "Remote",
  },
  {
    id: 1492,
    title: "Mid-Senior Software Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "32K - 47K EUR",
    date: "March 2022",
    average_salary: 39.5,
    place: "Barcelona",
  },
  {
    id: 1493,
    title: "Senior Backend Engineer (node)",
    company: "TheyDo",
    salary: "60K - 90K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1494,
    title: "Mid-Senior Software Engineer (Rails/Stimulus)",
    company: "Rzilient",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1495,
    title: "Backend Software Engineer (PHP)",
    company: "Filmin",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1496,
    title: "Data Engineer",
    company: "Audiense",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1497,
    title: "Mid Frontend Developer",
    company: "Tangelo Games",
    salary: "30K - 40K EUR",
    date: "March 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1498,
    title: "Senior Frontend Engineer",
    company: "Teamwire",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1499,
    title: "Engineering Manager",
    company: "Adevinta",
    salary: "69K - 100K EUR",
    date: "March 2022",
    average_salary: 84.5,
    place: "Barcelona",
  },
  {
    id: 1500,
    title: "Senior Back End Developer (Python)",
    company: "Teamwire",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1501,
    title: "Senior Cloud Engineer",
    company: "Gartner",
    salary: "75K - 85K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1502,
    title: "Lead Software Engineer (PHP/Golang)",
    company: "Gartner",
    salary: "80K - 90K EUR",
    date: "March 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1503,
    title: "Senior Backend Engineer",
    company: "Primer",
    salary: "60K - 90K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1504,
    title: "Data Engineer",
    company: "ConsenSys",
    salary: "75K - 100K EUR",
    date: "March 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1505,
    title: "Data Analyst",
    company: "ConsenSys",
    salary: "75K - 100K EUR",
    date: "March 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1506,
    title: "Golang Backend Engineer",
    company: "ConsenSys",
    salary: "75K - 100K EUR",
    date: "March 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1507,
    title: "Product Managers",
    company: "ConsenSys",
    salary: "75K - 100K EUR",
    date: "March 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1508,
    title: "Semi-senior Software Engineer",
    company: "Edpuzzle",
    salary: "32K - 47K EUR",
    date: "March 2022",
    average_salary: 39.5,
    place: "Barcelona",
  },
  {
    id: 1509,
    title: "Senior Product Engineer",
    company: "Wayflyer :unicorn_face:",
    salary: "80K - 100K GBP",
    date: "March 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1510,
    title: "Lead Engineer",
    company: "Wayflyer :unicorn_face:",
    salary: "100K - 125K GBP",
    date: "March 2022",
    average_salary: 112.5,
    place: "Remote",
  },
  {
    id: 1511,
    title: "Senior Kafka Expert",
    company: "Dynatrace",
    salary: "50K - 70K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1512,
    title: "Freelance Lead Teacher MERN",
    company: "Assembler Institute",
    salary: "30K - 60K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1513,
    title: "Machine Learning Engineer",
    company: "Bling",
    salary: "40K - 70K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1514,
    title: "DevOps",
    company: "Bling",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1515,
    title: "SecDevOps",
    company: "Bling",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1516,
    title: "Python Engineering Manager",
    company: "Bling",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1517,
    title: "Product Manager, Admin Engagement",
    company: "Docplanner Tech",
    salary: "95K - 95K EUR",
    date: "March 2022",
    average_salary: 95,
    place: "Barcelona/Remote",
  },
  {
    id: 1518,
    title: "Product Manager, EHR",
    company: "Docplanner Tech",
    salary: "95K - 95K EUR",
    date: "March 2022",
    average_salary: 95,
    place: "Barcelona/Remote",
  },
  {
    id: 1519,
    title: "Senior iOS Engineer with A/B tests",
    company: "Brainly",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1520,
    title: "Senior Frontend Engineer",
    company: "LoveToKnow Media",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1521,
    title: "Backend Developer Jr",
    company: "Housfy",
    salary: "25K - 25K EUR",
    date: "March 2022",
    average_salary: 25,
    place: "Barcelona/Remote",
  },
  {
    id: 1522,
    title: "Senior Engineering Manager",
    company: "Visma | Yuki",
    salary: "65K - 85K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1523,
    title: "Senior Backend Developer",
    company: "Visma | Yuki",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1524,
    title: "Staff Engineer - Observability",
    company: "New Relic",
    salary: "75K - 100K EUR",
    date: "March 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1525,
    title: "Senior Software Engineer",
    company: "New Relic",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1526,
    title: "Senior/Staff Software Engineer Business Operations",
    company: "Factorial HR",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1527,
    title: "Mobile Domain Lead (Android&iOS)",
    company: "Brainly",
    salary: "65K - 90K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1528,
    title: "Full-stack Engineer (Go preferred+React)",
    company: "Brainly",
    salary: "32K - 55K EUR",
    date: "March 2022",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1529,
    title: "Full-stack Engineer(mid/senior Go preferred+React)",
    company: "Brainly",
    salary: "38K - 76K EUR",
    date: "March 2022",
    average_salary: 57,
    place: "Barcelona/Remote",
  },
  {
    id: 1530,
    title: "Junior DevOps Engineer",
    company: "Adobe",
    salary: "37K - 48K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 1531,
    title: "DevOps Engineer",
    company: "Adobe",
    salary: "46K - 64K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1532,
    title: "(Junior) Frontend Engineer",
    company: "Adobe",
    salary: "37K - 48K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 1533,
    title: "Magento SW Engineer",
    company: "Adobe",
    salary: "46K - 64K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1534,
    title: "(Mid) Magento SW Engineer",
    company: "Adobe",
    salary: "55K - 80K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 1535,
    title: "(Senior) Magento SW Engineer",
    company: "Adobe",
    salary: "76K - 100K EUR",
    date: "March 2022",
    average_salary: 88,
    place: "Barcelona",
  },
  {
    id: 1536,
    title: "Digital Product Designer",
    company: "Flexidao",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1537,
    title: "Frontend Engineer. Javascript, Typescript, React",
    company: "UserZoom",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1538,
    title: "Senior Backend Developer",
    company: "11Onze",
    salary: "70K - 85K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1539,
    title: "Senior Backend Developer",
    company: "Cobee",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1540,
    title: ".NET Software Engineer",
    company: "Gartner",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1541,
    title: "Node.js Full-stack Developer",
    company: "MarsBased",
    salary: "55K - 55K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1542,
    title: "Python developer",
    company: "Immfly",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1543,
    title: "React Developer",
    company: "Immfly",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1544,
    title: "QA Engineer",
    company: "Brainly",
    salary: "23K - 41K EUR",
    date: "March 2022",
    average_salary: 32,
    place: "Barcelona/Remote",
  },
  {
    id: 1545,
    title: "QA Engineer",
    company: "Brainly",
    salary: "23K - 41K EUR",
    date: "March 2022",
    average_salary: 32,
    place: "Barcelona/Remote",
  },
  {
    id: 1546,
    title: "RoR Developer",
    company: "Vizzuality",
    salary: "40K - 48K EUR",
    date: "March 2022",
    average_salary: 44,
    place: "Remote",
  },
  {
    id: 1547,
    title: "Senior Back-end Engineer (to learn Golang)",
    company: "Brainly",
    salary: "49K - 76K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1548,
    title: "Digital Product Designer",
    company: "Flexidao",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1549,
    title: "Senior Software Engineer (React)",
    company: "GetApp (Gartner)",
    salary: "70K - 85K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1550,
    title: "QA automation",
    company: "Bling",
    salary: "45K - 75K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 1551,
    title: "QA Engineer",
    company: "NPAW",
    salary: "22K - 25K EUR",
    date: "March 2022",
    average_salary: 23.5,
    place: "Barcelona",
  },
  {
    id: 1552,
    title: "Software Engineer (Python)",
    company: "Alfred Smart",
    salary: "30K - 35K EUR",
    date: "March 2022",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 1553,
    title: "Senior Software Engineer - Ruby on Rails",
    company: "Red Hat",
    salary: "40K - 70K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1554,
    title: "Senior Software Engineer (Ruby on Rails)",
    company: "Capterra (Gartner)",
    salary: "60K - 80K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1555,
    title: "Software Engineer",
    company: "Alfred Smart Systems",
    salary: "30K - 35K EUR",
    date: "March 2022",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 1556,
    title: "BI Analyst",
    company: "Caravelo",
    salary: "35K - 40K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 1557,
    title: "Senior Java Developer",
    company: "Mirai",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1558,
    title: "Senior iOS Developer",
    company: "Fashionalia",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1559,
    title: "Backend Developer",
    company: "OLYN",
    salary: "70K - 90K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 1560,
    title: "Data Engineer",
    company: "Brainly",
    salary: "50K - 75K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1561,
    title: ".Net Developer",
    company: "Picis",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1562,
    title: "Software Engineer (Product Team)",
    company: "Flanks",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1563,
    title: "Software Engineer (Senior / Product Team)",
    company: "Flanks",
    salary: "45K - 52K EUR",
    date: "March 2022",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1564,
    title: "Frontend Tech Lead",
    company: "Housfy",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1565,
    title: "SysAdmin Linux / DevOps",
    company: "NPAW",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 1566,
    title: "Senior Backend Developer",
    company: "SeQura",
    salary: "55K - 75K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1567,
    title: "Python Developer",
    company: "Keblar Solutions",
    salary: "40K - 45K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1568,
    title: "Technical lead (Elixir/React)",
    company: "Horizon65",
    salary: "55K - 80K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1569,
    title: "senior frontend engineer",
    company: "crowdhouse",
    salary: "70K - 80K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1570,
    title: "DevOps",
    company: "NFQ (NWorld)",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1571,
    title: "Frontend Engineer (Angular)",
    company: "Seqera",
    salary: "35K - 50K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1572,
    title: ".NET Software Engineer",
    company: "Gartner",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1573,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1574,
    title: "Senior Software Engineer",
    company: "New Relic",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1575,
    title: "Mid Software Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "32K - 47K EUR",
    date: "March 2022",
    average_salary: 39.5,
    place: "Barcelona",
  },
  {
    id: 1576,
    title: "Backend Engineer (Python)",
    company: "Lupa",
    salary: "35K - 48K EUR",
    date: "March 2022",
    average_salary: 41.5,
    place: "Barcelona",
  },
  {
    id: 1577,
    title: "Sr Backend Developer",
    company: "Nextail",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1578,
    title: "Sr Frontend Developer",
    company: "Nextail",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1579,
    title: "Junior Backend Engineer",
    company: "Brickken",
    salary: "22K - 25K EUR",
    date: "March 2022",
    average_salary: 23.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1580,
    title: "Ruby on Rails Junior Developer",
    company: "MarsBased",
    salary: "30K - 35K EUR",
    date: "March 2022",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 1581,
    title: "Digital Product Designer",
    company: "Flexidao",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1582,
    title: "Backend Engineer (Python OR Typescript)",
    company: "Sennder",
    salary: "50K - 65K EUR",
    date: "March 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1583,
    title: "Senior Backend Engineer (Python OR TypeScript)",
    company: "Sennder",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1584,
    title: "Senior Frontend Engineer",
    company: "Capdesk",
    salary: "70K - 85K GBP",
    date: "March 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1585,
    title: "Full-stack Engineer",
    company: "Brainly",
    salary: "32K - 55K EUR",
    date: "March 2022",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1586,
    title: "Mid/Senior Full-stack Engineer",
    company: "Brainly",
    salary: "38K - 76K EUR",
    date: "March 2022",
    average_salary: 57,
    place: "Barcelona/Remote",
  },
  {
    id: 1587,
    title: "Team Manager",
    company: "Lifull Connect",
    salary: "60K - 80K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1588,
    title: "PHP Developer",
    company: "Damecode",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1589,
    title: "Senior Frontend Software Developer (React)",
    company: "Gartner",
    salary: "60K - 75K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1590,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "60K - 75K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1591,
    title: "Engineering Manager Platform Teams",
    company: "Factorial",
    salary: "55K - 85K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1592,
    title: "Mid/Senior Frontend Developer",
    company: "Fastloop",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1593,
    title: "Mid/Senior iOS/Android Developer",
    company: "Fastloop",
    salary: "35K - 45K EUR",
    date: "March 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1594,
    title: "Senior Java Developer",
    company: "Mirai",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1595,
    title: "Senior C++ Game Developer",
    company: "Deca Live Operations",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1596,
    title: "Senior Backend Engineer (Python)",
    company: "Big Blue Analytics",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1597,
    title: "Senior Frontend Engineer",
    company: "Big Blue Analytics",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1598,
    title: "Frontend Engineer",
    company: "Vitaance",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1599,
    title: "Lead Product Designer",
    company: "Blacklane",
    salary: "60K - 78K EUR",
    date: "March 2022",
    average_salary: 69,
    place: "Remote",
  },
  {
    id: 1600,
    title: "PHP Developer",
    company: "Runroom",
    salary: "35K - 40K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1601,
    title: "Senior Frontend React",
    company: "Sesamy",
    salary: "40K - 60K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1602,
    title: "DevOps",
    company: "Weecover",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1603,
    title: "PHP Laravel Developer",
    company: "Mayo",
    salary: "25K - 35K EUR",
    date: "March 2022",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 1604,
    title: "Mid FullStack Python/Django",
    company: "Coneptum AI",
    salary: "30K - 35K EUR",
    date: "March 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1605,
    title: "Full Stack Developer (Java)",
    company: "elAbogado",
    salary: "34K - 40K EUR",
    date: "March 2022",
    average_salary: 37,
    place: "Barcelona/Remote",
  },
  {
    id: 1606,
    title: "Senior Full Stack Engineer",
    company: "Capdesk",
    salary: "85K - 100K EUR",
    date: "March 2022",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 1607,
    title: "Web Developer",
    company: "Capdesk",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1608,
    title: "PHP Developer",
    company: "GreyHounders",
    salary: "30K - 40K EUR",
    date: "March 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 1609,
    title: "Senior Backend Developer",
    company: "Getir",
    salary: "70K - 85K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1610,
    title: "Node.js/React Senior Full-stack Developer",
    company: "Brickbro",
    salary: "40K - 45K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1611,
    title: "Senior/Intermediate Frontend Engineer",
    company: "Circutor",
    salary: "40K - 45K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1612,
    title: "Product Manager (Gaming)",
    company: "Kronte",
    salary: "70K - 80K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1613,
    title: "Senior / Staff Software Engineer",
    company: "Factorial",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1614,
    title: "Junior Software Engineer",
    company: "Factorial",
    salary: "30K - 30K EUR",
    date: "March 2022",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 1615,
    title: "Frontend Lead",
    company: "Plum Guide",
    salary: "80K - 90K EUR",
    date: "March 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1616,
    title: ".Net developer",
    company: "Plum Guide",
    salary: "60K - 80K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 1617,
    title: "PHP Developer",
    company: "www.ushahidi.com",
    salary: "40K - 60K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1618,
    title: "Mid Software Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "40K - 52K EUR",
    date: "March 2022",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 1619,
    title: "Senior & Mid PHP developer",
    company: "Your New Self",
    salary: "35K - 42K EUR",
    date: "March 2022",
    average_salary: 38.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1620,
    title: "Engineering Manager",
    company: "Sendwave",
    salary: "90K - 160K USD",
    date: "March 2022",
    average_salary: 125,
    place: "Remote",
  },
  {
    id: 1621,
    title: "Python Developer",
    company: "Sendwave",
    salary: "60K - 130K USD",
    date: "March 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1622,
    title: "Senior Backend Developer (Java)",
    company: "Caravelo",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1623,
    title: "Senior SRE",
    company: "BackMarket",
    salary: "70K - 90K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1624,
    title: "User Research Team Leader",
    company: "Docplanner Tech",
    salary: "42K - 68K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1625,
    title: "UI Developer",
    company: "coches.com",
    salary: "30K - 45K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1626,
    title: "Senior Frontend Developer",
    company: "coches.com",
    salary: "40K - 45K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1627,
    title: "Sr. Data Engineer Platforms",
    company: "OLX Autos",
    salary: "52K - 82K EUR",
    date: "March 2022",
    average_salary: 67,
    place: "Barcelona/Remote",
  },
  {
    id: 1628,
    title: "Data Engineer",
    company: "Audiense",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1629,
    title: "Software Engineer",
    company: "AdaptMX",
    salary: "50K - 90K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1630,
    title: "Data Architect (with Snowflake experience)",
    company: "Brainly",
    salary: "88K - 105K EUR",
    date: "March 2022",
    average_salary: 96.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1631,
    title: "Mobile Domain Lead (Android & iOS experience)",
    company: "Brainly",
    salary: "85K - 102K EUR",
    date: "March 2022",
    average_salary: 93.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1632,
    title: "Mid Software Engineer (PHP)",
    company: "Filmin",
    salary: "35K - 40K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 1633,
    title: "Senior Frontend Engineer",
    company: "Wayflyer",
    salary: "80K - 110K EUR",
    date: "March 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1634,
    title: "Tech Lead",
    company: "Odin",
    salary: "80K - 120K EUR",
    date: "March 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1635,
    title: "Senior Frontend Engineer",
    company: "LoveToKnow Media",
    salary: "50K - 70K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1636,
    title: "Mobile QA/Test Engineer",
    company: "Brainly",
    salary: "23K - 41K EUR",
    date: "March 2022",
    average_salary: 32,
    place: "Barcelona/Remote",
  },
  {
    id: 1637,
    title: "Senior Backend Developer (php)",
    company: "holded",
    salary: "40K - 70K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1638,
    title: "React Native Developer",
    company: "holded",
    salary: "40K - 60K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1639,
    title: "Senior frontend Engineer",
    company: "holded",
    salary: "40K - 60K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1640,
    title: "SRE",
    company: "holded",
    salary: "50K - 70K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1641,
    title: "React Engineer",
    company: "CoCicurcular",
    salary: "35K - 35K EUR",
    date: "March 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 1642,
    title: "Site Reliability Engineer (SRE)",
    company: "Back Market",
    salary: "70K - 90K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1643,
    title: "Junior Android Developer",
    company: "IZI Record",
    salary: "28K - 35K EUR",
    date: "March 2022",
    average_salary: 31.5,
    place: "Barcelona",
  },
  {
    id: 1644,
    title: "(Senior) React Frontend Developer",
    company: "New Work - XING",
    salary: "40K - 65K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1645,
    title: "(Senior) Ruby on Rails Developer",
    company: "New Work - XING",
    salary: "40K - 65K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1646,
    title: "Team Lead Software Engineer",
    company: "New Work - XING",
    salary: "85K - 85K EUR",
    date: "March 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1647,
    title: "Team Lead Data Platform",
    company: "New Work",
    salary: "70K - 100K EUR",
    date: "March 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1648,
    title: "(Senior) Scala Developer",
    company: "New Work - XING",
    salary: "45K - 70K EUR",
    date: "March 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1649,
    title: "Front- End Developer",
    company: "FlexiDAO",
    salary: "30K - 45K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1650,
    title: "Back-End Developer",
    company: "FlexiDAO",
    salary: "30K - 45K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1651,
    title: "Python Backend Developer",
    company: "Bling",
    salary: "50K - 65K EUR",
    date: "March 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1652,
    title: "Senior Python Software Engineer",
    company: "Bling",
    salary: "50K - 75K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1653,
    title: "Senior PHP developer",
    company: "Your New Self",
    salary: "42K - 42K EUR",
    date: "March 2022",
    average_salary: 42,
    place: "Barcelona/Remote",
  },
  {
    id: 1654,
    title: "Backend Developer",
    company: "Sparta Commodities",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1655,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1656,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1657,
    title: "Python developer",
    company: "Immfly",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1658,
    title: "Senior Software Engineer (BE)",
    company: "Chartboost",
    salary: "50K - 70K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1659,
    title: "Senior Android Engineer",
    company: "Chartboost",
    salary: "50K - 70K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1660,
    title: "Senior iOS Engineer",
    company: "Chartboost",
    salary: "50K - 70K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1661,
    title: "Solution Architect",
    company: "Mindcurv",
    salary: "80K - 80K EUR",
    date: "March 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 1662,
    title: "Backend Software Engineer (JAVA)",
    company: "Twilio",
    salary: "60K - 60K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1663,
    title: "Python Software Engineer",
    company: "Twilio",
    salary: "60K - 60K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1664,
    title: "Python Backend Developer",
    company: "Bling",
    salary: "50K - 75K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1665,
    title: "Junior Data Engineer",
    company: "Lifull Connect",
    salary: "25K - 35K EUR",
    date: "March 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 1666,
    title: "Senior Ruby Engineer",
    company: "Helpling",
    salary: "60K - 70K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1667,
    title: "Senior Frontend Engineer",
    company: "Helpling",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1668,
    title: "Senior Software Developer",
    company: "Lifull Connect",
    salary: "40K - 60K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1669,
    title: "Senior Ruby/Golang Backend Engineer",
    company: "Qonto",
    salary: "65K - 90K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1670,
    title: "Lead Backend Engineer",
    company: "Qonto",
    salary: "65K - 90K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 1671,
    title: "Data Engineer",
    company: "Sparta",
    salary: "50K - 65K EUR",
    date: "March 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 1672,
    title: "Junior Site Reliability Engineer",
    company: "Socialpoint",
    salary: "30K - 40K EUR",
    date: "March 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1673,
    title: ".NET Software Developer",
    company: "Gartner",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1674,
    title: "Software Engineer (Python)",
    company: "Preply",
    salary: "50K - 80K EUR",
    date: "March 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1675,
    title: "DevOps",
    company: "BlueTC",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1676,
    title: "Frontend Developer React",
    company: "BlueTC",
    salary: "30K - 40K EUR",
    date: "March 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 1677,
    title: "Software Engineer in Test",
    company: "BlueTC",
    salary: "30K - 45K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1678,
    title: "Tech Lead",
    company: "Caravelo :airplane:",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1679,
    title: "Senior DevOps Engineer",
    company: "Caravelo :airplane:",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1680,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1681,
    title: "QA Engineer",
    company: "Seqera",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1682,
    title: "Lead QA Engineer",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "March 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1683,
    title: "Mid-Junior Full Stack Engineer",
    company: "Trialing",
    salary: "27K - 30K EUR",
    date: "March 2022",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1684,
    title: "Senior Software Engineer",
    company: "Cubyn",
    salary: "55K - 70K EUR",
    date: "March 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1685,
    title: "Frontend Developer React",
    company: "Backercamp",
    salary: "30K - 45K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1686,
    title: "Sales & Marketing Manager",
    company: "eKuore",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1687,
    title: "Senior Python Backend Developer",
    company: "BLING",
    salary: "60K - 75K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1688,
    title: "Senior Backend Engineer",
    company: "Capdesk",
    salary: "85K - 100K EUR",
    date: "March 2022",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 1689,
    title: "Senior Backend Engineer (PHP)",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1690,
    title: "Senior Frontend Engineer",
    company: "Back Market",
    salary: "65K - 75K EUR",
    date: "March 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1691,
    title: "Senior Back-end Developer",
    company: "Touché",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1692,
    title: "Senior devOps engineer",
    company: "( caravelo (",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1693,
    title: "iOS Engineer",
    company: "Papaya Pay",
    salary: "72K - 96K EUR",
    date: "March 2022",
    average_salary: 84,
    place: "Remote",
  },
  {
    id: 1694,
    title: "Senior Site Reliability Engineer",
    company: "New Relic",
    salary: "60K - 83K EUR",
    date: "March 2022",
    average_salary: 71.5,
    place: "Remote",
  },
  {
    id: 1695,
    title: "Senior Database Engineer",
    company: "New Relic",
    salary: "60K - 83K EUR",
    date: "March 2022",
    average_salary: 71.5,
    place: "Remote",
  },
  {
    id: 1696,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "March 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1697,
    title: "Senior Software Engineer",
    company: "New Relic",
    salary: "60K - 83K EUR",
    date: "March 2022",
    average_salary: 71.5,
    place: "Remote",
  },
  {
    id: 1698,
    title: "Backend Software Engineer",
    company: "StreamSets.com",
    salary: "30K - 60K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1699,
    title: "DevOps Engineer",
    company: "StreamSets.com",
    salary: "40K - 80K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1700,
    title: "System Engineer (Productivity)",
    company: "StreamSets.com",
    salary: "30K - 60K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1701,
    title: "Technical Support Engineer",
    company: "StreamSets.com",
    salary: "30K - 45K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1702,
    title: "Full stack developer",
    company: "Guzzu",
    salary: "40K - 45K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1703,
    title: "Tech Lead",
    company: "( caravelo (",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1704,
    title: "Senior/Staff Software Engineer",
    company: "Factorial HR",
    salary: "55K - 80K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1705,
    title: "Tech Leader",
    company: "Laurel Gaming",
    salary: "15K - 24K EUR",
    date: "March 2022",
    average_salary: 19.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1706,
    title: "Android Developer (mid), Machine Learning Team",
    company: "Brainly",
    salary: "32K - 55K EUR",
    date: "March 2022",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1707,
    title: "iOS Developer (mid), Machine Learning Team",
    company: "Brainly",
    salary: "32K - 55K EUR",
    date: "March 2022",
    average_salary: 43.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1708,
    title: "Mobile App Developer",
    company: "kariboo",
    salary: "40K - 48K EUR",
    date: "March 2022",
    average_salary: 44,
    place: "Remote",
  },
  {
    id: 1709,
    title: "Junior .Net Developer",
    company: "ivory",
    salary: "24K - 28K EUR",
    date: "March 2022",
    average_salary: 26,
    place: "Remote",
  },
  {
    id: 1710,
    title: "Frontend Lead Engineer (Angular)",
    company: "Simplr",
    salary: "55K - 65K EUR",
    date: "March 2022",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 1711,
    title: "Frontend React",
    company: "Altran",
    salary: "25K - 30K EUR",
    date: "March 2022",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1712,
    title: "(Mid) .NET Software Developer",
    company: "Gartner",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1713,
    title: "QA Engineer",
    company: "Stratio",
    salary: "40K - 55K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1714,
    title: "Desarrollador/a Java Microservicios",
    company: "Stratio",
    salary: "44K - 57K EUR",
    date: "March 2022",
    average_salary: 50.5,
    place: "Remote",
  },
  {
    id: 1715,
    title: "Full Stack Engineer",
    company: "Kleta",
    salary: "35K - 40K EUR",
    date: "March 2022",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 1716,
    title: "Backend Engineer",
    company: "Vizzuality",
    salary: "30K - 48K EUR",
    date: "March 2022",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 1717,
    title: "Lead Instructor - Data Analytics Part-Time (Bcn)",
    company: "Ironhack",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1718,
    title: "Senior React Developer",
    company: "Zinklar",
    salary: "45K - 60K EUR",
    date: "March 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1719,
    title: "Senior iOs Developer",
    company: "Apiumhub",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1720,
    title: "Android Developer (mid)",
    company: "Apiumhub",
    salary: "40K - 50K EUR",
    date: "March 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1721,
    title: "Senior Full-stack Engineer",
    company: "haddock",
    salary: "60K - 75K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1722,
    title: "Senior Javascript Engineer",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1723,
    title: "Senior Software Engineer (Backend / PHP)",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1724,
    title: "Software Engineer",
    company: "LambdaLoopers",
    salary: "31K - 43K EUR",
    date: "March 2022",
    average_salary: 37,
    place: "Remote",
  },
  {
    id: 1725,
    title: "Product Owner",
    company: "LambdaLoopers",
    salary: "22K - 40K EUR",
    date: "March 2022",
    average_salary: 31,
    place: "Remote",
  },
  {
    id: 1726,
    title: "UX/UI Designer",
    company: "Drees and Sommer",
    salary: "40K - 55K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1727,
    title: "People & Talent Manager",
    company: "Sparta",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1728,
    title: "Senior Backend Engineer",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "March 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1729,
    title: "Backend Engineer (Python or Typescript)",
    company: "sennder",
    salary: "50K - 65K EUR",
    date: "March 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1730,
    title: "Senior Full Stack Engineer",
    company: "KeyWords Studios",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1731,
    title: "Senior 365 Engineer",
    company: "KeyWords Studios",
    salary: "45K - 50K EUR",
    date: "March 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1732,
    title: "Frontend Developer",
    company: "Lang.ai",
    salary: "45K - 55K EUR",
    date: "March 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1733,
    title: "Senior Fullstack engineer",
    company: "Codurance",
    salary: "50K - 60K EUR",
    date: "March 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1734,
    title: "Data Architect with Snowflake experience",
    company: "Brainly",
    salary: "88K - 105K EUR",
    date: "March 2022",
    average_salary: 96.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1735,
    title: "Quality Assurance Test Engineer",
    company: "i2cat Foundation",
    salary: "32K - 32K EUR",
    date: "March 2022",
    average_salary: 32,
    place: "Barcelona/Remote",
  },
  {
    id: 1736,
    title: "Senior Python Backend Developer",
    company: "BLING",
    salary: "60K - 75K EUR",
    date: "March 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1737,
    title: "Tech Lead Data Scientist with Computer Vision",
    company: "Brainly",
    salary: "90K - 120K EUR",
    date: "March 2022",
    average_salary: 105,
    place: "Barcelona/Remote",
  },
  {
    id: 1738,
    title: "Tech Lead Data Scientist with NLP",
    company: "Brainly",
    salary: "90K - 120K EUR",
    date: "March 2022",
    average_salary: 105,
    place: "Barcelona/Remote",
  },
  {
    id: 1739,
    title: "Junior Frontend Developer (React)",
    company: "NPAW",
    salary: "25K - 30K EUR",
    date: "March 2022",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1740,
    title: "Back-end developer",
    company: "FlexiDAO",
    salary: "35K - 50K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1741,
    title: "Front-end developer",
    company: "FlexiDAO",
    salary: "35K - 50K EUR",
    date: "March 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1742,
    title: "DevOps Engineer",
    company: "RSS.com",
    salary: "75K - 90K USD",
    date: "March 2022",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 1743,
    title: "Software Engineer (Ruby on Rails/Stimulus/React)",
    company: "Rzilient",
    salary: "45K - 70K EUR",
    date: "March 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1744,
    title: "Software Engineer - Back-end",
    company: "New Relic",
    salary: "50K - 69K EUR",
    date: "March 2022",
    average_salary: 59.5,
    place: "Remote",
  },
  {
    id: 1745,
    title: "Senior Site Reliability Engineer K8 platform",
    company: "New Relic",
    salary: "59K - 83K EUR",
    date: "March 2022",
    average_salary: 71,
    place: "Remote",
  },
  {
    id: 1746,
    title: "Backend Tech Lead",
    company: "Product School",
    salary: "60K - 70K EUR",
    date: "April 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1747,
    title: "Android Engineer",
    company: "Qonton",
    salary: "80K - 80K EUR",
    date: "April 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1748,
    title: "Lead Android Engineer",
    company: "Qonto",
    salary: "90K - 90K EUR",
    date: "April 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 1749,
    title: "Backend Java",
    company: "Zurich",
    salary: "40K - 42K EUR",
    date: "April 2022",
    average_salary: 41,
    place: "Remote",
  },
  {
    id: 1750,
    title: "Backend java senior",
    company: "TWAGO",
    salary: "40K - 67K EUR",
    date: "April 2022",
    average_salary: 53.5,
    place: "Remote",
  },
  {
    id: 1751,
    title: "Full Stack Engineer (PHP, contract 6 months)",
    company: "LoveToKnow Media",
    salary: "43K - 53K EUR",
    date: "April 2022",
    average_salary: 48,
    place: "Barcelona/Remote",
  },
  {
    id: 1752,
    title: "Junior Java Developer",
    company: "Dragonfly",
    salary: "20K - 35K EUR",
    date: "April 2022",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1753,
    title: "Mid/Senior Frontend Developer (React)",
    company: "Shopery",
    salary: "36K - 48K EUR",
    date: "April 2022",
    average_salary: 42,
    place: "Remote",
  },
  {
    id: 1754,
    title: "Mid/Senior Backend Developer (PHP / Symfony)",
    company: "Shopery",
    salary: "36K - 48K EUR",
    date: "April 2022",
    average_salary: 42,
    place: "Remote",
  },
  {
    id: 1755,
    title: "Sr. ML Engineer with CV on mobile devices",
    company: "Brainly",
    salary: "65K - 96K EUR",
    date: "April 2022",
    average_salary: 80.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1756,
    title: "Sr. Back-end Engineer ( Go)",
    company: "Brainly",
    salary: "45K - 75K EUR",
    date: "April 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1757,
    title: "Senior Software Engineer",
    company: "Mundimoto",
    salary: "60K - 90K EUR",
    date: "April 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 1758,
    title: "Software Engineer",
    company: "Mundimoto",
    salary: "45K - 65K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1759,
    title: "Android Software Engineer",
    company: "Mundimoto",
    salary: "45K - 90K EUR",
    date: "April 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1760,
    title: "Junior Back-end Developer",
    company: "Spathios",
    salary: "27K - 32K EUR",
    date: "April 2022",
    average_salary: 29.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1761,
    title: "Senior Backend Developer",
    company: "Abi Global Health",
    salary: "50K - 60K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1762,
    title: "Data Engineer (mid/senior)",
    company: "Brainly",
    salary: "40K - 65K EUR",
    date: "April 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1763,
    title: "3D Unity Lead Developer",
    company: "Gamium",
    salary: "90K - 110K EUR",
    date: "April 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1764,
    title: "Java Backend Developer",
    company: "NPAW",
    salary: "40K - 50K EUR",
    date: "April 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1765,
    title: "Android Developer (SDK)",
    company: "NPAW",
    salary: "35K - 40K EUR",
    date: "April 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1766,
    title: "Senior Mobile Developer (Android / iOS)",
    company: "Trade Republic",
    salary: "80K - 90K EUR",
    date: "April 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1767,
    title: "Sr. Frontend Developer",
    company: "Brainly",
    salary: "50K - 75K EUR",
    date: "April 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1768,
    title: "Sr. PHP Developer",
    company: "Brainly",
    salary: "50K - 75K EUR",
    date: "April 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1769,
    title: "Junior Software Developer",
    company: "Wiris",
    salary: "24K - 30K EUR",
    date: "April 2022",
    average_salary: 27,
    place: "Barcelona/Remote",
  },
  {
    id: 1770,
    title: "Senior Scrum Master",
    company: "Hexagon M.I.",
    salary: "40K - 50K EUR",
    date: "April 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1771,
    title: "Platform Engineer",
    company: "Adevinta",
    salary: "45K - 60K EUR",
    date: "April 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1772,
    title: "Senior iOS developer",
    company: "Sendwave",
    salary: "85K - 105K EUR",
    date: "April 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1773,
    title: "Front End Engineer Angular (Jr, Ssr)",
    company: "KBC",
    salary: "30K - 40K EUR",
    date: "April 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1774,
    title: "Senior/Lead Search Engineer",
    company: "Brainly",
    salary: "75K - 105K EUR",
    date: "April 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 1775,
    title: "Product Manager, EHR",
    company: "Docplanner",
    salary: "95K - 95K EUR",
    date: "April 2022",
    average_salary: 95,
    place: "Barcelona/Remote",
  },
  {
    id: 1776,
    title: "Data Engineer",
    company: "Tinybird",
    salary: "65K - 65K EUR",
    date: "April 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1777,
    title: "Lead Frontend",
    company: "Selectra",
    salary: "45K - 60K EUR",
    date: "April 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1778,
    title: "Python Developer",
    company: "Essentialist",
    salary: "40K - 50K EUR",
    date: "April 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1779,
    title: "Frontend Developer",
    company: "Runroom",
    salary: "35K - 40K EUR",
    date: "April 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1780,
    title: "Mid Data Engineer",
    company: "Wetaca",
    salary: "35K - 52K EUR",
    date: "April 2022",
    average_salary: 43.5,
    place: "Remote",
  },
  {
    id: 1781,
    title: "Senior Backend Engineer (Python/Typescript/Node)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "April 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1782,
    title: "Backend Engineer (Python/Typescript/Node)",
    company: "sennder",
    salary: "50K - 65K EUR",
    date: "April 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1783,
    title: "Engineering Lead",
    company: "Manomano",
    salary: "60K - 70K EUR",
    date: "April 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1784,
    title: "Senior Backend Developer (Ruby on Rails)",
    company: "Gartner",
    salary: "60K - 75K EUR",
    date: "April 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1785,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "April 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1786,
    title: "Software Engineer (JavaScript)",
    company: "Edpuzzle",
    salary: "43K - 58K EUR",
    date: "April 2022",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1787,
    title: "FullStack Engineer (Contract 6 months) (PHP)",
    company: "LoveToKnow Media",
    salary: "45K - 53K EUR",
    date: "April 2022",
    average_salary: 49,
    place: "Barcelona/Remote",
  },
  {
    id: 1788,
    title: "Senior Data Engineer(AWS,Redshift, Snowflake, ETL)",
    company: "BRAINLY",
    salary: "50K - 76K EUR",
    date: "April 2022",
    average_salary: 63,
    place: "Barcelona/Remote",
  },
  {
    id: 1789,
    title: "Senior Backend Developer",
    company: "SeQura",
    salary: "55K - 75K EUR",
    date: "April 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1790,
    title: "Java Developer",
    company: "Dragonfly",
    salary: "25K - 50K EUR",
    date: "April 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1791,
    title: "Junior SRE",
    company: "Geoblink",
    salary: "35K - 45K EUR",
    date: "April 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1792,
    title: "Python Developer",
    company: "Immfly",
    salary: "45K - 50K EUR",
    date: "April 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1793,
    title: "React Developer",
    company: "Immfly",
    salary: "45K - 50K EUR",
    date: "April 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1794,
    title: "Fullstack developers (Java, Vue, Node, Spring)",
    company: "Schwarz IT BCN",
    salary: "45K - 55K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Remote (COVID)",
  },
  {
    id: 1795,
    title: "Senior Backend Engineer",
    company: "Basepaws",
    salary: "100K - 130K USD",
    date: "April 2022",
    average_salary: 115,
    place: "Remote",
  },
  {
    id: 1796,
    title: "Senior MEAN/full-stack Engineer",
    company: "The Good Goal",
    salary: "35K - 45K EUR",
    date: "April 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1797,
    title: "Symfony Technical Project Lead",
    company: "Xcelirate",
    salary: "55K - 80K EUR",
    date: "April 2022",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 1798,
    title: "Head of Eng or CTO",
    company: "Ninety Nine",
    salary: "80K - 120K EUR",
    date: "April 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 1799,
    title: "Senior Software Engineer (Ruby on Rails)",
    company: "GetApp (Gartner)",
    salary: "65K - 80K EUR",
    date: "April 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1800,
    title: "Senior Full Stack Software Engineer",
    company: "Capterra (Gartner)",
    salary: "65K - 80K EUR",
    date: "April 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1801,
    title: "Staff Software Engineer (NodeJS, ReactJS)",
    company: "Capterra (Gartner)",
    salary: "85K - 100K EUR",
    date: "April 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1802,
    title: "Senior PHP Engineer",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1803,
    title: "Senior JS/TS Engineer",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1804,
    title: "Python Engineer",
    company: "Vintra",
    salary: "70K - 90K EUR",
    date: "April 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1805,
    title: "Back-end Engineer (PHP)",
    company: "Ankorstore",
    salary: "52K - 70K EUR",
    date: "April 2022",
    average_salary: 61,
    place: "Barcelona/Remote",
  },
  {
    id: 1806,
    title: "Senior-level Software Engineer (Go)",
    company: "World Mobile",
    salary: "80K - 100K EUR",
    date: "April 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 1807,
    title: "Back-end Web Developer - Mid-Level (Go or NodeJS)",
    company: "World Mobile",
    salary: "40K - 60K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1808,
    title: "Plutus Smart Contracts Developer",
    company: "World Mobile",
    salary: "80K - 80K EUR",
    date: "April 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 1809,
    title: "Java Senior Engineer",
    company: "eDreams",
    salary: "48K - 60K EUR",
    date: "April 2022",
    average_salary: 54,
    place: "Barcelona/Remote",
  },
  {
    id: 1810,
    title: "TechLead PHP",
    company: "Aratech",
    salary: "35K - 40K EUR",
    date: "April 2022",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 1811,
    title: "Software Engineer PHP",
    company: "Playwing",
    salary: "20K - 30K EUR",
    date: "April 2022",
    average_salary: 25,
    place: "Remote",
  },
  {
    id: 1812,
    title: "Senior iOS Engineer, Monetization team",
    company: "Brainly",
    salary: "44K - 76K EUR",
    date: "April 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1813,
    title: "Senior PHP Developer (Symfony)",
    company: "Jagaad",
    salary: "45K - 70K EUR",
    date: "April 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 1814,
    title: "(Mid) .NET Software Developer",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1815,
    title: "Tech Lead",
    company: "Caravelo",
    salary: "45K - 70K EUR",
    date: "April 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1816,
    title: "Senior Backend Developer",
    company: "Caravelo",
    salary: "45K - 70K EUR",
    date: "April 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1817,
    title: "Senior DevOps Engineer",
    company: "Caravelo",
    salary: "45K - 70K EUR",
    date: "April 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1818,
    title: "Full-stack Developer",
    company: "Spathios",
    salary: "30K - 40K EUR",
    date: "April 2022",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 1819,
    title: "Elixir software engineer",
    company: "Horizon65",
    salary: "60K - 85K EUR",
    date: "April 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1820,
    title: "PHP Developer",
    company: "Degusta Box",
    salary: "40K - 40K EUR",
    date: "April 2022",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 1821,
    title: "VP of Engineering",
    company: "World Mobile",
    salary: "80K - 110K EUR",
    date: "April 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1822,
    title: "Sr. Front-end Engineer",
    company: "Brainly",
    salary: "50K - 75K EUR",
    date: "April 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1823,
    title: "SR. PHP Developer",
    company: "Brainly",
    salary: "50K - 75K EUR",
    date: "April 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1824,
    title: "Ruby/Elixir Sr. Software Engineer",
    company: "Rzilient",
    salary: "60K - 75K EUR",
    date: "April 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1825,
    title: "SRE",
    company: "Sparta",
    salary: "65K - 80K EUR",
    date: "April 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1826,
    title: "Full-stack Developer",
    company: "Spathios",
    salary: "35K - 45K EUR",
    date: "April 2022",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 1827,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "GetApp (Gartner)",
    salary: "60K - 80K EUR",
    date: "April 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1828,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Capterra (Gartner)",
    salary: "60K - 80K EUR",
    date: "April 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1829,
    title: "Senior Frontend Engineer",
    company: "YourYeti",
    salary: "75K - 95K EUR",
    date: "April 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1830,
    title: "Software Architect (Python OR C++)",
    company: "Dive tech",
    salary: "60K - 65K EUR",
    date: "April 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1831,
    title: "Java Backend Developer",
    company: "Instaply",
    salary: "50K - 55K EUR",
    date: "April 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1832,
    title: "Tech Lead (Python)",
    company: "Flanks",
    salary: "50K - 60K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1833,
    title: "Senior Software Engineer, Engineering productivity",
    company: "Flywire",
    salary: "45K - 75K EUR",
    date: "April 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1834,
    title: "Backend Engineer - C#",
    company: "Docplanner",
    salary: "30K - 60K EUR",
    date: "April 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1835,
    title: "Software Engineering Team Leader",
    company: "Docplanner",
    salary: "60K - 80K EUR",
    date: "April 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1836,
    title: "Senior Frontend Engineer",
    company: "ForceManager",
    salary: "45K - 55K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1837,
    title: "React Senior Developer",
    company: "Signa Sports United",
    salary: "45K - 60K EUR",
    date: "April 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 1838,
    title: "Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "40K - 60K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1839,
    title: "Junior Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "29K - 36K EUR",
    date: "April 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1840,
    title: "Junior Javascript Developer",
    company: "FCM Lab",
    salary: "25K - 32K EUR",
    date: "April 2022",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1841,
    title: "QA Tester",
    company: "FCM Lab",
    salary: "29K - 34K EUR",
    date: "April 2022",
    average_salary: 31.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1842,
    title: "QA Engineer/Tester",
    company: "Brainly",
    salary: "25K - 41K EUR",
    date: "April 2022",
    average_salary: 33,
    place: "Barcelona/Remote",
  },
  {
    id: 1843,
    title: "Senior Data Engineer (Snowflake, AWS, Python, SQL)",
    company: "Brainly",
    salary: "50K - 76K EUR",
    date: "April 2022",
    average_salary: 63,
    place: "Barcelona/Remote",
  },
  {
    id: 1844,
    title: "Data Engineer,Finance | Snowflake,AWS, Python, SQL",
    company: "Brainly",
    salary: "30K - 55K EUR",
    date: "April 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1845,
    title: "Senior Mobile Engineer (iOS+Android, ML, AI)",
    company: "Brainly",
    salary: "50K - 76K EUR",
    date: "April 2022",
    average_salary: 63,
    place: "Barcelona/Remote",
  },
  {
    id: 1846,
    title: "SENIOR SOFTWARE ARQUITECT",
    company: "HERTA SECURITY SL",
    salary: "40K - 60K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1847,
    title: "Software Engineer in Test",
    company: "Oasis.app",
    salary: "120K - 150K USD",
    date: "April 2022",
    average_salary: 135,
    place: "Remote",
  },
  {
    id: 1848,
    title: "CTO",
    company: "Oasis.app",
    salary: "180K - 250K USD",
    date: "April 2022",
    average_salary: 215,
    place: "Remote",
  },
  {
    id: 1849,
    title: "Fullstack Engineer (React/Node)",
    company: "Hera",
    salary: "60K - 90K USD",
    date: "April 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1850,
    title: "Senior QA Analyst",
    company: "Tangelo Games",
    salary: "30K - 40K EUR",
    date: "April 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 1851,
    title: "Mid Frontend Developer",
    company: "Tangelo Games",
    salary: "30K - 35K EUR",
    date: "April 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1852,
    title: "Senior Backend PHP Developer",
    company: "Foxstone",
    salary: "35K - 60K EUR",
    date: "April 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1853,
    title: "Senior Frontend Angular Developer",
    company: "Foxstone",
    salary: "35K - 60K EUR",
    date: "April 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1854,
    title: "Frontend React.js",
    company: "Weecover",
    salary: "30K - 40K EUR",
    date: "April 2022",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 1855,
    title: "Javascript Developer",
    company: "Bipicar",
    salary: "35K - 45K EUR",
    date: "April 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1856,
    title: "Software Engineer – Merchant Integrations - PHP",
    company: "WeGetFinancing",
    salary: "60K - 70K EUR",
    date: "April 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1857,
    title: "Senior Python Engineer",
    company: "Vintra.io",
    salary: "55K - 80K EUR",
    date: "April 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1858,
    title: "Full Stack Engineer",
    company: "Senniors",
    salary: "55K - 70K EUR",
    date: "April 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1859,
    title: "Full Stack Developper",
    company: "Bokokode",
    salary: "24K - 27K EUR",
    date: "April 2022",
    average_salary: 25.5,
    place: "Remote",
  },
  {
    id: 1860,
    title: "Frontend Developer (React)",
    company: "Abi Global Health",
    salary: "50K - 60K EUR",
    date: "April 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1861,
    title: "Infrastructure Engineer",
    company: "Devex",
    salary: "40K - 51K EUR",
    date: "April 2022",
    average_salary: 45.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1862,
    title: "Senior Site Reliability Engineer",
    company: "Moonpay",
    salary: "90K - 120K EUR",
    date: "April 2022",
    average_salary: 105,
    place: "Remote",
  },
  {
    id: 1863,
    title: "Data Engineer",
    company: "Netquest",
    salary: "40K - 50K EUR",
    date: "April 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1864,
    title: "Junior backend developer (PHP)",
    company: "Parkimeter -EasyPark",
    salary: "24K - 36K EUR",
    date: "April 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 1865,
    title: "Mid Full Stack Engineer",
    company: "Latitude",
    salary: "50K - 50K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1866,
    title: "Engineering Manager (Cloud)",
    company: "Datatonic",
    salary: "70K - 100K EUR",
    date: "April 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 1867,
    title: "Mid/Senior Frontend engineer",
    company: "Silt",
    salary: "50K - 50K EUR",
    date: "April 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1868,
    title: "JavaScript Software Developer",
    company: "Revieve",
    salary: "55K - 65K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 1869,
    title: "Node Developer",
    company: "TapTap",
    salary: "40K - 45K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1870,
    title: "Java developer",
    company: "sezamo (rohlikgroup)",
    salary: "45K - 70K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1871,
    title: "Senior Full-Stack Engineer",
    company: "Archlet",
    salary: "55K - 64K EUR",
    date: "May 2022",
    average_salary: 59.5,
    place: "Barcelona",
  },
  {
    id: 1872,
    title: "Android Developer",
    company: "sezamo (rohlikgroup)",
    salary: "45K - 70K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1873,
    title: "Engineering Team Lead",
    company: "OriGen.AI",
    salary: "60K - 75K EUR",
    date: "May 2022",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 1874,
    title: "Front End Developer (Design)",
    company: "OriGen. AI",
    salary: "35K - 45K EUR",
    date: "May 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1875,
    title: "Junior SysAdmin",
    company: "STR Sistemas",
    salary: "25K - 30K EUR",
    date: "May 2022",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 1876,
    title: "FullStack Developers Nodejs/React",
    company: "Volcanic Internet",
    salary: "40K - 55K EUR",
    date: "May 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 1877,
    title: "Senior Backend Engineer (Python / OOP)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "May 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1878,
    title: "Linux System Administrator",
    company: "DerbySoft",
    salary: "48K - 60K EUR",
    date: "May 2022",
    average_salary: 54,
    place: "Barcelona/Remote",
  },
  {
    id: 1879,
    title: "Frontend Engineer (Remote, 4 days a week)",
    company: "Silt",
    salary: "50K - 50K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1880,
    title: "Software Engineer .NET",
    company: "Ohpen",
    salary: "45K - 60K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1881,
    title: "QA Engineer Manual",
    company: "Ohpen",
    salary: "35K - 55K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 1882,
    title: ".NET Software Developer",
    company: "Gartner",
    salary: "55K - 60K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1883,
    title: "Android Developer",
    company: "The Knot Worldwide",
    salary: "35K - 45K EUR",
    date: "May 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1884,
    title: "Senior Data Engineer",
    company: "Caravelo :airplane:",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1885,
    title: "Senior Blockchain Infrastructure Engineer",
    company: "Polygon",
    salary: "90K - 200K USD",
    date: "May 2022",
    average_salary: 145,
    place: "Remote",
  },
  {
    id: 1886,
    title: "Senior Backend Developer",
    company: "SeQura",
    salary: "55K - 75K EUR",
    date: "May 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 1887,
    title: "Presales Manager",
    company: "Caravelo",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 1888,
    title: "Mid Level QA Automation Engineer",
    company: "Mindcurv",
    salary: "40K - 46K EUR",
    date: "May 2022",
    average_salary: 43,
    place: "Remote",
  },
  {
    id: 1889,
    title: "Android Engineer",
    company: "Brainly",
    salary: "40K - 80K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1890,
    title: "Front-end Developer",
    company: "Spathios",
    salary: "35K - 45K EUR",
    date: "May 2022",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 1891,
    title: "Senior Scala Software Engineer (no Data Engineers)",
    company: "Netquest",
    salary: "70K - 80K EUR",
    date: "May 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1892,
    title: "Lead Frontend Engineer",
    company: "Plum Guide",
    salary: "80K - 100K EUR",
    date: "May 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1893,
    title: "Java Backend Developer",
    company: "Smart Technologies",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1894,
    title: "Software Engineer (React/Node)",
    company: "Edpuzzle",
    salary: "43K - 58K EUR",
    date: "May 2022",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1895,
    title: "SRE",
    company: "Netquest",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1896,
    title: "Senior DevOps Engineer",
    company: "Uberall",
    salary: "75K - 95K EUR",
    date: "May 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1897,
    title: "Senior Backend Engineer - Kotlin/AWS",
    company: "GoodNotes",
    salary: "80K - 110K EUR",
    date: "May 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1898,
    title: "Senior/ Software Engineer - Data Platform",
    company: "GoodNotes",
    salary: "80K - 110K EUR",
    date: "May 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1899,
    title: "Senior Software Engineer, iOS",
    company: "GoodNotes",
    salary: "80K - 110K EUR",
    date: "May 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1900,
    title: "Senior Software Engineer, Swift (Cross platform)",
    company: "GoodNotes",
    salary: "80K - 110K EUR",
    date: "May 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 1901,
    title: "Lead Backend Developer",
    company: "Tonsser",
    salary: "85K - 100K EUR",
    date: "May 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1902,
    title: "Scout | IT Recruiter",
    company: "Manfred",
    salary: "20K - 30K EUR",
    date: "May 2022",
    average_salary: 25,
    place: "Remote",
  },
  {
    id: 1903,
    title: "Backend Nodejs",
    company: "Tookane",
    salary: "36K - 45K EUR",
    date: "May 2022",
    average_salary: 40.5,
    place: "Remote",
  },
  {
    id: 1904,
    title: "Senior Software Engineer - FrontEnd",
    company: "New Relic",
    salary: "60K - 83K EUR",
    date: "May 2022",
    average_salary: 71.5,
    place: "Remote",
  },
  {
    id: 1905,
    title: "Manager, Software Engineering",
    company: "New Relic",
    salary: "70K - 100K EUR",
    date: "May 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1906,
    title: "Product Designer",
    company: "Kariboo.io",
    salary: "20K - 35K EUR",
    date: "May 2022",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 1907,
    title: "Node.js Backend Engineer",
    company: "Consensys - Codefi",
    salary: "75K - 100K EUR",
    date: "May 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1908,
    title: "Site Reliability Engineer (SRE)",
    company: "Consensys - Infura",
    salary: "75K - 100K EUR",
    date: "May 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 1909,
    title: "Data Engineer",
    company: "Barkibu",
    salary: "35K - 50K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1910,
    title: "Senior Frontend VueJS Developer",
    company: "Mind The Bridge",
    salary: "35K - 40K EUR",
    date: "May 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 1911,
    title: "Frontend React Developer",
    company: "Colibid",
    salary: "35K - 45K EUR",
    date: "May 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1912,
    title: "Software Engineer PHP / Symfony",
    company: "Zinio",
    salary: "45K - 52K EUR",
    date: "May 2022",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1913,
    title: "Tech Lead (Knowledge Lead)",
    company: "Spathios",
    salary: "45K - 60K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 1914,
    title: "Android Developer (SDK)",
    company: "NPAW",
    salary: "35K - 50K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1915,
    title: "DevRel",
    company: "KuFlow",
    salary: "40K - 40K EUR",
    date: "May 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1916,
    title: "Software developer (30h/semana)",
    company: "Qatium",
    salary: "45K - 45K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1917,
    title: "Senior Backend Developer",
    company: "Caravelo",
    salary: "45K - 60K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1918,
    title: "Senior DevOps Engineer",
    company: "Caravelo",
    salary: "45K - 70K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1919,
    title: "Senior Frontend Developer",
    company: "Caravelo",
    salary: "45K - 60K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1920,
    title: "Tech Lead",
    company: "Caravelo",
    salary: "55K - 70K EUR",
    date: "May 2022",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 1921,
    title: "Lead Instructor - Web Development",
    company: "Ironhack",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1922,
    title: "Lead Instructor - UX/UI Design",
    company: "Ironhack",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1923,
    title: "Lead Instructor - Data Analytics",
    company: "Ironhack",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 1924,
    title: "Software Architect",
    company: "World Mobile",
    salary: "100K - 150K EUR",
    date: "May 2022",
    average_salary: 125,
    place: "Barcelona/Remote",
  },
  {
    id: 1925,
    title: "Software Engineer (Backend/Go)",
    company: "AREX Markets",
    salary: "35K - 42K EUR",
    date: "May 2022",
    average_salary: 38.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1926,
    title: "Full-Stack Engineer",
    company: "Trialing",
    salary: "27K - 30K EUR",
    date: "May 2022",
    average_salary: 28.5,
    place: "Remote",
  },
  {
    id: 1927,
    title: "Principal Software Engineer",
    company: "Zscaler",
    salary: "70K - 80K EUR",
    date: "May 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 1928,
    title: "Backend Developer",
    company: "Lolapay (GoTrendier)",
    salary: "43K - 52K EUR",
    date: "May 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1929,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Capterra (Gartner)",
    salary: "70K - 82K EUR",
    date: "May 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 1930,
    title: "Senior iOS Developer",
    company: "Fleksy",
    salary: "50K - 62K EUR",
    date: "May 2022",
    average_salary: 56,
    place: "Remote",
  },
  {
    id: 1931,
    title: "Angular and/or .NET Developer",
    company: "AlgoMilk",
    salary: "35K - 50K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1932,
    title: "DevOps Engineer",
    company: "GoTrendier",
    salary: "42K - 48K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1933,
    title: "Junior SRE",
    company: "Geoblink",
    salary: "35K - 45K EUR",
    date: "May 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 1934,
    title: "Python Developer",
    company: "Essentialist",
    salary: "40K - 50K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1935,
    title: "Frontend Engineer",
    company: "Tinybird",
    salary: "82K - 82K EUR",
    date: "May 2022",
    average_salary: 82,
    place: "Remote",
  },
  {
    id: 1936,
    title: "Senior Backend Engineer - API & Integrations",
    company: "Product School",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1937,
    title: "DevOps Engineer",
    company: "Edpuzzle",
    salary: "43K - 53K EUR",
    date: "May 2022",
    average_salary: 48,
    place: "Barcelona/Remote",
  },
  {
    id: 1938,
    title: "Senior Frontend Engineer (4 days/week)",
    company: "Silt",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1939,
    title: "Senior Software Engineer",
    company: "Wikimedia Foundation",
    salary: "60K - 72K EUR",
    date: "May 2022",
    average_salary: 66,
    place: "Remote",
  },
  {
    id: 1940,
    title: "Senior Full Stack Developer (Node/React)",
    company: "Capterra (Gartner)",
    salary: "60K - 72K EUR",
    date: "May 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 1941,
    title: ".NET Software Engineer",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1942,
    title: "Senior Frontend Developer",
    company: "TalentBait",
    salary: "43K - 53K EUR",
    date: "May 2022",
    average_salary: 48,
    place: "Barcelona",
  },
  {
    id: 1943,
    title: "Quality Assurance Engineer (manual)",
    company: "Brainly",
    salary: "25K - 41K EUR",
    date: "May 2022",
    average_salary: 33,
    place: "Barcelona/Remote",
  },
  {
    id: 1944,
    title: "Senior Data Engineer (AWS, Python, Snowflake)",
    company: "Brainly",
    salary: "50K - 76K EUR",
    date: "May 2022",
    average_salary: 63,
    place: "Barcelona/Remote",
  },
  {
    id: 1945,
    title: "iOS Engineer (with experience in ML integrations)",
    company: "Brainly",
    salary: "38K - 76K EUR",
    date: "May 2022",
    average_salary: 57,
    place: "Barcelona/Remote",
  },
  {
    id: 1946,
    title: "Backend Engineer (Golang/to learn Go)",
    company: "Brainly",
    salary: "40K - 65K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1947,
    title: "Senior Cloud Software Engineer",
    company: "Genie",
    salary: "60K - 85K EUR",
    date: "May 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1948,
    title: "Junior Java Developer",
    company: "Sll Concatel",
    salary: "18K - 25K EUR",
    date: "May 2022",
    average_salary: 21.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1949,
    title: "Junior .NET Developer",
    company: "Sll Concatel",
    salary: "18K - 25K EUR",
    date: "May 2022",
    average_salary: 21.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1950,
    title: "Senior Backend Developer | Data Oriented",
    company: "SMART Technologies",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1951,
    title: "SysAdmin",
    company: "TuLotero",
    salary: "30K - 40K EUR",
    date: "May 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 1952,
    title: ".NET Developer",
    company: "QBox",
    salary: "40K - 50K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1953,
    title: "Senior Java Developer",
    company: "Mindcurv",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1954,
    title: "Front End Developer with Angular",
    company: "Porsche Digital BCN",
    salary: "35K - 50K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1955,
    title: "Junior Backend Developer",
    company: "Inspiring Benefits",
    salary: "30K - 30K EUR",
    date: "May 2022",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 1956,
    title: "Mid .NET Developer",
    company: "Copyright CC",
    salary: "35K - 47K EUR",
    date: "May 2022",
    average_salary: 41,
    place: "Remote",
  },
  {
    id: 1957,
    title: "Data Engineer / DB Developer",
    company: "Aily Labs",
    salary: "40K - 55K EUR",
    date: "May 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1958,
    title: "Full-Stack Engineer",
    company: "StyleSage",
    salary: "55K - 55K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1959,
    title: "Java Backend Developer",
    company: "Portfolio",
    salary: "45K - 45K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1960,
    title: "Senior/Staff Software Engineer",
    company: "Factorial",
    salary: "55K - 70K EUR",
    date: "May 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 1961,
    title: "Senior Cloud Engineer",
    company: "Mitek Systems",
    salary: "50K - 65K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 1962,
    title: "Front-end to Full-stack Developer",
    company: "Spathios",
    salary: "40K - 50K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1963,
    title: "Tech Lead",
    company: "Spathios",
    salary: "45K - 70K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1964,
    title: "Data Scientist",
    company: "Spathios",
    salary: "48K - 55K EUR",
    date: "May 2022",
    average_salary: 51.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1965,
    title: "[ Dev(Sec)Ops | Platform | SRE ] Engineer",
    company: "Netquest",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1966,
    title: "Software Engineer",
    company: "Zinio",
    salary: "45K - 52K EUR",
    date: "May 2022",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1967,
    title: "Frontend Developer",
    company: "Whalar",
    salary: "65K - 65K EUR",
    date: "May 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1968,
    title: "PHP Developer with Laravel or other frameworks exp",
    company: "Collective minds",
    salary: "34K - 45K EUR",
    date: "May 2022",
    average_salary: 39.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1969,
    title: "DevOps",
    company: "Uberall",
    salary: "80K - 90K EUR",
    date: "May 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1970,
    title: "Lead Frontend engineer",
    company: "Plum Guide",
    salary: "80K - 100K EUR",
    date: "May 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 1971,
    title: "Node.js & React Dev!",
    company: "rinkel.nl",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 1972,
    title: "Node.js & Typescript",
    company: "Immfly",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1973,
    title: "Django Developer",
    company: "Immfly",
    salary: "40K - 50K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 1974,
    title: "Backend Lead",
    company: "FuelVsion",
    salary: "65K - 100K EUR",
    date: "May 2022",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 1975,
    title: "React Dev",
    company: "FuelVision",
    salary: "60K - 85K EUR",
    date: "May 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 1976,
    title: "Data Engineer",
    company: "Geoblink",
    salary: "45K - 65K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1977,
    title: "Senior PHP Backend Developer",
    company: "Parclick",
    salary: "45K - 52K EUR",
    date: "May 2022",
    average_salary: 48.5,
    place: "Remote",
  },
  {
    id: 1978,
    title: "SRE / DevOps",
    company: "Mlean",
    salary: "40K - 45K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 1979,
    title: "Backend Developer + DevOps",
    company: "IsEazy Engage",
    salary: "40K - 48K EUR",
    date: "May 2022",
    average_salary: 44,
    place: "Remote",
  },
  {
    id: 1980,
    title: "PHP Backend Developer",
    company: "IsEazy Author",
    salary: "38K - 45K EUR",
    date: "May 2022",
    average_salary: 41.5,
    place: "Remote",
  },
  {
    id: 1981,
    title: "C++ Developer",
    company: "Tinybird",
    salary: "38K - 73K EUR",
    date: "May 2022",
    average_salary: 55.5,
    place: "Remote",
  },
  {
    id: 1982,
    title: "Senior Data Engineer",
    company: "New Relic",
    salary: "60K - 83K EUR",
    date: "May 2022",
    average_salary: 71.5,
    place: "Remote",
  },
  {
    id: 1983,
    title: "DevRel developer",
    company: "Tilting Point",
    salary: "40K - 60K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1984,
    title: "Senior DevRel developer",
    company: "Tilting Point",
    salary: "60K - 80K EUR",
    date: "May 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 1985,
    title: "Java backend developer",
    company: "Rohlik",
    salary: "45K - 75K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1986,
    title: "Android developer",
    company: "Rohlik",
    salary: "45K - 75K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 1987,
    title: "Senior Data Scientist (user behavior)",
    company: "Brainly",
    salary: "90K - 105K EUR",
    date: "May 2022",
    average_salary: 97.5,
    place: "Barcelona/Remote",
  },
  {
    id: 1988,
    title: "Senior Backend Engineer",
    company: "Lifebit",
    salary: "45K - 65K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1989,
    title: "Senior Frontend Engineer",
    company: "Lifebit",
    salary: "45K - 65K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1990,
    title: "Lead UX/UI Designer",
    company: "TechTree",
    salary: "40K - 60K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 1991,
    title: "Front & App Tech Lead",
    company: "TOUS SA",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 1992,
    title: "Senior Software Engineer",
    company: "humanITcare",
    salary: "50K - 58K EUR",
    date: "May 2022",
    average_salary: 54,
    place: "Remote",
  },
  {
    id: 1993,
    title: "Data Engineer",
    company: "Prestalo",
    salary: "40K - 50K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 1994,
    title: "PHP Backend Developer",
    company: "Classlife",
    salary: "30K - 40K EUR",
    date: "May 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 1995,
    title: "Engineering Manager",
    company: "Voicemod",
    salary: "70K - 100K EUR",
    date: "May 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 1996,
    title: "Senior C++ Engineer",
    company: "Voicemod",
    salary: "50K - 60K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 1997,
    title: "Backend Engineer (Node.js)",
    company: "Crave Tech",
    salary: "50K - 80K EUR",
    date: "May 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 1998,
    title: "Senior and Principals Software Engineers",
    company: "Qualifyze",
    salary: "70K - 110K EUR",
    date: "May 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 1999,
    title: "Senior Ruby Backend Developer",
    company: "Bergamotte",
    salary: "65K - 80K EUR",
    date: "May 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2000,
    title: "Software Engineer",
    company: "Anansi",
    salary: "30K - 65K GBP",
    date: "May 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2001,
    title: "Senior Software Engineer",
    company: "Anansi",
    salary: "60K - 85K GBP",
    date: "May 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2002,
    title: "Mid Data Scientist",
    company: "Vlex",
    salary: "40K - 45K EUR",
    date: "May 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2003,
    title: "DevOps Expert - Cloud Software Development",
    company: "CloudBlue",
    salary: "50K - 70K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 2004,
    title: "Team Lead Data Platform",
    company: "New Work",
    salary: "80K - 110K EUR",
    date: "May 2022",
    average_salary: 95,
    place: "Barcelona/Remote",
  },
  {
    id: 2005,
    title: "Data Engineering",
    company: "New Work",
    salary: "45K - 65K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2006,
    title: "Node.js Developer",
    company: "New Work",
    salary: "40K - 65K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2007,
    title: "Team Lead Software Engineer (Java, RoR, React)",
    company: "New Work",
    salary: "80K - 80K EUR",
    date: "May 2022",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 2008,
    title: "REACT Developer (Mid or Senior)",
    company: "New Work",
    salary: "40K - 65K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2009,
    title: "Principal and Senior Software Engineers",
    company: "Qualifyze",
    salary: "100K - 100K EUR",
    date: "May 2022",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 2010,
    title: "NodeJS Express Senior Engineer",
    company: "Inviertis Properties",
    salary: "40K - 50K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2011,
    title: "Senior Javascript Engineer",
    company: "Userzoom",
    salary: "50K - 55K EUR",
    date: "May 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2012,
    title: "Senior Software Engineer",
    company: "Capdesk",
    salary: "70K - 85K GBP",
    date: "May 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 2013,
    title: "Senior Full Stack Engineer",
    company: "Capdesk",
    salary: "70K - 85K GBP",
    date: "May 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 2014,
    title: "Senior Product Designer",
    company: "Capdesk",
    salary: "60K - 68K GBP",
    date: "May 2022",
    average_salary: 64,
    place: "Remote",
  },
  {
    id: 2015,
    title: "Backend Software Engineer (Ruby/Rust/Go - AWS/GCP)",
    company: "Rzilient",
    salary: "60K - 75K EUR",
    date: "May 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2016,
    title: "Software Engineer .NET",
    company: "Ohpen",
    salary: "45K - 65K EUR",
    date: "May 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2017,
    title: "QA Engineer",
    company: "Ohpen",
    salary: "35K - 55K EUR",
    date: "May 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2018,
    title: "DevOps",
    company: "Ohpen",
    salary: "50K - 65K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2019,
    title: "Security Operations Engineer",
    company: "Preply",
    salary: "70K - 85K EUR",
    date: "May 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2020,
    title: "Frontend Engineer",
    company: "wallapop",
    salary: "40K - 71K EUR",
    date: "May 2022",
    average_salary: 55.5,
    place: "Barcelona",
  },
  {
    id: 2021,
    title: "iOS Engineer",
    company: "wallapop",
    salary: "40K - 75K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2022,
    title: "Android Engineer",
    company: "wallapop",
    salary: "40K - 75K EUR",
    date: "May 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2023,
    title: "Backend Engineer",
    company: "wallapop",
    salary: "40K - 77K EUR",
    date: "May 2022",
    average_salary: 58.5,
    place: "Barcelona",
  },
  {
    id: 2024,
    title: "Senior Frontend Engineer - 4 days, remote",
    company: "Silt",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2025,
    title: "Backend Tech Lead",
    company: "wallapop",
    salary: "68K - 92K EUR",
    date: "May 2022",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 2026,
    title: "Senior Product Manager - 4 days, remote",
    company: "Silt",
    salary: "45K - 55K EUR",
    date: "May 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2027,
    title: "Android Tech Lead",
    company: "wallapop",
    salary: "68K - 92K EUR",
    date: "May 2022",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 2028,
    title: "Lead Frontend",
    company: "Plum Guide",
    salary: "80K - 100K EUR",
    date: "May 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 2029,
    title: "Senior Backend Engineer",
    company: "Fever",
    salary: "50K - 70K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2030,
    title: "SRE/ Devops Engineer",
    company: "Fever",
    salary: "55K - 70K EUR",
    date: "May 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2031,
    title: "Senior Frontend Engineer",
    company: "Fever",
    salary: "50K - 70K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2032,
    title: "Senior Product Manager",
    company: "Fever",
    salary: "50K - 70K EUR",
    date: "May 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2033,
    title: "Engineering Manager",
    company: "Fever",
    salary: "60K - 90K EUR",
    date: "May 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2034,
    title: "SRE",
    company: "Zapier",
    salary: "80K - 95K EUR",
    date: "May 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 2035,
    title: "Full Stack Engineer - Security",
    company: "Zapier",
    salary: "75K - 90K EUR",
    date: "May 2022",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 2036,
    title: "Senior Frontend Engineer",
    company: "MURAL",
    salary: "68K - 80K EUR",
    date: "May 2022",
    average_salary: 74,
    place: "Remote",
  },
  {
    id: 2037,
    title: "Mobile Application Developer",
    company: "Ahead",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2038,
    title: "Backend Engineer",
    company: "Stream Hatchet",
    salary: "32K - 46K EUR",
    date: "June 2022",
    average_salary: 39,
    place: "Barcelona",
  },
  {
    id: 2039,
    title: "Backend Engineer",
    company: "Back Market",
    salary: "50K - 80K EUR",
    date: "June 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2040,
    title: "Senior Frontend Engieer",
    company: "Bloobirds",
    salary: "40K - 55K EUR",
    date: "June 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2041,
    title: "Backend Engineer @ Sequoia-backed RevMap",
    company: "RevMap",
    salary: "50K - 75K EUR",
    date: "June 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2042,
    title: "Full Stack Engineer @ Sequoia-backed",
    company: "RevMap",
    salary: "50K - 75K EUR",
    date: "June 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2043,
    title: "Frontend Engineer",
    company: "RevMap",
    salary: "50K - 70K EUR",
    date: "June 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2044,
    title: "Full Stack Javascript (React)",
    company: "Billin",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2045,
    title: "Full Stack Javascript (Node.JS)",
    company: "Billin",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2046,
    title: "Data Scientist",
    company: "Netquest",
    salary: "30K - 38K EUR",
    date: "June 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2047,
    title: "Lua, Go, Kong_API Developer",
    company: "GFT",
    salary: "40K - 65K EUR",
    date: "June 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2048,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "GetApp (Gartner)",
    salary: "60K - 80K EUR",
    date: "June 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2049,
    title: "SRE / DevOps Engineer",
    company: "Netquest",
    salary: "50K - 60K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2050,
    title: "Senior Frontend Developer_React / Angular",
    company: "GFT",
    salary: "30K - 50K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2051,
    title: "R / Python Developer",
    company: "Blue Telecom",
    salary: "30K - 45K EUR",
    date: "June 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 2052,
    title: "Senior iOS Engineer",
    company: "Brainly",
    salary: "55K - 80K EUR",
    date: "June 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2053,
    title: "Software Engineer (PHP Backend)",
    company: "PromoFarma/DocMorris",
    salary: "35K - 55K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2054,
    title: "Software Engineer (Java Backend)",
    company: "PromoFarma/DocMorris",
    salary: "35K - 55K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2055,
    title: "QA Automation Engineer",
    company: "PromoFarma/DocMorris",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2056,
    title: "Frontend Developer",
    company: "PromoFarma/DocMorris",
    salary: "35K - 55K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2057,
    title: "Frontend Developer",
    company: "Sparta",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2058,
    title: "Senior Angular Frontend Developer",
    company: "Parclick",
    salary: "45K - 52K EUR",
    date: "June 2022",
    average_salary: 48.5,
    place: "Remote",
  },
  {
    id: 2059,
    title: "Senior Frontend Developer",
    company: "Mindcurv",
    salary: "45K - 57K EUR",
    date: "June 2022",
    average_salary: 51,
    place: "Remote",
  },
  {
    id: 2060,
    title: "Senior Developer",
    company: "Daysk",
    salary: "55K - 55K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2061,
    title: "Software Automation Engineer - Remote",
    company: "Akamai Technologies",
    salary: "70K - 80K EUR",
    date: "June 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2062,
    title: "Senior Software Engineer (Python) - Remote",
    company: "Akamai Technologies",
    salary: "70K - 80K EUR",
    date: "June 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2063,
    title: "Senior Backend Engineer - Remote",
    company: "Akamai Technologies",
    salary: "70K - 80K EUR",
    date: "June 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2064,
    title: "Full Stack Developer",
    company: "Roboyo",
    salary: "40K - 55K EUR",
    date: "June 2022",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 2065,
    title: "Computer Vision Tech Lead",
    company: "Brainly",
    salary: "90K - 120K EUR",
    date: "June 2022",
    average_salary: 105,
    place: "Barcelona/Remote",
  },
  {
    id: 2066,
    title: "Senior Data Scientist with Computer Vision",
    company: "Brainly",
    salary: "90K - 105K EUR",
    date: "June 2022",
    average_salary: 97.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2067,
    title: "Mid Frontent Developer (VueJS)",
    company: "Mind the Bridge",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2068,
    title: "Senior Frontend Engineer - 4 days week - F Remote",
    company: "Silt",
    salary: "50K - 55K EUR",
    date: "June 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2069,
    title: "Blockchain Developer (Web3 project founding team)",
    company: "Wagmi Venture Studio",
    salary: "40K - 50K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2070,
    title: "Web3 Product Owner",
    company: "Wagmi Venture Studio",
    salary: "40K - 60K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2071,
    title: "Web3 Go-to-market Specialist",
    company: "Wagmi Venture Studio",
    salary: "30K - 40K EUR",
    date: "June 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 2072,
    title: "Senior Product",
    company: "Wayflyer",
    salary: "100K - 120K GBP",
    date: "June 2022",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 2073,
    title: "Tech Lead (TypeScript / TechClients Team)",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2074,
    title: "Engineering Manager",
    company: "sennder",
    salary: "80K - 105K EUR",
    date: "June 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2075,
    title: "Junior Fullstack Engineer",
    company: "The Hotels Network",
    salary: "24K - 24K EUR",
    date: "June 2022",
    average_salary: 24,
    place: "Barcelona/Remote",
  },
  {
    id: 2076,
    title: "SENIOR JAVA DEVELOPER",
    company: "Mediktor",
    salary: "50K - 60K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2077,
    title: "Senior Backend Engineer (Python)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "June 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2078,
    title: "Senior Backend Engineer (Typescript)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "June 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2079,
    title: "Backend Engineer (Python)",
    company: "sennder",
    salary: "50K - 65K EUR",
    date: "June 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2080,
    title: "PHP Developer",
    company: "Evalua ID",
    salary: "30K - 36K EUR",
    date: "June 2022",
    average_salary: 33,
    place: "Remote",
  },
  {
    id: 2081,
    title: "React Front Lead",
    company: "Abi Global Health",
    salary: "50K - 60K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2082,
    title: "Front End Developer",
    company: "trainline",
    salary: "50K - 80K EUR",
    date: "June 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2083,
    title: "Android Engineer (Junior to Senior)",
    company: "trainline",
    salary: "45K - 80K EUR",
    date: "June 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2084,
    title: "iOS Engineer (Junior to Senior)",
    company: "trainline",
    salary: "45K - 80K EUR",
    date: "June 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2085,
    title: "Backend Software Engineer",
    company: "trainline",
    salary: "45K - 80K EUR",
    date: "June 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2086,
    title: "Front-end Junior Developer",
    company: "Crowd",
    salary: "20K - 20K EUR",
    date: "June 2022",
    average_salary: 20,
    place: "Barcelona/Remote",
  },
  {
    id: 2087,
    title: "Senior Platform Engineer",
    company: "JobandTalent",
    salary: "75K - 90K EUR",
    date: "June 2022",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 2088,
    title: "Senior Frontend Engineer (React) - Remote",
    company: "ForceManager",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2089,
    title: "Product Manager",
    company: "YouTube",
    salary: "90K - 160K CHF",
    date: "June 2022",
    average_salary: 125,
    place: "Remote",
  },
  {
    id: 2090,
    title: "Senior PHP Engineer",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2091,
    title: "Senior JS/TS Engineer",
    company: "The Hotels Network",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2092,
    title: "Tech Lead (Tech Clients Team)",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2093,
    title: "PHP Developer",
    company: "app2u",
    salary: "26K - 40K EUR",
    date: "June 2022",
    average_salary: 33,
    place: "Barcelona",
  },
  {
    id: 2094,
    title: "SRE",
    company: "Revieve",
    salary: "40K - 50K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2095,
    title: "Data Scientist",
    company: "Mango",
    salary: "50K - 50K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2096,
    title: "Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "40K - 60K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2097,
    title: "Junior Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "29K - 36K EUR",
    date: "June 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2098,
    title: "QA Tester",
    company: "FCM Lab",
    salary: "29K - 34K EUR",
    date: "June 2022",
    average_salary: 31.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2099,
    title: "Senior Backend Engineer (Ruby on Rails)",
    company: "Gartner",
    salary: "63K - 73K EUR",
    date: "June 2022",
    average_salary: 68,
    place: "Barcelona/Remote",
  },
  {
    id: 2100,
    title: "QA Automation Engineer",
    company: "Mindcurv",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2101,
    title: "Engineering Manager (f/m/d) SaaS",
    company: "Workpath",
    salary: "100K - 100K EUR",
    date: "June 2022",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 2102,
    title: "Full-Stack Engineer (f/m/d) Analytics",
    company: "Workpath",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2103,
    title: "Senior Data Engineer (f/m/d)",
    company: "Workpath",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2104,
    title: "Senior QA Automation Engineer (f/m/d) Cypress",
    company: "Workpath",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2105,
    title: "Senior Ruby on Rails Backend Engineer (f/m/d)",
    company: "Workpath",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2106,
    title: "Full-Stack Engineer",
    company: "StyleSage",
    salary: "55K - 55K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2107,
    title: "Software Engineer (Python or Ruby)",
    company: "Moodys",
    salary: "50K - 60K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2108,
    title: "Junior DevOps Engineer (U.S. Timezone)",
    company: "Moodys",
    salary: "30K - 35K EUR",
    date: "June 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2109,
    title: "Data Engineer",
    company: "Lingokids",
    salary: "45K - 60K EUR",
    date: "June 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2110,
    title: "Backend Developer",
    company: "GoTrendier",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2111,
    title: "Junior Business Intelligence Engineer",
    company: "The Hotels Network",
    salary: "24K - 30K EUR",
    date: "June 2022",
    average_salary: 27,
    place: "Barcelona/Remote",
  },
  {
    id: 2112,
    title: "Mid Frontend Engineer",
    company: "The Hotels Network",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2113,
    title: "DevOps Engineer",
    company: "BMAT",
    salary: "45K - 50K EUR",
    date: "June 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2114,
    title: "Golang Developer",
    company: "Reby Rides",
    salary: "35K - 55K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2115,
    title: "Java Backend Developer",
    company: "Portfolio",
    salary: "40K - 40K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2116,
    title: "Senior Backend Developer",
    company: "Innovamat",
    salary: "40K - 55K EUR",
    date: "June 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2117,
    title: "Software Engineer",
    company: "Andjaro",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2118,
    title: "Data Engineer",
    company: "Netquest",
    salary: "40K - 50K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2119,
    title: "Software Architect",
    company: "Enig Media",
    salary: "60K - 70K EUR",
    date: "June 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2120,
    title: "iOS Developer",
    company: "Netquest",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2121,
    title: "Front-end Developer",
    company: "Netquest",
    salary: "45K - 45K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2122,
    title: "Head of Data Engineering",
    company: "Heroes",
    salary: "90K - 90K EUR",
    date: "June 2022",
    average_salary: 90,
    place: "Barcelona",
  },
  {
    id: 2123,
    title: "Senior Android Developer",
    company: "Teamwire",
    salary: "55K - 65K EUR",
    date: "June 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2124,
    title: "Lead Backend (NodeJS)",
    company: "viaPhoton",
    salary: "78K - 85K EUR",
    date: "June 2022",
    average_salary: 81.5,
    place: "Barcelona",
  },
  {
    id: 2125,
    title: "Lead Frontend",
    company: "viaPhoton",
    salary: "78K - 85K EUR",
    date: "June 2022",
    average_salary: 81.5,
    place: "Barcelona",
  },
  {
    id: 2126,
    title: "Systems Engineer",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2127,
    title: "Senior Backend Engineer (Python)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "June 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2128,
    title: "Backend Engineer",
    company: "sennder",
    salary: "50K - 65K EUR",
    date: "June 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2129,
    title: "Junior Backend Engineer",
    company: "sennder",
    salary: "32K - 50K EUR",
    date: "June 2022",
    average_salary: 41,
    place: "Barcelona/Remote",
  },
  {
    id: 2130,
    title: "Java Team Lead",
    company: "Small World",
    salary: "55K - 65K EUR",
    date: "June 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2131,
    title: "Engineer Manager",
    company: "sennder",
    salary: "80K - 105K EUR",
    date: "June 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2132,
    title: "Mid Node Backend Developer",
    company: "Abi Global Health",
    salary: "45K - 45K EUR",
    date: "June 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2133,
    title: "Senior iOS Engineer",
    company: "Brainly",
    salary: "55K - 80K EUR",
    date: "June 2022",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 2134,
    title: "Senior Data Engineer",
    company: "Didomi",
    salary: "90K - 90K EUR",
    date: "June 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 2135,
    title: "Software Engineer (Backend/Go)",
    company: "AREX Markets",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2136,
    title: "Senior Backend Developer",
    company: "Intergiro",
    salary: "70K - 70K EUR",
    date: "June 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2137,
    title: "Mid Java Developer",
    company: "Copyright Clearance",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2138,
    title: "Backend Developer",
    company: "BeBanjo",
    salary: "45K - 60K EUR",
    date: "June 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2139,
    title: "DevSecOps",
    company: "DEVO",
    salary: "60K - 75K EUR",
    date: "June 2022",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 2140,
    title: "Team Lead (JS/TS)",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2141,
    title: "Junior Business Intelligence Engineer",
    company: "The Hotels Network",
    salary: "24K - 30K EUR",
    date: "June 2022",
    average_salary: 27,
    place: "Barcelona/Remote",
  },
  {
    id: 2142,
    title: "MID JS/TS Engineer",
    company: "The Hotels Network",
    salary: "35K - 45K EUR",
    date: "June 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2143,
    title: "Senior Software Engineer (PHP)",
    company: "The Hotels Network",
    salary: "50K - 65K EUR",
    date: "June 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2144,
    title: "JavaScript Software Developer",
    company: "Revieve",
    salary: "55K - 65K EUR",
    date: "June 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2145,
    title: "Senior Backend Developer (PHP/Symfony)",
    company: "Signa Sports United",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2146,
    title: "Senior Frontend Developer (React or Vue)",
    company: "Signa Sports United",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2147,
    title: "Senior Mobile Developer (Flutter)",
    company: "Signa Sports United",
    salary: "45K - 65K EUR",
    date: "June 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2148,
    title: "Data Engineer",
    company: "Mailtrack",
    salary: "40K - 55K EUR",
    date: "June 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2149,
    title: "Senior Backend Developer",
    company: "Mailtrack",
    salary: "40K - 55K EUR",
    date: "June 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2150,
    title: "Product Owner",
    company: "LambdaLoopers",
    salary: "32K - 50K EUR",
    date: "June 2022",
    average_salary: 41,
    place: "Remote",
  },
  {
    id: 2151,
    title: "Senior Python Engineer",
    company: "Back Market",
    salary: "60K - 90K EUR",
    date: "June 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2152,
    title: "Senior Full Stack Engineer",
    company: "Trialing Health",
    salary: "40K - 45K EUR",
    date: "June 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2153,
    title: "Senior Frontend Engineer (React) - Remote",
    company: "ForceManager",
    salary: "45K - 55K EUR",
    date: "June 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2154,
    title: "Fullstack Engineer",
    company: "Opticks Security",
    salary: "32K - 40K EUR",
    date: "June 2022",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 2155,
    title: "Engineering Manager IAM",
    company: "Auth0",
    salary: "70K - 90K EUR",
    date: "June 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2156,
    title: "Senior Product Designer",
    company: "Auth0",
    salary: "60K - 80K EUR",
    date: "June 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2157,
    title: "Software Engineer, Financial Protocols",
    company: "Auth0",
    salary: "45K - 60K EUR",
    date: "June 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2158,
    title: "Software Engineer (React/Node)",
    company: "Edpuzzle",
    salary: "43K - 58K EUR",
    date: "July 2022",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2159,
    title: "DevOps Engineer",
    company: "Edpuzzle",
    salary: "43K - 58K EUR",
    date: "July 2022",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2160,
    title: "QA Engineer",
    company: "Edpuzzle",
    salary: "36K - 48K EUR",
    date: "July 2022",
    average_salary: 42,
    place: "Barcelona/Remote",
  },
  {
    id: 2161,
    title: "Backend PHP/Symfony Developer (Partial Remote)",
    company: "Classlife",
    salary: "30K - 40K EUR",
    date: "July 2022",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 2162,
    title: "Backend PHP/Symfony Developer (Partial Remote)",
    company: "Classlife",
    salary: "30K - 40K EUR",
    date: "July 2022",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 2163,
    title: "Senior Backend Engineer C# .Net",
    company: "ForceManager",
    salary: "40K - 50K EUR",
    date: "July 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2164,
    title: "Team Lead",
    company: "StyleSage",
    salary: "55K - 60K EUR",
    date: "July 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 2165,
    title: "Sales Director",
    company: "Fluendo",
    salary: "60K - 70K EUR",
    date: "July 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2166,
    title: "Go Developer",
    company: "Reby",
    salary: "35K - 50K EUR",
    date: "July 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2167,
    title: "DevSecOps",
    company: "DEVO",
    salary: "60K - 75K EUR",
    date: "July 2022",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 2168,
    title: "Senior DevOps",
    company: "Uberall",
    salary: "75K - 95K EUR",
    date: "July 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2169,
    title: "Team Lead (Clients Team)",
    company: "The Hotels Network",
    salary: "45K - 55K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2170,
    title: "Node.js/React Full-Stack Developer",
    company: "MarsBased",
    salary: "45K - 55K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2171,
    title: "Node.js Backend Developer",
    company: "MarsBased",
    salary: "45K - 55K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2172,
    title: "Senior Backend Engineer (Python)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "July 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2173,
    title: "Senior Software Engineer (Typescript)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "July 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2174,
    title: "Junior Backend Engineer",
    company: "sennder",
    salary: "32K - 50K EUR",
    date: "July 2022",
    average_salary: 41,
    place: "Barcelona/Remote",
  },
  {
    id: 2175,
    title: "Engineer Manager",
    company: "sennder",
    salary: "80K - 105K EUR",
    date: "July 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2176,
    title: "Software Architect (React)",
    company: "Wipro",
    salary: "55K - 75K EUR",
    date: "July 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2177,
    title: "Senior Engineer Manager",
    company: "sennder",
    salary: "80K - 130K EUR",
    date: "July 2022",
    average_salary: 105,
    place: "Barcelona/Remote",
  },
  {
    id: 2178,
    title: "Software Test Developer",
    company: "Smart Technologies",
    salary: "40K - 50K EUR",
    date: "July 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2179,
    title: "Technical Artist",
    company: "GGames",
    salary: "30K - 40K EUR",
    date: "July 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 2180,
    title: "Android Engineer- Cybersecurity",
    company: "Qustodio",
    salary: "35K - 65K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2181,
    title: "iOS Developer",
    company: "Netquest",
    salary: "45K - 55K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2182,
    title: "Staff Frontend Engineer",
    company: "Back Market",
    salary: "70K - 115K EUR",
    date: "July 2022",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 2183,
    title: "Systems Engineer",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "July 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2184,
    title: "Angular Frontend Developer",
    company: "Essentialist",
    salary: "40K - 50K EUR",
    date: "July 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2185,
    title: "QA Engineer",
    company: "Wenea",
    salary: "40K - 55K EUR",
    date: "July 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2186,
    title: "PHP Tech Lead",
    company: "FishHotels",
    salary: "45K - 50K EUR",
    date: "July 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2187,
    title: "Java Developer",
    company: "Rohlik Group",
    salary: "40K - 75K EUR",
    date: "July 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2188,
    title: "Android Developer",
    company: "Rohlik Group",
    salary: "40K - 75K EUR",
    date: "July 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2189,
    title: "SRE/Cloud Engineer",
    company: "Kantox",
    salary: "40K - 60K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2190,
    title: "Senior Backend Developer API Specialist",
    company: "MIMACOM",
    salary: "45K - 50K EUR",
    date: "July 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2191,
    title: "Data Engineer",
    company: "Emailing Network",
    salary: "25K - 40K EUR",
    date: "July 2022",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2192,
    title: "Senior Cloud Security Engineer",
    company: "Okta/Auth0",
    salary: "60K - 80K EUR",
    date: "July 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2193,
    title: "Node Backend Developer",
    company: "Revel",
    salary: "38K - 48K EUR",
    date: "July 2022",
    average_salary: 43,
    place: "Remote",
  },
  {
    id: 2194,
    title: "Full Stack Developer",
    company: "Escenitech",
    salary: "30K - 42K EUR",
    date: "July 2022",
    average_salary: 36,
    place: "Remote",
  },
  {
    id: 2195,
    title: "Front End UI Developer",
    company: "Browser London",
    salary: "30K - 50K GBP",
    date: "July 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2196,
    title: "Senior Backend Engineer (Python)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "July 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2197,
    title: "Senior Backend Engineer (Typescript)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "July 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2198,
    title: "Engineer Manager",
    company: "sennder",
    salary: "80K - 105K EUR",
    date: "July 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2199,
    title: "RoR or/and Python Developer",
    company: "Netquest",
    salary: "55K - 62K EUR",
    date: "July 2022",
    average_salary: 58.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2200,
    title: "Data Analyst",
    company: "Deporvillage",
    salary: "40K - 55K EUR",
    date: "July 2022",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 2201,
    title: "Frontend Developer",
    company: "Derivco",
    salary: "47K - 67K EUR",
    date: "July 2022",
    average_salary: 57,
    place: "Barcelona",
  },
  {
    id: 2202,
    title: "SRE/DevOps",
    company: "Zinkee",
    salary: "35K - 40K EUR",
    date: "July 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 2203,
    title: "Systems Engineer",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "July 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2204,
    title: "DevOps Engineer",
    company: "Netquest",
    salary: "60K - 60K EUR",
    date: "July 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2205,
    title: "Senior Backend Developer",
    company: "Kahoot!",
    salary: "50K - 70K EUR",
    date: "July 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2206,
    title: "Senior Frontend Web Developer",
    company: "Kahoot!",
    salary: "50K - 70K EUR",
    date: "July 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2207,
    title: "Software Engineer",
    company: "Seedtag",
    salary: "60K - 75K EUR",
    date: "July 2022",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 2208,
    title: "Backend Engineer (Python)",
    company: "Edgetier",
    salary: "40K - 50K EUR",
    date: "July 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2209,
    title: "Senior Backend Engineed (Python)",
    company: "Edgetier",
    salary: "50K - 65K EUR",
    date: "July 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2210,
    title: "Drupal Backend Developer",
    company: "Ymbra",
    salary: "30K - 35K EUR",
    date: "July 2022",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 2211,
    title: "Senior Front End Engineer - User Experience",
    company: "EVPassport",
    salary: "45K - 60K EUR",
    date: "July 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2212,
    title: "Front-end React developer",
    company: "GeekSquare",
    salary: "35K - 50K EUR",
    date: "July 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2213,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "July 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2214,
    title: "Senior Python Developer",
    company: "Netquest",
    salary: "45K - 62K EUR",
    date: "July 2022",
    average_salary: 53.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2215,
    title: "Junior DevOps Engineer",
    company: "Wiris",
    salary: "26K - 35K EUR",
    date: "July 2022",
    average_salary: 30.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2216,
    title: "Backend Engineer (Golang, microservices)",
    company: "Brainly",
    salary: "50K - 70K EUR",
    date: "July 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2217,
    title: "Senior Data Engineer",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "July 2022",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2218,
    title: "Computer Vision Tech Lead",
    company: "Brainly",
    salary: "90K - 130K EUR",
    date: "July 2022",
    average_salary: 110,
    place: "Barcelona/Remote",
  },
  {
    id: 2219,
    title: "Delivery Manager, AI/Visual Search",
    company: "Brainly",
    salary: "50K - 80K EUR",
    date: "July 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2220,
    title: "Senior PHP Developer (Symfony)",
    company: "Jagaad",
    salary: "45K - 70K EUR",
    date: "July 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 2221,
    title: "Flutter developer",
    company: "MediaMarkt Tech",
    salary: "40K - 65K EUR",
    date: "July 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2222,
    title: "Software Engineer",
    company: "StreamSets.com",
    salary: "40K - 80K EUR",
    date: "July 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2223,
    title: "Technical Support Engineer",
    company: "StreamSets.com",
    salary: "30K - 65K EUR",
    date: "July 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2224,
    title: "Senior Software Engineer",
    company: "Sysdig",
    salary: "52K - 65K EUR",
    date: "July 2022",
    average_salary: 58.5,
    place: "Remote",
  },
  {
    id: 2225,
    title: "Systems Software Engineer",
    company: "Sysdig",
    salary: "52K - 65K EUR",
    date: "July 2022",
    average_salary: 58.5,
    place: "Remote",
  },
  {
    id: 2226,
    title: "Senior Infrastructure Engineer",
    company: "Sysdig",
    salary: "52K - 65K EUR",
    date: "July 2022",
    average_salary: 58.5,
    place: "Remote",
  },
  {
    id: 2227,
    title: "Threat Research Engineer",
    company: "Sysdig",
    salary: "52K - 65K EUR",
    date: "July 2022",
    average_salary: 58.5,
    place: "Remote",
  },
  {
    id: 2228,
    title: "Senior Ruby on Rails Engineer",
    company: "Kantox",
    salary: "45K - 65K EUR",
    date: "July 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2229,
    title: "Senior Python Engineer",
    company: "Back Market",
    salary: "60K - 90K EUR",
    date: "July 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2230,
    title: "Principal Frontend Engineer",
    company: "Back Market",
    salary: "70K - 100K EUR",
    date: "July 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 2231,
    title: "Engineering Manager",
    company: "Back Market",
    salary: "70K - 100K EUR",
    date: "July 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 2232,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "GetApp (Gartner)",
    salary: "65K - 80K EUR",
    date: "July 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2233,
    title: "Senior Full-stack Engineer",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "July 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2234,
    title: "Data Engineer",
    company: "Reloadly.com",
    salary: "45K - 60K EUR",
    date: "July 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2235,
    title: "Java Developer",
    company: "DruID",
    salary: "37K - 50K EUR",
    date: "July 2022",
    average_salary: 43.5,
    place: "Remote",
  },
  {
    id: 2236,
    title: "Software Engineer-Tools & Automation (Go required)",
    company: "Sysdig",
    salary: "52K - 70K EUR",
    date: "July 2022",
    average_salary: 61,
    place: "Remote",
  },
  {
    id: 2237,
    title: "Senior Full-Stack Engineer",
    company: "Oliva Health",
    salary: "70K - 80K EUR",
    date: "July 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2238,
    title: "Java Developer",
    company: "NPAW",
    salary: "45K - 55K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2239,
    title: "Ruby On Rails Developer",
    company: "FCM Lab",
    salary: "40K - 60K EUR",
    date: "July 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2240,
    title: "Product Owner",
    company: "FCM Lab",
    salary: "35K - 47K EUR",
    date: "July 2022",
    average_salary: 41,
    place: "Barcelona/Remote",
  },
  {
    id: 2241,
    title: "Senior Software Engineer",
    company: "PayFit",
    salary: "58K - 79K EUR",
    date: "August 2022",
    average_salary: 68.5,
    place: "Remote",
  },
  {
    id: 2242,
    title: "Senior Software Engineer (BE/Python/AWS)",
    company: "WeGetFinancing",
    salary: "60K - 70K EUR",
    date: "August 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2243,
    title: "Java Backend Developer",
    company: "Zinkee",
    salary: "35K - 40K EUR",
    date: "August 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 2244,
    title: "DevOps Engineer",
    company: "Tuio",
    salary: "40K - 58K EUR",
    date: "August 2022",
    average_salary: 49,
    place: "Remote",
  },
  {
    id: 2245,
    title: "Mid Frontend Developer",
    company: "iContainers",
    salary: "35K - 45K EUR",
    date: "August 2022",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2246,
    title: "Full stack web developer",
    company: "MediaMarktSaturn",
    salary: "40K - 65K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2247,
    title: "Senior Backend Engineer (Python)",
    company: "QuestDB",
    salary: "70K - 100K EUR",
    date: "August 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2248,
    title: "Senior Cloud Engineer",
    company: "QuestDB",
    salary: "70K - 100K EUR",
    date: "August 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2249,
    title: "Senior Android Developer",
    company: "pEp Security",
    salary: "50K - 55K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2250,
    title: "Senior Software Engineer",
    company: "CoverWallet",
    salary: "43K - 60K EUR",
    date: "August 2022",
    average_salary: 51.5,
    place: "Remote",
  },
  {
    id: 2251,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "August 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2252,
    title: "Full-stack developer React, Typescript & Node",
    company: "Plug&Chain",
    salary: "40K - 50K EUR",
    date: "August 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2253,
    title: "Backend Developer PHP/Symfony",
    company: "Classlife",
    salary: "30K - 35K EUR",
    date: "August 2022",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 2254,
    title: "Senior Backend Engineer",
    company: "MediaMarktSaturn",
    salary: "40K - 65K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2255,
    title: "Senior fullstack engineer",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "August 2022",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 2256,
    title: "Engineering Manager",
    company: "sennder",
    salary: "80K - 105K EUR",
    date: "August 2022",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2257,
    title: "Elixir Software Engineer",
    company: "Kantox",
    salary: "50K - 85K EUR",
    date: "August 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2258,
    title: "Senior JS Software Engineer",
    company: "Alaya by Benevity",
    salary: "55K - 60K EUR",
    date: "August 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2259,
    title: "Data Enginners (Backend too)",
    company: "Worklytics (YC W18)",
    salary: "80K - 140K EUR",
    date: "August 2022",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 2260,
    title: "Junior Backend Engineer",
    company: "IZI Record",
    salary: "25K - 35K EUR",
    date: "August 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2261,
    title: "Senior Backend Engineer",
    company: "IZI Record",
    salary: "43K - 53K EUR",
    date: "August 2022",
    average_salary: 48,
    place: "Barcelona/Remote",
  },
  {
    id: 2262,
    title: "Python Developer",
    company: "Essentialist",
    salary: "40K - 50K EUR",
    date: "August 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2263,
    title: "S.R.E. / Devops Engineer",
    company: "FCM Travel",
    salary: "60K - 60K EUR",
    date: "August 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2264,
    title: "R+D Engineer",
    company: "Fluendo",
    salary: "45K - 60K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2265,
    title: "Senior Backend Engineer (Python)",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "August 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2266,
    title: "Senior Backend Engineer (Typescript)",
    company: "sennder",
    salary: "65K - 80K EUR",
    date: "August 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2267,
    title: "Senior Data Engineer, Finance&Revenue",
    company: "Brainly",
    salary: "40K - 70K EUR",
    date: "August 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2268,
    title: "Senior Python Developer",
    company: "Close",
    salary: "60K - 150K USD",
    date: "August 2022",
    average_salary: 105,
    place: "Remote",
  },
  {
    id: 2269,
    title: "Engineergin Manager (Python)",
    company: "Close",
    salary: "80K - 200K USD",
    date: "August 2022",
    average_salary: 140,
    place: "Remote",
  },
  {
    id: 2270,
    title: "Sr Frontend Engineer",
    company: "Nextail",
    salary: "55K - 70K EUR",
    date: "August 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2271,
    title: "Principal Frontend Engineer",
    company: "Back Market",
    salary: "80K - 130K EUR",
    date: "August 2022",
    average_salary: 105,
    place: "Remote",
  },
  {
    id: 2272,
    title: "Cloud Engineer",
    company: "Nextail",
    salary: "45K - 60K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2273,
    title: "Senior Node.js",
    company: "Intive",
    salary: "45K - 60K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2274,
    title: "Senior Software Engineer",
    company: "Factorial HR",
    salary: "55K - 55K EUR",
    date: "August 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2275,
    title: "Staff Software Engineer",
    company: "Factorial HR",
    salary: "70K - 70K EUR",
    date: "August 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2276,
    title: "Senior Software Engineer",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "August 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2277,
    title: "Senior Software Engineer (Go)",
    company: "Form3",
    salary: "100K - 100K EUR",
    date: "August 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 2278,
    title: "Senior Platform Engineer",
    company: "Form3",
    salary: "100K - 100K EUR",
    date: "August 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 2279,
    title: "Senior Software Engineer (Kubernetes)",
    company: "Form3",
    salary: "100K - 100K EUR",
    date: "August 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 2280,
    title: "Backend Tech Lead",
    company: "Stuart",
    salary: "72K - 92K EUR",
    date: "August 2022",
    average_salary: 82,
    place: "Barcelona/Remote",
  },
  {
    id: 2281,
    title: "Data Engineer",
    company: "Finletic",
    salary: "45K - 65K EUR",
    date: "August 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2282,
    title: "Senior Full Stack Developer",
    company: "Well Gesundheit AG",
    salary: "50K - 68K EUR",
    date: "August 2022",
    average_salary: 59,
    place: "Barcelona",
  },
  {
    id: 2283,
    title: "Staff Full Stack Engineer",
    company: "Aetion",
    salary: "90K - 110K EUR",
    date: "August 2022",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 2284,
    title: "Staff Site Reliability Engineers",
    company: "Aetion",
    salary: "90K - 110K EUR",
    date: "August 2022",
    average_salary: 100,
    place: "Barcelona",
  },
  {
    id: 2285,
    title: "Backend Engineer",
    company: "Tangelo Games",
    salary: "45K - 50K EUR",
    date: "August 2022",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 2286,
    title: "Java Developer",
    company: "DruID",
    salary: "37K - 48K EUR",
    date: "August 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 2287,
    title: "Frontend Engineer",
    company: "Ohpen",
    salary: "50K - 65K EUR",
    date: "August 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2288,
    title: "Software Engineer",
    company: "Ohpen",
    salary: "50K - 65K EUR",
    date: "August 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2289,
    title: "Head of Development",
    company: "Zazou Group",
    salary: "51K - 71K EUR",
    date: "August 2022",
    average_salary: 61,
    place: "Barcelona",
  },
  {
    id: 2290,
    title: "Backend Software Engineer",
    company: "Yego",
    salary: "30K - 50K EUR",
    date: "August 2022",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2291,
    title: "Senior Frontend Engineer",
    company: "Jeff",
    salary: "45K - 65K EUR",
    date: "August 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2292,
    title: "Senior Fullstack Engineer",
    company: "haddock",
    salary: "55K - 70K EUR",
    date: "August 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2293,
    title: "Full Stack NodeJS / React Developer",
    company: "Volcanic Internet",
    salary: "35K - 55K EUR",
    date: "August 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2294,
    title: "Elixir Software Engineer",
    company: "Kantox",
    salary: "50K - 85K EUR",
    date: "August 2022",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 2295,
    title: "Senior Lead Engineer (Back-end/Full-stack)",
    company: "Brainly",
    salary: "80K - 100K EUR",
    date: "August 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 2296,
    title: "QA Engineer",
    company: "Edpuzzle",
    salary: "36K - 48K EUR",
    date: "August 2022",
    average_salary: 42,
    place: "Barcelona/Remote",
  },
  {
    id: 2297,
    title: "Software Engineer (React/Node)",
    company: "Edpuzzle",
    salary: "43K - 58K EUR",
    date: "August 2022",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2298,
    title: "DevOps Engineer",
    company: "Edpuzzle",
    salary: "39K - 58K EUR",
    date: "August 2022",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2299,
    title: "Senior Engineer, Web Platform",
    company: "Pulse.tv",
    salary: "60K - 84K USD",
    date: "August 2022",
    average_salary: 72,
    place: "Remote",
  },
  {
    id: 2300,
    title: "Engineering Manager",
    company: "Packlink",
    salary: "68K - 80K EUR",
    date: "August 2022",
    average_salary: 74,
    place: "Remote",
  },
  {
    id: 2301,
    title: "Site Reliability Engineer",
    company: "Seqera",
    salary: "45K - 60K EUR",
    date: "August 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2302,
    title: "Product Manager",
    company: "Jeff",
    salary: "40K - 60K EUR",
    date: "August 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2303,
    title: "SRE / DevOps",
    company: "ClovrLabs",
    salary: "45K - 75K EUR",
    date: "August 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2304,
    title: "Senior iOS Developer",
    company: "Monkey Taps",
    salary: "60K - 80K EUR",
    date: "August 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2305,
    title: "Senior Java Back-end Engineer",
    company: "The Agile Monkeys",
    salary: "50K - 65K EUR",
    date: "August 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 2306,
    title: "Senior Backend",
    company: "Signa Sports United",
    salary: "45K - 65K EUR",
    date: "August 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2307,
    title: "Senior Fullstack Engineer",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "August 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2308,
    title: "Mid Fullstack Engineer",
    company: "haddock app",
    salary: "30K - 38K EUR",
    date: "August 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2309,
    title: "Senior Software Engineer",
    company: "PayFit",
    salary: "60K - 80K EUR",
    date: "August 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2310,
    title: "Principal Software Engineer",
    company: "Invisible",
    salary: "120K - 120K USD",
    date: "August 2022",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 2311,
    title: "Automation Consultant",
    company: "Invisible",
    salary: "81K - 110K USD",
    date: "August 2022",
    average_salary: 95.5,
    place: "Remote",
  },
  {
    id: 2312,
    title: "Sr Android Engineer",
    company: "Chartboost (TakeTwo)",
    salary: "60K - 90K EUR",
    date: "August 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2313,
    title: "Cloud Software Engineer",
    company: "Genie",
    salary: "60K - 85K EUR",
    date: "August 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2314,
    title: "Lead/Senior Back-end Engineer (Go)",
    company: "Odin",
    salary: "70K - 100K EUR",
    date: "August 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2315,
    title: "Senior Backend Engineer",
    company: "eterlast",
    salary: "40K - 60K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2316,
    title: "Senior Machine Learning Engineer",
    company: "Veriff",
    salary: "72K - 92K EUR",
    date: "September 2022",
    average_salary: 82,
    place: "Barcelona/Remote",
  },
  {
    id: 2317,
    title: "Senior Frontend Engineer",
    company: "Veriff",
    salary: "38K - 78K EUR",
    date: "September 2022",
    average_salary: 58,
    place: "Barcelona/Remote",
  },
  {
    id: 2318,
    title: "Android Engineer",
    company: "Qustodio",
    salary: "35K - 65K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2319,
    title: "Senior Python Engineer",
    company: "Veriff",
    salary: "52K - 84K EUR",
    date: "September 2022",
    average_salary: 68,
    place: "Barcelona/Remote",
  },
  {
    id: 2320,
    title: "Backend Developer (PHP)",
    company: "GoTrendier",
    salary: "42K - 55K EUR",
    date: "September 2022",
    average_salary: 48.5,
    place: "Remote",
  },
  {
    id: 2321,
    title: "Sr Backend PHP/Drupal Developer",
    company: "XpertAI",
    salary: "36K - 50K EUR",
    date: "September 2022",
    average_salary: 43,
    place: "Remote",
  },
  {
    id: 2322,
    title: "Backend Software Engineer",
    company: "SEAT:CODE",
    salary: "40K - 70K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2323,
    title: "Backend Tech Lead",
    company: "Actimo - Kahoot!",
    salary: "60K - 80K EUR",
    date: "September 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2324,
    title: "Lead software engineer",
    company: "Sane",
    salary: "60K - 90K EUR",
    date: "September 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2325,
    title: "Backend Engineering Manager (Python)",
    company: "Close",
    salary: "90K - 200K USD",
    date: "September 2022",
    average_salary: 145,
    place: "Remote",
  },
  {
    id: 2326,
    title: "Software Engineer",
    company: "LibLab",
    salary: "70K - 100K EUR",
    date: "September 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2327,
    title: "Senior Backend Engineer (Go)",
    company: "Spectrm",
    salary: "75K - 100K EUR",
    date: "September 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 2328,
    title: "React Native developer",
    company: "Sanno",
    salary: "40K - 70K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2329,
    title: "Senior Software Engineer",
    company: "Virtual Lab",
    salary: "60K - 80K USD",
    date: "September 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2330,
    title: "Senior Node Backend",
    company: "Yuvod",
    salary: "55K - 65K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2331,
    title: "Backend Engineer",
    company: "Everyday Speech",
    salary: "60K - 60K USD",
    date: "September 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2332,
    title: "Mid Backend Engineer (PHP)",
    company: "Filmin",
    salary: "35K - 40K EUR",
    date: "September 2022",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2333,
    title: "Senior Backend Engineer (PHP)",
    company: "Filmin",
    salary: "40K - 45K EUR",
    date: "September 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2334,
    title: "QA Automation Engineer",
    company: "InnoIT",
    salary: "60K - 60K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 2335,
    title: "Senior Fullstack Engineer - JS (React + Node)",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "September 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2336,
    title: "Mid Fullstack Engineer - JS (React + Node)",
    company: "haddock app",
    salary: "30K - 38K EUR",
    date: "September 2022",
    average_salary: 34,
    place: "Remote",
  },
  {
    id: 2337,
    title: "Engineer Manager",
    company: "Playtomic",
    salary: "65K - 65K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2338,
    title: "Senior Software Engineer",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "September 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2339,
    title: "Senior Backend Engineer",
    company: "Fever",
    salary: "50K - 70K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2340,
    title: "Engineering Manager",
    company: "Fever",
    salary: "70K - 90K EUR",
    date: "September 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2341,
    title: "Tech Lead",
    company: "Fever",
    salary: "60K - 80K EUR",
    date: "September 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2342,
    title: "Senior Frontend Engineer",
    company: "Fever",
    salary: "50K - 70K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2343,
    title: "Agile Coach",
    company: "wallapop",
    salary: "70K - 80K EUR",
    date: "September 2022",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 2344,
    title: "Senior Backend Engineer",
    company: "wallapop",
    salary: "50K - 77K EUR",
    date: "September 2022",
    average_salary: 63.5,
    place: "Barcelona",
  },
  {
    id: 2345,
    title: "Senior PHP Engineer",
    company: "Bumble :bee:",
    salary: "60K - 90K EUR",
    date: "September 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2346,
    title: "Software Engineer (Cloud Platforms)",
    company: "Elastic",
    salary: "65K - 85K EUR",
    date: "September 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2347,
    title: "JavaScript Developer",
    company: "NPAW",
    salary: "35K - 40K EUR",
    date: "September 2022",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2348,
    title: "Flutter senior developer",
    company: "Fewlaps",
    salary: "50K - 70K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2349,
    title: "Senior Python Engineer",
    company: "Back Market",
    salary: "60K - 90K EUR",
    date: "September 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2350,
    title: "Backend Python Developer",
    company: "Genie",
    salary: "40K - 65K USD",
    date: "September 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2351,
    title: "Senior Platform Engineer",
    company: "Tymit",
    salary: "65K - 80K EUR",
    date: "September 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2352,
    title: "Senior Backend Software Engineer (Ruby on Rails)",
    company: "GetApp (Gartner)",
    salary: "65K - 80K EUR",
    date: "September 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2353,
    title: "Engineering Lead (Golang/React)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "September 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2354,
    title: "Senior Full Stack Software Engineer (Golang/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2355,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "September 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2356,
    title: "Staff Software Engineer (Frontend React)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "September 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2357,
    title: "Engineering Lead (Elixir/GraphQL)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "September 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2358,
    title: "Senior Software Engineer (PHP/Golang)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2359,
    title: ".NET Software Engineer II",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2360,
    title: "Senior Front-end Engineer",
    company: "Brainly",
    salary: "50K - 75K EUR",
    date: "September 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2361,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2362,
    title: "Desarrollador experto en NodeJS / TS",
    company: "Helvix Gmbh",
    salary: "60K - 100K EUR",
    date: "September 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2363,
    title: "Fullstack Angular Developer",
    company: "Halo Media",
    salary: "95K - 95K EUR",
    date: "September 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 2364,
    title: "Frontend Developer (React)",
    company: "Wuolah",
    salary: "40K - 50K EUR",
    date: "September 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2365,
    title: "Product Security Engineer",
    company: "Auth0",
    salary: "60K - 60K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2366,
    title: "Senior Product Security Engineer",
    company: "Auth0",
    salary: "80K - 80K EUR",
    date: "September 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2367,
    title: "Frontend Developer",
    company: "Swipoo",
    salary: "28K - 32K EUR",
    date: "September 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2368,
    title: "Software Engineer Manager",
    company: "Syntax",
    salary: "80K - 100K EUR",
    date: "September 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 2369,
    title: "Senior Software Engineer (Python)",
    company: "WeGetFinancing",
    salary: "65K - 85K EUR",
    date: "September 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2370,
    title: "Senior Backend Engineer, CMS (to learn Go)",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "September 2022",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2371,
    title: "Senior Backend Engineer, Match&Search",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "September 2022",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2372,
    title: "Senior Fullstack Engineer",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "September 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2373,
    title: "Mid Fullstack Engineer",
    company: "haddock app",
    salary: "30K - 38K EUR",
    date: "September 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2374,
    title: "DBA",
    company: "TMB",
    salary: "50K - 50K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2375,
    title: "IT Product Manager",
    company: "TMB",
    salary: "50K - 50K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2376,
    title: "JS Engineer",
    company: "The Hotels Network",
    salary: "35K - 45K EUR",
    date: "September 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2377,
    title: "Backend Python Developer (Mid/Senior)",
    company: "Genie",
    salary: "40K - 60K USD",
    date: "September 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2378,
    title: "Senior Frontend Engineer (React)",
    company: "Nomo",
    salary: "45K - 55K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2379,
    title: "Android Engineer (React Native), B2B contract",
    company: "Playbook",
    salary: "70K - 105K USD",
    date: "September 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 2380,
    title: "Senior Frontend Engineer",
    company: "Landbot",
    salary: "50K - 75K EUR",
    date: "September 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2381,
    title: "BackEnd Software Engineer PHP",
    company: "QAMYNO",
    salary: "35K - 50K EUR",
    date: "September 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2382,
    title: "DevOps Engineer",
    company: "Dragonfly",
    salary: "35K - 45K EUR",
    date: "September 2022",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2383,
    title: "Junior QA Engineer",
    company: "NPAW",
    salary: "24K - 27K EUR",
    date: "September 2022",
    average_salary: 25.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2384,
    title: "Java Backend Developer",
    company: "NPAW",
    salary: "45K - 60K EUR",
    date: "September 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2385,
    title: "AI, CV, ML Engineer (4d a week, stock options)",
    company: "Silt",
    salary: "30K - 30K EUR",
    date: "September 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2386,
    title: "Frontend Engineer (4d a week, stock options)",
    company: "Silt",
    salary: "30K - 30K EUR",
    date: "September 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2387,
    title: "Product (4d a week, stock options)",
    company: "Silt",
    salary: "30K - 30K EUR",
    date: "September 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2388,
    title: "Mid/Senior Frontend Developer",
    company: "Woffu",
    salary: "40K - 45K EUR",
    date: "September 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2389,
    title: "Senior Software Engineer, Developer Experience",
    company: "Auth0",
    salary: "80K - 80K EUR",
    date: "September 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2390,
    title: "Auth0 Senior Site Reliability Engineer",
    company: "Auth0",
    salary: "80K - 80K EUR",
    date: "September 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2391,
    title: "Backend engineer (PHP)",
    company: "Filmin",
    salary: "40K - 45K EUR",
    date: "September 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2392,
    title: "Senior Front End Developer",
    company: "Last.app",
    salary: "60K - 80K EUR",
    date: "September 2022",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2393,
    title: "Mid/Senior Data Engineeer",
    company: "Last.app",
    salary: "40K - 60K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2394,
    title: "Senior Python Developer",
    company: "Close",
    salary: "80K - 150K USD",
    date: "September 2022",
    average_salary: 115,
    place: "Remote",
  },
  {
    id: 2395,
    title: "Senior Backend Developer",
    company: "Kahoot!",
    salary: "50K - 70K EUR",
    date: "September 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2396,
    title: "Senior Software Engineer (C++ & Cloud required)",
    company: "Sysdig",
    salary: "50K - 75K EUR",
    date: "September 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2397,
    title: "Windows Automation Engineer (Senior)",
    company: "Sysdig",
    salary: "45K - 65K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2398,
    title: "Threat Research Engineer",
    company: "Sysdig",
    salary: "40K - 65K EUR",
    date: "September 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2399,
    title: "Senior Android Engineer",
    company: "Jeff",
    salary: "45K - 65K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2400,
    title: "Senior iOS Engineer",
    company: "Jeff",
    salary: "45K - 65K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2401,
    title: "Mid / Senior data engineer",
    company: "Tangelo Games",
    salary: "40K - 60K EUR",
    date: "September 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2402,
    title: "Senior Fullstack Engineer",
    company: "humanITcare",
    salary: "40K - 55K EUR",
    date: "September 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2403,
    title: "Senior Cloud Engineer (AI/ML Ops)",
    company: "Mitek Systems",
    salary: "60K - 70K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2404,
    title: "Applications Operations Manager",
    company: "Mitek Systems",
    salary: "50K - 60K EUR",
    date: "September 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2405,
    title: "DevOps Engineer",
    company: "Porsche Digital",
    salary: "50K - 80K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2406,
    title: "Frontend Engineer",
    company: "Porsche Digital",
    salary: "50K - 80K EUR",
    date: "September 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2407,
    title: "Mid Fullstack Engineer (React + Node.js)",
    company: "haddock",
    salary: "30K - 38K EUR",
    date: "September 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2408,
    title: "Software Engineer in Test (SDET)",
    company: "Sensor Tower",
    salary: "65K - 90K EUR",
    date: "September 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 2409,
    title: "Software Engineer (JavaScript)",
    company: "Devex",
    salary: "34K - 57K EUR",
    date: "October 2022",
    average_salary: 45.5,
    place: "Barcelona",
  },
  {
    id: 2410,
    title: "Senior Security Engineer",
    company: "Docplanner",
    salary: "50K - 75K EUR",
    date: "October 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2411,
    title: "SysOps Engineer",
    company: "Docplanner",
    salary: "30K - 60K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2412,
    title: "Platform Security Engineer / DevSecOps Engineer",
    company: "Docplanner",
    salary: "45K - 85K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2413,
    title: "Senior PHP Developer",
    company: "marketgoo",
    salary: "50K - 60K EUR",
    date: "October 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2414,
    title: "Senior SRE",
    company: "Socialpoint",
    salary: "45K - 60K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2415,
    title: "Senior Backend Engineer (lead)",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "October 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2416,
    title: "Senior Backend Engineer (Typescript)",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "October 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2417,
    title: "Senior Platform Engineer (DevOps & SRE)",
    company: "Spectrm",
    salary: "75K - 100K EUR",
    date: "October 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 2418,
    title: "Sitecore Backend Engineer",
    company: "Porsche Digital",
    salary: "50K - 80K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2419,
    title: "Director of Engineering - Blockchain",
    company: "Moodys Analytics",
    salary: "80K - 140K EUR",
    date: "October 2022",
    average_salary: 110,
    place: "Barcelona/Remote",
  },
  {
    id: 2420,
    title: "Quantum Computing Engineer",
    company: "Moodys Analytics",
    salary: "100K - 140K EUR",
    date: "October 2022",
    average_salary: 120,
    place: "Remote",
  },
  {
    id: 2421,
    title: "IT recruiter",
    company: "Verbio",
    salary: "35K - 35K EUR",
    date: "October 2022",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 2422,
    title: "Senior Back End Engineer (Remote)",
    company: "Sensor Tower Inc.",
    salary: "70K - 110K EUR",
    date: "October 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 2423,
    title: "Staff Software Engineer (Frontend React)",
    company: "Capterra (Gartner)",
    salary: "70K - 85K EUR",
    date: "October 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2424,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Capterra (Gartner)",
    salary: "70K - 85K EUR",
    date: "October 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2425,
    title: "Senior Full Stack Software Engineer (Golang/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2426,
    title: "Senior Frontend Software Engineer (React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2427,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner",
    salary: "60K - 70K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2428,
    title: "Lead Backend Engineer",
    company: "Capterra (Gartner)",
    salary: "70K - 85K EUR",
    date: "October 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2429,
    title: ".NET Software Engineer II",
    company: "Gartner",
    salary: "50K - 60K EUR",
    date: "October 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2430,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "70K - 80K EUR",
    date: "October 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2431,
    title: "Software Quality Assurance Engineer",
    company: "Edpuzzle",
    salary: "33K - 39K EUR",
    date: "October 2022",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 2432,
    title: "Software Engineer (React/Node)",
    company: "Edpuzzle",
    salary: "33K - 39K EUR",
    date: "October 2022",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 2433,
    title: "DevOps Engineer",
    company: "Edpuzzle",
    salary: "39K - 58K EUR",
    date: "October 2022",
    average_salary: 48.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2434,
    title: "Senior Full Stack Engineer",
    company: "Lumo (YC S22)",
    salary: "45K - 65K EUR",
    date: "October 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2435,
    title: "Senior Software Engineer",
    company: "Zinio",
    salary: "40K - 50K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2436,
    title: "Senior BackEnd Engineer (100% remote)",
    company: "Sensor Tower Inc. LA",
    salary: "80K - 120K EUR",
    date: "October 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 2437,
    title: "Mid front end developer",
    company: "SH Barcelona",
    salary: "28K - 35K EUR",
    date: "October 2022",
    average_salary: 31.5,
    place: "Barcelona",
  },
  {
    id: 2438,
    title: "Java Software Engineer",
    company: "Enreach",
    salary: "34K - 44K EUR",
    date: "October 2022",
    average_salary: 39,
    place: "Barcelona/Remote",
  },
  {
    id: 2439,
    title: "Software Engineer (Vue/.Net)",
    company: "ZeroD",
    salary: "40K - 45K EUR",
    date: "October 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2440,
    title: "Backend Engineer",
    company: "Roadfans",
    salary: "60K - 100K EUR",
    date: "October 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2441,
    title: "Affiliation and Facebook Lead Manager",
    company: "Emailing network",
    salary: "60K - 60K EUR",
    date: "October 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2442,
    title: "Senior Software Engineer",
    company: "StreamSets.com",
    salary: "45K - 75K EUR",
    date: "October 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2443,
    title: "Software Engineer",
    company: "StreamSets.com",
    salary: "30K - 75K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2444,
    title: "Senior Product Manager",
    company: "StreamSets.com",
    salary: "40K - 75K EUR",
    date: "October 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2445,
    title: "Senior Software Automation Engineer",
    company: "Sysdig",
    salary: "50K - 70K EUR",
    date: "October 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2446,
    title: "Senior Back Java-PHP",
    company: "Basebone(videogames)",
    salary: "40K - 45K EUR",
    date: "October 2022",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 2447,
    title: "Senior Software Engineer (Node.js)",
    company: "Mavenoid.com",
    salary: "85K - 115K EUR",
    date: "October 2022",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 2448,
    title: "Senior Full Stack Engineer",
    company: "Amenitiz",
    salary: "65K - 75K EUR",
    date: "October 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2449,
    title: "Lead Backend Engineer (to learn Golang)",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "October 2022",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2450,
    title: "Senior Backend Engineer, Match&Search(to learn Go)",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "October 2022",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2451,
    title: "Mid Fullstack Engineer",
    company: "haddock app",
    salary: "30K - 38K EUR",
    date: "October 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2452,
    title: "Software Engineer- Kubernetes",
    company: "New Relic",
    salary: "52K - 70K EUR",
    date: "October 2022",
    average_salary: 61,
    place: "Barcelona/Remote",
  },
  {
    id: 2453,
    title: "Senior Android Engineer",
    company: "Dynatrace",
    salary: "50K - 75K EUR",
    date: "October 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2454,
    title: "Senior GoLang Engineer",
    company: "Unimedia Technology",
    salary: "45K - 60K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2455,
    title: "QA Engineer",
    company: "Erika Lust",
    salary: "30K - 38K EUR",
    date: "October 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2456,
    title: "Senior Full Stack",
    company: "Lumo (YC S22)",
    salary: "45K - 65K EUR",
    date: "October 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2457,
    title: "Senior Data Engineer",
    company: "PepsiCo",
    salary: "65K - 75K EUR",
    date: "October 2022",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2458,
    title: "Lead Data Engineer",
    company: "PepsiCo",
    salary: "65K - 75K EUR",
    date: "October 2022",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2459,
    title: "Engineering Lead (Golang/React)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "October 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2460,
    title: "Senior Software Engineer (PHP/Golang)",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "October 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2461,
    title: "Node.js/React Full-stack Developer",
    company: "MarsBased",
    salary: "45K - 55K EUR",
    date: "October 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2462,
    title: "Node.js Developer",
    company: "MarsBased",
    salary: "45K - 55K EUR",
    date: "October 2022",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2463,
    title: "Desarrollador Full Stack, NodeJS y React",
    company: "BestPrice",
    salary: "60K - 60K EUR",
    date: "October 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2464,
    title: "Mid / Senior JS/TS Enginerr",
    company: "The Hotels Network",
    salary: "40K - 65K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2465,
    title: "Frontend Engineer",
    company: "The Hotels Network",
    salary: "35K - 65K EUR",
    date: "October 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2466,
    title: "Senior Frontend Engineer",
    company: "Intent HQ",
    salary: "55K - 80K EUR",
    date: "October 2022",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2467,
    title: "Head of Engineering (potential CTO - new start-up)",
    company: "FABBRIC",
    salary: "40K - 50K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2468,
    title: "Senior Ruby Engineer (EU Remote)",
    company: "Sensor Tower",
    salary: "70K - 120K EUR",
    date: "October 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 2469,
    title: "Software QA Engineer",
    company: "Zscaler",
    salary: "40K - 50K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2470,
    title: "Lead Vulnerability Researcher",
    company: "Sysdig",
    salary: "60K - 80K EUR",
    date: "October 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2471,
    title: "Senior Manager of Product Marketing",
    company: "Sysdig",
    salary: "60K - 80K EUR",
    date: "October 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2472,
    title: "Senior Software Automation Engineer",
    company: "Sysdig",
    salary: "50K - 70K EUR",
    date: "October 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2473,
    title: "Engineer Manager (JS)",
    company: "Helvix Gmbh",
    salary: "85K - 95K EUR",
    date: "October 2022",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 2474,
    title: "Systems Engineer",
    company: "Rakuten TV",
    salary: "45K - 60K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2475,
    title: "Java developer",
    company: "Inno-IT",
    salary: "45K - 60K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2476,
    title: ".Net Developer",
    company: "Inno-IT",
    salary: "50K - 60K EUR",
    date: "October 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2477,
    title: "Senior Backend Developer",
    company: "Kahoot!",
    salary: "55K - 70K EUR",
    date: "October 2022",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2478,
    title: "Backend Engineer (PHP)",
    company: "Filmin",
    salary: "40K - 45K EUR",
    date: "October 2022",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2479,
    title: "Product Designer",
    company: "Aikido",
    salary: "70K - 84K EUR",
    date: "October 2022",
    average_salary: 77,
    place: "Barcelona/Remote",
  },
  {
    id: 2480,
    title: "Senior Full Stack Engineer",
    company: "Fingerprint",
    salary: "85K - 100K EUR",
    date: "October 2022",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 2481,
    title: "Mid Fullstack Engineer",
    company: "haddock app",
    salary: "30K - 38K EUR",
    date: "October 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2482,
    title: "DevOps Engineer",
    company: "OneClick",
    salary: "42K - 50K EUR",
    date: "October 2022",
    average_salary: 46,
    place: "Remote",
  },
  {
    id: 2483,
    title: "Mobile developer - React Native",
    company: "OneClick",
    salary: "34K - 40K EUR",
    date: "October 2022",
    average_salary: 37,
    place: "Remote",
  },
  {
    id: 2484,
    title: "Senior Backend Engineer",
    company: "Fingerprint",
    salary: "85K - 100K EUR",
    date: "October 2022",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 2485,
    title: "Senior Developer Advocate",
    company: "Fingerprint",
    salary: "80K - 95K EUR",
    date: "October 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 2486,
    title: "Senior Java Developer and Junior Team Leader",
    company: "Netquest",
    salary: "40K - 50K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2487,
    title: "Lead Full Stack Software Engineer (Javascript)",
    company: "Gartner",
    salary: "70K - 82K EUR",
    date: "October 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2488,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Gartner (Capterra)",
    salary: "70K - 82K EUR",
    date: "October 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2489,
    title: "Staff Software Engineer (Frontend React)",
    company: "Gartner (Capterra)",
    salary: "70K - 82K EUR",
    date: "October 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2490,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner (Capterra)",
    salary: "60K - 70K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2491,
    title: "Senior Frontend Software Engineer (React)",
    company: "Gartner (GetApp)",
    salary: "60K - 70K EUR",
    date: "October 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2492,
    title: "Engineering Lead (Backend)",
    company: "Gartner",
    salary: "70K - 82K EUR",
    date: "October 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2493,
    title: ".NET Software Engineer II",
    company: "Gartner",
    salary: "50K - 65K EUR",
    date: "October 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2494,
    title: "Python Developer",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2495,
    title: "Senior Software Engineer",
    company: "Crisis Text Line",
    salary: "96K - 120K EUR",
    date: "October 2022",
    average_salary: 108,
    place: "Barcelona/Remote",
  },
  {
    id: 2496,
    title: "Senior Software Engineer",
    company: "Virtual Lab",
    salary: "60K - 80K USD",
    date: "October 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2497,
    title: "Sr/Lead Backend Engineer (Delivery team)",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "October 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2498,
    title: "QA Engineer",
    company: "NPAW",
    salary: "25K - 32K EUR",
    date: "October 2022",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2499,
    title: "Senior Php Engineer",
    company: "Apiumhub",
    salary: "50K - 65K EUR",
    date: "October 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2500,
    title: "Senior Backend Engineer (Go & k8s control plane)",
    company: "Nearby Computing",
    salary: "50K - 65K EUR",
    date: "October 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2501,
    title: "Senior DevOps",
    company: "Aizon",
    salary: "40K - 65K EUR",
    date: "October 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2502,
    title: "Cyber Security Architect",
    company: "Clariant",
    salary: "50K - 65K EUR",
    date: "October 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2503,
    title: "Python Backend Developer",
    company: "Bamboo Energy",
    salary: "28K - 32K EUR",
    date: "October 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2504,
    title: "Senior Software Engineer",
    company: "Form3",
    salary: "68K - 100K EUR",
    date: "October 2022",
    average_salary: 84,
    place: "Remote",
  },
  {
    id: 2505,
    title: "Junior Software Developer",
    company: "Factorial HR",
    salary: "34K - 37K EUR",
    date: "October 2022",
    average_salary: 35.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2506,
    title: "Mid Software Developer",
    company: "Factorial HR",
    salary: "38K - 48K EUR",
    date: "October 2022",
    average_salary: 43,
    place: "Barcelona/Remote",
  },
  {
    id: 2507,
    title: "Software Engineer (Frontend Expertise)",
    company: "Landbot",
    salary: "40K - 50K EUR",
    date: "October 2022",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2508,
    title: "Senior Software Engineer (Backend Expertise)",
    company: "Landbot",
    salary: "55K - 70K EUR",
    date: "October 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2509,
    title: "Associate Software Engineer",
    company: "Landbot",
    salary: "25K - 35K EUR",
    date: "October 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2510,
    title: "Sofware Engineer (Backend Expertise)",
    company: "Landbot",
    salary: "40K - 55K EUR",
    date: "October 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2511,
    title: "Senior Product Designer",
    company: "Splash (Splashthat)",
    salary: "60K - 80K EUR",
    date: "October 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2512,
    title: "Senior Frontend Developer",
    company: "Red Points",
    salary: "40K - 55K EUR",
    date: "October 2022",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 2513,
    title: "Senior .Net FullStack Developer",
    company: "Apiumhub",
    salary: "50K - 65K EUR",
    date: "October 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 2514,
    title: "Data Engineer",
    company: "Chartboost(Take-two)",
    salary: "50K - 60K EUR",
    date: "October 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2515,
    title: "Senior Data Engineer",
    company: "Chartboost(Take-two)",
    salary: "65K - 85K EUR",
    date: "October 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2516,
    title: "Ruby on Rails developer",
    company: "Foodetective",
    salary: "25K - 30K EUR",
    date: "November 2022",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 2517,
    title: "Java Software Engineer",
    company: "Enreach",
    salary: "38K - 44K EUR",
    date: "November 2022",
    average_salary: 41,
    place: "Barcelona/Remote",
  },
  {
    id: 2518,
    title: "Ruby on Rails",
    company: "Enerfip",
    salary: "30K - 30K EUR",
    date: "November 2022",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 2519,
    title: "Senior Backend Developer @",
    company: "@seQura",
    salary: "55K - 75K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2520,
    title: "Product Manager",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2521,
    title: "Mobile Tech Lead/Developer FOUNDER",
    company: "Laurel Gaming",
    salary: "1K - 1K EUR",
    date: "November 2022",
    average_salary: 1,
    place: "Barcelona/Remote",
  },
  {
    id: 2522,
    title: "Senior JAVA BackEnd Engineer",
    company: "Reloadly",
    salary: "50K - 55K EUR",
    date: "November 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2523,
    title: "Lead Full Stack Software Engineer (Javascript)",
    company: "Gartner",
    salary: "70K - 82K EUR",
    date: "November 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2524,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Gartner (Capterra)",
    salary: "60K - 72K EUR",
    date: "November 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2525,
    title: "Senior Frontend Software Engineer (React)",
    company: "Gartner (GetApp)",
    salary: "60K - 72K EUR",
    date: "November 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2526,
    title: "Engineering Lead (Backend)",
    company: "Gartner (Capterra)",
    salary: "70K - 82K EUR",
    date: "November 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2527,
    title: ".NET Software Engineer II",
    company: "Gartner",
    salary: "55K - 65K EUR",
    date: "November 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2528,
    title: "Senior Software Engineer (Chance to learn Elixir)",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "November 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2529,
    title: "Staff Software Engineer (Full Stack Node/React)",
    company: "Gartner (Capterra)",
    salary: "70K - 82K EUR",
    date: "November 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2530,
    title: "Staff Software Engineer (Frontend React)",
    company: "Gartner (Capterra)",
    salary: "70K - 82K EUR",
    date: "November 2022",
    average_salary: 76,
    place: "Barcelona/Remote",
  },
  {
    id: 2531,
    title: "Software Engineer",
    company: "Bankflip",
    salary: "40K - 50K EUR",
    date: "November 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2532,
    title: "Software Engineer (Node.JS)",
    company: "Viaplay",
    salary: "40K - 60K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2533,
    title: "Sr Software Engineer (Python)",
    company: "Chartboost (TakeTwo)",
    salary: "60K - 85K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2534,
    title: "Senior Backend Web Developer",
    company: "Genie",
    salary: "60K - 85K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2535,
    title: "iOS mid developer",
    company: "GoTrendier",
    salary: "40K - 48K EUR",
    date: "November 2022",
    average_salary: 44,
    place: "Barcelona/Remote",
  },
  {
    id: 2536,
    title: "Sr Software Engineer",
    company: "Boats Group",
    salary: "60K - 70K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2537,
    title: "Front end Tech Lead",
    company: "Wallapop",
    salary: "70K - 92K EUR",
    date: "November 2022",
    average_salary: 81,
    place: "Barcelona/Remote",
  },
  {
    id: 2538,
    title: "Junior Crwaler Analyst",
    company: "Red Points",
    salary: "22K - 30K EUR",
    date: "November 2022",
    average_salary: 26,
    place: "Barcelona",
  },
  {
    id: 2539,
    title: "Android Tech Lead",
    company: "Wallapop",
    salary: "70K - 100K EUR",
    date: "November 2022",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 2540,
    title: "Senior Software Engineer",
    company: "StreamSets.com",
    salary: "50K - 80K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2541,
    title: "Systems Engineer",
    company: "StreamSets.com",
    salary: "30K - 70K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2542,
    title: "Product Manager",
    company: "StreamSets.com",
    salary: "50K - 80K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2543,
    title: "PHP Backend Developer",
    company: "Housfy",
    salary: "30K - 40K EUR",
    date: "November 2022",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 2544,
    title: "DevOps",
    company: "Wallapop",
    salary: "35K - 75K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2545,
    title: "Senior Backend Engineer",
    company: "Wallapop",
    salary: "45K - 77K EUR",
    date: "November 2022",
    average_salary: 61,
    place: "Barcelona/Remote",
  },
  {
    id: 2546,
    title: "Principal Engineer, Web",
    company: "Xcelirate",
    salary: "70K - 100K EUR",
    date: "November 2022",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 2547,
    title: "React developer",
    company: "Abzu",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2548,
    title: "Project/Product Manager",
    company: "Justifay SCCL",
    salary: "60K - 60K EUR",
    date: "November 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2549,
    title: "Senior Backend Web Developer (Python + Julia)",
    company: "Genie",
    salary: "60K - 80K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2550,
    title: "(Senior) DevOps Engineer",
    company: "New Work SE",
    salary: "40K - 60K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2551,
    title: "Software Engineer (Python), Data Team",
    company: "Kognia Sports",
    salary: "40K - 55K EUR",
    date: "November 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2552,
    title: "Frontend Developer React MID",
    company: "ARATECH",
    salary: "33K - 37K EUR",
    date: "November 2022",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 2553,
    title: "Developer Advocate",
    company: "Fingerprint",
    salary: "65K - 85K USD",
    date: "November 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2554,
    title: "Senior Technical Product Manager",
    company: "Fingerprint",
    salary: "80K - 110K USD",
    date: "November 2022",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 2555,
    title: "Senior/Lead Software Engineer (Backend)",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2556,
    title: "Mid Fullstack Engineer",
    company: "haddock",
    salary: "30K - 38K EUR",
    date: "November 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2557,
    title: "Mid Cloud Developer",
    company: "Viaplay",
    salary: "35K - 50K EUR",
    date: "November 2022",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2558,
    title: "Senior React Developer",
    company: "Yuvod",
    salary: "55K - 65K EUR",
    date: "November 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2559,
    title: "Senior Software Engineer",
    company: "humanITcare",
    salary: "45K - 50K EUR",
    date: "November 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2560,
    title: "Software Engineer",
    company: "Gartner",
    salary: "45K - 60K EUR",
    date: "November 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2561,
    title: "Senior Software Engineer",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "November 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2562,
    title: "Lead Software Engineer (React Native)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "November 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2563,
    title: "Principal Software Engineer",
    company: "Gartner",
    salary: "80K - 92K EUR",
    date: "November 2022",
    average_salary: 86,
    place: "Barcelona/Remote",
  },
  {
    id: 2564,
    title: "Java Architect",
    company: "IntaPeople",
    salary: "80K - 130K EUR",
    date: "November 2022",
    average_salary: 105,
    place: "Barcelona",
  },
  {
    id: 2565,
    title: "Senior Java Engineer",
    company: "Dynatrace",
    salary: "50K - 75K EUR",
    date: "November 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2566,
    title: "Senior Cloud Infrastructure Engineer",
    company: "Fingerprint",
    salary: "95K - 115K USD",
    date: "November 2022",
    average_salary: 105,
    place: "Remote",
  },
  {
    id: 2567,
    title: "Backend Lead",
    company: "Alfred Smart Systems",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2568,
    title: "Principal Engineer, Web, PHP, Python",
    company: "Xcelirate",
    salary: "85K - 110K EUR",
    date: "November 2022",
    average_salary: 97.5,
    place: "Barcelona",
  },
  {
    id: 2569,
    title: ".NET developer",
    company: "Grupo SPEC",
    salary: "30K - 35K EUR",
    date: "November 2022",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 2570,
    title: "Gameplay Developer Unreal Engine",
    company: "SynergyGames",
    salary: "45K - 75K EUR",
    date: "November 2022",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2571,
    title: "Backend Developer (Node + AWS)",
    company: "Ikigai Talent Group",
    salary: "50K - 80K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2572,
    title: "Java Developer",
    company: "Ikigai Talent Group",
    salary: "55K - 55K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2573,
    title: "Android Developer",
    company: "Ikigai Talent Group",
    salary: "60K - 60K EUR",
    date: "November 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2574,
    title: "Data Engineer",
    company: "Ikigai Talent Group",
    salary: "65K - 65K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2575,
    title: "C++ Software Engineer",
    company: "Fleksy",
    salary: "70K - 70K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2576,
    title: "Devops Engineer",
    company: "TheyDo",
    salary: "75K - 100K EUR",
    date: "November 2022",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 2577,
    title: "Senior Back-end Developer (Node)",
    company: "Outvio",
    salary: "55K - 90K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2578,
    title: "Senior Front-end Developer (React)",
    company: "Outvio",
    salary: "55K - 90K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2579,
    title: "Java/Go/Rust Software Eng.for Security Tooling",
    company: "Eclipse Foundation",
    salary: "60K - 90K EUR",
    date: "November 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2580,
    title: "SecOps Engineer",
    company: "Eclipse Foundation",
    salary: "60K - 80K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2581,
    title: "Open Source Software Engineer",
    company: "Eclipse Foundation",
    salary: "60K - 80K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2582,
    title: "Software Engineer",
    company: "Electromaps",
    salary: "35K - 55K EUR",
    date: "November 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2583,
    title: "Senior iOS Engineer",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "November 2022",
    average_salary: 68.5,
    place: "Remote",
  },
  {
    id: 2584,
    title: "UX/UI Designer",
    company: "EdgeTier",
    salary: "40K - 60K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2585,
    title: "Observability Lead",
    company: "Veriff",
    salary: "70K - 95K EUR",
    date: "November 2022",
    average_salary: 82.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2586,
    title: "Senior Machine Learning Engineer",
    company: "Veriff",
    salary: "65K - 100K EUR",
    date: "November 2022",
    average_salary: 82.5,
    place: "Barcelona",
  },
  {
    id: 2587,
    title: "Senior Back-End Engineer (Node+TS)",
    company: "Veriff",
    salary: "60K - 80K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2588,
    title: "Front-End Engineer",
    company: "Veriff",
    salary: "60K - 80K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2589,
    title: "Senior Data Scientist",
    company: "Veriff",
    salary: "75K - 105K EUR",
    date: "November 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 2590,
    title: "Sr Software Engineer",
    company: "Boats Group",
    salary: "60K - 90K EUR",
    date: "November 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2591,
    title: "Sr. Data Enginere",
    company: "Boats Group",
    salary: "70K - 100K EUR",
    date: "November 2022",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 2592,
    title: "QA Junior Engineer",
    company: "GoTrendier",
    salary: "23K - 30K EUR",
    date: "November 2022",
    average_salary: 26.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2593,
    title: "iOS developer",
    company: "GoTrendier",
    salary: "40K - 48K EUR",
    date: "November 2022",
    average_salary: 44,
    place: "Remote",
  },
  {
    id: 2594,
    title: "Mid Fullstack Engineer",
    company: "haddock",
    salary: "30K - 38K EUR",
    date: "November 2022",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2595,
    title: "DevOps Engineer",
    company: "Porsche Digital",
    salary: "50K - 80K EUR",
    date: "November 2022",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2596,
    title: "Senior Software Engineer (Backend Expertise)",
    company: "Landbot",
    salary: "55K - 70K EUR",
    date: "November 2022",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2597,
    title: "Software Engineer (Frontend Expertise)",
    company: "Landbot",
    salary: "40K - 53K EUR",
    date: "November 2022",
    average_salary: 46.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2598,
    title: "Senior QA Engineer",
    company: "Immfly",
    salary: "40K - 60K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2599,
    title: "Infraestructure Developer",
    company: "IMMFLY",
    salary: "40K - 50K EUR",
    date: "November 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2600,
    title: "Infraestructuras - Security Operations Engineer",
    company: "IMMFLY",
    salary: "40K - 55K EUR",
    date: "November 2022",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2601,
    title: "Product Owner",
    company: "Bridge for Billions",
    salary: "25K - 30K EUR",
    date: "November 2022",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 2602,
    title: "Senior Software Engineer",
    company: "Crisis Text Line",
    salary: "96K - 120K EUR",
    date: "November 2022",
    average_salary: 108,
    place: "Barcelona/Remote",
  },
  {
    id: 2603,
    title: "Staff DevOps Engineer",
    company: "Crisis Text Line",
    salary: "96K - 120K EUR",
    date: "November 2022",
    average_salary: 108,
    place: "Remote",
  },
  {
    id: 2604,
    title: "Software Engineer",
    company: "GitBook",
    salary: "80K - 105K EUR",
    date: "November 2022",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 2605,
    title: "Lead Java Developer",
    company: "TX Tomorrow Explored",
    salary: "80K - 96K EUR",
    date: "November 2022",
    average_salary: 88,
    place: "Remote",
  },
  {
    id: 2606,
    title: ".NET Developer",
    company: "Grupo SPEC",
    salary: "30K - 32K EUR",
    date: "November 2022",
    average_salary: 31,
    place: "Barcelona",
  },
  {
    id: 2607,
    title: "Senior Software Engineer",
    company: "humanITcare",
    salary: "45K - 60K EUR",
    date: "November 2022",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2608,
    title: "Head of Frontend",
    company: "Latency",
    salary: "75K - 90K EUR",
    date: "November 2022",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 2609,
    title: "Infraestructure Developer - Rust/Go/Python",
    company: "IMMFLY",
    salary: "40K - 60K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2610,
    title: "Salesforce Developer",
    company: "Kantox",
    salary: "45K - 70K EUR",
    date: "November 2022",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2611,
    title: "Fullstack Developer (Node+React)",
    company: "InnoIT",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2612,
    title: "Java Developer",
    company: "InnoIT",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2613,
    title: "DevOps Engineer",
    company: "InnoIT",
    salary: "40K - 55K EUR",
    date: "November 2022",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2614,
    title: "React Native (Mobile Developer)",
    company: "Docplanner",
    salary: "40K - 60K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2615,
    title: "Test Automation Engineer",
    company: "Materialise",
    salary: "50K - 55K EUR",
    date: "November 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2616,
    title: "Software Engineer",
    company: "Qonto",
    salary: "60K - 90K EUR",
    date: "November 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2617,
    title: "SalesForce Developer",
    company: "Kantox",
    salary: "45K - 70K EUR",
    date: "November 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2618,
    title: "DevOps Engineer AWS",
    company: "Qamyno",
    salary: "45K - 55K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2619,
    title: "Senior Software Engineer",
    company: "Qonto",
    salary: "90K - 90K EUR",
    date: "November 2022",
    average_salary: 90,
    place: "Barcelona/Remote",
  },
  {
    id: 2620,
    title: "Senior Sotware Engineer",
    company: "Qonto",
    salary: "60K - 90K EUR",
    date: "November 2022",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2621,
    title: "Software Architect (Cloud)",
    company: "Materialise",
    salary: "70K - 75K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 2622,
    title: "Senior Software Engineer - Full Stack",
    company: "Happy Scribe",
    salary: "90K - 90K EUR",
    date: "November 2022",
    average_salary: 90,
    place: "Barcelona",
  },
  {
    id: 2623,
    title: "Senior Software Engineer - Backend",
    company: "Happy Scribe",
    salary: "90K - 90K EUR",
    date: "November 2022",
    average_salary: 90,
    place: "Barcelona",
  },
  {
    id: 2624,
    title: "Full-Stack Engineer React C#/.NET",
    company: "Materialise",
    salary: "52K - 58K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2625,
    title: "Node.js Developer",
    company: "TalentBait GmbH",
    salary: "45K - 70K EUR",
    date: "November 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2626,
    title: "React Developer",
    company: "TalentBait GmbH",
    salary: "45K - 70K EUR",
    date: "November 2022",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2627,
    title: "Senior Backend Developer",
    company: "marketgoo",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2628,
    title: "Frontend Dev",
    company: "Red Points",
    salary: "40K - 55K EUR",
    date: "November 2022",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 2629,
    title: "Senior Software Engineer",
    company: "humanITcare",
    salary: "45K - 65K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2630,
    title: "DevOps Engineer",
    company: "Tappx & Techsoulogy",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2631,
    title: "Engineering Manager (Backend)",
    company: "Qonto",
    salary: "85K - 85K EUR",
    date: "November 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2632,
    title: "Frontend Engineer",
    company: "Caravelo",
    salary: "45K - 60K EUR",
    date: "November 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2633,
    title: "Lead Data Engineer",
    company: "OTA Insight",
    salary: "60K - 80K EUR",
    date: "November 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2634,
    title: "Senior Back-end Developer",
    company: "Link",
    salary: "50K - 60K EUR",
    date: "November 2022",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2635,
    title: "Senior Software Engineer (Python)",
    company: "sennder",
    salary: "60K - 85K EUR",
    date: "November 2022",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2636,
    title: "Junior Deep Learnign Engineer",
    company: "Methinks",
    salary: "30K - 30K EUR",
    date: "November 2022",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2637,
    title: "Data Engineer",
    company: "Methinks",
    salary: "50K - 50K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2638,
    title: "Customer Success Engineer",
    company: "Methinks",
    salary: "50K - 50K EUR",
    date: "November 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2639,
    title: "Full Stack Engineer",
    company: "Methinks",
    salary: "60K - 60K EUR",
    date: "November 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2640,
    title: "Staff Quality Engineer",
    company: "Okta",
    salary: "80K - 90K EUR",
    date: "November 2022",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2641,
    title: "Sr. Software Engineer",
    company: "Boats Group",
    salary: "60K - 80K EUR",
    date: "December 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2642,
    title: "Senior Software Engineer",
    company: "Tempus Labs",
    salary: "60K - 75K EUR",
    date: "December 2022",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 2643,
    title: "DevOps Engineer",
    company: "PromoFarma/DocMorris",
    salary: "45K - 55K EUR",
    date: "December 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2644,
    title: "IT Security Officer",
    company: "PromoFarma/DocMorris",
    salary: "60K - 95K EUR",
    date: "December 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2645,
    title: "Cloud Native Developer",
    company: "Unikraft",
    salary: "60K - 80K EUR",
    date: "December 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2646,
    title: "Senior NetOPs/DevOps",
    company: "Sateliot",
    salary: "40K - 60K EUR",
    date: "December 2022",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2647,
    title: "Senior Backend Engineer (Node.js - AWS)",
    company: "Clibank",
    salary: "60K - 80K EUR",
    date: "December 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2648,
    title: "DevOps Engineer",
    company: "mlean",
    salary: "33K - 45K EUR",
    date: "December 2022",
    average_salary: 39,
    place: "Remote",
  },
  {
    id: 2649,
    title: "Frontend Developer",
    company: "mlean",
    salary: "27K - 40K EUR",
    date: "December 2022",
    average_salary: 33.5,
    place: "Remote",
  },
  {
    id: 2650,
    title: "iOS Developer",
    company: "mlean",
    salary: "27K - 40K EUR",
    date: "December 2022",
    average_salary: 33.5,
    place: "Remote",
  },
  {
    id: 2651,
    title: "Data Engineer - Python",
    company: "TALENTBAIT GmbH",
    salary: "50K - 70K EUR",
    date: "December 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2652,
    title: "Lead Full Stack Software Engineer (Javascript)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "December 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2653,
    title: "Staff Software Engineer (Frontend React)",
    company: "Capterra (Gartner)",
    salary: "70K - 85K EUR",
    date: "December 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2654,
    title: "Senior Frontend Developer (React)",
    company: "GetApp (Gartner)",
    salary: "60K - 72K EUR",
    date: "December 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2655,
    title: "Engineering Lead (Backend)",
    company: "Capterra (Gartner)",
    salary: "70K - 85K EUR",
    date: "December 2022",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2656,
    title: "Senior Full Stack Software Engineer (Node/React)",
    company: "Capterra (Gartner)",
    salary: "60K - 72K EUR",
    date: "December 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2657,
    title: "Senior .NET Software Engineer",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "December 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2658,
    title: "Senior Software Engineer",
    company: "Gartner",
    salary: "60K - 72K EUR",
    date: "December 2022",
    average_salary: 66,
    place: "Barcelona/Remote",
  },
  {
    id: 2659,
    title: ".NET Software Engineer II",
    company: "Gartner",
    salary: "55K - 65K EUR",
    date: "December 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2660,
    title: "QA Automation Engineer",
    company: "Launchmetrics",
    salary: "28K - 38K EUR",
    date: "December 2022",
    average_salary: 33,
    place: "Remote",
  },
  {
    id: 2661,
    title: "Platform Technical Lead",
    company: "Adhara",
    salary: "70K - 85K EUR",
    date: "December 2022",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 2662,
    title: "Senior PHP Developer",
    company: "Parkimeter",
    salary: "40K - 50K EUR",
    date: "December 2022",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2663,
    title: "Senior Software Engineer",
    company: "Latitude",
    salary: "60K - 60K EUR",
    date: "December 2022",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2664,
    title: "Senior DevOps Engineer",
    company: "receeve",
    salary: "60K - 70K EUR",
    date: "December 2022",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2665,
    title: "Senior Full stack Engineer",
    company: "www.receeve.com",
    salary: "70K - 70K EUR",
    date: "December 2022",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2666,
    title: "Tech Team Lead",
    company: "www.receeve.com",
    salary: "70K - 80K EUR",
    date: "December 2022",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2667,
    title: "Frontend Engineer",
    company: "sennder",
    salary: "45K - 60K EUR",
    date: "December 2022",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2668,
    title: "Senior Software Engineer",
    company: "Tempus Labs",
    salary: "60K - 75K EUR",
    date: "December 2022",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 2669,
    title: "Software Engineer, IAM Core Cryptography",
    company: "Okta",
    salary: "63K - 63K EUR",
    date: "December 2022",
    average_salary: 63,
    place: "Remote",
  },
  {
    id: 2670,
    title: "Backend Engineer (NodeJS)",
    company: "Theydo.io",
    salary: "70K - 90K EUR",
    date: "December 2022",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2671,
    title: "Applications Operations Engineer",
    company: "Mitek Systems",
    salary: "35K - 40K EUR",
    date: "December 2022",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 2672,
    title: "QA Software Engineer",
    company: "Kantox",
    salary: "40K - 65K EUR",
    date: "December 2022",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2673,
    title: "Senior Software Tech Lead",
    company: "humanITcare",
    salary: "50K - 65K EUR",
    date: "December 2022",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 2674,
    title: "Sr. Product Designer",
    company: "Aikido Security",
    salary: "70K - 84K EUR",
    date: "December 2022",
    average_salary: 77,
    place: "Barcelona/Remote",
  },
  {
    id: 2675,
    title: "Senior DevOps Enginer / SRE",
    company: "Clovr Labs",
    salary: "70K - 70K EUR",
    date: "December 2022",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2676,
    title: "QA Automation Engineer",
    company: "M47 Labs",
    salary: "35K - 55K EUR",
    date: "January 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 2677,
    title: "QA Software Tester",
    company: "M47 Labs",
    salary: "30K - 40K EUR",
    date: "January 2023",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 2678,
    title: "Lead Front End Engineer - final client",
    company: "The Valley Talent",
    salary: "70K - 80K EUR",
    date: "January 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2679,
    title: "Senior Software Engineer",
    company: "Tempus Labs",
    salary: "60K - 75K EUR",
    date: "January 2023",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 2680,
    title: "Lead Engineer",
    company: "Clovr Labs",
    salary: "60K - 80K EUR",
    date: "January 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2681,
    title: "C++ developer",
    company: "Tinybird",
    salary: "60K - 85K EUR",
    date: "January 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2682,
    title: "QA Software Engineer",
    company: "Kantox",
    salary: "40K - 65K EUR",
    date: "January 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2683,
    title: "Backend Engineer (Java)",
    company: "CoCircular",
    salary: "24K - 27K EUR",
    date: "January 2023",
    average_salary: 25.5,
    place: "Remote",
  },
  {
    id: 2684,
    title: "Cloud Engineer/Devops",
    company: "Luma (lumaev.com)",
    salary: "30K - 45K EUR",
    date: "January 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2685,
    title: "Senior Full-Stack Developer",
    company: "Homyspace",
    salary: "40K - 55K EUR",
    date: "January 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2686,
    title: "Mid Frontend Developer",
    company: "Wuolah",
    salary: "24K - 28K EUR",
    date: "January 2023",
    average_salary: 26,
    place: "Remote",
  },
  {
    id: 2687,
    title: "Technical Developer Advocate",
    company: "Fingerprint",
    salary: "65K - 85K EUR",
    date: "January 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2688,
    title: "Junior Software Engineer",
    company: "Okify",
    salary: "20K - 25K EUR",
    date: "January 2023",
    average_salary: 22.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2689,
    title: "Senior Software Engineer",
    company: "Tempus Labs",
    salary: "65K - 75K EUR",
    date: "January 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2690,
    title: "Technical Product Manager (Monitor)",
    company: "Sysdig",
    salary: "60K - 85K EUR",
    date: "January 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2691,
    title: "Senior Product Manager (Secure)",
    company: "Sysdig",
    salary: "60K - 85K EUR",
    date: "January 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2692,
    title: "Technical Marketing Manager (Product Mkt-Secure)",
    company: "Sysdig",
    salary: "60K - 80K EUR",
    date: "January 2023",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2693,
    title: "Senior Manager of Product Marketing (Secure)",
    company: "Sysdig",
    salary: "75K - 90K EUR",
    date: "January 2023",
    average_salary: 82.5,
    place: "Remote",
  },
  {
    id: 2694,
    title: "Security Content Engineer",
    company: "Sysdig",
    salary: "60K - 75K EUR",
    date: "January 2023",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 2695,
    title: "Backend Engineer (PHP)",
    company: "Filmin",
    salary: "40K - 45K EUR",
    date: "January 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2696,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "January 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2697,
    title: "Lead Full Stack Developer (React/PHP)",
    company: "GMW",
    salary: "60K - 70K EUR",
    date: "January 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2698,
    title: "Cybersecurity Architect",
    company: "Clariant",
    salary: "65K - 75K EUR",
    date: "January 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2699,
    title: "Clojure(Script) & Rust Developer for OSS project",
    company: "DepsCheck",
    salary: "30K - 30K EUR",
    date: "January 2023",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 2700,
    title: "Full Stack Engineer",
    company: "Knack",
    salary: "35K - 55K EUR",
    date: "January 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2701,
    title: "Front-end Engineer",
    company: "Knack",
    salary: "40K - 65K EUR",
    date: "January 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2702,
    title: "Back-end Engineer",
    company: "Knack",
    salary: "40K - 65K EUR",
    date: "January 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2703,
    title: "SysAdmin",
    company: "Housfy",
    salary: "50K - 60K EUR",
    date: "January 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2704,
    title: "Mid/Senior Software Engineer",
    company: "Latitude",
    salary: "40K - 60K EUR",
    date: "January 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2705,
    title: "Lead Back-End Engineer (PHP Symfony)",
    company: "GMW",
    salary: "60K - 70K EUR",
    date: "January 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2706,
    title: "Senior Java Engineer",
    company: "Dynatrace",
    salary: "55K - 80K EUR",
    date: "January 2023",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2707,
    title: "PHP Developer",
    company: "Parkimeter",
    salary: "45K - 50K EUR",
    date: "January 2023",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2708,
    title: "QA engineer",
    company: "GoTrendier",
    salary: "23K - 32K EUR",
    date: "January 2023",
    average_salary: 27.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2709,
    title: "Senior Software Developer (Java)",
    company: "Boats Group",
    salary: "60K - 70K EUR",
    date: "January 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2710,
    title: "Frontend Developer",
    company: "Red Points",
    salary: "40K - 50K EUR",
    date: "January 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 2711,
    title: "Mid Data Engineer",
    company: "GoTrendier",
    salary: "40K - 50K EUR",
    date: "January 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2712,
    title: "Senior Node Backend",
    company: "Yuvod",
    salary: "55K - 65K EUR",
    date: "January 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2713,
    title: "Senior Backend Developer",
    company: "Mailtrack",
    salary: "45K - 55K EUR",
    date: "January 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2714,
    title: "Senior Javascript Developer",
    company: "Mailtrack",
    salary: "45K - 55K EUR",
    date: "January 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2715,
    title: "Android Developer",
    company: "Clutch",
    salary: "45K - 55K EUR",
    date: "January 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2716,
    title: "AWS Devops Engineer",
    company: "GMW",
    salary: "40K - 60K EUR",
    date: "January 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2717,
    title: "Senior Software Developer (30 h/w)",
    company: "Enginy Digital",
    salary: "30K - 35K EUR",
    date: "January 2023",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 2718,
    title: "Senior Backend Developer (EdTech Company)",
    company: "Innovamat",
    salary: "42K - 48K EUR",
    date: "January 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2719,
    title: "Tech Lead HealthTech Company",
    company: "CMRAD",
    salary: "50K - 60K EUR",
    date: "January 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2720,
    title: "Frontend Engineer",
    company: "sennder",
    salary: "45K - 60K EUR",
    date: "January 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2721,
    title: "Android Engineer",
    company: "Wallapop",
    salary: "40K - 65K EUR",
    date: "January 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2722,
    title: "Quality Assistance Lead",
    company: "Wallapop",
    salary: "55K - 68K EUR",
    date: "January 2023",
    average_salary: 61.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2723,
    title: "Quality Assurance Engineer",
    company: "Wallapop",
    salary: "41K - 60K EUR",
    date: "January 2023",
    average_salary: 50.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2724,
    title: "Senior Back-End Developer",
    company: "https://outvio.com/",
    salary: "50K - 70K EUR",
    date: "January 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2725,
    title: "Senior Front-End Developer",
    company: "https://outvio.com/",
    salary: "50K - 70K EUR",
    date: "January 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2726,
    title: "Mid-Level Back-End Developer",
    company: "https://outvio.com/",
    salary: "50K - 70K EUR",
    date: "January 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2727,
    title: "Senior DevOps",
    company: "Seqera Labs",
    salary: "65K - 80K EUR",
    date: "January 2023",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2728,
    title: "Principal Engineer, Web",
    company: "Xcelirare",
    salary: "80K - 100K EUR",
    date: "January 2023",
    average_salary: 90,
    place: "Barcelona",
  },
  {
    id: 2729,
    title: "Professor Associat Xarxes (1 quadrimestre)",
    company: "UAB",
    salary: "10K - 10K EUR",
    date: "January 2023",
    average_salary: 10,
    place: "Barcelona",
  },
  {
    id: 2730,
    title: "QA Software Engineer",
    company: "Kantox",
    salary: "40K - 60K EUR",
    date: "January 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2731,
    title: "Senior Backend Engineer - Telematics/GPS team",
    company: "sennder",
    salary: "85K - 85K EUR",
    date: "January 2023",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 2732,
    title: "Lead Unity Developer",
    company: "Univrse",
    salary: "50K - 60K EUR",
    date: "January 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2733,
    title: "Senior Fullstack Software Engineer",
    company: "Tempus Labs",
    salary: "65K - 75K EUR",
    date: "January 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2734,
    title: "Senior Scala Developer",
    company: "Intellias",
    salary: "50K - 60K EUR",
    date: "January 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2735,
    title: "Senior Embedded C++ Engineer",
    company: "Intellias",
    salary: "35K - 45K EUR",
    date: "January 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2736,
    title: "Software Engineer Junior",
    company: "Inviertis",
    salary: "22K - 26K EUR",
    date: "January 2023",
    average_salary: 24,
    place: "Barcelona",
  },
  {
    id: 2737,
    title: "QA Engineer Junior",
    company: "Inviertis",
    salary: "22K - 26K EUR",
    date: "January 2023",
    average_salary: 24,
    place: "Barcelona",
  },
  {
    id: 2738,
    title: "Sr. Software Developer",
    company: "Boats Group",
    salary: "60K - 70K EUR",
    date: "January 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2739,
    title: "Lead Cloud Software Engineer/Architect",
    company: "Fingerprint",
    salary: "90K - 130K USD",
    date: "January 2023",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 2740,
    title: "Mid QA Engineer",
    company: "GoTrendier",
    salary: "25K - 35K EUR",
    date: "February 2023",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2741,
    title: "Senior Backend Developer (PHP)",
    company: "GoTrendier",
    salary: "45K - 60K EUR",
    date: "February 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2742,
    title: "Software Architect",
    company: "MENU Technologies",
    salary: "75K - 75K EUR",
    date: "February 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2743,
    title: "Lead Full Stack Developer (React/PHP Symfony)",
    company: "GMW",
    salary: "60K - 70K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2744,
    title: "Staff Software Engineer",
    company: "Crisis Text Line",
    salary: "116K - 165K EUR",
    date: "February 2023",
    average_salary: 140.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2745,
    title: "Frontend Engineer",
    company: "Xceed",
    salary: "40K - 55K EUR",
    date: "February 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2746,
    title: "Software Engineer",
    company: "NPAW",
    salary: "45K - 55K EUR",
    date: "February 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2747,
    title: "Mid Data Engineer",
    company: "GoTrendier",
    salary: "40K - 50K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2748,
    title: "Data Architect",
    company: "Gartner",
    salary: "85K - 90K EUR",
    date: "February 2023",
    average_salary: 87.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2749,
    title: "Lead Data Modeler",
    company: "Gartner",
    salary: "85K - 100K EUR",
    date: "February 2023",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2750,
    title: "Senior Software Engineer (React Native)",
    company: "Factorial",
    salary: "55K - 70K EUR",
    date: "February 2023",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2751,
    title: "Senior Machine Learning Engineer (MLOps)",
    company: "sennder",
    salary: "65K - 85K EUR",
    date: "February 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2752,
    title: "Backend Engineer",
    company: "sennder",
    salary: "45K - 60K EUR",
    date: "February 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2753,
    title: "Senior Software Engineer (GoLang, NextJS)",
    company: "Crisis Text Line",
    salary: "96K - 120K EUR",
    date: "February 2023",
    average_salary: 108,
    place: "Barcelona/Remote",
  },
  {
    id: 2754,
    title: "Senior Full Stack Developer",
    company: "Fermat",
    salary: "50K - 60K EUR",
    date: "February 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2755,
    title: "Tech Lead (PHP Laravel) Healthtech",
    company: "CMRAD",
    salary: "60K - 70K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2756,
    title: "Software Engineer (Elixir, PHP)",
    company: "Andjaro",
    salary: "40K - 50K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2757,
    title: "DevOps Engineer",
    company: "Andjaro",
    salary: "55K - 65K EUR",
    date: "February 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2758,
    title: "Senior Frontend Engineer",
    company: "MURAL",
    salary: "66K - 82K EUR",
    date: "February 2023",
    average_salary: 74,
    place: "Remote",
  },
  {
    id: 2759,
    title: "Senior Data Engineer",
    company: "Kantox",
    salary: "60K - 80K EUR",
    date: "February 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2760,
    title: "Senior QA Software Engineer",
    company: "Kantox",
    salary: "40K - 60K EUR",
    date: "February 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2761,
    title: "Sr. Software Developer",
    company: "Boats Group",
    salary: "60K - 70K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2762,
    title: "Senior SRE",
    company: "Workato",
    salary: "80K - 120K EUR",
    date: "February 2023",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 2763,
    title: "Senior Development Engineer",
    company: "Openbravo",
    salary: "45K - 50K EUR",
    date: "February 2023",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2764,
    title: "Senior Mobile engineer - Flutter",
    company: "MediaMarktSaturn",
    salary: "40K - 65K EUR",
    date: "February 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2765,
    title: "Senior Backend Python Engineer",
    company: "Moodys Analytics",
    salary: "52K - 65K EUR",
    date: "February 2023",
    average_salary: 58.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2766,
    title: "Senior Backend Software Engineer (Ruby)",
    company: "Moodys Analytics",
    salary: "52K - 65K EUR",
    date: "February 2023",
    average_salary: 58.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2767,
    title: "Senior Data Engineer",
    company: "Moodys Analytics",
    salary: "52K - 65K EUR",
    date: "February 2023",
    average_salary: 58.5,
    place: "Barcelona",
  },
  {
    id: 2768,
    title: "Fullstack developer junior",
    company: "MediaMarktSaturn",
    salary: "30K - 45K EUR",
    date: "February 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2769,
    title: "Senior Fullstack engineer",
    company: "MediaMarktSaturn",
    salary: "35K - 60K EUR",
    date: "February 2023",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2770,
    title: "Full stack developer",
    company: "Egruppa.com",
    salary: "40K - 60K EUR",
    date: "February 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2771,
    title: "Senior Software Engineer",
    company: "Tempus Labs",
    salary: "65K - 75K EUR",
    date: "February 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2772,
    title: "Ssr. Python Backend Developer",
    company: "Stock Agile",
    salary: "45K - 55K EUR",
    date: "February 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2773,
    title: "Founding Engineer (with shares in startup)",
    company: "Mindbly",
    salary: "35K - 40K EUR",
    date: "February 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2774,
    title: "Node Backend Developer",
    company: "Durcal",
    salary: "50K - 55K EUR",
    date: "February 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2775,
    title: "Sr Web Developer (PHP)",
    company: "EFL",
    salary: "36K - 39K EUR",
    date: "February 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2776,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2777,
    title: "Platform Engineer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2778,
    title: "Mid Software Engineer",
    company: "honei",
    salary: "30K - 45K EUR",
    date: "February 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2779,
    title: "Senior React Native & Frontend Developer",
    company: "Promofarma",
    salary: "35K - 55K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2780,
    title: "Drupal - PHP Developer",
    company: "Promofarma",
    salary: "35K - 55K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2781,
    title: "Junior SysAdmin",
    company: "Fluendo",
    salary: "40K - 45K EUR",
    date: "February 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2782,
    title: "Backend Developer",
    company: "Zaleos",
    salary: "45K - 45K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2783,
    title: "Senior Software Engineer - Cryptography",
    company: "Okta",
    salary: "80K - 80K EUR",
    date: "February 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2784,
    title: "Senior Software Engineer - Financial Grade IAM",
    company: "Okta",
    salary: "80K - 80K EUR",
    date: "February 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2785,
    title: "Senior Shopify Developer",
    company: "Netenders",
    salary: "40K - 50K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 2786,
    title: "Frontend Developer - React Native",
    company: "Orca Technologies AS",
    salary: "30K - 45K EUR",
    date: "February 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2787,
    title: "Backend Engineer (PHP)",
    company: "Filmin",
    salary: "40K - 45K EUR",
    date: "February 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2788,
    title: "Founding Full Stack Engineer (AI Startup)",
    company: "Wagmi Venture Studio",
    salary: "40K - 70K EUR",
    date: "February 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2789,
    title: "Senior DevOps Engineer",
    company: "Receeve",
    salary: "60K - 70K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2790,
    title: "Frontend React Developer",
    company: "GeekSquare",
    salary: "35K - 50K EUR",
    date: "February 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2791,
    title: "BackEnd Developer (Symfony)",
    company: "GMW",
    salary: "35K - 45K EUR",
    date: "February 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2792,
    title: "Data Engineer",
    company: "GoTrendier",
    salary: "40K - 50K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2793,
    title: "Senior PHP Backend Developer",
    company: "GoTrendier",
    salary: "46K - 60K EUR",
    date: "February 2023",
    average_salary: 53,
    place: "Barcelona/Remote",
  },
  {
    id: 2794,
    title: "QA Engineer",
    company: "GoTrendier",
    salary: "30K - 38K EUR",
    date: "February 2023",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 2795,
    title: "Director of Engineering (Platform)",
    company: "Wave Mobile Money",
    salary: "191K - 191K USD",
    date: "February 2023",
    average_salary: 191,
    place: "Remote",
  },
  {
    id: 2796,
    title: "Test Automation engineer",
    company: "Materialise",
    salary: "50K - 55K EUR",
    date: "February 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2797,
    title: "Frontend Developer",
    company: "Materialise",
    salary: "50K - 55K EUR",
    date: "February 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2798,
    title: "Senior .NET Developer",
    company: "Materialise",
    salary: "58K - 63K EUR",
    date: "February 2023",
    average_salary: 60.5,
    place: "Barcelona",
  },
  {
    id: 2799,
    title: "Senior DevOps Engineer",
    company: "Lang.ai",
    salary: "52K - 74K EUR",
    date: "February 2023",
    average_salary: 63,
    place: "Remote",
  },
  {
    id: 2800,
    title: "Senior Scala Engineer",
    company: "Intellias",
    salary: "50K - 60K EUR",
    date: "February 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2801,
    title: "Senior Software Engineer (Backend Expertise)",
    company: "Landbot",
    salary: "57K - 75K EUR",
    date: "February 2023",
    average_salary: 66,
    place: "Remote",
  },
  {
    id: 2802,
    title: "Senior Site Reliability Engineer",
    company: "Landbot",
    salary: "57K - 75K EUR",
    date: "February 2023",
    average_salary: 66,
    place: "Remote",
  },
  {
    id: 2803,
    title: "Senior Frontend Engineer ( React )",
    company: "Teamwire",
    salary: "60K - 80K EUR",
    date: "February 2023",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2804,
    title: "Technical Leader/Architect",
    company: "Commercegate",
    salary: "75K - 80K EUR",
    date: "February 2023",
    average_salary: 77.5,
    place: "Barcelona",
  },
  {
    id: 2805,
    title: "DevSecOps Manager",
    company: "RatedPower",
    salary: "85K - 85K EUR",
    date: "February 2023",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 2806,
    title: "Senior Python Developer",
    company: "Vinissimus",
    salary: "35K - 45K EUR",
    date: "February 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2807,
    title: "Backend Engineer - Workflows Team",
    company: "sennder",
    salary: "45K - 55K EUR",
    date: "February 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2808,
    title: "Senior Frontend Engineer - React",
    company: "Dynatrace",
    salary: "50K - 80K EUR",
    date: "February 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 2809,
    title: "Backend Developer (PHP Symfony)",
    company: "GMW",
    salary: "35K - 50K EUR",
    date: "February 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2810,
    title: "Senior Backend Software Engineer",
    company: "Qonto",
    salary: "60K - 90K EUR",
    date: "February 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2811,
    title: "Android Engineer (Kotlin)",
    company: "Qonto",
    salary: "40K - 60K EUR",
    date: "February 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2812,
    title: "Senior Ruby Bankend Engineer",
    company: "Qonto",
    salary: "60K - 90K EUR",
    date: "February 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2813,
    title: "Full Stack Developer (React/PHP Symfony)",
    company: "GMW",
    salary: "35K - 55K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 2814,
    title: "Senior Front End Engineer",
    company: "Qonto",
    salary: "60K - 90K EUR",
    date: "February 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2815,
    title: "Senior .NET Full Stack Developer",
    company: "Rubiko Tech",
    salary: "45K - 70K EUR",
    date: "February 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2816,
    title: "Backend Developer (PHP Laravel) HealthTech",
    company: "CMRAD",
    salary: "40K - 50K EUR",
    date: "February 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 2817,
    title: "QA Automation Engineer",
    company: "Promofarma/Docmorris",
    salary: "35K - 50K EUR",
    date: "February 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2818,
    title: "Senior Data Scientist (m/f/d)",
    company: "Promofarma/Docmorris",
    salary: "35K - 50K EUR",
    date: "February 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2819,
    title: "Senior React Native Engineer",
    company: "Factorial",
    salary: "51K - 60K EUR",
    date: "February 2023",
    average_salary: 55.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2820,
    title: "Full Stack Developer (PHP Laravel)",
    company: "cucunver",
    salary: "35K - 45K EUR",
    date: "February 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2821,
    title: "CLOUD Web System Administrator",
    company: "Teradisk",
    salary: "40K - 46K EUR",
    date: "February 2023",
    average_salary: 43,
    place: "Barcelona/Remote",
  },
  {
    id: 2822,
    title: "Software Engineer II - IAM",
    company: "Okta",
    salary: "60K - 60K EUR",
    date: "February 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 2823,
    title: "Senior Software Engineer - IAM Cryptography",
    company: "Okta",
    salary: "80K - 80K EUR",
    date: "February 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2824,
    title: "Senior Software Engineer - IAM",
    company: "Okta",
    salary: "80K - 80K EUR",
    date: "March 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2825,
    title: "PHP FullStack Developer",
    company: "Bingosoft YoBrands",
    salary: "35K - 55K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2826,
    title: "Node.js Developer",
    company: "Bingosoft YoBrands",
    salary: "35K - 55K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2827,
    title: "Staff Engineer",
    company: "Okta",
    salary: "95K - 95K EUR",
    date: "March 2023",
    average_salary: 95,
    place: "Remote",
  },
  {
    id: 2828,
    title: "Software Engineer",
    company: "Okta",
    salary: "42K - 63K EUR",
    date: "March 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2829,
    title: "Senior Observability Engineer",
    company: "Okta",
    salary: "55K - 83K EUR",
    date: "March 2023",
    average_salary: 69,
    place: "Remote",
  },
  {
    id: 2830,
    title: "Senior Devops Engineer",
    company: "Gartner",
    salary: "70K - 70K EUR",
    date: "March 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2831,
    title: "Full Stack Software Engineer (ReactJS, NodeJS)",
    company: "Gartner",
    salary: "70K - 85K EUR",
    date: "March 2023",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2832,
    title: "Senior/Principal QA Software Engineer",
    company: "Kantox",
    salary: "55K - 75K EUR",
    date: "March 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2833,
    title: ".NET Software Engineer",
    company: "Thunes",
    salary: "42K - 42K EUR",
    date: "March 2023",
    average_salary: 42,
    place: "Barcelona/Remote",
  },
  {
    id: 2834,
    title: "Software QA Engineer (Remote in Spain)",
    company: "Edpuzzle",
    salary: "33K - 39K EUR",
    date: "March 2023",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 2835,
    title: "Security Engineer (Remote in Spain)",
    company: "Edpuzzle",
    salary: "39K - 53K EUR",
    date: "March 2023",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 2836,
    title: "DevOps Engineer (Remote in Spain)",
    company: "Edpuzzle",
    salary: "39K - 53K EUR",
    date: "March 2023",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 2837,
    title: "Software Engineer - JavaScript (Remote in Spain)",
    company: "Edpuzzle",
    salary: "33K - 39K EUR",
    date: "March 2023",
    average_salary: 36,
    place: "Barcelona/Remote",
  },
  {
    id: 2838,
    title: "DevOps",
    company: "GMW",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2839,
    title: "Backend Developer (PHP Symfony)",
    company: "GMW",
    salary: "35K - 50K EUR",
    date: "March 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2840,
    title: "Engineering Team Lead (Orders & Payments)",
    company: "Parkos",
    salary: "75K - 95K EUR",
    date: "March 2023",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 2841,
    title: "Senior Java Engineer (Backend)",
    company: "Dow Jones",
    salary: "40K - 50K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2842,
    title: "Senior Software Engineer .NET",
    company: "Dow Jones",
    salary: "40K - 50K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2843,
    title: "Engineering Manager",
    company: "Dow Jones",
    salary: "57K - 70K EUR",
    date: "March 2023",
    average_salary: 63.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2844,
    title: "Senior Software Engineer (Microservices)",
    company: "Dow Jones",
    salary: "40K - 50K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2845,
    title: "Senior Software Engineer (Ruby)",
    company: "Moodys Analytics",
    salary: "60K - 70K EUR",
    date: "March 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2846,
    title: "Lead Software Engineer",
    company: "MongoDB",
    salary: "100K - 120K EUR",
    date: "March 2023",
    average_salary: 110,
    place: "Barcelona/Remote",
  },
  {
    id: 2847,
    title: "Senior Full Stack Engineer",
    company: "Fingerprint",
    salary: "80K - 120K USD",
    date: "March 2023",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 2848,
    title: "Software Engineer",
    company: "humanITcare",
    salary: "30K - 45K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 2849,
    title: "Security Content Engineer",
    company: "Sysdig",
    salary: "50K - 90K EUR",
    date: "March 2023",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2850,
    title: "Technical Product Manager - Monitor (Prometheus)",
    company: "Sysdig",
    salary: "60K - 90K EUR",
    date: "March 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2851,
    title: "Senior Product Manager - Secure",
    company: "Sysdig",
    salary: "60K - 90K EUR",
    date: "March 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 2852,
    title: "iOS developer",
    company: "GoTrendier",
    salary: "40K - 48K EUR",
    date: "March 2023",
    average_salary: 44,
    place: "Barcelona/Remote",
  },
  {
    id: 2853,
    title: "iOS Engineer",
    company: "Qustodio",
    salary: "45K - 70K EUR",
    date: "March 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2854,
    title: "Backend Developer Node.js",
    company: "Avantio",
    salary: "40K - 50K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2855,
    title: "Backend Developer C# .NET",
    company: "QWI",
    salary: "30K - 36K EUR",
    date: "March 2023",
    average_salary: 33,
    place: "Remote",
  },
  {
    id: 2856,
    title: "Full Stack Developer",
    company: "QWI",
    salary: "30K - 38K EUR",
    date: "March 2023",
    average_salary: 34,
    place: "Remote",
  },
  {
    id: 2857,
    title: "Backend Developer - Java",
    company: "Cleverpy",
    salary: "25K - 40K EUR",
    date: "March 2023",
    average_salary: 32.5,
    place: "Remote",
  },
  {
    id: 2858,
    title: "Tech Lead - PHP",
    company: "Intelia",
    salary: "40K - 55K EUR",
    date: "March 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2859,
    title: "Frontend Developer - React",
    company: "Cleverpy",
    salary: "25K - 35K EUR",
    date: "March 2023",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 2860,
    title: "Analista Funcional - Java",
    company: "Cleverpy",
    salary: "30K - 45K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 2861,
    title: "Backend Developer - PHP",
    company: "Wild Branders",
    salary: "30K - 35K EUR",
    date: "March 2023",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2862,
    title: "Frontend Developer - Angular",
    company: "VTO",
    salary: "40K - 45K EUR",
    date: "March 2023",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 2863,
    title: "Javascript Engineer (React)",
    company: "Rakuten TV",
    salary: "30K - 50K EUR",
    date: "March 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2864,
    title: "Salesforce Engineer",
    company: "Rakuten TV",
    salary: "30K - 55K EUR",
    date: "March 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2865,
    title: "Backend Engineer",
    company: "Xceed",
    salary: "40K - 55K EUR",
    date: "March 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2866,
    title: "Senior-Mid Backend Developer",
    company: "Laurel Gaming",
    salary: "35K - 40K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2867,
    title: "Co-founder & ML Engineer",
    company: "Wagmi Venture Studio",
    salary: "50K - 70K EUR",
    date: "March 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 2868,
    title: "Full Stack Developer",
    company: "laTostadora",
    salary: "35K - 40K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2869,
    title: "Backend Developer (PHP Symfony)",
    company: "GMW",
    salary: "35K - 50K EUR",
    date: "March 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 2870,
    title: "Full Stack Engineer",
    company: "LambdaLoopers",
    salary: "41K - 51K EUR",
    date: "March 2023",
    average_salary: 46,
    place: "Barcelona/Remote",
  },
  {
    id: 2871,
    title: "Frontend Developer - React",
    company: "NPAW",
    salary: "35K - 40K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2872,
    title: "Python Developer",
    company: "Stock Agile",
    salary: "40K - 45K EUR",
    date: "March 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2873,
    title: "Sr. Full Stack Developer (React/Node.js)",
    company: "Phiture",
    salary: "50K - 60K EUR",
    date: "March 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2874,
    title: "Sr Backend Engineer",
    company: "Smadex",
    salary: "50K - 60K EUR",
    date: "March 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2875,
    title: "Python developer (Odoo, Django)",
    company: "Som IT Cooperatiu",
    salary: "24K - 34K EUR",
    date: "March 2023",
    average_salary: 29,
    place: "Barcelona/Remote",
  },
  {
    id: 2876,
    title: "Lead Backend Developer (PHP Symfony)",
    company: "Infonet",
    salary: "55K - 65K EUR",
    date: "March 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 2877,
    title: "Principal Development Engineer",
    company: "Openbravo",
    salary: "50K - 65K EUR",
    date: "March 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2878,
    title: "Senior Frontend using Angular framework",
    company: "Memodo",
    salary: "153K - 153K EUR",
    date: "March 2023",
    average_salary: 153,
    place: "Remote",
  },
  {
    id: 2879,
    title: "iOS developer",
    company: "GoTrendier",
    salary: "40K - 50K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2880,
    title: "Sr Android Developer",
    company: "BLING",
    salary: "45K - 65K EUR",
    date: "March 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2881,
    title: "Frontend Engineer",
    company: "Buscoresi",
    salary: "35K - 40K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2882,
    title: "PHP Backend Developer (Laravel)",
    company: "VICIO",
    salary: "40K - 50K EUR",
    date: "March 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2883,
    title: "Director of Engineering for Quantum Company",
    company: "QCentroid",
    salary: "50K - 75K EUR",
    date: "March 2023",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2884,
    title: "Senior Python Software Engineer",
    company: "Mr. Milú",
    salary: "45K - 60K EUR",
    date: "March 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 2885,
    title: "Web Developer",
    company: "WindowSight",
    salary: "35K - 35K EUR",
    date: "March 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 2886,
    title: "Chief Technology Officer (CTO) with stock options",
    company: "mindbly.com",
    salary: "35K - 40K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2887,
    title: "Software Engineer, Engineering Productivity",
    company: "StreamSets",
    salary: "50K - 50K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2888,
    title: "Devops Engineer",
    company: "Fluendo",
    salary: "50K - 60K EUR",
    date: "March 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2889,
    title: "Full-stack (React-NodeJS)",
    company: "Seat:Code",
    salary: "30K - 55K EUR",
    date: "March 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2890,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "60K - 73K EUR",
    date: "March 2023",
    average_salary: 66.5,
    place: "Barcelona",
  },
  {
    id: 2891,
    title: "Senior Database Administrator",
    company: "Gartner",
    salary: "55K - 70K EUR",
    date: "March 2023",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 2892,
    title: "Senior QA Engineer (with any Cypress experience)",
    company: "Brainly",
    salary: "38K - 55K EUR",
    date: "March 2023",
    average_salary: 46.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2893,
    title: "Senior Front-end Engineer (with next.js)",
    company: "Brainly",
    salary: "55K - 82K EUR",
    date: "March 2023",
    average_salary: 68.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2894,
    title: "Craftperson-in-training (30K - 40K tras formación)",
    company: "Codurance",
    salary: "26K - 30K EUR",
    date: "March 2023",
    average_salary: 28,
    place: "Barcelona/Remote",
  },
  {
    id: 2895,
    title: "DevOps Engineer",
    company: "Red Points",
    salary: "50K - 65K EUR",
    date: "March 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2896,
    title: "Software Engineer (Python)",
    company: "Emburse",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2897,
    title: "Senior JavaScript Engineer (React prefered)",
    company: "Emburse",
    salary: "40K - 65K EUR",
    date: "March 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2898,
    title: "Software Engineer in Test",
    company: "Emburse",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2899,
    title: "Site Reliability Engineer",
    company: "Emburse",
    salary: "45K - 65K EUR",
    date: "March 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2900,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "March 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2901,
    title: "Business Analyst (ERP)",
    company: "Som IT Cooperatiu",
    salary: "24K - 34K EUR",
    date: "March 2023",
    average_salary: 29,
    place: "Barcelona/Remote",
  },
  {
    id: 2902,
    title: "Co-founder CTO - Hands On",
    company: "Vuala",
    salary: "35K - 40K EUR",
    date: "March 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2903,
    title: "Senior Software Engineer",
    company: "Edpuzzle",
    salary: "53K - 73K EUR",
    date: "March 2023",
    average_salary: 63,
    place: "Barcelona/Remote",
  },
  {
    id: 2904,
    title: "Node Backend Developer",
    company: "Bipi",
    salary: "35K - 45K EUR",
    date: "March 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2905,
    title: "DevOps Engineer",
    company: "InnoIT",
    salary: "45K - 65K EUR",
    date: "March 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2906,
    title: "Backend Developer (PHP Laravel) Health Tech",
    company: "CMRAD",
    salary: "32K - 60K EUR",
    date: "March 2023",
    average_salary: 46,
    place: "Barcelona",
  },
  {
    id: 2907,
    title: "AWS DevOps Engineer",
    company: "GMW",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2908,
    title: "Junior Software Engineer (Python / C++)",
    company: "QORVO",
    salary: "32K - 36K EUR",
    date: "March 2023",
    average_salary: 34,
    place: "Barcelona",
  },
  {
    id: 2909,
    title: "Senior Frontend Developer",
    company: "Nexus Clips",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2910,
    title: "Machine Learning Engineer",
    company: "Nexus Clips",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2911,
    title: "Data scientist",
    company: "Nexus Clips",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2912,
    title: "Product Designer",
    company: "Nexus Clips",
    salary: "40K - 60K EUR",
    date: "March 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2913,
    title: "Data Operations Analyst",
    company: "haddock",
    salary: "30K - 33K EUR",
    date: "March 2023",
    average_salary: 31.5,
    place: "Barcelona",
  },
  {
    id: 2914,
    title: "Senior PHP Backend Developer",
    company: "GoTrendier",
    salary: "45K - 60K EUR",
    date: "April 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2915,
    title: "AWS DevOps Engineer",
    company: "Gracia Media Web",
    salary: "40K - 60K EUR",
    date: "April 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2916,
    title: "iOS Developer (SwiftUI)",
    company: "one sec app",
    salary: "69K - 69K EUR",
    date: "April 2023",
    average_salary: 69,
    place: "Remote",
  },
  {
    id: 2917,
    title: "L2 Customer Success / Application Support",
    company: "Dragonfly",
    salary: "30K - 40K EUR",
    date: "April 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 2918,
    title: "Backend Software Engineer",
    company: "Woffu",
    salary: "40K - 50K EUR",
    date: "April 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2919,
    title: "Lead Software Architect",
    company: "Fingerprint",
    salary: "140K - 165K USD",
    date: "April 2023",
    average_salary: 152.5,
    place: "Remote",
  },
  {
    id: 2920,
    title: "Junior Ethical Hacker",
    company: "Factorial HR",
    salary: "30K - 30K EUR",
    date: "April 2023",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 2921,
    title: "Frontend Engineer (React)",
    company: "Materialise",
    salary: "50K - 55K EUR",
    date: "April 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2922,
    title: "Mid/Senior Software Engineer",
    company: "Factorial HR",
    salary: "38K - 70K EUR",
    date: "April 2023",
    average_salary: 54,
    place: "Remote",
  },
  {
    id: 2923,
    title: "Senior Product Designer",
    company: "Factorial HR",
    salary: "60K - 70K EUR",
    date: "April 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2924,
    title: "Senior Software Engineer",
    company: "WOGRA",
    salary: "40K - 70K EUR",
    date: "April 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 2925,
    title: "Lead Software Engineer",
    company: "WOGRA",
    salary: "60K - 80K EUR",
    date: "April 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 2926,
    title: "Senior Security Software Engineer",
    company: "Okta",
    salary: "80K - 80K EUR",
    date: "April 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2927,
    title: "Senior Software Engineer - IAM",
    company: "Okta",
    salary: "80K - 80K EUR",
    date: "April 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 2928,
    title: "AWS DevOps Engineer",
    company: "GMW",
    salary: "40K - 60K EUR",
    date: "April 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2929,
    title: "Backend developer (NodeJS) - Consumer App",
    company: "MediaMarktSaturn",
    salary: "30K - 60K EUR",
    date: "April 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2930,
    title: "Senior NodeJS+Python Developer",
    company: "Deep Solutions",
    salary: "40K - 70K EUR",
    date: "April 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2931,
    title: "Data Scientist Lead",
    company: "Sanofi",
    salary: "40K - 65K EUR",
    date: "April 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2932,
    title: "Data Scientist",
    company: "Sanofi",
    salary: "30K - 50K EUR",
    date: "April 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2933,
    title: "Data Analyst",
    company: "haddock app",
    salary: "30K - 33K EUR",
    date: "April 2023",
    average_salary: 31.5,
    place: "Barcelona",
  },
  {
    id: 2934,
    title: "PHP Laravel Developer (Health Tech)",
    company: "CMRAD",
    salary: "32K - 60K EUR",
    date: "April 2023",
    average_salary: 46,
    place: "Barcelona",
  },
  {
    id: 2935,
    title: "Python Senior Developer",
    company: "Deale",
    salary: "45K - 55K EUR",
    date: "April 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2936,
    title: "Senior Fullstack Engineer",
    company: "SCRM - Lidl",
    salary: "50K - 70K EUR",
    date: "April 2023",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 2937,
    title: "Senior Software Engineer (Ruby)",
    company: "Moodys Analytics",
    salary: "70K - 70K EUR",
    date: "April 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2938,
    title: "Senior DevOps Engineer",
    company: "Gartner",
    salary: "60K - 75K EUR",
    date: "April 2023",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 2939,
    title: "Front-end Developer",
    company: "Touché",
    salary: "35K - 40K EUR",
    date: "April 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 2940,
    title: "Product Designer",
    company: "Alhona",
    salary: "40K - 50K EUR",
    date: "April 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 2941,
    title: "Frontend Developer (React Native, Flutter)",
    company: "Mecena",
    salary: "35K - 45K EUR",
    date: "April 2023",
    average_salary: 40,
    place: "Barcelona/Remote",
  },
  {
    id: 2942,
    title: "DevSecOps Manager",
    company: "RatedPower",
    salary: "75K - 80K EUR",
    date: "April 2023",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 2943,
    title: "Backend Developer",
    company: "RatedPower",
    salary: "45K - 50K EUR",
    date: "April 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 2944,
    title: "Software Architect",
    company: "RatedPower",
    salary: "70K - 75K EUR",
    date: "April 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2945,
    title: "DevOps & AWS Engineer",
    company: "RatedPower",
    salary: "60K - 65K EUR",
    date: "April 2023",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 2946,
    title: "Senior PHP Engineer",
    company: "Bumble",
    salary: "60K - 70K EUR",
    date: "April 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2947,
    title: "Principal QA Engineer",
    company: "Kantox",
    salary: "65K - 80K EUR",
    date: "April 2023",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 2948,
    title: "Software Engineer (PHP Backend)",
    company: "PromoFarma/DocMorris",
    salary: "35K - 55K EUR",
    date: "April 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2949,
    title: "Senior React Native & Frontend Developer",
    company: "Promofarma/Docmorris",
    salary: "35K - 55K EUR",
    date: "April 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2950,
    title: "PHP Backend Engineer",
    company: "PromoFarma/DocMorris",
    salary: "35K - 55K EUR",
    date: "April 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2951,
    title: "Data Scientist",
    company: "Promofarma/Docmorris",
    salary: "35K - 50K EUR",
    date: "April 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2952,
    title: "QA Analyst/Tester",
    company: "CMRAD",
    salary: "35K - 45K EUR",
    date: "April 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2953,
    title: "Senior DevOps Engineer (L3)",
    company: "Intelygenz",
    salary: "50K - 60K EUR",
    date: "April 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2954,
    title: "Senior DevOps Engineer (L5)",
    company: "Intelygenz",
    salary: "60K - 75K EUR",
    date: "April 2023",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 2955,
    title: "QA Automation Engineer",
    company: "Promofarma/Docmorris",
    salary: "35K - 50K EUR",
    date: "April 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2956,
    title: "Tech Lead PHP",
    company: "Between Technology",
    salary: "50K - 80K EUR",
    date: "April 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 2957,
    title: "Senior FrontEnd Developer (Angular)",
    company: "Roadfans",
    salary: "50K - 90K EUR",
    date: "May 2023",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 2958,
    title: "PHP Developer",
    company: "Between Technology",
    salary: "33K - 45K EUR",
    date: "May 2023",
    average_salary: 39,
    place: "Barcelona",
  },
  {
    id: 2959,
    title: "Senior Software Engineer",
    company: "Nexus Clips",
    salary: "40K - 60K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2960,
    title: "Backend Developer",
    company: "NAKIMA",
    salary: "30K - 40K EUR",
    date: "May 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 2961,
    title: "Senior Full Stack Engineer",
    company: "Aetion",
    salary: "65K - 80K EUR",
    date: "May 2023",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2962,
    title: "React Native Developer",
    company: "OpositaTest",
    salary: "35K - 45K EUR",
    date: "May 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 2963,
    title: "Senior Machine Learning Engineer + Data Scientist",
    company: "Aetion",
    salary: "65K - 80K EUR",
    date: "May 2023",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2964,
    title: "Technical Project Manager",
    company: "Aetion",
    salary: "55K - 60K EUR",
    date: "May 2023",
    average_salary: 57.5,
    place: "Barcelona",
  },
  {
    id: 2965,
    title: "Java Developer",
    company: "GrayHair Software",
    salary: "40K - 60K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2966,
    title: "JavaScript Smart TV Engineer",
    company: "Rakuten TV",
    salary: "35K - 60K EUR",
    date: "May 2023",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 2967,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "May 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 2968,
    title: "Offensive Security Researcher :rotating_light:",
    company: "Sysdig",
    salary: "55K - 90K EUR",
    date: "May 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2969,
    title: "Fullstack Developer (m/f/d)",
    company: "Promofarma/Docmorris",
    salary: "35K - 55K EUR",
    date: "May 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2970,
    title: "Ruby Back-end Engineer",
    company: "Roca",
    salary: "50K - 65K EUR",
    date: "May 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2971,
    title: "IoT Engineer - Liferay",
    company: "Roca",
    salary: "35K - 55K EUR",
    date: "May 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 2972,
    title: "Web Product Owner (eCommerce)",
    company: "Roca",
    salary: "60K - 80K EUR",
    date: "May 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 2973,
    title: "Java Software Engineer",
    company: "Affirm",
    salary: "67K - 67K EUR",
    date: "May 2023",
    average_salary: 67,
    place: "Remote",
  },
  {
    id: 2974,
    title: "Frontend Engineer (Vue 3)",
    company: "TROOP",
    salary: "55K - 55K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2975,
    title: "Backend Engineer (Node.js)",
    company: "TROOP",
    salary: "55K - 55K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 2976,
    title: "Staff Backend Engineer",
    company: "Voodoo.io",
    salary: "80K - 100K EUR",
    date: "May 2023",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 2977,
    title: "Senior Python Developer",
    company: "Xcelirate",
    salary: "80K - 80K EUR",
    date: "May 2023",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 2978,
    title: "Principal Software Engineer (Python, PHP, sysops)",
    company: "Xcelirate",
    salary: "100K - 150K EUR",
    date: "May 2023",
    average_salary: 125,
    place: "Barcelona",
  },
  {
    id: 2979,
    title: "Backend Developer (.NET)",
    company: "M47 Labs",
    salary: "38K - 42K EUR",
    date: "May 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2980,
    title: "Java Developer",
    company: "Hibrid",
    salary: "30K - 40K EUR",
    date: "May 2023",
    average_salary: 35,
    place: "Barcelona",
  },
  {
    id: 2981,
    title: "Technical Product Manager - Remote",
    company: "Ensemble IP",
    salary: "90K - 110K EUR",
    date: "May 2023",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 2982,
    title: "Data Analyst",
    company: "GoTrendier",
    salary: "35K - 50K EUR",
    date: "May 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2983,
    title: "PHP Laravel Developer (HealthTech)",
    company: "CMRAD",
    salary: "32K - 60K EUR",
    date: "May 2023",
    average_salary: 46,
    place: "Barcelona",
  },
  {
    id: 2984,
    title: "Senior PHP Backend Developer",
    company: "GoTrendier",
    salary: "45K - 55K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 2985,
    title: "QA Tester/Analyst (HealthTech)",
    company: "CMRAD",
    salary: "35K - 45K EUR",
    date: "May 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 2986,
    title: "Senior/Lead Systems Engineer",
    company: "Stealth Startup BCN",
    salary: "70K - 90K EUR",
    date: "May 2023",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 2987,
    title: "Senior Product Manager",
    company: "Affirm",
    salary: "70K - 75K EUR",
    date: "May 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 2988,
    title: "(Junior-Mid) Software Engineer (Python)",
    company: "CRAHI",
    salary: "30K - 35K EUR",
    date: "May 2023",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 2989,
    title: "Python Developer",
    company: "Flanks",
    salary: "23K - 30K EUR",
    date: "May 2023",
    average_salary: 26.5,
    place: "Barcelona",
  },
  {
    id: 2990,
    title: "Android Software Engineer",
    company: "BLING",
    salary: "45K - 65K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 2991,
    title: "Mobile Engineer",
    company: "World Wide Mobility",
    salary: "45K - 55K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 2992,
    title: "DevOps/Cloud Engineer",
    company: "Dehn",
    salary: "45K - 61K EUR",
    date: "May 2023",
    average_salary: 53,
    place: "Remote",
  },
  {
    id: 2993,
    title: "Product Manager",
    company: "Qamyno",
    salary: "45K - 60K EUR",
    date: "May 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 2994,
    title: "Engineering Manager, Infrastructure",
    company: "Seqera Labs",
    salary: "80K - 120K EUR",
    date: "May 2023",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 2995,
    title: "Senior Software Engineer (Space & Ground Segment)",
    company: "Sateliot",
    salary: "40K - 60K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 2996,
    title: "Sr. Product Manager, Data",
    company: "Chartboost",
    salary: "60K - 90K EUR",
    date: "May 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2997,
    title: "Sr. Product Manager, Mobile SDK",
    company: "Chartboost",
    salary: "60K - 90K EUR",
    date: "May 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 2998,
    title: "Senior Data Scientist (OR ML engineer)",
    company: "Chartboost",
    salary: "60K - 85K EUR",
    date: "May 2023",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 2999,
    title: "Senior Developer Evangelist",
    company: "Fingerprint",
    salary: "100K - 114K EUR",
    date: "May 2023",
    average_salary: 107,
    place: "Remote",
  },
  {
    id: 3000,
    title: "Staff Software Engineer, Devops",
    company: "Gamium",
    salary: "70K - 100K USD",
    date: "May 2023",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 3001,
    title: "Senior Software Engineer, Unity",
    company: "Gamium",
    salary: "50K - 80K USD",
    date: "May 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 3002,
    title: "Senior Software Engineer, React Native",
    company: "Gamium",
    salary: "50K - 80K USD",
    date: "May 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 3003,
    title: "Back-end Developer",
    company: "Valerdat",
    salary: "38K - 42K EUR",
    date: "May 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3004,
    title: "Backend Developer (Nodejs)",
    company: "NAKIMA",
    salary: "30K - 40K EUR",
    date: "May 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 3005,
    title: "Senior React Developer",
    company: "Zinklar",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 3006,
    title: "iOS Developer",
    company: "World Wide Mobility",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3007,
    title: "Python Developer",
    company: "World Wide Mobility",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3008,
    title: "Expert API Designer",
    company: "Telefónica",
    salary: "40K - 55K EUR",
    date: "May 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 3009,
    title: "Backend Developer (Java/Kotlin)",
    company: "Telefónica",
    salary: "45K - 55K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3010,
    title: "iOS Developer",
    company: "Telefónica",
    salary: "40K - 55K EUR",
    date: "May 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 3011,
    title: "Senior Python developer (Odoo)",
    company: "Som IT Cooperatiu",
    salary: "30K - 39K EUR",
    date: "May 2023",
    average_salary: 34.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3012,
    title: "Lead DevOps Engineer",
    company: "GFT Group",
    salary: "60K - 80K EUR",
    date: "May 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 3013,
    title: "Frontend Tech Lead - React",
    company: "GFT Group",
    salary: "80K - 80K EUR",
    date: "May 2023",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 3014,
    title: "Backend engineer (Node.js)",
    company: "Rinkel",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3015,
    title: "Senior Cloud Engineer",
    company: "Mitek Systems-ID R&D",
    salary: "60K - 70K EUR",
    date: "May 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 3016,
    title: "Machine Learning Engineer ID R&D",
    company: "Mitek Systems",
    salary: "55K - 70K EUR",
    date: "May 2023",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 3017,
    title: "Fullstack Go developer",
    company: "IT HUB",
    salary: "70K - 70K EUR",
    date: "May 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 3018,
    title: "Python Developer",
    company: "World Wide Mobility",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3019,
    title: "iOS Developer",
    company: "World Wide Mobility",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3020,
    title: "Principal ecurity Architect",
    company: "Job and Talent",
    salary: "70K - 100K EUR",
    date: "May 2023",
    average_salary: 85,
    place: "Remote",
  },
  {
    id: 3021,
    title: "HQ - Senior Engineering Manager (EMEA remote)",
    company: "Job and Talent",
    salary: "150K - 200K EUR",
    date: "May 2023",
    average_salary: 175,
    place: "Remote",
  },
  {
    id: 3022,
    title: "iOS Mobile Developer",
    company: "Taykus",
    salary: "50K - 60K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3023,
    title: "Product Minded Software Developer with React",
    company: "Cooltra",
    salary: "30K - 60K EUR",
    date: "May 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3024,
    title: "Product Manager",
    company: "Cooltra",
    salary: "30K - 60K EUR",
    date: "May 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3025,
    title: "iOS Software Engineer",
    company: "Science4Tech",
    salary: "42K - 60K EUR",
    date: "May 2023",
    average_salary: 51,
    place: "Remote",
  },
  {
    id: 3026,
    title: "Middle Front End Developer",
    company: "Talleres Serra SL",
    salary: "40K - 60K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3027,
    title: "Backend Engineer (Node.js)",
    company: "Rinkel",
    salary: "55K - 60K EUR",
    date: "May 2023",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 3028,
    title: "Frontend Developer (VueJS)",
    company: "CMRAD (HealthTech)",
    salary: "32K - 60K EUR",
    date: "May 2023",
    average_salary: 46,
    place: "Barcelona",
  },
  {
    id: 3029,
    title: "Senior React Engineer",
    company: "Dynatrace",
    salary: "50K - 80K EUR",
    date: "May 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3030,
    title: "Software Engineer",
    company: "Bankflip",
    salary: "40K - 50K EUR",
    date: "May 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3031,
    title: "Senior Frontend Software Engineer",
    company: "New Relic",
    salary: "63K - 88K EUR",
    date: "May 2023",
    average_salary: 75.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3032,
    title: "Web Developer",
    company: "Seqera Labs",
    salary: "35K - 50K EUR",
    date: "May 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3033,
    title: "Senior Frontend Developer (React & TypeScript)",
    company: "EdgeTier",
    salary: "50K - 75K EUR",
    date: "May 2023",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3034,
    title: "DevSecOps",
    company: "TMB",
    salary: "50K - 50K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3035,
    title: "Database Administrator (Postgres)",
    company: "EdgeTier",
    salary: "55K - 75K EUR",
    date: "May 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3036,
    title: "Senior DevOps Engineer (AWS)",
    company: "EdgeTier",
    salary: "45K - 75K EUR",
    date: "May 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3037,
    title: "DevOps Engineer",
    company: "Agency",
    salary: "30K - 45K EUR",
    date: "May 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 3038,
    title: "Senior Backend Developer (Python)",
    company: "EdgeTier",
    salary: "50K - 75K EUR",
    date: "May 2023",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3039,
    title: "Senior Ruby on Rails Engineer",
    company: "Kantox",
    salary: "65K - 85K EUR",
    date: "May 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 3040,
    title: "Senior Full Stack Engineer (Typescript/React/Node)",
    company: "Fingerprint",
    salary: "95K - 108K EUR",
    date: "May 2023",
    average_salary: 101.5,
    place: "Remote",
  },
  {
    id: 3041,
    title: "Mid/Senior Backend Engineer",
    company: "Deale",
    salary: "45K - 55K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3042,
    title: "Senior Fullstack Developer",
    company: "Benevity",
    salary: "50K - 70K EUR",
    date: "May 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3043,
    title: "Senior iOS Engineer",
    company: "Dynatrace",
    salary: "50K - 80K EUR",
    date: "May 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3044,
    title: "Cybersecurity Analyst",
    company: "Schwarz",
    salary: "27K - 30K EUR",
    date: "May 2023",
    average_salary: 28.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3045,
    title: "Senior Cybersecurity Analyst",
    company: "Schwarz",
    salary: "40K - 43K EUR",
    date: "May 2023",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3046,
    title: "Senior Frontend Developer",
    company: "Last App",
    salary: "60K - 80K EUR",
    date: "May 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 3047,
    title: "Mid/Senior Frontend Engineer (React ideally)",
    company: "Workfully",
    salary: "40K - 60K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3048,
    title: "Unity Software Engineer",
    company: "Tripledot Studios",
    salary: "45K - 65K EUR",
    date: "May 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3049,
    title: "Backend Engineer",
    company: "PromoFarma/DocMorris",
    salary: "35K - 55K EUR",
    date: "May 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3050,
    title: "DevOps Engineer",
    company: "PromoFarma/DocMorris",
    salary: "45K - 55K EUR",
    date: "May 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3051,
    title: "Test/QA Engineer (Spain, full-remote)",
    company: "Mitek Systems",
    salary: "35K - 45K EUR",
    date: "May 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 3052,
    title: "Android Developer",
    company: "Improfit.ai",
    salary: "25K - 40K EUR",
    date: "May 2023",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 3053,
    title: "Senior Java Developer",
    company: "GrayHair Software",
    salary: "55K - 65K EUR",
    date: "May 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3054,
    title: "Senior Full Stack developer",
    company: "Factorial",
    salary: "60K - 60K EUR",
    date: "May 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3055,
    title: "Senior Backend Developer",
    company: "Piper",
    salary: "55K - 70K EUR",
    date: "June 2023",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 3056,
    title: "Hands-on C-Tech Lead",
    company: "Celebreak",
    salary: "60K - 90K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 3057,
    title: "Senior PHP Laravel Developer (HealthTech)",
    company: "CMRAD",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3058,
    title: "FrontEnd VueJS Engineer (HealthTech)",
    company: "CMRAD",
    salary: "40K - 50K EUR",
    date: "June 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3059,
    title: "Senior Elixir Software Engineer",
    company: "Kantox",
    salary: "60K - 85K EUR",
    date: "June 2023",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 3060,
    title: "Software engineer (iOS)",
    company: "Affirm",
    salary: "70K - 70K EUR",
    date: "June 2023",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 3061,
    title: "Frontend Software Engineer",
    company: "Science4Tech",
    salary: "42K - 60K EUR",
    date: "June 2023",
    average_salary: 51,
    place: "Remote",
  },
  {
    id: 3062,
    title: "Frontend React Developer",
    company: "Tangent",
    salary: "55K - 65K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3063,
    title: "Junior Software Engineer (Python / C++)",
    company: "Qorvo",
    salary: "32K - 36K EUR",
    date: "June 2023",
    average_salary: 34,
    place: "Barcelona/Remote",
  },
  {
    id: 3064,
    title: "Senior Backend Developer (PHP)",
    company: "Mailtrack.io",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3065,
    title: "Senior Python Developer",
    company: "Flanks",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3066,
    title: "Sr. Product Manager",
    company: "Manychat",
    salary: "60K - 90K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 3067,
    title: "Full Stack Dev/ CTO",
    company: "Ticketdoor",
    salary: "40K - 60K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3068,
    title: "Senior Backend Developer Node.js",
    company: "TalentBait",
    salary: "43K - 70K EUR",
    date: "June 2023",
    average_salary: 56.5,
    place: "Barcelona",
  },
  {
    id: 3069,
    title: "Software Engineer (Spain, full-remote)",
    company: "Mitek Systems",
    salary: "45K - 60K EUR",
    date: "June 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 3070,
    title: "VueJS FrontEnd Developer",
    company: "CMRAD (HealthTech)",
    salary: "45K - 50K EUR",
    date: "June 2023",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 3071,
    title: "Corporate IT Lead",
    company: "Urban Sports Club",
    salary: "65K - 85K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 3072,
    title: "Tech Lead (no-stack)",
    company: "Erictel",
    salary: "50K - 70K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3073,
    title: "Senior .NET Developer",
    company: "Trifork",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3074,
    title: "Senior Angular Developer",
    company: "Trifork",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3075,
    title: "Senior Android Developer",
    company: "Trifork",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3076,
    title: "Principal Data Scientist (Spain, full-remote)",
    company: "Mitek Systems",
    salary: "65K - 85K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 3077,
    title: "Delivery Manager",
    company: "Mindcurv",
    salary: "55K - 65K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3078,
    title: "Staff Engineer",
    company: "Benevity",
    salary: "65K - 80K EUR",
    date: "June 2023",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 3079,
    title: "SRE / DevOps",
    company: "Giesecke+Devrient",
    salary: "45K - 60K EUR",
    date: "June 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 3080,
    title: "Team Lead Engineering (Nuxt/Vue, Node.js)",
    company: "Parkos",
    salary: "65K - 85K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 3081,
    title: "UX Designer",
    company: "GoTrendier",
    salary: "30K - 40K EUR",
    date: "June 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 3082,
    title: "Senior Software Architect",
    company: "Seqera Labs",
    salary: "90K - 110K EUR",
    date: "June 2023",
    average_salary: 100,
    place: "Barcelona/Remote",
  },
  {
    id: 3083,
    title: "Lead Design System",
    company: "PayFit",
    salary: "73K - 92K EUR",
    date: "June 2023",
    average_salary: 82.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3084,
    title: "Full Stack Developer (Angular/NodeJS)",
    company: "Nucleo360",
    salary: "35K - 45K EUR",
    date: "June 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 3085,
    title: "Full Stack Developer (React + Java / Python)",
    company: "The Cliff",
    salary: "33K - 40K EUR",
    date: "June 2023",
    average_salary: 36.5,
    place: "Remote",
  },
  {
    id: 3086,
    title: "Unreal Engine Developer",
    company: "The Cliff",
    salary: "35K - 45K EUR",
    date: "June 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 3087,
    title: "Senior Frontend React Developer (Inglés)",
    company: "Tangent",
    salary: "55K - 65K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3088,
    title: "Data Architect",
    company: "Gartner",
    salary: "85K - 100K EUR",
    date: "June 2023",
    average_salary: 92.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3089,
    title: "Senior Front End Software Engineer (React)",
    company: "Gartner",
    salary: "65K - 85K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 3090,
    title: "Systems Engineer (Mac environment)",
    company: "Gartner",
    salary: "48K - 55K EUR",
    date: "June 2023",
    average_salary: 51.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3091,
    title: "Operations Specialist (Salesforce)",
    company: "Gartner",
    salary: "45K - 50K EUR",
    date: "June 2023",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3092,
    title: "Android Developer (Spain, full-remote)",
    company: "Mitek Systems",
    salary: "50K - 70K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3093,
    title: "Software Engineer - TypeScript",
    company: "sennder",
    salary: "60K - 80K EUR",
    date: "June 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 3094,
    title: "Senior Fullstack Engineer - Typescript",
    company: "sennder",
    salary: "75K - 95K EUR",
    date: "June 2023",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 3095,
    title: "Go fullstack developer",
    company: "Schwarz",
    salary: "50K - 70K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3096,
    title: "Senior fullstack React/PHP/Go developer for IT Hub",
    company: "Schwarz",
    salary: "50K - 80K EUR",
    date: "June 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 3097,
    title: "Data enginees for european it Hub in Barcelona",
    company: "Schwarz",
    salary: "50K - 80K EUR",
    date: "June 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 3098,
    title: "Frontend Angular developer",
    company: "Apartool",
    salary: "35K - 45K EUR",
    date: "June 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3099,
    title: "Cloud database (MS SQL) Engineer",
    company: "SGS",
    salary: "39K - 50K EUR",
    date: "June 2023",
    average_salary: 44.5,
    place: "Barcelona",
  },
  {
    id: 3100,
    title: "Senior Angular Developer",
    company: "Webhelp",
    salary: "38K - 45K EUR",
    date: "June 2023",
    average_salary: 41.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3101,
    title: "Senior PHP Backend Developer",
    company: "GoTrendier",
    salary: "45K - 55K EUR",
    date: "June 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3102,
    title: "Senior Back-end Engineer with RoR",
    company: "Roca Group",
    salary: "50K - 65K EUR",
    date: "June 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3103,
    title: "QA Tester/Analyst",
    company: "CMRAD (HealthTech)",
    salary: "35K - 45K EUR",
    date: "June 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3104,
    title: "Data Scientist",
    company: "Retail",
    salary: "35K - 48K EUR",
    date: "June 2023",
    average_salary: 41.5,
    place: "Barcelona",
  },
  {
    id: 3105,
    title: ":react: Product Minded Software Developer w/ React",
    company: "Cooltra",
    salary: "30K - 60K EUR",
    date: "June 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3106,
    title: "Fullstacl developer",
    company: "Prestalo",
    salary: "40K - 45K EUR",
    date: "June 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3107,
    title: "Site Reliability Engineer",
    company: "Landbot",
    salary: "75K - 75K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 3108,
    title: "Senior Software Engineer (Backend Expertise)",
    company: "Landbot",
    salary: "50K - 70K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3109,
    title: "Sr. Data & Analytics Engineer",
    company: "Landbot",
    salary: "50K - 75K EUR",
    date: "June 2023",
    average_salary: 62.5,
    place: "Remote",
  },
  {
    id: 3110,
    title: "Site Reliability Engineer",
    company: "Landbot",
    salary: "60K - 75K EUR",
    date: "June 2023",
    average_salary: 67.5,
    place: "Remote",
  },
  {
    id: 3111,
    title: "Front-End Engineer",
    company: "Kantox",
    salary: "45K - 65K EUR",
    date: "June 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3112,
    title: "Mid Backend Developer (Go)",
    company: "GIG",
    salary: "42K - 47K EUR",
    date: "June 2023",
    average_salary: 44.5,
    place: "Remote",
  },
  {
    id: 3113,
    title: "JavaScript Instructor",
    company: "Codeworks",
    salary: "35K - 45K EUR",
    date: "June 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3114,
    title: "Lead Fullstack Engineer",
    company: "TKWW (Bodas.net)",
    salary: "70K - 75K EUR",
    date: "June 2023",
    average_salary: 72.5,
    place: "Remote",
  },
  {
    id: 3115,
    title: "Frontend Engineer",
    company: "Odin",
    salary: "55K - 65K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3116,
    title: "Backend Engineer",
    company: "Odin",
    salary: "55K - 65K EUR",
    date: "June 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3117,
    title: "Backend Software Engineer (PHP Laravel)",
    company: "CMRAD (HealthTech)",
    salary: "32K - 60K EUR",
    date: "June 2023",
    average_salary: 46,
    place: "Barcelona",
  },
  {
    id: 3118,
    title: "Tech Lead (Java)",
    company: "Build38",
    salary: "60K - 70K EUR",
    date: "June 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3119,
    title: "Tech Lead (Golang) for european IT Hub",
    company: "SGS",
    salary: "50K - 75K EUR",
    date: "June 2023",
    average_salary: 62.5,
    place: "Barcelona",
  },
  {
    id: 3120,
    title: "Python Software Engineer",
    company: "Thunes",
    salary: "75K - 75K EUR",
    date: "June 2023",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 3121,
    title: "IT Security Engineer",
    company: "AilyLabs",
    salary: "30K - 45K EUR",
    date: "June 2023",
    average_salary: 37.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3122,
    title: "Staff Software Engineer",
    company: "Affirm",
    salary: "82K - 93K EUR",
    date: "July 2023",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 3123,
    title: "Senior Frontend Developer",
    company: "humanITcare",
    salary: "50K - 65K EUR",
    date: "July 2023",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 3124,
    title: "Junior .NET Developer",
    company: "ForceManager",
    salary: "25K - 35K EUR",
    date: "July 2023",
    average_salary: 30,
    place: "Remote",
  },
  {
    id: 3125,
    title: "ML Engineer/NLP",
    company: "Ensemble IP",
    salary: "60K - 80K EUR",
    date: "July 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 3126,
    title: "Sr IT Talent Acquisition",
    company: "CAPITOLE CONSULTING",
    salary: "25K - 35K EUR",
    date: "July 2023",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 3127,
    title: "IT Analytics Finance Business Analyst",
    company: "Wipro",
    salary: "96K - 116K EUR",
    date: "July 2023",
    average_salary: 106,
    place: "Barcelona",
  },
  {
    id: 3128,
    title: "Backend Engineer",
    company: "Zealy",
    salary: "80K - 80K EUR",
    date: "July 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 3129,
    title: "Senior Frontend Engineer (VueJs)",
    company: "TheyDo",
    salary: "80K - 100K EUR",
    date: "July 2023",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 3130,
    title: "DevSecOps Consultant",
    company: "Wipro",
    salary: "72K - 84K EUR",
    date: "July 2023",
    average_salary: 78,
    place: "Barcelona",
  },
  {
    id: 3131,
    title: "Senior PHP Backend Developer",
    company: "GoTrendier",
    salary: "45K - 55K EUR",
    date: "July 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3132,
    title: "Cloud Engineer",
    company: "Dehn",
    salary: "45K - 60K EUR",
    date: "July 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 3133,
    title: "FrontEnd Developer",
    company: "CMRAD (HealthTech)",
    salary: "45K - 50K EUR",
    date: "July 2023",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 3134,
    title: "Product Designer",
    company: "Knack",
    salary: "50K - 50K EUR",
    date: "July 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3135,
    title: "Software Engineer (Go)",
    company: "Bankable",
    salary: "45K - 65K EUR",
    date: "July 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 3136,
    title: "Java Developer",
    company: "Between Technology",
    salary: "35K - 45K EUR",
    date: "July 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3137,
    title: "Senior PHP Developer",
    company: "Between Technology",
    salary: "35K - 50K EUR",
    date: "July 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 3138,
    title: "Analista Programador/a .NET con WPF",
    company: "Between Technology",
    salary: "35K - 45K EUR",
    date: "July 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3139,
    title: "Backend Engineer",
    company: "IZI Record",
    salary: "35K - 50K EUR",
    date: "July 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3140,
    title: "CTO Full Stack Developer",
    company: "Pulso",
    salary: "60K - 75K EUR",
    date: "July 2023",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3141,
    title: "Senior Fullstack Software Engineer",
    company: "haddock app",
    salary: "55K - 70K EUR",
    date: "July 2023",
    average_salary: 62.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3142,
    title: "Senior Backend Engineer - Python",
    company: "sennder",
    salary: "70K - 100K EUR",
    date: "July 2023",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 3143,
    title: "Senior Frontend Developer (React)",
    company: "OpositaTest",
    salary: "40K - 50K EUR",
    date: "July 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 3144,
    title: "Senior App Developer",
    company: "Sivasdescalzo (SVD)",
    salary: "50K - 60K EUR",
    date: "July 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 3145,
    title: "Frontend Developer (React)",
    company: "Sivasdescalzo (SVD)",
    salary: "50K - 60K EUR",
    date: "July 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 3146,
    title: "Desarrollador senior React JS/Node JS (full-stack)",
    company: "Marvut Technologies",
    salary: "25K - 40K EUR",
    date: "July 2023",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3147,
    title: "Ruby on Rails Senior Software Engineer",
    company: "Moodys Analytics",
    salary: "50K - 70K EUR",
    date: "July 2023",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 3148,
    title: "API Architect",
    company: "Fingerprint",
    salary: "140K - 170K USD",
    date: "July 2023",
    average_salary: 155,
    place: "Remote",
  },
  {
    id: 3149,
    title: "Engineering Manager",
    company: "Adevinta",
    salary: "100K - 100K EUR",
    date: "July 2023",
    average_salary: 100,
    place: "Barcelona",
  },
  {
    id: 3150,
    title: "Lead Software Engineer",
    company: "TKWW (Bodas.net)",
    salary: "65K - 79K EUR",
    date: "July 2023",
    average_salary: 72,
    place: "Barcelona/Remote",
  },
  {
    id: 3151,
    title: "Senior Full-stack Developer",
    company: "Dogfy Diet",
    salary: "50K - 60K EUR",
    date: "July 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3152,
    title: "Senior Data Engineer, PHP",
    company: "Dealfront",
    salary: "70K - 80K EUR",
    date: "July 2023",
    average_salary: 75,
    place: "Remote",
  },
  {
    id: 3153,
    title: "Product Data Engineer",
    company: "Alhona",
    salary: "50K - 70K EUR",
    date: "July 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3154,
    title: "Tech Lead (Java)",
    company: "Build38",
    salary: "60K - 70K EUR",
    date: "July 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 3155,
    title: "UX/UI Lead",
    company: "CrossPoint365",
    salary: "40K - 48K EUR",
    date: "July 2023",
    average_salary: 44,
    place: "Barcelona/Remote",
  },
  {
    id: 3156,
    title: "Senior Frontend Engineer",
    company: "Kantox",
    salary: "50K - 65K EUR",
    date: "July 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3157,
    title: "Senior Frontend Developer",
    company: "Laurel Gaming",
    salary: "40K - 50K EUR",
    date: "July 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3158,
    title: "Principal Eng Cloud Platform Network Architect",
    company: "New Relic",
    salary: "100K - 130K EUR",
    date: "July 2023",
    average_salary: 115,
    place: "Barcelona/Remote",
  },
  {
    id: 3159,
    title: "Data Engineer",
    company: "SDG Group",
    salary: "65K - 65K EUR",
    date: "July 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 3160,
    title: "Machine Learning Engineer",
    company: "Tether",
    salary: "30K - 35K EUR",
    date: "July 2023",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 3161,
    title: "Principal Database Architect (Remote)",
    company: "New Relic",
    salary: "100K - 130K EUR",
    date: "July 2023",
    average_salary: 115,
    place: "Barcelona/Remote",
  },
  {
    id: 3162,
    title: "Senior Backend Engineer (Remote/Hybrid) possible",
    company: "Qonto",
    salary: "60K - 85K EUR",
    date: "July 2023",
    average_salary: 72.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3163,
    title: "Business Development Manager",
    company: "TechHunt",
    salary: "50K - 70K EUR",
    date: "July 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3164,
    title: "DevOps Engineer",
    company: "Edpuzzle",
    salary: "43K - 63K EUR",
    date: "July 2023",
    average_salary: 53,
    place: "Barcelona/Remote",
  },
  {
    id: 3165,
    title: "Sr. DevOps Engineer",
    company: "Smadex",
    salary: "60K - 100K EUR",
    date: "July 2023",
    average_salary: 80,
    place: "Barcelona/Remote",
  },
  {
    id: 3166,
    title: "Senior Software Engineer (TypeScript)",
    company: "DFNS",
    salary: "80K - 140K USD",
    date: "July 2023",
    average_salary: 110,
    place: "Remote",
  },
  {
    id: 3167,
    title: "Senior Data Engineer",
    company: "IOMED",
    salary: "60K - 70K EUR",
    date: "July 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 3168,
    title: "Senior FrontEnd Engineer (Flutter pref)",
    company: "humanITcare",
    salary: "50K - 65K EUR",
    date: "July 2023",
    average_salary: 57.5,
    place: "Remote",
  },
  {
    id: 3169,
    title: "Android Engineer",
    company: "Adevinta",
    salary: "50K - 55K EUR",
    date: "July 2023",
    average_salary: 52.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3170,
    title: "Senior Fullstack Engineer",
    company: "sennder",
    salary: "70K - 100K EUR",
    date: "July 2023",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 3171,
    title: "React Native Front-End Engineer",
    company: "DealDash",
    salary: "30K - 75K EUR",
    date: "July 2023",
    average_salary: 52.5,
    place: "Remote",
  },
  {
    id: 3172,
    title: "Full Stack Engineer",
    company: "Porsche Digital",
    salary: "60K - 75K EUR",
    date: "July 2023",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 3173,
    title: "Backend Developer",
    company: "Valerdat",
    salary: "38K - 42K EUR",
    date: "July 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3174,
    title: "Frontend Developer",
    company: "Cafler",
    salary: "50K - 50K EUR",
    date: "July 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3175,
    title: "Technical Delivery Manager",
    company: "Erictel",
    salary: "40K - 45K EUR",
    date: "July 2023",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 3176,
    title: "Python Developer",
    company: "Bronze",
    salary: "40K - 46K EUR",
    date: "July 2023",
    average_salary: 43,
    place: "Barcelona/Remote",
  },
  {
    id: 3177,
    title: "Salesforce Developer",
    company: "Discovered",
    salary: "60K - 68K EUR",
    date: "July 2023",
    average_salary: 64,
    place: "Remote",
  },
  {
    id: 3178,
    title: "Staff/Senior Software Engineer",
    company: "Latitude",
    salary: "60K - 75K EUR",
    date: "July 2023",
    average_salary: 67.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3179,
    title: "PHP Backend Developer (Mid)",
    company: "OpositaTest",
    salary: "30K - 40K EUR",
    date: "August 2023",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 3180,
    title: "Delivery Manager",
    company: "Mindcurv",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3181,
    title: "Co-founder & CPO",
    company: "Antai Ventures",
    salary: "40K - 40K EUR",
    date: "August 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3182,
    title: "DevOps & AWS Engineer",
    company: "RatedPower",
    salary: "70K - 70K EUR",
    date: "August 2023",
    average_salary: 70,
    place: "Barcelona/Remote",
  },
  {
    id: 3183,
    title: "Software Architect",
    company: "RatedPower",
    salary: "85K - 85K EUR",
    date: "August 2023",
    average_salary: 85,
    place: "Barcelona/Remote",
  },
  {
    id: 3184,
    title: "QA Analyst/Engineer",
    company: "RatedPower",
    salary: "50K - 50K EUR",
    date: "August 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3185,
    title: "Full Stack Developer",
    company: "Porsche Digital",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3186,
    title: "Tech Lead - Full stack Developer",
    company: "Porsche Digital",
    salary: "60K - 75K EUR",
    date: "August 2023",
    average_salary: 67.5,
    place: "Barcelona",
  },
  {
    id: 3187,
    title: "Technical Product Owner",
    company: "Porsche Digital",
    salary: "60K - 85K EUR",
    date: "August 2023",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 3188,
    title: "Frontend Developer",
    company: "Cafler",
    salary: "50K - 50K EUR",
    date: "August 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3189,
    title: "Senior JavaScript Instructor",
    company: "Codeworks",
    salary: "40K - 55K EUR",
    date: "August 2023",
    average_salary: 47.5,
    place: "Barcelona",
  },
  {
    id: 3190,
    title: "Platform/DevOps Engineer",
    company: "Bankable",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 3191,
    title: "Kubernetes Platform Engineer (Security)",
    company: "Roche",
    salary: "65K - 65K EUR",
    date: "August 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3192,
    title: "QA Automation Engineer with Python",
    company: "DerbySoft",
    salary: "45K - 45K EUR",
    date: "August 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3193,
    title: "Senior Engineer IAM",
    company: "Okta CIC (ex Auth0)",
    salary: "90K - 90K EUR",
    date: "August 2023",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 3194,
    title: "Big Data Backend Developer for european IT HUB",
    company: "Schwarz",
    salary: "50K - 70K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3195,
    title: "Site Reliability Engineer for european IT HUB",
    company: "Schwarz",
    salary: "45K - 75K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3196,
    title: "Senior Full Stack Developer (Node+React)",
    company: "Benevity",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3197,
    title: "IAM Engineer",
    company: "Adevinta",
    salary: "30K - 33K EUR",
    date: "August 2023",
    average_salary: 31.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3198,
    title: "Junior iOS Developer",
    company: "Ray Motors",
    salary: "25K - 32K EUR",
    date: "August 2023",
    average_salary: 28.5,
    place: "Barcelona",
  },
  {
    id: 3199,
    title: "Senior Software Engineer",
    company: "Wave Mobile Money",
    salary: "118K - 138K USD",
    date: "August 2023",
    average_salary: 128,
    place: "Remote",
  },
  {
    id: 3200,
    title: "Software Engineer (Solutions Team)",
    company: "Flanks",
    salary: "40K - 45K EUR",
    date: "August 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 3201,
    title: "Senior Software Engineer (Solutions Team)",
    company: "Flanks",
    salary: "50K - 55K EUR",
    date: "August 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 3202,
    title: "Senior Fullstack Engineer",
    company: "sennder",
    salary: "70K - 100K EUR",
    date: "August 2023",
    average_salary: 85,
    place: "Barcelona",
  },
  {
    id: 3203,
    title: "JavaScript Instructor",
    company: "Codeworks",
    salary: "40K - 50K EUR",
    date: "August 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3204,
    title: "Senior FullStack Engineer (React + Node.js)",
    company: "haddock",
    salary: "40K - 55K EUR",
    date: "August 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 3205,
    title: "Product Designer",
    company: "haddock",
    salary: "40K - 45K EUR",
    date: "August 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 3206,
    title: "DevOps Engineer",
    company: "Less Than Three <3",
    salary: "30K - 40K EUR",
    date: "August 2023",
    average_salary: 35,
    place: "Remote",
  },
  {
    id: 3207,
    title: "Senior Python Engineer",
    company: "Xcelirate",
    salary: "70K - 70K EUR",
    date: "August 2023",
    average_salary: 70,
    place: "Barcelona",
  },
  {
    id: 3208,
    title: "DevSecOps Engineer",
    company: "Xcelirate",
    salary: "76K - 76K EUR",
    date: "August 2023",
    average_salary: 76,
    place: "Barcelona",
  },
  {
    id: 3209,
    title: "Principal Software Engineer",
    company: "Xcelirate",
    salary: "120K - 120K EUR",
    date: "August 2023",
    average_salary: 120,
    place: "Barcelona",
  },
  {
    id: 3210,
    title: "Senior Fullstack Engineer",
    company: "Busuu",
    salary: "80K - 80K EUR",
    date: "August 2023",
    average_salary: 80,
    place: "Remote",
  },
  {
    id: 3211,
    title: "Junior Firmware Engineer",
    company: "Cactus",
    salary: "30K - 30K EUR",
    date: "August 2023",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 3212,
    title: "React Native Developer",
    company: "GeekSquare",
    salary: "35K - 50K EUR",
    date: "August 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3213,
    title: "Engineering Manager",
    company: "Kantox",
    salary: "60K - 90K EUR",
    date: "August 2023",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 3214,
    title: "Software Engineer Python",
    company: "011h",
    salary: "45K - 45K EUR",
    date: "August 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3215,
    title: "Fullstack Developer (Internal tools)",
    company: "Manychat",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3216,
    title: "Frontend app developer",
    company: "Break Tour",
    salary: "30K - 35K EUR",
    date: "August 2023",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3217,
    title: "Backend app developer",
    company: "Break Tour",
    salary: "30K - 35K EUR",
    date: "August 2023",
    average_salary: 32.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3218,
    title: "Frontend Engineer",
    company: "Kantox",
    salary: "45K - 65K EUR",
    date: "August 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3219,
    title: "Senior Python Developer",
    company: "World Wide Mobility",
    salary: "50K - 60K EUR",
    date: "August 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3220,
    title: "Developer FS SENIOR React JS/Node JS (full-stack)",
    company: "Marvut technologies",
    salary: "40K - 60K EUR",
    date: "August 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3221,
    title: "Staff Engineer",
    company: "Relay Commerce",
    salary: "95K - 105K EUR",
    date: "August 2023",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 3222,
    title: "Senior Java Developer",
    company: "Mindcurv",
    salary: "45K - 55K EUR",
    date: "August 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3223,
    title: "Senior Frontend Developer (Core Stream)",
    company: "Manychat",
    salary: "75K - 76K EUR",
    date: "August 2023",
    average_salary: 75.5,
    place: "Barcelona",
  },
  {
    id: 3224,
    title: "UI Designer",
    company: "Mindcurv",
    salary: "35K - 45K EUR",
    date: "August 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 3225,
    title: "Senior Full Stack Developer",
    company: "Benevity",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3226,
    title: "Platform Engineer",
    company: "Bankable",
    salary: "55K - 65K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Barcelona/Remote",
  },
  {
    id: 3227,
    title: "Senior Front End Software Engineer",
    company: "Cloudpay",
    salary: "40K - 60K EUR",
    date: "August 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3228,
    title: "Senior Full Stack Engineer",
    company: "Brickbro",
    salary: "40K - 50K EUR",
    date: "August 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3229,
    title: "Senior Golang Software Engineer",
    company: "Gartner",
    salary: "75K - 75K EUR",
    date: "August 2023",
    average_salary: 75,
    place: "Barcelona/Remote",
  },
  {
    id: 3230,
    title: "Senior Cloud Network Engineer",
    company: "New Relic",
    salary: "60K - 80K EUR",
    date: "August 2023",
    average_salary: 70,
    place: "Remote",
  },
  {
    id: 3231,
    title: "Principal Eng Cloud Platform Network Architect",
    company: "New Relic",
    salary: "100K - 125K EUR",
    date: "August 2023",
    average_salary: 112.5,
    place: "Remote",
  },
  {
    id: 3232,
    title: "Database Cloud Architect",
    company: "New Relic",
    salary: "50K - 70K EUR",
    date: "August 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3233,
    title: "Lead Software Engineer Frontend Architect, Design",
    company: "New Relic",
    salary: "80K - 100K EUR",
    date: "August 2023",
    average_salary: 90,
    place: "Remote",
  },
  {
    id: 3234,
    title: "Product Analyst",
    company: "Manychat",
    salary: "45K - 55K EUR",
    date: "September 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3235,
    title: "Senior SysOps Engineer",
    company: "Flanks",
    salary: "50K - 55K EUR",
    date: "September 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 3236,
    title: "IT Compliance Manager",
    company: "Flanks",
    salary: "35K - 45K EUR",
    date: "September 2023",
    average_salary: 40,
    place: "Barcelona",
  },
  {
    id: 3237,
    title: "Software Engineer (Python)",
    company: "011h",
    salary: "45K - 45K EUR",
    date: "September 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3238,
    title: "Senior PHP Developer",
    company: "Manychat",
    salary: "70K - 75K EUR",
    date: "September 2023",
    average_salary: 72.5,
    place: "Barcelona",
  },
  {
    id: 3239,
    title: "Founding Software Engineer",
    company: "ZenRows",
    salary: "70K - 115K EUR",
    date: "September 2023",
    average_salary: 92.5,
    place: "Remote",
  },
  {
    id: 3240,
    title: "Senior Data Engineer",
    company: "Alhona",
    salary: "50K - 70K EUR",
    date: "September 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3241,
    title: "Senior Engineer",
    company: "Abzu",
    salary: "60K - 70K EUR",
    date: "September 2023",
    average_salary: 65,
    place: "Barcelona",
  },
  {
    id: 3242,
    title: "PHP developer (Product team)",
    company: "Manychat",
    salary: "55K - 65K EUR",
    date: "September 2023",
    average_salary: 60,
    place: "Barcelona",
  },
  {
    id: 3243,
    title: "Backend Engineer",
    company: "VideoAsk by Typeform",
    salary: "55K - 65K EUR",
    date: "September 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3244,
    title: "Back End Developer",
    company: "Outvio",
    salary: "50K - 72K EUR",
    date: "September 2023",
    average_salary: 61,
    place: "Remote",
  },
  {
    id: 3245,
    title: "React Native Developer",
    company: "GeekSquare",
    salary: "35K - 50K EUR",
    date: "September 2023",
    average_salary: 42.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3246,
    title: "Angular Frontend Developer",
    company: "Parclick",
    salary: "35K - 40K EUR",
    date: "September 2023",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 3247,
    title: "Full Stack Developer (React/PHP)",
    company: "Infonet",
    salary: "35K - 55K EUR",
    date: "September 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3248,
    title: "Python Developer",
    company: "NFQ",
    salary: "50K - 50K EUR",
    date: "September 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3249,
    title: "Mid Backend Developer",
    company: "Woffu",
    salary: "30K - 40K EUR",
    date: "September 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 3250,
    title: "Senior Backend Developer",
    company: "Woffu",
    salary: "40K - 50K EUR",
    date: "September 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3251,
    title: "Software Engineer (Focussed on LLM Integrations)",
    company: "Açai Travel",
    salary: "40K - 60K EUR",
    date: "September 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3252,
    title: "Senior Software Engineer (Building LLM Copilots)",
    company: "Acai Travel",
    salary: "80K - 80K EUR",
    date: "September 2023",
    average_salary: 80,
    place: "Barcelona",
  },
  {
    id: 3253,
    title: "Senior DevOps Engineer",
    company: "Less Than Three",
    salary: "40K - 45K EUR",
    date: "September 2023",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 3254,
    title: "Python Developer",
    company: "Red Points",
    salary: "50K - 55K EUR",
    date: "September 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 3255,
    title: "Java Developer",
    company: "NFQ",
    salary: "50K - 50K EUR",
    date: "September 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3256,
    title: "Senior Python Developer",
    company: "CMRAD (HealthTech)",
    salary: "50K - 60K EUR",
    date: "September 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3257,
    title: "AWS DevOps Engineer",
    company: "GMW",
    salary: "60K - 90K EUR",
    date: "September 2023",
    average_salary: 75,
    place: "Barcelona",
  },
  {
    id: 3258,
    title: "Database Admin",
    company: "APFutura",
    salary: "30K - 40K EUR",
    date: "September 2023",
    average_salary: 35,
    place: "Barcelona/Remote",
  },
  {
    id: 3259,
    title: "Senior Product Mobile Engineer",
    company: "Erictel",
    salary: "45K - 55K EUR",
    date: "September 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3260,
    title: "Junior Backend Developer",
    company: "Nester",
    salary: "25K - 35K EUR",
    date: "September 2023",
    average_salary: 30,
    place: "Barcelona/Remote",
  },
  {
    id: 3261,
    title: "Fully Remote React Developer (PartTime) 2 days p/w",
    company: "Discovered",
    salary: "17K - 19K EUR",
    date: "September 2023",
    average_salary: 18,
    place: "Remote",
  },
  {
    id: 3262,
    title: "Junior DevOps Engineer",
    company: "Rand App",
    salary: "35K - 45K EUR",
    date: "September 2023",
    average_salary: 40,
    place: "Remote",
  },
  {
    id: 3263,
    title: "Backend Developer",
    company: "IZI Record",
    salary: "35K - 50K EUR",
    date: "September 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 3264,
    title: "SysAdmin",
    company: "OpositaTest",
    salary: "25K - 30K EUR",
    date: "September 2023",
    average_salary: 27.5,
    place: "Remote",
  },
  {
    id: 3265,
    title: "Tech Talent Advocate",
    company: "Joppy",
    salary: "24K - 30K EUR",
    date: "September 2023",
    average_salary: 27,
    place: "Barcelona",
  },
  {
    id: 3266,
    title: "FullStack PHP Laravel (Mid/Sr.)",
    company: "Carloapp",
    salary: "35K - 50K EUR",
    date: "September 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 3267,
    title: "Mid/Senior Backend Software Engineer",
    company: "JW Player",
    salary: "60K - 70K EUR",
    date: "September 2023",
    average_salary: 65,
    place: "Remote",
  },
  {
    id: 3268,
    title: "Desarrollador/a full-stack - Senior",
    company: "ARCO Partners",
    salary: "45K - 60K EUR",
    date: "September 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 3269,
    title: "Security Engineer",
    company: "IOMED",
    salary: "45K - 55K EUR",
    date: "September 2023",
    average_salary: 50,
    place: "Barcelona",
  },
  {
    id: 3270,
    title: "Product Designer",
    company: "Knack",
    salary: "30K - 60K EUR",
    date: "September 2023",
    average_salary: 45,
    place: "Remote",
  },
  {
    id: 3271,
    title: "Senior Full Stack Engineer",
    company: "Cosuno",
    salary: "80K - 95K EUR",
    date: "September 2023",
    average_salary: 87.5,
    place: "Remote",
  },
  {
    id: 3272,
    title: "DevOps Engineer",
    company: "TuoTempo",
    salary: "40K - 55K EUR",
    date: "September 2023",
    average_salary: 47.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3273,
    title: "Senior Software Engineer - JavaScript",
    company: "sennder",
    salary: "70K - 85K EUR",
    date: "October 2023",
    average_salary: 77.5,
    place: "Remote",
  },
  {
    id: 3274,
    title: "JavaScript Instructor",
    company: "Codeworks",
    salary: "35K - 50K EUR",
    date: "October 2023",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 3275,
    title: "Senior Python Developer (HealthTech)",
    company: "CMRAD",
    salary: "50K - 60K EUR",
    date: "October 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3276,
    title: "Backend Developer (PHP Symfony)",
    company: "Infonet",
    salary: "35K - 50K EUR",
    date: "October 2023",
    average_salary: 42.5,
    place: "Barcelona",
  },
  {
    id: 3277,
    title: "Salesforce Specialist",
    company: "The Hotels Network",
    salary: "32K - 45K EUR",
    date: "October 2023",
    average_salary: 38.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3278,
    title: "Software Engineer",
    company: "Bankflip",
    salary: "40K - 50K EUR",
    date: "October 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3279,
    title: "React Native Developer",
    company: "NAKIMA",
    salary: "28K - 33K EUR",
    date: "October 2023",
    average_salary: 30.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3280,
    title: "Frontend Engineer (Vue 3)",
    company: "TROOP",
    salary: "40K - 70K EUR",
    date: "October 2023",
    average_salary: 55,
    place: "Remote",
  },
  {
    id: 3281,
    title: "Backend Engineer",
    company: "TROOP",
    salary: "50K - 70K EUR",
    date: "October 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3282,
    title: "Java Developer",
    company: "Mitek Systems",
    salary: "40K - 50K EUR",
    date: "October 2023",
    average_salary: 45,
    place: "Barcelona/Remote",
  },
  {
    id: 3283,
    title: "Senior/Staff Software Engineer Python & ML",
    company: "Emburse",
    salary: "70K - 85K EUR",
    date: "October 2023",
    average_salary: 77.5,
    place: "Barcelona",
  },
  {
    id: 3284,
    title: "Junior Software Engineer .Net",
    company: "Ohpen",
    salary: "25K - 35K EUR",
    date: "October 2023",
    average_salary: 30,
    place: "Barcelona",
  },
  {
    id: 3285,
    title: "Senior Software Engineer .NET",
    company: "Ohpen",
    salary: "45K - 65K EUR",
    date: "October 2023",
    average_salary: 55,
    place: "Barcelona",
  },
  {
    id: 3286,
    title: "Senior DevOPs Engineer",
    company: "Sateliot",
    salary: "50K - 65K EUR",
    date: "October 2023",
    average_salary: 57.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3287,
    title: "Senior Software Engineer",
    company: "Sateliot",
    salary: "45K - 55K EUR",
    date: "October 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3288,
    title: "Senior Backend Engineer - Python",
    company: "sennder",
    salary: "70K - 85K EUR",
    date: "October 2023",
    average_salary: 77.5,
    place: "Barcelona/Remote",
  },
  {
    id: 3289,
    title: "IAM Engineer",
    company: "Adevinta",
    salary: "30K - 45K EUR",
    date: "October 2023",
    average_salary: 37.5,
    place: "Barcelona",
  },
  {
    id: 3290,
    title: "iOS Engineer",
    company: "Adevinta",
    salary: "40K - 50K EUR",
    date: "October 2023",
    average_salary: 45,
    place: "Barcelona",
  },
  {
    id: 3291,
    title: "Backend developer (node)",
    company: "IMA",
    salary: "36K - 50K EUR",
    date: "October 2023",
    average_salary: 43,
    place: "Barcelona",
  },
  {
    id: 3292,
    title: "Senior iOS Engineer",
    company: "237 Global",
    salary: "55K - 65K EUR",
    date: "October 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3293,
    title: "Senior Platform Engineer",
    company: "Cable",
    salary: "90K - 110K GBP",
    date: "October 2023",
    average_salary: 100,
    place: "Remote",
  },
  {
    id: 3294,
    title: "Full Stack Engineer (Go)",
    company: "LambdaLooperss",
    salary: "41K - 51K EUR",
    date: "October 2023",
    average_salary: 46,
    place: "Remote",
  },
  {
    id: 3295,
    title: "Senior Backend Developer",
    company: "seQura",
    salary: "55K - 75K EUR",
    date: "October 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3296,
    title: "Product developer with SRE skills (30H / Week)",
    company: "Qatium",
    salary: "45K - 50K EUR",
    date: "October 2023",
    average_salary: 47.5,
    place: "Remote",
  },
  {
    id: 3297,
    title: "Software developer",
    company: "Damecode",
    salary: "30K - 45K EUR",
    date: "October 2023",
    average_salary: 37.5,
    place: "Remote",
  },
  {
    id: 3298,
    title: "Python Engineer",
    company: "CMRAD (HealthTech)",
    salary: "45K - 60K EUR",
    date: "October 2023",
    average_salary: 52.5,
    place: "Barcelona",
  },
  {
    id: 3299,
    title: "Machine Learning",
    company: "Tether",
    salary: "30K - 35K EUR",
    date: "October 2023",
    average_salary: 32.5,
    place: "Barcelona",
  },
  {
    id: 3300,
    title: "Cloud Infraestructure Engineer",
    company: "Kantox",
    salary: "45K - 65K EUR",
    date: "October 2023",
    average_salary: 55,
    place: "Barcelona/Remote",
  },
  {
    id: 3301,
    title: "Microsoft 365 Developer",
    company: "isolutions",
    salary: "65K - 65K EUR",
    date: "October 2023",
    average_salary: 65,
    place: "Barcelona/Remote",
  },
  {
    id: 3302,
    title: "Software Engineer",
    company: "FCM Lab",
    salary: "40K - 60K EUR",
    date: "October 2023",
    average_salary: 50,
    place: "Barcelona/Remote",
  },
  {
    id: 3303,
    title: "Product Engineer (Frontend Software Dev)",
    company: "Alhona",
    salary: "40K - 60K EUR",
    date: "October 2023",
    average_salary: 50,
    place: "Remote",
  },
  {
    id: 3304,
    title: "C++ Software Engineer",
    company: "Mitek Systems",
    salary: "35K - 50K EUR",
    date: "October 2023",
    average_salary: 42.5,
    place: "Remote",
  },
  {
    id: 3305,
    title: "Sr iOS developer",
    company: "Ellu Company",
    salary: "55K - 65K EUR",
    date: "October 2023",
    average_salary: 60,
    place: "Remote",
  },
  {
    id: 3306,
    title: "Senior Rails Developer",
    company: "Relay Commerce",
    salary: "85K - 95K USD",
    date: "October 2023",
    average_salary: 90,
    place: "Remote",
  },
];

export default salaries;
