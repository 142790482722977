import HistogramBySalaries from "../HistogramBySalaries";
import computeAverageSalary from "../utils/regexSalary";
import "./Experience.css";
import Navbar from "./NavBar";

const Experience = () => {
  const dataExperience = [
    { profession: "Junior", salary: computeAverageSalary(["junior"]) },
    { profession: "Mid", salary: computeAverageSalary(["mid"]) },
    { profession: "Senior", salary: computeAverageSalary(["senior"]) },
    { profession: "Staff", salary: computeAverageSalary(["staff"]) },
    {
      profession: "Principal / Architect",
      salary: computeAverageSalary(["principal", "architect"]),
    },
    {
      profession: "Tech Lead",
      salary: computeAverageSalary(["tech lead"]),
    },
    {
      profession: "Project Manager",
      salary: computeAverageSalary(["product manager"]),
    },
    {
      profession: "Engineering Manager",
      salary: computeAverageSalary(["engineering manager"]),
    },
  ];

  return (
    <>
      <Navbar></Navbar>
      <h3 className="title">Barcelona Salaries By Title Experience</h3>
      <HistogramBySalaries data={dataExperience}></HistogramBySalaries>
    </>
  );
};

export default Experience;
