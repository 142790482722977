import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";

const HistogramBySalaries = ({ data }) => {
  const [windowWidth] = useState(window.innerWidth);
  const [windowHeight] = useState(window.innerHeight);

  const margin = { top: 0, right: 100, bottom: 70, left: 100 };
  const width = windowWidth - margin.left - margin.right;
  const height = windowHeight - margin.top - margin.bottom - 200;

  const d3Container = useRef(null);

  useEffect(() => {
    const svg = d3
      .select(d3Container.current)
      .attr("width", windowWidth)
      .attr("height", windowHeight)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Define scales
    const x = d3.scaleBand().range([0, width]).padding(0.2);
    const y = d3.scaleLinear().range([height, 0]);

    x.domain(data.map((d) => d.profession));
    y.domain([0, Math.max(...data.map((d) => d.salary + 20))]);

    // Draw bars
    svg
      .call(
        d3.axisLeft(y).tickFormat(function (d) {
          return d + "K";
        })
      )
      .selectAll(".tick text") // Selects all text elements within ticks
      .style("font-size", "18px");

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.profession))
      .attr("width", x.bandwidth())
      .attr("y", (d) => y(d.salary))
      .attr("height", (d) => height - y(d.salary))
      .style("fill", "#69b3a2")
      .style("font-size", "16px");

    // Add axes
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .selectAll(".tick text") // Selects all text elements within ticks
      .style("font-size", "18px");
  });
  return <svg ref={d3Container} />;
};
export default HistogramBySalaries;
